import { createSelector } from 'reselect';
import { reportDataSelector, getTableData } from '../reportDataSelectors';
import { get } from 'lodash';
import { reportFilterSelector } from '../../report-path/reportPathSelectors';

export const reportDonutSelector = createSelector(
    [reportDataSelector, reportFilterSelector],
    (report = {}, filter) => {
        const { data: reportData } = report;
        const result = {
            data: [],
            dataKey: null,
            nameKey: null,
            legend: {
                show: false,
            },
            label: {
                showValues: false,
                showPercentages: false,
            },
            total: {
                show: false,
            },
        };
        const chart = get(reportData, 'reportMetadata.chart');
        if (!reportData || !chart) {
            return result;
        }
        const {
            groupings,
            // chartType,
            // hasLegend,
            legendPosition,
            showChartValues,
            showChartPercentages,
            showChartTotal,
            summaries,
            // summaryAxisLocations,
            // summaryColors,
            // summaryVisualizationTypes,
        } = chart;

        let dataKey = null;
        let nameKey = null;
        let showLegend = false;

        if (summaries.length) {
            dataKey = summaries[0];
        }

        if (groupings.length) {
            nameKey = groupings[0];
        }
        const { rows, columns, grandTotal } = getTableData({
            report,
            filter,
            showGrandTotalIfAvailable: true,
        });

        showLegend = rows.length > 0;
        let legendLayout =
            legendPosition === 'Right' ? 'vertical' : 'horizontal';
        let legendPositionX = legendPosition === 'Right' ? 'right' : 'center';
        let legendPositionY = legendPosition === 'Right' ? 'middle' : 'bottom';
        const legendColumn = columns.find(
            column => column.accessor === nameKey,
        );
        let legendTitle = legendColumn && legendColumn.label;

        result.data = rows;
        result.dataKey = dataKey;
        result.nameKey = nameKey;
        result.legend = {
            show: showLegend,
            title: legendTitle,
            layout: legendLayout,
            positionX: legendPositionX,
            positionY: legendPositionY,
        };
        result.label = {
            showValues: showChartValues,
            showPercentages: showChartPercentages,
        };
        result.total = {
            show: showChartTotal,
            value: grandTotal[dataKey],
        };
        return result;
    },
);

export default reportDonutSelector;
