import React, { Component } from 'react';
import {
    Bar,
    BarChart,
    ResponsiveContainer,
    CartesianGrid,
    Legend,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import PropTypes from 'prop-types';
import EmptyReport from '../empty';
import getCurrencyFormatter from '../CurrencyFormatter';

// Vertical Bar (Column)
//
// X axis = any of groupings = X category, max 2
//
// Y axis = any of aggregates(summaries) = vertical bars, max 1, shown on legend with title and values
// Reference line on Y axis
//
// Measures = additional charts , max 3?
// On the first measure is avalable option "plot as a line chart",
// If this option is enabled, it draws single line, other measures can't be added, but option "plot on second axis" is available
// If option "Plot on Second Axis is enabled", the second Y axis will be show on the right
//
// If plot as line is disabled, draw vertical bar per each measure,
// Always shown on legend as measures with value equal to column name
// Show reference line is always available, draws line on Y axis
export class ColumnChartType extends Component {
    static propTypes = {
        data: PropTypes.array,
        columns: PropTypes.array,
        groupings: PropTypes.array,
        summaries: PropTypes.array,
        showLegend: PropTypes.bool,
        width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        colorPalette: PropTypes.arrayOf(PropTypes.string),
        animated: PropTypes.bool,
    };

    static defaultProps = {
        showLegend: false,
        data: [],
        width: '100%',
        height: 375,
        groupings: [],
        summaries: [],
        colorPalette: ['#00B06D', '#8FCC22', '#F7D108'],
        animated: false,
    };

    render() {
        const {
            width,
            height,
            data,
            showLegend,
            columns,
            groupings,
            summaries,
            colorPalette,
            animated,
        } = this.props;

        const categories = groupings.filter(grouping =>
            columns.find(column => column.accessor === grouping),
        );

        if (!data.length || !categories.length || !summaries.length) {
            return <EmptyReport />;
        }

        let currency = false;
        if (summaries.length) {
            const firstCol = columns.find(
                column => column.accessor === summaries[0],
            );
            if (firstCol && firstCol.dataType === 'currency') {
                currency = '$';
            }
        }

        return (
            <ResponsiveContainer width={width} height={height}>
                <BarChart data={data}>
                    <CartesianGrid horizontal={true} vertical={false} />
                    <XAxis type='category' dataKey={categories[0]} hide />
                    <YAxis type='number' />
                    <Tooltip formatter={getCurrencyFormatter(currency)} />
                    {showLegend && <Legend />}
                    {summaries.map((summary, index) => {
                        const column = columns.find(
                            column => column.accessor === summary,
                        );
                        return (
                            <Bar
                                key={`bar_${index}`}
                                dataKey={summary}
                                name={column ? column.label : null}
                                fill={colorPalette[index % colorPalette.length]}
                                isAnimationActive={animated}
                            />
                        );
                    })}
                </BarChart>
            </ResponsiveContainer>
        );
    }
}

export default ColumnChartType;
