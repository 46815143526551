import React from 'react';
import PropTypes from 'prop-types';

/**
 * Draws svg arrow
 * @param {Object} props
 * @param {number} props.cx - X coordinate of the center
 * @param {number} props.cy - Y coordinate of the center
 * @param {number} props.midAngle - Middle of the arrow in angles
 * @param {number} innerRadius - Inner radius of gauge
 */
export const Arrow = ({ cx, cy, midAngle, innerRadius }) => {
    const RADIAN = Math.PI / 180;
    /**
     * Y is inverted
     */
    const Y_RADIAN = -RADIAN;
    /**
     * Raduis of arrow
     */
    const radius = innerRadius * 0.8;
    /**
     * X point of arrow's end
     */
    const mx = cx + radius * Math.cos(RADIAN * midAngle);
    /**
     * Y point of arrow's end
     * Y axis is inverted, so -RADIAN
     */
    const my = cy + radius * Math.sin(Y_RADIAN * midAngle);
    /**
     * Radius of arrows circle
     */
    const r = innerRadius * 0.05;
    /**
     * Angle of left point on the inner circle
     */
    const leftAngle = midAngle + 90;
    /**
     * Angle of right point on the inner circle
     */
    const rightAngle = midAngle - 90;
    const lx = cx + r * Math.cos(RADIAN * leftAngle);
    const ly = cy + r * Math.sin(Y_RADIAN * leftAngle);
    const rx = cx + r * Math.cos(RADIAN * rightAngle);
    const ry = cy + r * Math.sin(Y_RADIAN * rightAngle);
    return (
        <g>
            <circle
                cx={cx}
                cy={cy}
                fill='#00B06D'
                stroke='#00B06D'
                strokeWidth='1px'
                r={r}
            />
            <polygon
                stroke='#00B06D'
                fill='#00B06D'
                strokeWidth='1px'
                strokeLinecap='round'
                points={`${lx} ${ly} ${mx} ${my} ${rx} ${ry}`}
            />
        </g>
    );
};

Arrow.propTypes = {
    cx: PropTypes.number,
    cy: PropTypes.number,
    midAngle: PropTypes.number,
    outerRadius: PropTypes.number,
    innerRadius: PropTypes.number,
};

export default Arrow;
