import * as types from './contactActionTypes';

export const timeZoneUpdate = (timeZone, contactId) => ({
    type: types.CONTACT_TIMEZONE_REQUEST,
    contactId: contactId,
    timeZone: timeZone,
});

export const lockContact = ({ contactId }) => ({
    type: types.CONTACT_LOCK_REQUEST,
    payload: {
        contactId,
    },
});

export const unlockContact = ({ contactId }) => ({
    type: types.CONTACT_UNLOCK_REQUEST,
    payload: {
        contactId,
    },
});
