import { takeLatest, select, put, call } from 'redux-saga/effects';

import getSelectors from './selectors';
import getActions from './actions';
import { getPrefixedActionType, types } from './actionTypes';

export const getSaga = (prefix, fetchCall) => {
    if (typeof fetchCall !== 'function') {
        throw new Error('Fetch call must be a function');
    }
    const selectors = getSelectors(prefix);
    const actions = getActions(prefix);
    const fetchList = function*() {
        try {
            const skip = yield select(selectors.skipSelector);
            const limit = yield select(selectors.pageSizeSelector);
            const filter = yield select(selectors.filterSelector);
            const sort = yield select(selectors.sortSelector);
            const params = {
                skip,
                limit,
                filter,
                sort,
            };
            const result = yield call(fetchCall, params);
            const { total = 0, data = [] } = result;
            yield put(
                actions.success({
                    data,
                    total,
                }),
            );
        } catch (error) {
            yield put(
                actions.error({
                    errorMessage: error.message,
                }),
            );
        }
    };

    const rootSaga = function*() {
        yield takeLatest(
            getPrefixedActionType(prefix, types.REQUEST),
            fetchList,
        );
    };
    return rootSaga;
};

export default getSaga;
