import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Image } from 'semantic-ui-react';
import get from 'lodash/get';

export class AccountUser extends Component {
    static propTypes = { row: PropTypes.object };
    render() {
        const avatarUrl = get(
            this.props,
            'row.assignee.user.tflRampAvatarUrlC',
        );
        const name = get(this.props, 'row.assignee.name');
        const avatar = avatarUrl ? <Image src={avatarUrl} avatar /> : null;
        return (
            <div>
                {avatar}
                <span>{name}</span>
            </div>
        );
    }
}
export default AccountUser;
