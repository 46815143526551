import React, { Component } from 'react';
import { CaseModal } from './CaseModal';
import PropTypes from 'prop-types';
import CaseNumber from './CaseNumber';
import { connect } from 'react-redux';
import * as caseActions from '../../../store/case/caseActions';

export class CaseEvent extends Component {
    state = {
        open: false,
    };
    static propTypes = {
        patientRExternalIdC: PropTypes.string,
        caseNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    };

    handleSelectPatient = () => {
        this.setState({ open: false });
    };

    handleAssignToMe = () => {
        this.props.assignToMe(this.props.row.id, this.props.contact.id);
        this.setState({ open: false });
    };

    handleOpen = () => {
        this.setState({ open: true });
    };
    handleClose = () => {
        this.setState({ open: false });
    };

    render() {
        return (
            <React.Fragment>
                <CaseModal
                    patientId={
                        this.props.row.patient
                            ? this.props.row.patient.id
                            : null
                    }
                    open={this.state.open}
                    problem={this.props.row.subject}
                    handleAssignToMe={this.handleAssignToMe}
                    handleModalClose={this.handleClose}
                />
                <CaseNumber
                    caseNumber={this.props.row.caseNumber}
                    handleClick={this.handleOpen}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state = {}) => {
    return {
        contact: state.auth.contact,
        assigning: state.cases.assigning,
    };
};

const mapDispatchToProps = dispatch => ({
    assignToMe: (caseId, assigneeId) =>
        dispatch(caseActions.assignToMe(caseId, assigneeId)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CaseEvent);
