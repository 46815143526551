import { put, select, takeLatest } from 'redux-saga/effects';
import { getOrderList, getStatusCount } from '../../api/orderStatusAPI';
import * as actions from './orderStatusActions';
import * as types from './orderStatusActionTypes';
import * as selectors from './orderStatusSelectors';

export function* fetchList() {
    try {
        const skip = yield select(selectors.getOrderStatusSkipItems);
        const limit = yield select(selectors.getOrderStatusPageSize);
        const filter = yield select(
            selectors.patientOrderStatusFiltersSelector,
        );
        const sort = yield select(selectors.patientOrderStatusSort);
        const params = {
            skip,
            limit,
            filter,
            sort,
        };
        const result = yield getOrderList(params);
        const { total = 0, data = [] } = result;
        yield put(
            actions.patientOrderStatusSuccess({
                data,
                total,
            }),
        );
    } catch (error) {
        yield put(actions.patientOrderStatusError(error.message));
    }
}

export function* requestSaga() {
    yield put(actions.patientOrderStatusRequest());
}

export function* fetchCountSaga() {
    try {
        const filter = yield select(
            selectors.patientOrderStatusFiltersSelector,
        );
        const params = {
            filter,
        };
        const statusCount = yield getStatusCount(params);
        yield put(
            actions.patientOrderStatusCountSuccess({
                statusCount,
            }),
        );
    } catch (error) {
        yield put(actions.patientOrderStatusCountError(error.message));
    }
}

export function* countStatusSaga() {
    yield put(actions.patientOrderStatusCountRequest());
}

export function* rootSaga() {
    yield takeLatest(types.PATIENT_ORDER_STATUS_REQUEST, fetchList);

    yield takeLatest(types.PATIENT_ORDER_STATUS_COUNT_REQUEST, fetchCountSaga);

    yield takeLatest(types.PATIENT_ORDER_STATUS_FILTER, requestSaga);
    yield takeLatest(types.PATIENT_ORDER_STATUS_FILTER, countStatusSaga);
    yield takeLatest(types.PATIENT_ORDER_STATUS_FILTER_RESET, requestSaga);
    yield takeLatest(types.PATIENT_ORDER_STATUS_FILTER_RESET, countStatusSaga);

    yield takeLatest(types.PATIENT_ORDER_STATUS_SORT, requestSaga);

    yield takeLatest(types.PATIENT_ORDER_STATUS_GO_TO_NEXT_PAGE, requestSaga);
    yield takeLatest(types.PATIENT_ORDER_STATUS_GO_TO_PREV_PAGE, requestSaga);
    yield takeLatest(types.PATIENT_ORDER_STATUS_GO_TO_FIRST_PAGE, requestSaga);
    yield takeLatest(types.PATIENT_ORDER_STATUS_GO_TO_LAST_PAGE, requestSaga);

    yield takeLatest(types.SET_PAGE_SIZE, requestSaga);
}

export default rootSaga;
