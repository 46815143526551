import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Divider, Grid, Header, Modal } from 'semantic-ui-react';
import ReactCollapsingTable from 'react-collapsing-table';
import { CLINICAL_ASSESSMENT_COLUMNS } from '../../../.././store/constants/table';
import { FILL_ICONS } from '../../../.././store/constants/tableIcons'; //import EventDate from '../EventDate';
/**/ import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { resolveAssessment } from '../../../../store/patient/patientActions';
import { activeCaseSelector } from '../../../../store/patient/patientSelectors';
export class ClinicalModal extends Component {
    static propTypes = {
        handleClose: PropTypes.func,
        open: PropTypes.bool,
    };

    handleResolve = () => {
        this.props.resolveAssessment(this.props.event.id);
        this.props.handleClose();
    };

    render() {
        const { hasActiveCase } = this.props;
        return (
            <Modal
                size='tiny'
                open={this.props.open}
                closeOnDimmerClick={false}
                closeOnDocumentClick={false}
            >
                <Modal.Header>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column
                                className='event-modal__header'
                                width={16}
                            >
                                <Header as='h2'>Clinical Assessment</Header>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Modal.Header>

                <Modal.Content scrolling>
                    <div className='flexDiv justifyContent'>
                        <Header as='h3'>Survey Results</Header>
                        <Header as='h3'>
                            {this.props.event
                                ? this.props.event.assessmentNameC
                                : ''}
                        </Header>
                    </div>
                    <Divider />
                    <Modal.Description>
                        <ReactCollapsingTable
                            rows={
                                this.props.event
                                    ? this.props.event.responses
                                    : []
                            }
                            columns={CLINICAL_ASSESSMENT_COLUMNS}
                            icons={FILL_ICONS}
                        />
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions border='true'>
                    <Grid columns={2} className='marginV0'>
                        <Grid.Column>
                            <Button
                                primary={hasActiveCase}
                                disabled={!hasActiveCase}
                                fluid
                                onClick={this.handleResolve}
                            >
                                Resolve
                            </Button>
                        </Grid.Column>
                        <Grid.Column>
                            <Button
                                secondary
                                fluid
                                onClick={this.props.handleClose}
                            >
                                Close
                            </Button>
                        </Grid.Column>
                    </Grid>
                </Modal.Actions>
            </Modal>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    hasActiveCase: ownProps.event
        ? !!activeCaseSelector(state, { assessmentId: ownProps.event.id })
        : false,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            resolveAssessment,
        },
        dispatch,
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ClinicalModal);
