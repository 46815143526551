import React, { Component } from 'react';
import {
    Bar,
    BarChart,
    ResponsiveContainer,
    CartesianGrid,
    Legend,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import PropTypes from 'prop-types';
import EmptyReport from '../empty';
import getCurrencyFormatter from '../CurrencyFormatter';

// Horizontal Bar (Bar)
// Y axis = any of groupings, max 2
// X axis = any of aggregates(summaries) = measure, max 1
// Reference line on X axis
//
// Measure = any of aggregates = bars, max 3. shown as measures on legend, with values equal to column names
// Reference line for each of x axis is available on X axis
//
// Show values = show labels
//
// If there is no additional measures, legend is hidden
// Legend position is available if legend is shown

export class BarChartType extends Component {
    static propTypes = {
        data: PropTypes.array,
        columns: PropTypes.array,
        groupings: PropTypes.array,
        summaries: PropTypes.array,
        showLegend: PropTypes.bool,
        width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        colorPalette: PropTypes.arrayOf(PropTypes.string),
        animated: PropTypes.bool,
    };

    static defaultProps = {
        showLegend: false,
        data: [],
        width: '100%',
        height: 375,
        groupings: [],
        summaries: [],
        colorPalette: ['#00B06D', '#8FCC22', '#F7D108'],
        animated: false,
    };

    render() {
        const {
            width,
            height,
            data,
            showLegend,
            columns,
            groupings,
            summaries,
            colorPalette,
            animated,
        } = this.props;
        if (!data.length || !groupings.length || !summaries.length) {
            return <EmptyReport />;
        }

        let currency = false;
        if (summaries.length) {
            const firstCol = columns.find(
                column => column.accessor === summaries[0],
            );
            if (firstCol && firstCol.dataType === 'currency') {
                currency = '$';
            }
        }

        return (
            <ResponsiveContainer width={width} height={height}>
                <BarChart data={data} layout='vertical'>
                    <CartesianGrid horizontal={false} vertical={true} />
                    <XAxis type='number' />
                    <YAxis type='category' dataKey={groupings[0]} />
                    <Tooltip
                        dataKey={groupings[0]}
                        formatter={getCurrencyFormatter(currency)}
                    />
                    {showLegend && <Legend />}
                    {summaries.map((summary, index) => {
                        const column = columns.find(
                            column => column.accessor === summary,
                        );
                        return (
                            <Bar
                                key={`bar_${index}`}
                                dataKey={summary}
                                fill={colorPalette[index % colorPalette.length]}
                                name={column ? column.label : null}
                                isAnimationActive={animated}
                            />
                        );
                    })}
                </BarChart>
            </ResponsiveContainer>
        );
    }
}

export default BarChartType;
