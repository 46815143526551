import { createSelector } from 'reselect';
import sortBy from 'lodash/sortBy';

export const agentIsOnlineSelector = state => state.chat.agentIsOnline;

export const allMessagesSelector = state => state.chat.messages;

export const chatMessagesSelector = createSelector(
    allMessagesSelector,
    messages => sortBy(messages, 'date'),
);

export const chatStartedSelector = state => state.chat.started;

export const waitingForAgentToAcceptSelector = state =>
    state.chat.waitingForAgentToAccept;
