import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Feed, Grid, Dimmer, Loader } from 'semantic-ui-react';
import * as patientActions from '../../../store/patient/patientActions';
import PatientTimelineEvent from './PatientTimelineEvent';

export class PatientTimeline extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
        };
    }

    componentDidMount() {
        if (this.props.patient && this.props.patient.memberPlan) {
            this.props.getPatientTimeline(this.props.patient.memberPlan.id);
        }
    }
    /**
     * THIS IS A SORTED FEED
     * ---
     * YOU CAN REARANGE THE FEEDS TO YOUR DESIRED LIST
     * @param {Array} fill
     * @param {Array} assessment
     * @param {Array} copay
     */
    sortedFeed(fill, assessment, copay) {
        return (
            <Grid className='patient-timeline margin0 grow' stretched>
                <Feed>
                    {fill &&
                        fill.length &&
                        fill.map(timeLineEvent => {
                            return (
                                <PatientTimelineEvent
                                    key={timeLineEvent.key}
                                    event={timeLineEvent}
                                />
                            );
                        })}
                    {copay &&
                        copay.length &&
                        copay.map(timeLineEvent => {
                            return (
                                <PatientTimelineEvent
                                    key={timeLineEvent.key}
                                    event={timeLineEvent}
                                />
                            );
                        })}
                    {assessment &&
                        assessment.length &&
                        assessment.map(timeLineEvent => {
                            return (
                                <PatientTimelineEvent
                                    key={timeLineEvent.key}
                                    event={timeLineEvent}
                                />
                            );
                        })}
                </Feed>
            </Grid>
        );
    }
    /**
     * @param {Array} list
     */
    sortTimeLine(list) {
        try {
            if (list && list.length)
                return list.sort(
                    (a, b) => b.claims[0].shipDateC - a.claims[0].shipDateC,
                );
            else return list;
        } catch (error) {
            console.log(error);
        }
    }
    filter() {
        try {
            const { timeLine } = this.props;
            if (timeLine && timeLine.length) {
                const fill = this.sortTimeLine(
                    timeLine.filter(item => item.type === 'fill'),
                );
                const copay = this.sortTimeLine(
                    timeLine.filter(item => item.type === 'copay'),
                );
                const assessment = timeLine.filter(
                    item => item.type === 'assessment',
                );
                return { fill, assessment, copay };
            } else return { fill: [], assessment: [], copay: [] };
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        // const { fill, assessment, copay } = this.filter()
        let timeLine = this.props.loading ? (
            <Dimmer active inverted>
                <Loader size='large'>Loading</Loader>
            </Dimmer>
        ) : (
            <Grid className='patient-timeline margin0 grow' stretched>
                <Feed>
                    {this.props.timeLine.map(timeLineEvent => {
                        console.log(timeLineEvent);
                        return (
                            <PatientTimelineEvent
                                key={timeLineEvent.key}
                                event={timeLineEvent}
                            />
                        );
                    })}
                </Feed>
            </Grid>
        );

        return <div>{timeLine}</div>;
    }
}

const mapDispatchToProps = dispatch => ({
    getPatientTimeline: patientId => {
        return dispatch(patientActions.getPatientTimeline(patientId));
    },
});

const mapStateToProps = (state = {}) => {
    return {
        account: state.auth.account,
        loading: state.patient.loading,
        timeLine: state.patient.timeLine,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PatientTimeline);
