import * as types from './authActionTypes';
import * as auth from '../../helpers/auth.helpers';

export default (
    state = {
        isAuthenticated: auth.isAuthenticated(),
        currentRole: auth.role() ? JSON.parse(auth.role()) : {},
        profile: auth.profile() ? JSON.parse(auth.profile()) : {},
        account: auth.account() ? JSON.parse(auth.account()) : {},
        contact: auth.contact() ? JSON.parse(auth.contact()) : {},
        user: auth.user() ? JSON.parse(auth.user()) : {},
        error: null,
        loading: false,
    },
    action,
) => {
    switch (action.type) {
        case types.LOGIN_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case types.LOGIN_ERROR:
            return {
                ...state,
                error: action.error,
            };
        case types.LOGIN_SUCCESS:
            auth.setProfile(action.profile.profile);
            auth.setAccount(action.profile.account);
            auth.setContact(action.profile.contact);
            auth.setSession(action.profile.access_token);
            auth.setUser(action.profile.user);
            auth.setRole(action.profile.role);
            return {
                ...state,
                currentRole: action.profile.role,
                isAuthenticated: true,
                profile: action.profile.profile,
                account: action.profile.account,
                contact: action.profile.contact,
                user: action.profile.user,
            };
        case types.LOGOUT_SUCCESS:
            auth.removeSession();
            return {
                ...state,
                isAuthenticated: false,
                profile: {},
                account: {},
                user: {},
                contact: {},
                currentRole: '',
                error: action.error,
            };
        case types.PASSWORD_RESET_REQUEST:
            return {
                ...state,
                error: null,
            };
        case types.PASSWORD_RESET_SUCCESS:
            return {
                ...state,
            };
        case types.PASSWORD_RESET_ERROR:
            return {
                ...state,
                error: action.error,
            };
        case types.UPDATE_CONTACT: {
            const account = state.account;
            const contacts = account.list;
            const contactIndex = contacts.findIndex(
                contact => contact.id === action.payload.contact.id,
            );
            if (contactIndex === -1) {
                return state;
            }
            const newContact = {
                ...account.list[contactIndex],
                ...action.payload.contact,
            };
            return {
                ...state,
                account: {
                    ...state.account,
                    list: [
                        ...state.account.list.slice(0, contactIndex),
                        newContact,
                        ...state.account.list.slice(contactIndex + 1),
                    ],
                },
            };
        }
        case types.UPDATE_USER_SUCCESS: {
            auth.setUser(action.user);
            return {
                ...state,
                user: action.user,
            };
        }
        default:
            return state;
    }
};
