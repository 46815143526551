import React from 'react';
import PropTypes from 'prop-types';
import withReportBar from '../../../common/report/withReportBar';
import BarChartType from '../../../common/charts/bar';

export const BarChart = withReportBar(props => <BarChartType {...props} />);

BarChart.propTypes = {
    sfid: PropTypes.string.isRequired,
    defaultDateFilterC: PropTypes.string,
};

export default BarChart;
