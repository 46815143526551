import * as types from './orderStatusActionTypes';
import * as selectors from './orderStatusSelectors';
import { PATIENT_ORDER_STATUS_COLUMNS } from '../constants/table';

export const PATIENT_ORDER_STATUS_FIRST_PAGE = 1;

const defaultSort = {
    column: PATIENT_ORDER_STATUS_COLUMNS[0].accessor,
    direction: 'ascending',
};

export default (
    state = {
        total: 0,
        error: null,
        loading: false,
        data: [],
        filters: {},
        page: PATIENT_ORDER_STATUS_FIRST_PAGE,
        pageSize: 10,
        sort: defaultSort,
        statusCount: {},
    },
    action,
) => {
    switch (action.type) {
        case types.PATIENT_ORDER_STATUS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case types.PATIENT_ORDER_STATUS_SUCCESS: {
            const { total = 0, data = [] } = action;
            return {
                ...state,
                loading: false,
                data: Array.isArray(data) ? data : [],
                total,
            };
        }
        case types.PATIENT_ORDER_STATUS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case types.PATIENT_ORDER_STATUS_COUNT_SUCCESS: {
            const { statusCount = {} } = action;
            return {
                ...state,
                statusCount,
            };
        }
        case types.PATIENT_ORDER_STATUS_FILTER: {
            const { value, name, like, date, dateFormat } = action.payload;
            if (!name) {
                return state;
            }
            const newFilter = {
                value,
                like,
                date,
                dateFormat,
            };
            const filters = {
                ...state.filters,
                [name]: newFilter,
            };
            return {
                ...state,
                filters,
                page: 1,
            };
        }
        case types.PATIENT_ORDER_STATUS_FILTER_RESET: {
            return {
                ...state,
                filters: {},
                page: 1,
            };
        }
        case types.PATIENT_ORDER_STATUS_GO_TO_PREV_PAGE: {
            const page = selectors.getOrderStatusCurrentPage({
                orderStatus: state,
            });
            return {
                ...state,
                page: page > PATIENT_ORDER_STATUS_FIRST_PAGE ? page - 1 : page,
            };
        }
        case types.PATIENT_ORDER_STATUS_GO_TO_NEXT_PAGE: {
            const page = selectors.getOrderStatusCurrentPage({
                orderStatus: state,
            });
            const maxPage = selectors.getOrderStatusLastPage({
                orderStatus: state,
            });
            return {
                ...state,
                page: page < maxPage ? page + 1 : page,
            };
        }
        case types.PATIENT_ORDER_STATUS_GO_TO_FIRST_PAGE: {
            return {
                ...state,
                page: PATIENT_ORDER_STATUS_FIRST_PAGE,
            };
        }
        case types.PATIENT_ORDER_STATUS_GO_TO_LAST_PAGE: {
            const lastPage = selectors.getOrderStatusLastPage({
                orderStatus: state,
            });
            return {
                ...state,
                page: lastPage,
            };
        }
        case types.PATIENT_ORDER_STATUS_SORT: {
            return {
                ...state,
                sort: {
                    ...state.sort,
                    ...action.sort,
                },
            };
        }
        case types.SET_PAGE_SIZE: {
            const newPageSize = Math.round(+action.payload.pageSize);
            if (isNaN(newPageSize)) {
                return state;
            }
            if (newPageSize === state.pageSize) {
                return state;
            }
            return {
                ...state,
                pageSize: newPageSize,
                loaded: state.page === 1 && newPageSize < state.pageSize,
            };
        }
        default:
            return state;
    }
};
