import React, { PureComponent } from 'react';
import { Accordion } from 'semantic-ui-react';
import ReportFilterGroup from './ReportFilterGroup';

export class ReportFilter extends PureComponent {
    state = { activeIndex: 0 };

    open = group => {
        const { activeIndex } = this.state;
        const newIndex = activeIndex === group ? -1 : group;

        this.setState({ activeIndex: newIndex });
    };

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
        };
    }

    render() {
        const { activeIndex } = this.state;
        return (
            <Accordion className='largeIcon' styled>
                <ReportFilterGroup
                    key={`report_filter_fmgroup`}
                    group='Financial Reports'
                    active={activeIndex === 0}
                    toggle={() => this.open(0)}
                />
                <ReportFilterGroup
                    key={`report_filter_crgroup`}
                    group='Clinical Reports'
                    active={activeIndex === 1}
                    toggle={() => this.open(1)}
                />
                <ReportFilterGroup
                    key={`report_filter_ingroup`}
                    group='Initiatives and Performance'
                    active={activeIndex === 2}
                    toggle={() => this.open(2)}
                />
            </Accordion>
        );
    }
}

export default ReportFilter;
