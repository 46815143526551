import moment from 'moment';
import api from './api';

/**
 *
 * @param {Object} datesRange
 * @param {?Date} datesRange.start
 * @param {?Date} datesRange.end
 */
export const downloadData = ({ start, end }) =>
    api.get('/ClaimCs/downloadPatientData', {
        params: {
            start: start ? moment(start).format('YYYY-MM-DD') : '',
            end: end ? moment(end).format('YYYY-MM-DD') : '',
        },
        responseType: 'blob',
    });
