import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Header } from 'semantic-ui-react';
import queryString from 'query-string';
import NavMenu from '../../common/NavMenu';

import * as orderStatusActions from '../../../store/order-status/orderStatusActions';
import PropTypes from 'prop-types';
import PatientOrderSearch from './PatientOrderSearch';
import PatientOrderStatusList from './PatientOrderStatusList';
import PatientOrderStatusPagination from './PatientOrderStatusPagination';
import { paths } from '../../../routes';
import { push } from 'connected-react-router';

export class PatientOrderStatus extends Component {
    static propTypes = {
        getOrderStatusList: PropTypes.func.isRequired,
        getOrderStatusCount: PropTypes.func.isRequired,
    };

    componentDidMount() {
        let filters = { 'referral.statusC': 'Open Orders' };
        if (this.props.location.search) {
            filters = queryString.parse(this.props.location.search);
        }
        if (Object.keys(filters).length) {
            for (const key in filters) {
                const filter = {
                    field: key,
                    value: filters[key],
                    like: false,
                };
                this.props.filterOrderStatusList(filter);
            }
            this.props.dispatch(push(paths.PATIENT_ORDER_STATUS));
        } else {
            this.props.getOrderStatusList();
            this.props.getOrderStatusCount();
        }
    }

    render() {
        return (
            <React.Fragment>
                <Grid className='full-page' stretched>
                    <Grid.Row className='full-page'>
                        <NavMenu />
                        <Grid.Column className='stretch-col'>
                            <Grid className='shrink marginV0' stackable>
                                <Grid.Row
                                    columns={2}
                                    className='pageHeader'
                                    verticalAlign='middle'
                                >
                                    <Grid.Column computer={11} tablet={10}>
                                        <Header as='h1'>Order Status</Header>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row className='paddingV0 shrink'>
                                    <Grid.Column width={16}>
                                        <Grid.Row>
                                            <PatientOrderSearch />
                                        </Grid.Row>
                                        <Grid.Row className='paddingV0 shrink mobileViewportPadding'>
                                            <PatientOrderStatusList />
                                            <PatientOrderStatusPagination />
                                        </Grid.Row>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    filterOrderStatusList: ({ field, value, like }) =>
        dispatch(
            orderStatusActions.filterPatientOrderStatus({
                name: field,
                value,
                like,
            }),
        ),
    getOrderStatusList: () =>
        dispatch(orderStatusActions.patientOrderStatusRequest()),
    getOrderStatusCount: () =>
        dispatch(orderStatusActions.patientOrderStatusCountRequest()),
});

export default connect(
    null,
    mapDispatchToProps,
)(PatientOrderStatus);
