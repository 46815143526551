import api from './api';

export const login = (email, password) =>
    api.post('/Auth/signin', {
        email: email,
        password: password,
    });

export const passwordReset = async email =>
    api.post('/Auth/changePassword', {
        email: email,
    });

export const getDomainInfo = async domain => api.get(`/Auth/domain/${domain}`);
