import axios from 'axios';
import moment from 'moment';

import * as auth from '../helpers/auth.helpers';
import { history } from '../store';
import get from 'lodash/get';

/**
 * @returns { import('axios').AxiosInstance }
 */
export const getInstance = () => {
    const instance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
    });
    instance.defaults.headers.common['Accept'] = 'application/json';
    instance.defaults.headers.common['Content-Type'] = 'application/json';

    instance.interceptors.request.use(config => {
        const token = auth.getSession();
        if (token) {
            const { Authorization, ...otherHeaders } = config.headers || {};
            config.headers = {
                ...otherHeaders,
                Authorization: Authorization
                    ? Authorization
                    : `Bearer ${token}`,
            };
        }
        return config;
    });

    instance.interceptors.response.use(
        response => response.data,
        error => {
            const response = error.response;
            if (!response) {
                throw new Error('Network error, please try again later');
            }
            const { status } = response;
            if (status === 401) {
                history.push('/login');
            }
            const message = get(
                response,
                'data.error.message',
                'Unknown error',
            );
            throw new Error(message);
        },
    );
    return instance;
};

export const buildCountParams = ({ filter }) => {
    const where = buildWhereFilter(filter);
    return {
        params: {
            where,
        },
    };
};

export const buildWhereFilter = filter => {
    const filters = [];
    for (const key in filter) {
        const { value, like, date, dateFormat } = filter[key];
        if (value === '' || value === null || value === undefined) {
            continue;
        }
        let formattedValue = value;
        if (date) {
            formattedValue = moment.utc(formattedValue, dateFormat).format();
        }
        if (like) {
            formattedValue = `%${
                typeof formattedValue === 'string'
                    ? formattedValue.trim()
                    : formattedValue.toString().trim()
            }%`;
        }
        const newFilter = {
            [key]: like
                ? {
                      ilike: formattedValue,
                  }
                : typeof formattedValue === 'string'
                ? formattedValue.trim()
                : formattedValue,
        };
        filters.push(newFilter);
    }
    return {
        and: [...filters],
    };
};

/**
 * Build params to query api
 */
export const buildSearchParams = ({ skip, limit, filter = {}, sort }) => {
    const apiFilter = {
        limit: limit,
        skip: skip,
        where: buildWhereFilter(filter),
    };

    if (typeof sort === 'object' && sort.column) {
        let { column, direction = 'asc' } = sort;
        switch (direction.toLowerCase()) {
            case 'desc':
            case 'descending':
                direction = 'DESC';
                break;
            case 'asc':
            case 'ascending':
            default:
                direction = 'ASC';
                break;
        }
        apiFilter.order = `${column} ${direction}`;
    }
    return {
        params: {
            filter: apiFilter,
        },
    };
};

export default getInstance();
