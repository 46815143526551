import React from 'react';
import PropTypes from 'prop-types';
import withReportBar from '../../../common/report/withReportBar';
import ColumnChartType from '../../../common/charts/bar/Column';

export const ColumnChart = withReportBar(props => (
    <ColumnChartType {...props} />
));

ColumnChart.propTypes = {
    sfid: PropTypes.string.isRequired,
    defaultDateFilterC: PropTypes.string,
};

export default ColumnChart;
