import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose, setPropTypes, flattenProp, setDisplayName } from 'recompose';

import withLoading from './withLoading';
import {
    getReportPathData,
    getReportPathStatus,
} from '../../store/report-path/reportPathSelectors';
import { fetchReportPath } from '../../store/report-path/reportPathActions';

const mapStateToProps = (state, ownProps) => ({
    data: getReportPathData(state, ownProps.sfid),
    status: getReportPathStatus(state, ownProps.sfid),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    load: () => dispatch(fetchReportPath(ownProps.sfid)),
});

export const withReportPath = WrappedComponent => {
    const enhance = compose(
        setPropTypes({
            sfid: PropTypes.string.isRequired,
        }),
        connect(
            mapStateToProps,
            mapDispatchToProps,
        ),
        setPropTypes({
            reportIdC: PropTypes.string,
            name: PropTypes.string,
            sfid: PropTypes.string.isRequired,
        }),
        flattenProp('data'),
        flattenProp('status'),
        withLoading,
        setDisplayName('WithReportPath'),
    );
    return enhance(WrappedComponent);
};
export default withReportPath;
