import React, { Component } from 'react';
import { Button, Grid, Header } from 'semantic-ui-react';
import PatientStatistics from './PatientStatistics';

export class PatientDrawer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
        };
        this.toggleExpanded = this.toggleExpanded.bind(this);
    }

    toggleExpanded() {
        this.setState({ expanded: !this.state.expanded });
    }

    render() {
        const classToggle = this.state.expanded
            ? 'mobile-show drawer active'
            : 'mobile-show drawer inactive';
        const buttonToggle = this.state.expanded ? 'Hide' : 'Expand';
        const dimmerToggle = this.state.expanded
            ? 'drawerDimmer active'
            : 'drawerDimmer';

        return (
            <React.Fragment>
                <div className={classToggle}>
                    <Grid
                        columns={2}
                        className='drawer-header'
                        verticalAlign='middle'
                        onClick={this.toggleExpanded}
                    >
                        <Grid.Column>
                            <Header as='h4'>Supporting Data</Header>
                        </Grid.Column>
                        <Grid.Column textAlign='right'>
                            <Button
                                basic
                                compact
                                size='mini'
                                className='noBorder'
                            >
                                {buttonToggle}
                            </Button>
                        </Grid.Column>
                    </Grid>
                    <div className='drawer-content padding0'>
                        <div className='reportCard shaded grow'>
                            <PatientStatistics profile={this.props.profile} />
                        </div>
                    </div>
                </div>

                <div className={dimmerToggle} />
            </React.Fragment>
        );
    }
}

export default PatientDrawer;
