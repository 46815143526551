import React from 'react';
import { Dropdown, Grid, Divider } from 'semantic-ui-react';
import { compose, withHandlers, setDisplayName } from 'recompose';
import StatusButton from './StatusButton';
import { PATIENT_ORDER_STATUS_FILTERS } from '../../.././store/constants/dropdown';

const enhance = compose(
    withHandlers({
        setValue: ({ onChange }) => value => {
            onChange(null, { value });
        },
    }),
    setDisplayName('StatusFilter'),
);

const StatusFilter = enhance(({ defaultValue, setValue, onChange }) => {
    return (
        <>
            <Grid className='paddingV2rem shrink tablet-up-show'>
                <Grid.Column className='shrink' verticalAlign='middle'>
                    <p>
                        <strong>FILTERS</strong>
                    </p>
                </Grid.Column>
                <Grid.Column className='grow'>
                    {PATIENT_ORDER_STATUS_FILTERS.map(orderStatusFilter => {
                        return (
                            <StatusButton
                                key={orderStatusFilter.key}
                                value={orderStatusFilter.value}
                                current={defaultValue}
                                handleClick={setValue}
                                title={orderStatusFilter.text}
                            />
                        );
                    })}
                </Grid.Column>
            </Grid>
            <Grid className='mobile-show'>
                <Grid.Column width={16} className='paddingV0 grow'>
                    <Dropdown
                        placeholder='Filters'
                        fluid
                        selection
                        options={PATIENT_ORDER_STATUS_FILTERS}
                        value={defaultValue || ''}
                        onChange={onChange}
                    />
                    <Divider className='mobile-show' hidden />
                </Grid.Column>
            </Grid>
        </>
    );
});

export default StatusFilter;
