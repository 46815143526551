import api from './api';

export const getDashboardsItemChildReportPath = dashboardItemId =>
    api.get('/ReportPathCs', {
        params: {
            filter: {
                where: {
                    and: [
                        {
                            portalReportC: dashboardItemId,
                        },
                        {
                            parentReportPathC: null,
                        },
                    ],
                },
            },
        },
    });

export const getReportChildItemReportPath = reportId =>
    api.get('/ReportPathCs', {
        params: {
            filter: {
                where: {
                    and: [
                        {
                            payerCustomReportC: reportId,
                        },
                        {
                            parentReportPathC: null,
                        },
                    ],
                },
            },
        },
    });

export const getReportPathById = id =>
    api.get('/ReportPathCs', {
        params: {
            filter: {
                where: {
                    sfid: id,
                },
            },
        },
    });

export const getChildReportPath = id =>
    api.get('/ReportPathCs', {
        params: {
            filter: {
                where: {
                    parentReportPathC: id,
                },
            },
        },
    });
