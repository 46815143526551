import * as types from './domainActionTypes';

export const verifyDomain = domain => ({
    type: types.DOMAIN_VERIFY_REQUEST,
    domain,
});

export const verifyDomainSuccess = ({ name, logo }) => ({
    type: types.DOMAIN_VERIFY_SUCCESS,
    name,
    logo,
});

export const verifyDomainError = error => ({
    type: types.DOMAIN_VERIFY_ERROR,
    error,
});

export const changeDomain = domain => ({
    type: types.CHANGE_DOMAIN,
    domain,
});
