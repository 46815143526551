import React, { Fragment } from 'react';
import { Button, Form, TextArea, Icon, Label } from 'semantic-ui-react';

import { compose, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';
import { startChat } from '../../store/chat/chatActions';
import {
    waitingForAgentToAcceptSelector,
    agentIsOnlineSelector,
} from '../../store/chat/chatSelectors';
const isAvailable = false; // If live agent feature is available
export const Prechat = ({
    handleSubmit,
    handleChange,
    waitingForAgentToAccept,
    agentIsOnline,
}) => {
    return (
        <>
            {isAvailable && !agentIsOnline && (
                <Fragment>
                    <div className='marginB1'>
                        <Label circular color={'red'} empty />{' '}
                        <span>No Agents Online</span>
                    </div>
                </Fragment>
            )}
            {isAvailable && waitingForAgentToAccept && (
                <Fragment>
                    <div className='marginB1'>
                        <Icon loading name='spinner' />{' '}
                        <span className='greenColor'>
                            Waiting For Agent To Accept
                        </span>
                    </div>
                </Fragment>
            )}
            {!isAvailable && (
                <Fragment>
                    <div className='marginB1'>
                        <span>
                            All inquiries will be responded to within one
                            business day
                        </span>
                    </div>
                </Fragment>
            )}
            <Form onSubmit={handleSubmit} loading={waitingForAgentToAccept}>
                <Form.Input
                    required
                    label='Subject'
                    name='subject'
                    onChange={handleChange}
                />
                <p>
                    <strong>Description</strong>
                </p>
                <TextArea
                    required
                    label='Description'
                    name='description'
                    onChange={handleChange}
                />
                <br />
                <br />
                <Button type='submit' className='primary'>
                    {agentIsOnline ? 'Start Chat' : 'Create Case'}
                </Button>
            </Form>
        </>
    );
};

export default compose(
    withState('data', 'updateData', {}),
    connect(
        state => ({
            waitingForAgentToAccept: waitingForAgentToAcceptSelector(state),
            agentIsOnline: agentIsOnlineSelector(state),
        }),
        dispatch => ({
            startChat: prechatData => dispatch(startChat(prechatData)),
        }),
    ),
    withHandlers({
        handleChange: props => (e, { name, value }) =>
            props.updateData({
                ...props.data,
                [name]: value,
            }),
        handleSubmit: props => e => {
            e.preventDefault();
            props.startChat(props.data);
        },
    }),
)(Prechat);
