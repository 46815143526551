import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

import ReportHeader from '../../report-preview/ReportHeader';
import Item from './Item';

export const ItemGroup = ({ title, items }) => {
    if (!Array.isArray(items)) {
        return null;
    }

    return (
        <>
            <ReportHeader name={title} />
            <Grid textAlign='center' verticalAlign='middle'>
                {items.map(item => {
                    return (
                        <Grid.Column
                            key={`reports_item_group_${item.chartReportIdC}`}
                            computer={8}
                            tablet={8}
                            mobile={8}
                        >
                            <Item
                                showHeader={false}
                                showViewButton={false}
                                showDate
                                data={item}
                            />
                        </Grid.Column>
                    );
                })}
            </Grid>
        </>
    );
};

ItemGroup.propTypes = {
    items: PropTypes.array,
    title: PropTypes.string,
};

ItemGroup.defaultProps = {
    title: 'Key Financial Metrics',
};

export default ItemGroup;
