import React from 'react';
import PropTypes from 'prop-types';
import Metric from '../../../common/charts/metric';
import withReportMetric from '../../../common/report/withReportMetric';

export const MetricChart = withReportMetric(props => <Metric {...props} />);

MetricChart.propTypes = {
    sfid: PropTypes.string.isRequired,
    clickableC: PropTypes.bool,
    onViewReport: PropTypes.func,
    showDate: PropTypes.bool,
    defaultDateFilterC: PropTypes.string,
};

export default MetricChart;
