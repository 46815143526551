import React, { Component } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import LiveAgent from '../chat/LiveAgent';

export class LiveAgentModal extends Component {
    state = { open: false };

    show = size => () => this.setState({ size, open: true });
    close = () => this.setState({ open: false });

    render() {
        const { open } = this.state;
        return (
            <React.Fragment>
                <Button
                    basic
                    icon
                    className='noBorder noBG'
                    size='tiny'
                    onClick={this.show()}
                >
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='22'
                        height='23'
                        viewBox='0 0 22 23'
                    >
                        <g
                            fill='none'
                            fillRule='evenodd'
                            stroke='#FFF'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth='1.5'
                            transform='translate(.868 1.014)'
                        >
                            <path d='M9.84375,16.40625 C9.11887627,16.40625 8.53125,15.8186237 8.53125,15.09375 L8.53125,8.53125 C8.53125,7.80637627 9.11887627,7.21875 9.84375,7.21875 L19.03125,7.21875 C19.7561237,7.21875 20.34375,7.80637627 20.34375,8.53125 L20.34375,15.09375 C20.34375,15.8186237 19.7561237,16.40625 19.03125,16.40625 L17.71875,16.40625 L17.71875,20.34375 L13.78125,16.40625 L9.84375,16.40625 Z' />
                            <path d='M5.90625,11.15625 L3.28125,13.78125 L3.28125,9.84375 L1.96875,9.84375 C1.24387627,9.84375 0.65625,9.25612373 0.65625,8.53125 L0.65625,1.96875 C0.65625,1.24387627 1.24387627,0.65625 1.96875,0.65625 L11.15625,0.65625 C11.8811237,0.65625 12.46875,1.24387627 12.46875,1.96875 L12.46875,4.59375' />
                        </g>
                    </svg>
                </Button>

                <Modal size='small' open={open} onClose={this.close}>
                    <Modal.Header>Chat Support</Modal.Header>
                    <Modal.Content>
                        <LiveAgent />
                    </Modal.Content>
                </Modal>
            </React.Fragment>
        );
    }
}

export default LiveAgentModal;
