import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    ResponsiveContainer,
    LineChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    Line,
} from 'recharts';
import EmptyReport from '../empty';

export class LineChartType extends Component {
    static propTypes = {
        width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        data: PropTypes.array.isRequired,
        columns: PropTypes.array,
        groupings: PropTypes.arrayOf(PropTypes.string),
        summaries: PropTypes.arrayOf(PropTypes.string),
        legendPosition: PropTypes.string,
    };

    static defaultProps = {
        width: '100%',
        height: 375,
    };

    render() {
        const colorPallete = ['#00B06D', '#8FCC22'];
        const {
            data,
            width,
            height,
            groupings,
            columns,
            summaries,
            // legendPosition,
        } = this.props;

        if (!data.length) {
            return <EmptyReport />;
        }
        return (
            <ResponsiveContainer width={width} height={height}>
                <LineChart data={data}>
                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis dataKey={groupings[0]} />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    {summaries.map((summary, index) => {
                        const column = columns.find(
                            column => column.accessor === summary,
                        );
                        return (
                            <Line
                                key={index}
                                type='monotone'
                                dataKey={summary}
                                name={column ? column.label : null}
                                stroke={
                                    colorPallete[index % colorPallete.length]
                                }
                            />
                        );
                    })}
                </LineChart>
            </ResponsiveContainer>
        );
    }
}

export default LineChartType;
