import { put, takeLatest, select, fork } from 'redux-saga/effects';
import * as actions from './domainActions';
import * as types from './domainActionTypes';
import * as selectors from './domainSelectors';
import { getDomainInfo } from '../../api/authAPI';
import { push } from 'connected-react-router';
import { USE_SUBDOMAINS, ROOT_DOMAIN } from '../../App';

export function* verifyDomainSaga({ domain }) {
    try {
        const result = yield getDomainInfo(domain);
        if (!result) {
            if (USE_SUBDOMAINS) {
                window.location.href = window.location.replace(
                    window.location.href.replace(
                        window.location.hostname,
                        ROOT_DOMAIN,
                    ),
                );
            } else {
                yield put(push('/'));
            }
            return;
        }
        const { logo, name } = result;
        yield put(actions.verifyDomainSuccess({ logo, name, verified: true }));
    } catch (error) {
        yield put(actions.verifyDomainError(error.message));
        yield fork(changeDomainSaga, { domain: '' });
    }
}

export function* changeDomainSaga({ domain }) {
    const prevDomain = yield select(selectors.domainSelector);
    if (domain === prevDomain) {
        return;
    }
    if (!USE_SUBDOMAINS) {
        yield window.location.replace(
            `${window.location.origin}/${domain.replace(/[^\w]/g, '')}`,
        );
    } else {
        if (prevDomain) {
            window.location.href = window.location.href.replace(
                prevDomain,
                domain,
            );
        } else {
            window.location.href = window.location.href.replace(
                ROOT_DOMAIN,
                `${domain}.${ROOT_DOMAIN}`,
            );
        }
    }
}

export function* rootSaga() {
    yield takeLatest(types.CHANGE_DOMAIN, changeDomainSaga);
    yield takeLatest(types.DOMAIN_VERIFY_REQUEST, verifyDomainSaga);
}

export default rootSaga;
