import React, { Fragment } from 'react';
import { Grid, Header } from 'semantic-ui-react';
import LiveAgent from '../../chat/LiveAgent';

export const DashboardSidebar = () => (
    <Fragment>
        <Grid.Column
            className='paddingH0 shaded tablet-up-show'
            computer={5}
            tablet={6}
            mobile={16}
        >
            <div className='padded2rem'>
                <Header as='h4'>Customer Service Support</Header>
                <LiveAgent />
            </div>
        </Grid.Column>
    </Fragment>
);

export default DashboardSidebar;
