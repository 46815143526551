import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    loadingSelector,
    getDashboardItems,
    loadedSelector,
    errorSelector,
} from '../../../store/dashboard/dashboardSelectors';
import { fetchDashboardItems } from '../../../store/dashboard/dashboardActions';
import DashboardItem from '../item';
import DashboardItemGroup from '../item-group';
import { Grid } from 'semantic-ui-react';
import { AllCustomChartTypes } from '../../reports/report-chart/ChartTypes';
import withLoading from '../../common/withLoading';
import { ITEM_TYPES } from '../../../helpers/grouping.helpers';

export const DashboardItems = ({ items }) => (
    <React.Fragment>
        {items.map((item, key) => {
            const { type, data } = item;
            return (
                <Grid.Column key={key}>
                    {type === ITEM_TYPES.SINGLE_ITEM && (
                        <DashboardItem data={data} />
                    )}
                    {type === ITEM_TYPES.ITEM_GROUP && (
                        <DashboardItemGroup items={data} />
                    )}
                </Grid.Column>
            );
        })}
    </React.Fragment>
);

DashboardItems.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.oneOf([
                ITEM_TYPES.SINGLE_ITEM,
                ITEM_TYPES.ITEM_GROUP,
            ]).isRequired,
            data: PropTypes.oneOfType([
                PropTypes.shape({
                    chartTypeC: PropTypes.oneOf(AllCustomChartTypes),
                    chartReportIdC: PropTypes.string,
                    name: PropTypes.string,
                    clickableC: PropTypes.bool,
                }),
                PropTypes.arrayOf(
                    PropTypes.shape({
                        chartTypeC: PropTypes.oneOf(AllCustomChartTypes),
                        chartReportIdC: PropTypes.string,
                        name: PropTypes.string,
                        clickableC: PropTypes.bool,
                    }),
                ),
            ]),
        }),
    ),
};

DashboardItems.defaultProps = {
    items: [],
};

const mapStateToProps = state => ({
    items: getDashboardItems(state),
    loading: loadingSelector(state),
    loaded: loadedSelector(state),
    error: errorSelector(state),
});

const mapDispatchToProps = dispatch => ({
    load: () => dispatch(fetchDashboardItems()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withLoading(DashboardItems));
