import { merge, from } from 'rxjs';
import {
    startSession as startLiveAgentSession,
    Api,
} from 'rx-sfliveagent-client';
import uuid from 'uuid/v4';
import { ofType, combineEpics } from 'redux-observable';
import * as types from './chatActionTypes';
import Notify from '../../helpers/notification.helpers';
import * as selectors from './chatSelectors';
import * as actions from './chatActions';
import { LOGOUT_SUCCESS } from '../auth/authActionTypes';
import { getContact } from '../auth/authSelectors';
import {
    mapTo,
    mergeMap,
    filter,
    ignoreElements,
    map,
    takeUntil,
    catchError,
    tap,
} from 'rxjs/operators';
import { of, interval } from 'rxjs';
import * as caseApi from '../../api/caseAPI';

const config = {
    buttonId: process.env.REACT_APP_LIVE_AGENT_BUTTON_ID,
    deploymentId: process.env.REACT_APP_LIVE_AGENT_DEPLOYMENT_ID,
    //https://acariahealthapp--trifindev.lightning.force.com/lightning/setup/LiveAgentSettings/home
    host: process.env.REACT_APP_LIVE_AGENT_HOST,
    // https://acariahealthapp--trifindev.my.salesforce.com/00D0t0000008r8M?retURL=%2Fui%2Fsetup%2FSetup%3Fsetupid%3DCompanyProfile&setupid=CompanyProfileInfo
    organizationId: process.env.REACT_APP_LIVE_AGENT_ORGANIZATION_ID,
    // 42 is the latest lib version
    version: process.env.REACT_APP_LIVE_AGENT_API_VERSION,
};

const resolution = `${window.screen.width * window.devicePixelRatio}x${window
    .screen.height * window.devicePixelRatio}`;

const visitor = {
    language: 'en',
    userAgent: window.navigator.userAgent,
    visitorName: 'Anonimous',
    prechatDetails: [],
    prechatEntities: [],
    receiveQueueUpdates: true,
    screenResolution: resolution,
    isPost: true,
};

const api = new Api(config);

const availablityEpic = action$ =>
    action$.pipe(
        ofType(types.CHAT_SUBSCRIBE_AVAILABILITY),
        mergeMap(() => interval(5000)),
        mergeMap(() => from(api.availability())),
        filter(event => event.message.results && event.message.results.length),
        map(event =>
            actions.setAgentStatus({
                isOnline: event.message.results[0].isAvailable,
            }),
        ),
        takeUntil(action$.pipe(ofType(types.CHAT_UNSUBSCRIBE_AVAILABILITY))),
    );
const startChatEpic = (action$, state$) =>
    action$.pipe(
        ofType(types.CHAT_START_REQUEST),
        filter(() => !!getContact(state$.value)),
        filter(() => !!selectors.agentIsOnlineSelector(state$.value)),
        mergeMap(() => {
            const { subject, description } = state$.value.chat.prechat;
            const contact = getContact(state$.value);
            const session = startLiveAgentSession(config, {
                ...visitor,
                prechatDetails: [
                    {
                        label: 'Subject',
                        value: subject,
                        transcriptFields: ['Subject__c'],
                        displayToAgent: true,
                    },
                    {
                        label: 'Description',
                        value: description,
                        transcriptFields: ['Description__c'],
                        displayToAgent: true,
                    },
                    {
                        label: 'Contact',
                        value: contact.sfId,
                        transcriptFields: ['ContactId'],
                        displayToAgent: true,
                        entityMaps: [
                            {
                                entityName: 'Contact',
                                fieldName: 'id',
                                isFastFillable: false,
                                isAutoQueryable: true,
                                isExactMatchable: true,
                            },
                        ],
                    },
                ],
                visitorName: contact.name,
            });
            return of(session);
        }),
        map(session => actions.startSession(session)),
    );

const chatStartSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(types.CHAT_START_REQUEST),
        filter(() => !!state$.value.chat.session),
        mergeMap(() => state$.value.chat.session.chatEstablished$),
        map(() => actions.startChatSuccess()),
    );

const chatStartFailEpic = (action$, state$) =>
    action$.pipe(
        ofType(types.CHAT_START_REQUEST),
        filter(() => !!state$.value.chat.session),
        mergeMap(() => state$.value.chat.session.chatRequestFail$),
        map(event => actions.startChatError(event.message.reason)),
    );

const errorsEpic = (action$, state$) =>
    action$.pipe(
        ofType(types.CHAT_START_SUCCESS),
        filter(() => !!state$.value.chat.session),
        mergeMap(() => state$.value.chat.session.error$),
        tap(() => Notify.error('Unexpected error')),
        map(error => actions.receiveError({ error })),
    );

const getMessageEpic = (action$, state$) =>
    action$.pipe(
        ofType(types.CHAT_START_SUCCESS),
        filter(() => !!state$.value.chat.session),
        mergeMap(() =>
            merge(
                state$.value.chat.session.chasitorChatMessage$,
                state$.value.chat.session.chatMessage$,
            ),
        ),
        map(event => {
            console.log(event.message);
            const { text, name, agentId } = event.message;
            return actions.receiveMessage({
                id: uuid(),
                text,
                author: name,
                date: new Date(),
                userSend: agentId === state$.value.chat.session.api.visitor.id,
            });
        }),
    );

const sendMessageEpic = (action$, state$) =>
    action$.pipe(
        ofType(types.CHAT_SEND_MESSAGE),
        filter(() => !!state$.value.chat.session),
        mergeMap(({ payload: { text } }) =>
            state$.value.chat.session.api.chatMessage({
                text,
            }),
        ),
        ignoreElements(),
    );

const createCaseEpic = (action$, state$) =>
    action$.pipe(
        ofType(types.CHAT_CREATE_CASE_REQUEST),
        mergeMap(() =>
            from(
                caseApi.createCase({
                    ...state$.value.chat.prechat,
                    contactId: getContact(state$.value).sfId,
                }),
            ).pipe(
                tap(() =>
                    Notify.success(
                        'Thank you for opening a case with Acaria Support! An Account Manager will reach out to you shortly',
                    ),
                ),
                map(() => actions.createCaseSucess()),
                catchError(error => {
                    Notify.error(
                        `Thank you for opening a case with Acaria Support! An Account Manager will reach out to you shortly`,
                    );
                    return of(actions.createCaseError(error));
                }),
            ),
        ),
    );

const createCaseOnAgentIsOffline = (action$, state$) =>
    action$.pipe(
        ofType(types.CHAT_START_REQUEST),
        filter(() => !selectors.agentIsOnlineSelector(state$.value)),
        map(() => actions.createCaseRequest()),
    );

const createCaseOnStartChartFail = action$ =>
    action$.pipe(
        ofType(types.CHAT_START_ERROR),
        map(() => actions.createCaseRequest()),
    );

const chatEndedByAgentEpic = (action$, state$) =>
    action$.pipe(
        ofType(types.CHAT_START_SUCCESS),
        filter(() => !!state$.value.chat.session),
        mergeMap(() => state$.value.chat.session.chatEnded$),
        tap(() => Notify.success('Agent ended the chat')),
        map(() => actions.endedByAgent()),
    );

const chatEndEpic = (action$, state$) =>
    action$.pipe(
        ofType(types.CHAT_END_BY_USER),
        filter(() => !!state$.value.chat.session),
        mergeMap(() => state$.value.chat.session.api.chatEnd()),
    );

const endChatOnLogoutEpic = action$ =>
    action$.pipe(
        ofType(LOGOUT_SUCCESS),
        mapTo(actions.endChatByUser),
    );

export default combineEpics(
    startChatEpic,
    getMessageEpic,
    chatEndedByAgentEpic,
    chatEndEpic,
    errorsEpic,
    availablityEpic,
    createCaseEpic,
    sendMessageEpic,
    endChatOnLogoutEpic,
    chatStartSuccessEpic,
    chatStartFailEpic,
    createCaseOnStartChartFail,
    createCaseOnAgentIsOffline,
);
