import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

export const ViewReportButton = ({ handleClick }) => (
    <Button className='view-more mobile-show' onClick={handleClick} primary>
        View report
    </Button>
);

ViewReportButton.propTypes = {
    handleClick: PropTypes.func.isRequired,
};

export default ViewReportButton;
