import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Feed, Image, Button, Header } from 'semantic-ui-react';
import EventDate from '../EventDate';
import icon_clinical from '../../../../assets/images/icon-clinical.svg';
import icon_clinical_negative from '../../../../assets/images/icon-clinical-negative.svg';
import ClinicalModal from '../EventModals/ClinicalModal';

class ClinicalEvent extends Component {
    static propTypes = {
        eventType: PropTypes.string,
        handleClick: PropTypes.func,
    };

    static defaultProps = {
        handleClick: () => {},
        eventType: 'clinical',
    };

    render() {
        const eventHasOpenCase = this.props.event.cases
            ? this.props.event.cases.find(aCase => aCase.status !== 'Closed')
            : false;
        return (
            <React.Fragment>
                <Feed.Event onClick={this.props.handleClick}>
                    <Feed.Label>
                        <Image
                            src={
                                eventHasOpenCase
                                    ? icon_clinical_negative
                                    : icon_clinical
                            }
                            alt='Works~'
                            width='29'
                        />
                    </Feed.Label>
                    <Feed.Content>
                        <EventDate date={this.props.event.createdDate} />
                        <div className='event-content'>
                            <div className='event-content__detail'>
                                <Header as='h5'>Clinical Assessment</Header>
                                <Header as='h3'>
                                    {this.props.event.assessmentNameC}
                                </Header>
                            </div>
                            <div className='event-content__button'>
                                <Button size='mini' content='View' primary />
                            </div>
                        </div>
                        <ClinicalModal />
                    </Feed.Content>
                </Feed.Event>
            </React.Fragment>
        );
    }
}

export default ClinicalEvent;
