import React from 'react';
import { connect } from 'react-redux';

import { resetFilter } from '../../../store/patient-roster/patientRosterActions';
import { Button } from 'semantic-ui-react';

export const ResetFilter = ({ reset }) => (
    <Button type='submit' primary onClick={reset}>
        Clear filters
    </Button>
);

const mapDispatchToProps = dispatch => ({
    reset: () => dispatch(resetFilter()),
});

export default connect(
    null,
    mapDispatchToProps,
)(ResetFilter);
