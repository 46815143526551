import React, { Component } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import * as authActions from '../../../store/auth/authActions';
import { connect } from 'react-redux';
import Notify from '../../../helpers/notification.helpers';
import PropTypes from 'prop-types';

export class ResetPasswordModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: false,
            email: this.props.profile.email,
            open: false,
        };

        this.handlePasswordReset = this.handlePasswordReset.bind(this);
    }
    show = size => () => this.setState({ size, open: true });
    hide = size => () => this.setState({ size, open: false });

    componentWillReceiveProps(nextProps) {
        if (nextProps.profile !== this.props.profile) {
            this.setState({
                email: this.props.profile.email,
            });
        }
    }

    handlePasswordReset(e) {
        e.preventDefault();
        if (this.state.email) {
            this.props.onPasswordReset(this.state.email);
            Notify.success(
                'If you have an account with Acaria Health, ' +
                    'then a password reset email has been sent to the email provided.',
            );
            this.setState({ open: false });
        } else {
            Notify.error(
                'Please fill in the email field to reset your password.',
            );
        }
    }

    static propTypes = {
        account: PropTypes.object,
    };

    render() {
        const { open } = this.state;

        return (
            <React.Fragment>
                <Button primary size='tiny' onClick={this.show()}>
                    Reset Password
                </Button>
                <Modal size='tiny' open={open} onClose={this.close}>
                    <Modal.Header>Confirm Password Reset</Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <p>
                                Confirm your password reset request. You will
                                receive an email to reset your password, please
                                follow the instructions
                            </p>
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            style={{ backgroundColor: '#000000de' }}
                            primary
                            onClick={this.hide()}
                        >
                            Cancel
                        </Button>
                        <Button primary onClick={this.handlePasswordReset}>
                            Yes, Reset Password
                        </Button>
                    </Modal.Actions>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStatesToProps = (state, ownProps) => {
    return {
        profile: state.auth.profile,
    };
};

const mapDispatchToProps = dispatch => ({
    onPasswordReset: email => dispatch(authActions.passwordResetRequest(email)),
});

export default connect(
    mapStatesToProps,
    mapDispatchToProps,
)(ResetPasswordModal);
