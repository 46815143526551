export const getSelectors = prefix => {
    const skipSelector = state =>
        (state[prefix].page - 1) * state[prefix].pageSize;
    const pageSizeSelector = state => state[prefix].pageSize;
    const filterSelector = state => state[prefix].filter;
    const sortSelector = state => state[prefix].sort;
    const currentPageSelector = state => state[prefix].page;
    const totalPagesSelector = state =>
        Math.ceil(state[prefix].total / state[prefix].pageSize) || 1;
    const dataSelector = state => state[prefix].data;
    const loadingSelector = state => state[prefix].loading;
    const loadedSelector = state => state[prefix].loaded;
    const errorSelector = state => state[prefix].error;
    return {
        skipSelector,
        pageSizeSelector,
        filterSelector,
        sortSelector,
        currentPageSelector,
        totalPagesSelector,
        dataSelector,
        loadingSelector,
        loadedSelector,
        errorSelector,
    };
};

export default getSelectors;
