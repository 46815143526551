import * as types from './caseActionTypes';

export default (
    state = {
        assigning: false,
        error: null,
    },
    action,
) => {
    switch (action.type) {
        case types.ASSIGN_TO_ME_REQUEST:
            return {
                ...state,
                assigning: true,
                error: null,
            };
        case types.ASSIGN_TO_ME_SUCCESS:
            return {
                ...state,
                assigning: false,
            };
        case types.ASSIGN_TO_ME_ERROR:
            return {
                ...state,
                assigning: false,
                error: action.error,
            };
        default:
            return state;
    }
};
