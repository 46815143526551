import api from './api';

export const getDashboards = accountId =>
    api.get('/PortalReportCs', {
        params: {
            filter: {
                where: {
                    payerC: accountId,
                },
            },
        },
    });
