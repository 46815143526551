import React, { useCallback } from 'react';
import { Grid, Form } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import NewCalendar from '../../../newComponents/Calendar';
import withDebounce from '../../common/DebouncedInput';
import ResetFilter from './ResetFilter';
import withSearch from './withSearch';

const SearchInput = withSearch(withDebounce(Form.Input));

const SearchDate = withSearch(withDebounce(DateInput));

const NewSearchDate = withSearch(withDebounce(NewCalendar));

const PatientRosterSearch = () => {
    const onHidePicker = useCallback(() => {
        document.getElementsByTagName('body')[0].classList.add('no-picker');
    }, []);

    const onShowPicker = useCallback(() => {
        document.getElementsByTagName('body')[0].classList.remove('no-picker');
    }, []);

    return (
        <Grid>
            <Grid.Column
                computer={3}
                tablet={4}
                mobile={16}
                className='marginBDot5rem'
            >
                <Form>
                    <SearchInput
                        name='member.name'
                        like
                        label='Patient Search'
                        placeholder='ex: Sam Samson'
                    />
                </Form>
            </Grid.Column>
            <Grid.Column computer={3} tablet={4} mobile={8}>
                <Form>
                    <NewSearchDate
                        name='member.birthDateC'
                        dateFormat='MM-DD-YYYY'
                        label='DOB'
                        type='roster'
                    />
                    {
                        // <SearchDate
                        // date
                        // name='member.birthDateC'
                        // dateFormat='MM-DD-YYYY'
                        // label='DOB'
                        // placeholder='Date'
                        // iconPosition='left'
                        // onKeyDown={onHidePicker}
                        // onClick={onShowPicker}
                        // closable
                        // delay={1000}
                        // />
                    }
                </Form>
            </Grid.Column>
            <Grid.Column computer={3} tablet={4} mobile={8}>
                <Form>
                    <SearchInput
                        name='claims.patientReferenceCategoryC'
                        like
                        label='Disease State Search'
                        placeholder='ex: Hypercholestrolemia'
                    />
                </Form>
            </Grid.Column>
            <Grid.Column computer={3} tablet={4} mobile={16}>
                <Form className='paddedNoLabel'>
                    <ResetFilter />
                </Form>
            </Grid.Column>
        </Grid>
    );
};
export default PatientRosterSearch;
