import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Grid, Image, Menu } from 'semantic-ui-react';
import SVGS from '../svgs';
import SidebarNavMenu from './SidebarNavMenu';
import LiveAgentModal from './LiveAgentModal';
import NotificationAlert from './notifications/NotificationAlert';
import DefaultUserImage from '../../assets/images/defaultUserImage.png';

export class GlobalHeader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedNavOption: this.props.selectedNavOption,
            leftSidebarVisible: false,
            toggleLeftSidebar: undefined,
            accessMap: props.accessMap,
            currentRole: props.currentRole,
            currentId: props.currentId,
        };
    }

    render() {
        let clientLogo = this.props.account ? this.props.account.logoUrlC : '';
        let userImage = this.props.user.tflRampAvatarUrlC
            ? this.props.user.tflRampAvatarUrlC
            : DefaultUserImage;
        return (
            <Grid.Row
                verticalAlign='middle'
                textAlign='left'
                className='global-header'
            >
                <Grid.Column mobile={10} tablet={8} computer={11}>
                    <SidebarNavMenu />
                    <SVGS logo />
                </Grid.Column>
                <Grid.Column
                    className='user-comms'
                    mobile={5}
                    tablet={8}
                    computer={5}
                >
                    <Menu className='user-menu' style={{ margin: 'auto' }} text>
                        <Menu.Item name='chat' className='mobile-show'>
                            <LiveAgentModal />
                        </Menu.Item>
                        <Menu.Item name='alert'>
                            <NotificationAlert />
                        </Menu.Item>
                        <Menu.Item as={NavLink} to='/account'>
                            <span className='tablet-up-show'>
                                {this.props.contact.name}
                            </span>
                        </Menu.Item>
                        <Menu.Item as={NavLink} to='/account'>
                            <Image
                                style={{
                                    borderRadius: 5,
                                    width: 30,
                                    height: 30,
                                    objectFit: 'cover',
                                }}
                                src={userImage}
                            />
                        </Menu.Item>
                        <Menu.Item
                            className='payerBrand tablet-up-show'
                            style={{ marginLeft: '10%', borderRadious: 10 }}
                        >
                            <Image
                                className='left aligned'
                                src={clientLogo}
                                alt='client name'
                            />
                        </Menu.Item>
                    </Menu>
                </Grid.Column>
            </Grid.Row>
        );
    }
}

const mapStateToProps = (state, ownProps = {}) => {
    return {
        contact: state.auth.contact,
        account: state.auth.account,
        user: state.auth.user,
        profile: state.auth.profile,
    };
};

export default connect(
    mapStateToProps,
    null,
)(GlobalHeader);
