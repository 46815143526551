import { createSelector } from 'reselect';
import { get } from 'lodash';
import { reportDataSelector } from '../reportDataSelectors';

export const reportMetricSelector = createSelector(
    [reportDataSelector],
    reportData => {
        if (!reportData) {
            return '';
        }
        return {
            data: get(reportData, 'data.factMap[T!T].aggregates[0].label'),
            name: get(reportData, 'data.attributes.reportName'),
        };
    },
);

export default reportMetricSelector;
