import React, { Component } from 'react';
import { Button, Grid, Header } from 'semantic-ui-react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PatientRosterStatistics from './PatientRosterStatistics';

export class PatientRosterDrawer extends Component {
    static propTypes = {
        loading: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
        };
        this.toggleExpanded = this.toggleExpanded.bind(this);
    }

    toggleExpanded() {
        this.setState({ expanded: !this.state.expanded });
    }

    render() {
        const classToggle = this.state.expanded
            ? 'mobile-show drawer active'
            : 'mobile-show drawer inactive';
        const buttonToggle = this.state.expanded ? 'Hide' : 'Expand';
        const dimmerToggle = this.state.expanded
            ? 'drawerDimmer active'
            : 'drawerDimmer';

        return (
            <React.Fragment>
                <div className={classToggle}>
                    <Grid
                        columns={2}
                        className='drawer-header'
                        verticalAlign='middle'
                        onClick={this.toggleExpanded}
                    >
                        <Grid.Column>
                            <Header as='h4'>Overview</Header>
                        </Grid.Column>
                        <Grid.Column textAlign='right'>
                            <Button
                                basic
                                compact
                                size='mini'
                                className='noBorder'
                            >
                                {buttonToggle}
                            </Button>
                        </Grid.Column>
                    </Grid>
                    <div className='drawer-content'>
                        <PatientRosterStatistics />
                    </div>
                </div>

                <div className={dimmerToggle} onClick={this.toggleExpanded} />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps = {}) => {
    return {
        loading: state.patientRoster.loading,
    };
};

export default connect(
    mapStateToProps,
    null,
)(PatientRosterDrawer);
