export const uploadFile = file => {
    const {
        REACT_APP_CLOUDINARY_UPLOAD_IMAGE_PRESET,
        REACT_APP_CLOUDINARY_CLOUD,
    } = process.env;
    console.log({
        REACT_APP_CLOUDINARY_UPLOAD_IMAGE_PRESET,
        REACT_APP_CLOUDINARY_CLOUD,
    });
    const data = new FormData();
    data.append(
        'upload_preset',
        process.env.REACT_APP_CLOUDINARY_UPLOAD_IMAGE_PRESET,
    );
    data.append('file', file);
    return fetch(
        `https://api.cloudinary.com/v1_1/${
            process.env.REACT_APP_CLOUDINARY_CLOUD
        }/upload`,
        {
            method: 'POST',
            body: data,
        },
    ).then(response => {
        const _response = response.json();
        console.log(_response);
        return _response;
    });
};
