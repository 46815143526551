import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Grid, Header } from 'semantic-ui-react';

export const Drawer = ({ title, content }) => {
    const [expanded, toggle] = useState(false);
    const toggleExpanded = () => {
        toggle(!expanded);
    };

    const contentClasses = classNames({
        'mobile-show': true,
        drawer: true,
        active: expanded,
        inactive: !expanded,
    });

    const dimmerClasses = classNames({
        drawerDimmer: true,
        active: expanded,
    });
    return (
        <>
            <div className={contentClasses}>
                <Grid
                    columns={2}
                    className='drawer-header'
                    verticalAlign='middle'
                    onClick={toggleExpanded}
                >
                    <Grid.Column>
                        <Header as='h4'>{title}</Header>
                    </Grid.Column>
                    <Grid.Column textAlign='right'>
                        <Button basic compact size='mini' className='noBorder'>
                            {expanded ? 'Hide' : 'Expand'}
                        </Button>
                    </Grid.Column>
                </Grid>
                <div className='drawer-content'>
                    <div>{content}</div>
                </div>
            </div>
            <div className={dimmerClasses} />
        </>
    );
};
Drawer.propTypes = {
    title: PropTypes.string,
    content: PropTypes.element.isRequired,
};
export default Drawer;
