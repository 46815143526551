export const PATIENT_ROSTER_LIST_REQUEST = 'PATIENT_ROSTER_LIST_REQUEST';
export const PATIENT_ROSTER_LIST_SUCCESS = 'PATIENT_ROSTER_LIST_SUCCESS';
export const PATIENT_ROSTER_LIST_ERROR = 'PATIENT_ROSTER_LIST_ERROR';

export const PATIENT_ROSTER_FILTER = 'PATIENT_ROSTER_FILTER';
export const PATIENT_ROSTER_FILTER_MULTIPLE = 'PATIENT_ROSTER_FILTER_MULTIPLE';
export const PATIENT_ROSTER_FILTER_RESET =
    'PATIPATIENT_ROSTER_FILTER_RESETENT_FILTER_RESET';

export const PATIENT_ROSTER_OVERVIEW_REQUEST =
    'PATIENT_ROSTER_OVERVIEW_REQUEST';
export const PATIENT_ROSTER_OVERVIEW_SUCCESS =
    'PATIENT_ROSTER_OVERVIEW_SUCCESS';
export const PATIENT_ROSTER_OVERVIEW_ERROR = 'PATIENT_ROSTER_OVERVIEW_ERROR';

export const PATIENT_ROSTER_GO_TO_NEXT_PAGE = 'PATIENT_ROSTER_GO_TO_NEXT_PAGE';
export const PATIENT_ROSTER_GO_TO_PREV_PAGE = 'PATIENT_ROSTER_GO_TO_PREV_PAGE';
export const PATIENT_ROSTER_GO_TO_FIRST_PAGE =
    'PATIENT_ROSTER_GO_TO_FIRST_PAGE';
export const PATIENT_ROSTER_GO_TO_LAST_PAGE = 'PATIENT_ROSTER_GO_TO_LAST_PAGE';

export const PATIENT_ROSTER_SORT = 'PATIENT_ROSTER_SORT';

export const SET_PAGE_SIZE = 'PATIENT_ROSTER_SET_PAGE_SIZE';
