import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ReportPreview } from '../../reports/report-preview/ReportPreview';
import { viewReport } from '../../../store/dashboard/dashboardActions';
import { AllCustomChartTypes } from '../../reports/report-chart/ChartTypes';

export const DashboardItem = ({
    showHeader,
    showViewButton,
    data,
    viewReport,
    defaultSortColumnLabelC,
}) => {
    //console.log(data);
    return (
        <ReportPreview
            showHeader={showHeader}
            showViewButton={showViewButton}
            data={data}
            viewReport={viewReport}
            defaultSortColumnLabelC={defaultSortColumnLabelC}
        />
    );
};

DashboardItem.propTypes = {
    showHeader: PropTypes.bool,
    showViewButton: PropTypes.bool,
    data: PropTypes.shape({
        chartTypeC: PropTypes.oneOf(AllCustomChartTypes),
        chartReportIdC: PropTypes.string,
        name: PropTypes.string,
        clickableC: PropTypes.bool,
        defaultSortColumnLabelC: PropTypes.string,
    }),
    viewReport: PropTypes.func,
};

DashboardItem.defaultProps = {
    showHeader: true,
    showViewButton: true,
};

const mapDispatchToProps = dispatch => ({
    viewReport: sfid => dispatch(viewReport(sfid)),
});

export default connect(
    null,
    mapDispatchToProps,
)(DashboardItem);
