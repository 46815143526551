import * as types from './patientRosterActionTypes';

export const filter = ({ name, value, like, date, dateFormat }) => {
    return {
        type: types.PATIENT_ROSTER_FILTER,
        payload: {
            name,
            value,
            like,
            date,
            dateFormat,
        },
    };
};

export const filterMultiple = ({ filters }) => {
    return {
        type: types.PATIENT_ROSTER_FILTER_MULTIPLE,
        filters: filters,
    };
};
export const resetFilter = () => ({
    type: types.PATIENT_ROSTER_FILTER_RESET,
});

export const fetch = () => ({
    type: types.PATIENT_ROSTER_LIST_REQUEST,
});

export const getOverview = () => ({
    type: types.PATIENT_ROSTER_OVERVIEW_REQUEST,
});

export const goToNextPage = () => ({
    type: types.PATIENT_ROSTER_GO_TO_NEXT_PAGE,
});

export const goToPrevPage = () => ({
    type: types.PATIENT_ROSTER_GO_TO_PREV_PAGE,
});

export const goToFirstPage = () => ({
    type: types.PATIENT_ROSTER_GO_TO_FIRST_PAGE,
});

export const goToLastPage = () => ({
    type: types.PATIENT_ROSTER_GO_TO_LAST_PAGE,
});

export const sort = sort => ({
    type: types.PATIENT_ROSTER_SORT,
    sort,
});

export const setPageSize = ({ pageSize }) => ({
    type: types.SET_PAGE_SIZE,
    payload: {
        pageSize,
    },
});
