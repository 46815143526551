import React from 'react';
import GlobalHeader from './GlobalHeader';
import Footer from './Footer';
import MobileNavMenu from './MobileNavMenu';

export const withLayout = WrappedComponent => props => (
    <React.Fragment>
        <GlobalHeader />
        <div className='ui stretched grid full-page'>
            <WrappedComponent {...props} />
        </div>
        <Footer />
        <MobileNavMenu />
    </React.Fragment>
);
