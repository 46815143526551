import * as types from './dashboardActionTypes';
import sortBy from 'lodash/sortBy';

export const defaultState = {
    dashboardItemsIds: [],
    dashboardItemsIdMap: {},
    reportPathMap: {},
    error: null,
    loading: false,
    loaded: false,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case types.FETCH_DASHBOARD_ITEMS_REQUEST:
            return {
                ...state,
                loading: true,
                loaded: false,
                error: null,
            };
        case types.FETCH_DASHBOARD_ITEMS_SUCCESS: {
            const items = sortBy(action.result, 'componentOrderC');
            const ids = items.map(item => item.sfid);
            const idMap = items.reduce(
                (acc, curr) => ({ ...acc, [curr.sfid]: curr }),
                {},
            );
            return {
                ...state,
                loading: false,
                loaded: true,
                dashboardItemsIds: ids,
                dashboardItemsIdMap: idMap,
            };
        }
        case types.FETCH_DASHBOARD_ITEMS_ERROR:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: action.error,
            };
        case types.FETCH_DASHBOARD_ITEM_CHILD_REPORT_PATH_SUCCESS: {
            const reportPathMap = {
                ...state.reportPathMap,
                [action.id]: action.result,
            };
            return {
                ...state,
                reportPathMap,
            };
        }
        default:
            return state;
    }
};
