import React from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

/**
 * Debounces callback prop call
 *
 * @param {string} prop Prop with handler
 * @param {number|Function} delay Delay to call in ms or function of props
 */
export const withDebounceHandler = (
    prop,
    delay,
) => WrappedComponent => props => {
    const delayValue = typeof delay === 'function' ? delay(props) : delay;
    const debounced = debounce((...args) => props[prop](...args), delayValue);
    const handle = (e, ...rest) => {
        // React events are pulled, so we have to persist it for debounced callback
        if (e && typeof e.persist === 'function') {
            e.persist();
        }
        debounced(e, ...rest);
    };
    WrappedComponent.propTypes = {
        ...WrappedComponent.propTypes,
        [prop]: PropTypes.func.isRequired,
    };
    return <WrappedComponent {...{ ...props, [prop]: handle }} />;
};

export default withDebounceHandler;
