import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeReportChartTypeSelector } from '../../../store/report-data/reportDataSelectors';
import {
    AllCustomChartTypes,
    CustomChartTypes,
    SalesforceChartTypes,
    AllSalesforceChartTypes,
} from './ChartTypes';

import GuageChart from './charts/GaugeChart';
import LineChart from './charts/LineChart';
import StackedColumn from './charts/StackedColumnChart';
import ColumnChart from './charts/ColumnChart';
import BarChart from './charts/BarChart';
import StackedBarChart from './charts/StackedBarChart';
import MetricChart from './charts/MetricChart';
import TableChart from './charts/TableChart';
import DonutChart from './charts/DonutChart';

export const ReportChart = ({
    sfid,
    customChartType,
    chartType,
    clickableC,
    onViewReport,
    showDate,
    defaultDateFilterC,
    visibleLinesC,
    defaultSortColumnLabelC,
    redirectFilterApiNameC,
}) => {
    switch (customChartType || chartType) {
        case CustomChartTypes.DONUT:
        case SalesforceChartTypes.DONUT:
            return (
                <DonutChart
                    sfid={sfid}
                    defaultDateFilterC={defaultDateFilterC}
                />
            );
        case CustomChartTypes.GAUGE:
        case SalesforceChartTypes.GAUGE:
            return (
                <GuageChart
                    sfid={sfid}
                    defaultDateFilterC={defaultDateFilterC}
                />
            );
        case CustomChartTypes.LINE:
        case SalesforceChartTypes.LINE:
            return (
                <LineChart
                    sfid={sfid}
                    defaultDateFilterC={defaultDateFilterC}
                />
            );
        case CustomChartTypes.STACKED_COLUMN:
        case SalesforceChartTypes.STACKED_COLUMN:
        case SalesforceChartTypes.STACKED_COLUMN_WITH_LINE:
            return (
                <StackedColumn
                    sfid={sfid}
                    defaultDateFilterC={defaultDateFilterC}
                />
            );
        case CustomChartTypes.COLUMN:
        case SalesforceChartTypes.COLUMN:
        case SalesforceChartTypes.COLUMN_WITH_LINE:
        case SalesforceChartTypes.GROUPED_COLUMN:
        case SalesforceChartTypes.GROUPED_COLUMN_WITH_LINE:
            return (
                <ColumnChart
                    sfid={sfid}
                    defaultDateFilterC={defaultDateFilterC}
                />
            );
        case CustomChartTypes.BAR:
        case SalesforceChartTypes.BAR:
            return (
                <BarChart sfid={sfid} defaultDateFilterC={defaultDateFilterC} />
            );
        case CustomChartTypes.STACKED_BAR:
        case SalesforceChartTypes.STACKED_BAR:
            return (
                <StackedBarChart
                    sfid={sfid}
                    defaultDateFilterC={defaultDateFilterC}
                />
            );
        case CustomChartTypes.METRIC:
            return (
                <MetricChart
                    sfid={sfid}
                    clickableC={clickableC}
                    onViewReport={onViewReport}
                    showDate={showDate}
                    defaultDateFilterC={defaultDateFilterC}
                />
            );
        case CustomChartTypes.TABLE:
            return (
                <TableChart
                    sfid={sfid}
                    defaultDateFilterC={defaultDateFilterC}
                    visibleLinesC={visibleLinesC}
                    defaultSortColumnLabelC={defaultSortColumnLabelC}
                    redirectFilterApiNameC={redirectFilterApiNameC}
                />
            );
        default:
            return null;
    }
};

ReportChart.propTypes = {
    sfid: PropTypes.string.isRequired,
    customChartType: PropTypes.oneOf(AllCustomChartTypes),
    chartType: PropTypes.oneOf(AllSalesforceChartTypes),
    clickableC: PropTypes.bool,
    onViewReport: PropTypes.func,
    showDate: PropTypes.bool,
    defaultDateFilterC: PropTypes.string,
    defaultSortColumnLabelC: PropTypes.string,
    visibleLinesC: PropTypes.number,
    redirectFilterApiNameC: PropTypes.string,
};

const mapStateToProps = () => {
    const chartTypeSelector = makeReportChartTypeSelector();
    return (state, ownProps) => ({
        chartType: chartTypeSelector(state, ownProps),
    });
};

export default connect(mapStateToProps)(ReportChart);
