import { createSelector } from 'reselect';
import { get } from 'lodash';

import { reportDataSelector } from '../reportDataSelectors';

export const reportGaugeSelector = createSelector(
    [reportDataSelector],
    report => get(report, 'data.factMap[T!T].aggregates[0].value'),
);

export default reportGaugeSelector;
