import * as types from './patientActionTypes';
import { formatter } from '../../helpers/date.helpers';
import orderBy from 'lodash/orderBy';

export default (
    state = {
        error: null,
        loading: false,
        profileLoad: false,
        patientProfile: {},
        patient: '',
        timeLine: [],
        // TODO: should be selector
        unFilteredTimeLine: [],
        filter: 'all',
    },
    action,
) => {
    switch (action.type) {
        case types.PATIENT_FLAGGED_REQUEST:
            return {
                ...state,
                patientProfile: {
                    ...state.patientProfile,
                    flaggedC: action.flaggedC,
                    flaggedReasonC: action.flaggedReasonC,
                },
                loading: true,
                error: null,
            };
        case types.PATIENT_FLAGGED_SUCCESS:
            return {
                ...state,
                loading: false,
                patient: action.patient,
            };
        case types.PATIENT_FLAGGED_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case types.PATIENT_PROFILE_REQUEST:
            return {
                ...state,
                profileLoad: true,
                error: null,
            };
        case types.PATIENT_PROFILE_SUCCESS: {
            if (action.patientProfile && action.patientProfile.birthDateC) {
                action.patientProfile.birthDateC = formatter(
                    action.patientProfile.birthDateC,
                );
            }

            return {
                ...state,
                profileLoad: false,
                patientProfile: action.patientProfile,
            };
        }
        case types.PATIENT_PROFILE_ERROR:
            return {
                ...state,
                profileLoad: false,
                error: action.error,
            };
        case types.FILTER_TIMELINE_REQUEST:
            let timeLine = [];

            if (action.filter === 'all') {
                timeLine = state.unFilteredTimeLine;
            } else {
                if (
                    state.unFilteredTimeLine &&
                    state.unFilteredTimeLine.length > 0
                ) {
                    timeLine = state.unFilteredTimeLine.filter(
                        event =>
                            (event.type === 'copay' ? 'fill' : event.type) ===
                            action.filter,
                    );
                }
            }

            return {
                ...state,
                filter: action.filter,
                timeLine: timeLine,
                error: null,
            };
        case types.PATIENT_FILL_INFO_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case types.PATIENT_FILL_INFO_SUCCESS:
            for (let fill of action.fillInfo) {
                if (fill.createdDate) {
                    fill.createdDate = formatter(fill.createdDate);
                }

                if (fill.claims) {
                    for (let claim of fill.claims) {
                        claim.fillDateC = formatter(claim.fillDateC);
                    }
                }
            }

            const timeLineC =
                state.filter === 'all'
                    ? action.fillInfo
                    : action.fillInfo.filter(
                          event =>
                              (event.type === 'copay' ? 'fill' : event.type) ===
                              state.filter,
                      );
            timeLineC.map(timeline => {
                if (timeline.type !== 'copay') {
                    timeline.sortDate = timeline.createdDate;
                } else {
                    let claims = timeline.claims;
                    const claimsByDate = orderBy(
                        claims,
                        ['shipDateC'],
                        ['desc'],
                    );
                    timeline.sortDate =
                        (claimsByDate.length && claimsByDate[0].shipDateC) ||
                        null;
                }
                return timeline;
            });
            timeLineC.sort(function(a, b) {
                return new Date(b.sortDate) - new Date(a.sortDate);
            });
            timeLineC.map(timeline => delete timeline.sortDate);
            return {
                ...state,
                loading: false,
                timeLine: timeLineC,
                unFilteredTimeLine: action.fillInfo,
            };
        case types.PATIENT_FILL_INFO_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
};
