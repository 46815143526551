import React, { Component } from 'react';
import FillModal from './EventModals/FillModal';
import ClinicalModal from './EventModals/ClinicalModal';
import CopayModal from './EventModals/CopayModal';
import FillEvent from './EventTypes/FillEvent';
import CopayAssistanceEvent from './EventTypes/CopayAssistanceEvent';
import ClinicalEvent from './EventTypes/ClinicalEvent';

export class PatientTimelineEvent extends Component {
    state = {
        FillOpen: false,
        ClinicalOpen: false,
        CopayOpen: false,
    };

    handleFillClose = () => {
        this.setState({ FillOpen: false });
    };
    handleClinicalClose = () => {
        this.setState({ ClinicalOpen: false });
    };
    handleCopayClose = () => {
        this.setState({ CopayOpen: false });
    };

    handleFillOpen = () => {
        this.setState({ FillOpen: true });
    };
    handleClinicalOpen = () => {
        this.setState({ ClinicalOpen: true });
    };
    handleCopayOpen = () => {
        this.setState({ CopayOpen: true });
    };

    render() {
        let event = '';

        switch (this.props.event.type) {
            case 'fill':
                event = (
                    <React.Fragment>
                        <FillModal
                            open={this.state.FillOpen}
                            handleClose={this.handleFillClose}
                            event={this.props.event}
                        />
                        <FillEvent
                            handleClick={this.handleFillOpen}
                            event={this.props.event}
                        />
                    </React.Fragment>
                );
                break;
            case 'copay':
                event = (
                    <React.Fragment>
                        <CopayAssistanceEvent
                            event={this.props.event}
                            handleClick={this.handleCopayOpen}
                        />
                        <CopayModal
                            open={this.state.CopayOpen}
                            handleClose={this.handleCopayClose}
                            event={this.props.event}
                        />
                    </React.Fragment>
                );
                break;
            case 'assessment':
                event = (
                    <React.Fragment>
                        <ClinicalModal
                            open={this.state.ClinicalOpen}
                            handleClose={this.handleClinicalClose}
                            event={this.props.event}
                        />
                        <ClinicalEvent
                            event={this.props.event}
                            handleClick={this.handleClinicalOpen}
                        />
                    </React.Fragment>
                );
                break;
            default:
                break;
        }

        return <React.Fragment>{event}</React.Fragment>;
    }
}

export default PatientTimelineEvent;
