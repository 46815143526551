import React, { Component } from 'react';
import { Button, Modal, Header } from 'semantic-ui-react';
export default class index extends Component {
    constructor(props) {
        super(props);
        this.state = { open: false };
        this.ok = this.ok.bind(this);
        this.close = this.close.bind(this);
    }
    componentWillReceiveProps(props) {
        const { open } = props;
        this.setState({ open: open });
    }
    componentDidMount() {
        const { open } = this.props;
        this.setState({ open: open });
    }
    ok() {
        const { ok } = this.props;
        this.setState({ open: false }, () => ok());
    }
    close() {
        const { cancel } = this.props;
        this.setState({ open: false }, () => cancel());
    }
    render() {
        const {
            trigger,
            ok,
            cancel,
            header,
            subheader,
            content,
            status,
        } = this.props;
        const { open } = this.state;
        return (
            <Modal
                trigger={trigger}
                size='mini'
                style={style}
                open={open}
                closeIcon
            >
                <Modal.Header>
                    <Header>{header}</Header>
                    <Header.Subheader>{subheader}</Header.Subheader>
                </Modal.Header>
                <Modal.Content>{content}</Modal.Content>
                <Modal.Actions
                    style={{
                        display: 'inline-flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        justifyContent: cancel ? 'space-between' : 'flex-end',
                        width: '100%',
                    }}
                >
                    {cancel && (
                        <Button secondary onClick={this.close}>
                            Cancel
                        </Button>
                    )}
                    {ok && (
                        <Button primary onClick={this.ok}>
                            {'OK, ' + status}
                        </Button>
                    )}
                </Modal.Actions>
            </Modal>
        );
    }
}

const style = {};
