import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { gotoChildReportPath } from '../../../../store/report-path/reportPathActions';

export const ReportPathLink = ({ row, accessor, handleClick }) => {
    return (
        <a href='/' onClick={handleClick}>
            {get(row, accessor)}
        </a>
    );
};

const mapDispatchToProps = (dispatch, { accessor, row, CustomFunction }) => ({
    handleClick: e => {
        e.preventDefault();
        dispatch(
            gotoChildReportPath({
                filterColumn:
                    CustomFunction !== undefined
                        ? CustomFunction(accessor) || accessor
                        : accessor,
                filterTitle: row[accessor],
                filterValue: row.filters[accessor],
            }),
        );
    },
});

export default connect(
    null,
    mapDispatchToProps,
)(ReportPathLink);
