import { createSelector } from 'reselect';
import { get } from 'lodash';
import { reportDataSelector, getTableData } from '../reportDataSelectors';
import { reportFilterSelector } from '../../report-path/reportPathSelectors';

export const reportLineSelector = createSelector(
    [reportDataSelector, reportFilterSelector],
    (report = {}, filter) => {
        const { data: reportData } = report;
        const result = {
            data: [],
            columns: [],
            groupings: [],
            summaries: [],
            legendPosition: null,
        };
        const chart = get(reportData, 'reportMetadata.chart');
        if (!reportData || !chart) {
            return result;
        }
        const { groupings, legendPosition, summaries } = chart;
        const { rows, columns } = getTableData({
            report,
            filter,
            showGrandTotalIfAvailable: true,
            useValues: true,
        });
        return {
            data: rows,
            columns,
            groupings,
            summaries,
            legendPosition,
        };
    },
);

export default reportLineSelector;
