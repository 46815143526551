import { CustomChartTypes } from '../components/reports/report-chart/ChartTypes';

export const ITEM_TYPES = {
    SINGLE_ITEM: 'single',
    ITEM_GROUP: 'group',
};

/**
 * Return true if two items should be grouped
 *
 * @param {Object} a
 * @param {Object} b
 *
 * @returns {bool}
 */
export const areTheSameGroup = (a, b) => {
    return isMetric(a) && isMetric(b) && a.name === b.name;
};

/**
 * Get name for a group of items
 *
 * @param {Array<Object>} items
 *
 * @returns {string}
 */
export const getGroupName = (items = []) => {
    return items.length > 0 ? items[0].name : '';
};

/**
 *
 * @param {Array} group
 * @param {Func} namingStrategy
 */
export const createGroup = group => ({
    type: ITEM_TYPES.ITEM_GROUP,
    name: getGroupName(group),
    data: [...group],
});

export const isMetric = data => data.chartTypeC === CustomChartTypes.METRIC;

export const createSingle = data => {
    if (data.chartTypeC === CustomChartTypes.METRIC) {
        return createGroup([data]);
    }
    return {
        type: ITEM_TYPES.SINGLE_ITEM,
        name: data.name,
        data: data,
    };
};

/**
 *
 * @param {Array} items
 * @param {Func} groupingStrategy
 * @param {Func} namingStrategy
 *
 * @returns {Array}
 */
export const groupItems = items => {
    if (!items.length) return [];
    const groups = [];
    let currentGroup = [];
    [...items, {}].reduce((prev, item) => {
        const shouldGroup = areTheSameGroup(prev, item);
        if (!shouldGroup) {
            if (currentGroup.length) {
                currentGroup.push(prev);
                groups.push(createGroup(currentGroup));
                currentGroup = [];
            } else {
                groups.push(createSingle(prev));
            }
        }
        if (shouldGroup) {
            currentGroup.push(prev);
        }
        return item;
    });
    if (currentGroup.length) {
        groups.push(createGroup(currentGroup));
    }
    return groups;
};

/**
 *
 * @param {Array} items
 * @param {Func} groupingStrategy
 * @param {Func} namingStrategy
 *
 * @returns {Array}
 */
export const groupItemsByName = items => {
    if (!items.length) return [];
    const groups = [];
    items.forEach(item => {
        const itemIsMetric = isMetric(item);
        if (!itemIsMetric) {
            groups.push(createSingle(item));
        } else {
            const group = groups.find(group => group.name === item.name);
            if (!group) {
                groups.push(createGroup([item]));
            } else {
                group.data.push(item);
            }
        }
    });
    return groups;
};

/**
 * @param {Object} group
 * @param {string} group.type
 * @param {Object|Array} group.data
 *
 * @returns {?string|String[]}
 */
export const getGroupSfid = ({ type, data = {} }) => {
    const sfid =
        type === ITEM_TYPES.SINGLE_ITEM
            ? data.sfid
            : Array.isArray(data) && data.length
            ? data.map(data => data.sfid)
            : null;
    return sfid;
};
