export const TIMEZONE = [
    { key: 'et', text: 'Eastern', value: 'Eastern' },
    { key: 'ct', text: 'Central', value: 'Central' },
    { key: 'mt', text: 'Mountain', value: 'Mountain' },
    { key: 'pt', text: 'Pacific', value: 'Pacific' },
];

// mobile FILTERS
export const ROSTER_FILTER_OPTION = [
    { key: 'open-orders', text: 'Open Orders (30)', value: 'open-orders' },
    { key: 'in-progress', text: 'In Progress (10)', value: 'in-progress' },
    { key: 'attention', text: 'Needs Attention (12)', value: 'attention' },
    { key: 'completed', text: 'Completed (105)', value: 'completed' },
];

export const PATIENT_ORDER_STATUS_FILTERS = [
    { key: 'open-orders', text: 'Open Orders', value: 'Open Orders' },
    { key: 'in-progress', text: 'In Progress', value: 'In Progress' },
    { key: 'attention', text: 'Needs Attention', value: 'Needs Attention' },
    { key: 'completed', text: 'Completed', value: 'Completed' },
];
