import * as types from './reportPathActionTypes';

export default (
    state = {
        valueMap: {},
        statusMap: {},
        filterMap: {},
    },
    action,
) => {
    switch (action.type) {
        case types.FETCH_REPORT_PATH_REQUEST: {
            const id = action.id;
            const status = state.statusMap[id] || {};
            const statusMap = {
                ...state.statusMap,
                [id]: {
                    ...status,
                    loading: true,
                    loaded: false,
                    error: null,
                },
            };
            return {
                ...state,
                statusMap,
            };
        }
        case types.FETCH_REPORT_PATH_SUCCESS: {
            const id = action.id;

            const status = state.statusMap[id] || {};
            const statusMap = {
                ...state.statusMap,
                [id]: {
                    ...status,
                    loading: false,
                    loaded: true,
                    error: null,
                },
            };
            const valueMap = {
                ...state.valueMap,
                [id]: action.result,
            };
            return {
                ...state,
                statusMap,
                valueMap,
            };
        }
        case types.FETCH_REPORT_PATH_ERROR: {
            const id = action.id;
            const status = state.statusMap[id] || {};
            const statusMap = {
                ...state.statusMap,
                [id]: {
                    ...status,
                    loading: false,
                    loaded: false,
                    error: action.error,
                },
            };
            return {
                ...state,
                statusMap,
            };
        }
        case types.FETCH_REPORT_PATH_CHILD_REQUEST: {
            const filterColumn = {
                ...state.filterMap[action.filterColumn],
                [action.filterValue]: action.filterTitle,
            };

            const filterMap = {
                ...state.filterMap,
                [action.filterColumn]: filterColumn,
            };
            return {
                ...state,
                filterMap,
            };
        }
        default:
            return state;
    }
};
