import get from 'lodash/get';
import { NumberFormat } from '../../../../helpers/currency.helpers';

export const CurrencyFormatter = ({ accessor, row }) => {
    const num = Number(get(row, accessor));
    if (Number.isNaN(num)) {
        return null;
    }
    return NumberFormat.format(num);
};

export default CurrencyFormatter;
