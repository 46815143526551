import React from 'react';
import PropTypes from 'prop-types';
import withReportBar from '../../../common/report/withReportBar';
import StackedBarChartType from '../../../common/charts/bar/StackedBar';

export const StackedBarChart = withReportBar(props => (
    <StackedBarChartType {...props} />
));

StackedBarChart.propTypes = {
    sfid: PropTypes.string.isRequired,
    defaultDateFilterC: PropTypes.string,
};

export default StackedBarChart;
