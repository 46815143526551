import {
    compose,
    setPropTypes,
    withHandlers,
    mapProps,
    defaultProps,
    setDisplayName,
} from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { patientRosterFilterValueSelector } from '../../../store/patient-roster/patientRosterSelectors';
import { filter } from '../../../store/patient-roster/patientRosterActions';

const mapStateToProps = (state, { name }) => {
    return {
        defaultValue: patientRosterFilterValueSelector(state, { name }),
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            filter: filter,
        },
        dispatch,
    );

export const withSearch = compose(
    setPropTypes({
        like: PropTypes.bool,
        name: PropTypes.string.isRequired,
        date: PropTypes.bool,
        dateFormat: PropTypes.string,
    }),
    defaultProps({
        like: false,
    }),
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withHandlers({
        onChange: ({ filter, like, name, date, dateFormat }) => (
            _,
            { value },
        ) => {
            filter({ name, value, date, dateFormat, like });
        },
    }),
    mapProps(({ filter, like, date, ...otherProps }) => otherProps),
    setDisplayName('withSearch'),
);

export default withSearch;
