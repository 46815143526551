import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';

export class SidebarNavMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            selectedNavOption: this.props.selectedNavOption,
            accessMap: props.accessMap,
            currentId: props.currentId,
        };
        this.toggleExpanded = this.toggleExpanded.bind(this);
    }

    toggleExpanded() {
        this.setState({ expanded: !this.state.expanded });
    }

    render() {
        const menuToggle = this.state.expanded
            ? 'mobile-show sidebarNav active'
            : 'mobile-show sidebarNav inactive';
        const buttonToggle = this.state.expanded
            ? 'mobile-show sideBarNavToggle active'
            : 'mobile-show sideBarNavToggle';
        const dimmerToggle = this.state.expanded
            ? 'sideBarDimmer active'
            : 'sideBarDimmer';

        return (
            <React.Fragment>
                <div className={buttonToggle} onClick={this.toggleExpanded}>
                    <div className='toggleWrap'>
                        <span>&nbsp;</span>
                        <span>&nbsp;</span>
                        <span>&nbsp;</span>
                    </div>
                </div>
                <div className={menuToggle}>
                    <div className='navContent'>
                        <Menu icon labeled='true' vertical>
                            <Menu.Item
                                as={NavLink}
                                name='dashboard'
                                to='/dashboard'
                                activeClassName='active'
                            >
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='25'
                                    height='25'
                                    viewBox='0 0 25 25'
                                >
                                    <g fill='none' fillRule='evenodd'>
                                        <rect
                                            width='25'
                                            height='25'
                                            fill='#D8D8D8'
                                            fillOpacity='.01'
                                            fillRule='nonzero'
                                        />
                                        <path
                                            stroke='#ffffff'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                            strokeWidth='1.5'
                                            d='M10.5 12.526C10.5011063 13.0657455 10.0647441 13.5043455 9.525 13.506L1.725 13.506C1.46570961 13.5054704 1.21726432 13.4018909 1.03438747 13.2180762.851510606 13.0342616.749203274 12.7852897.75 12.526L.75 1.736C.749469024 1.47661811.851999132 1.22764895 1.03503475 1.04386318 1.21807038.860077415 1.46661811.756529891 1.726.756L9.526.769C10.0653531.771204824 10.5011075 1.20964354 10.5 1.749L10.5 12.526zM23.246 6.544C23.242147 7.07877668 22.8057802 7.50932528 22.271 7.506L14.471 7.506C14.2140963 7.50786272 13.9669895 7.40752294 13.7841113 7.22708311 13.6012331 7.04664328 13.4975857 6.80090554 13.496 6.544L13.496 1.731C13.4993042 1.19677676 13.9347737.766230312 14.469.769L22.269.756C22.8043344.752118569 23.2415988 1.18266962 23.246 1.718L23.246 6.544zM10.5 22.291C10.4984136 22.5485267 10.3945876 22.7948751 10.2113634 22.9758488 10.0281393 23.1568225 9.78052669 23.2575962 9.523 23.256L1.723 23.243C1.18822289 23.2452147.752754866 22.8137746.75 22.279L.75 17.47C.751322053 17.2128299.854820953 16.9667335 1.03769877 16.7859189 1.22057659 16.6051043 1.46783145 16.5044042 1.725 16.506L9.525 16.506C10.0603308 16.5032303 10.4966958 16.9346722 10.5 17.47L10.5 22.291zM14.472 23.256C14.2124444 23.2554704 13.9637448 23.1517856 13.7806804 22.9677834 13.597616 22.7837811 13.4952033 22.5345549 13.496 22.275L13.496 11.487C13.4952031 11.2276179 13.5974787 10.978543 13.7803269 10.7945695 13.9631751 10.6105961 14.2116179 10.5067944 14.471 10.506L22.271 10.506C22.5303821 10.5067944 22.7788249 10.6105961 22.9616731 10.7945695 23.1445213 10.978543 23.2467969 11.2276179 23.246 11.487L23.246 22.262C23.2471092 22.8015202 22.8115143 23.2402457 22.272 23.243L14.472 23.256z'
                                            transform='translate(.534 .468)'
                                        />
                                    </g>
                                </svg>
                                Dashboard
                            </Menu.Item>

                            <Menu.Item
                                as={NavLink}
                                name='reports'
                                to='/reports'
                                activeClassName='active'
                            >
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='25'
                                    height='25'
                                    viewBox='0 0 25 25'
                                >
                                    <g fill='none' fillRule='evenodd'>
                                        <rect
                                            width='25'
                                            height='25'
                                            fill='#D8D8D8'
                                            fillOpacity='.01'
                                            fillRule='nonzero'
                                        />
                                        <path
                                            stroke='#ffffff'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                            strokeWidth='1.5'
                                            d='M.75 19.75L23.25 19.75M6 9.25L3 9.25C2.58578644 9.25 2.25 9.58578644 2.25 10L2.25 19.75 6.75 19.75 6.75 10C6.75 9.58578644 6.41421356 9.25 6 9.25zM13.5.25L10.5.25C10.0857864.25 9.75.585786438 9.75 1L9.75 19.75 14.25 19.75 14.25 1C14.25.585786438 13.9142136.25 13.5.25zM21 4.75L18 4.75C17.5857864 4.75 17.25 5.08578644 17.25 5.5L17.25 19.75 21.75 19.75 21.75 5.5C21.75 5.08578644 21.4142136 4.75 21 4.75z'
                                            transform='translate(.477 2.355)'
                                        />
                                    </g>
                                </svg>
                                Reports
                            </Menu.Item>

                            <Menu.Item
                                as={NavLink}
                                name='case-management'
                                to='/case-management'
                                activeClassName='active'
                            >
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='25'
                                    height='25'
                                    viewBox='0 0 25 25'
                                >
                                    <g
                                        fill='none'
                                        fillRule='evenodd'
                                        stroke='#ffffff'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        strokeWidth='1.5'
                                        transform='translate(.566 .56)'
                                    >
                                        <path
                                            stroke='#ffffff'
                                            d='M23.25,14.5314196 L23.25,17.9460739 L23.25,19.4574375 L23.25,22.271 C23.25,22.8111345 22.8121345,23.249 22.272,23.249 L1.728,23.249 C1.18786551,23.249 0.75,22.8111345 0.75,22.271 L0.75,19.4666705 L0.75,17.8897292 L0.75,14.4557803'
                                        />
                                        <path
                                            stroke='#ffffff'
                                            d='M.749304274 14.5144925L.750000082 13.7920001C.750221004 13.2520219 1.18802177 12.8144002 1.728 12.8144002L22.272 12.814C22.8121345 12.814 23.25 13.2518655 23.25 13.792L23.25 14.5299991M2.25 12.814L2.25 6.977C2.25330448 6.43488472 2.69487918 5.99778393 3.237 6L11.625 6C11.9349627 5.99952676 12.2271432 6.14469125 12.414 6.392L13.6 8.248 20.014 8.248C20.5565079 8.24633984 20.997795 8.68449409 21 9.227L21 12.814M9.065 15.748L14.935 15.748M19.737 5.248L19.737 3.977C19.7336955 3.43488472 19.2921208 2.99778393 18.75 3L13.6 3 12.414 1.14C12.2271432.892691251 11.9349627.747526762 11.625.748L4.5.748C4.23929154.746936973 3.98883896.849484633 3.80374009 1.03308321 3.61864121 1.21668179 3.51405871 1.46629152 3.513 1.727L3.513 2.77'
                                        />
                                    </g>
                                </svg>
                                Case Management
                            </Menu.Item>

                            <Menu.Item
                                as={NavLink}
                                name='order-status'
                                to='/patient-order-status'
                                activeClassName='active'
                            >
                                <svg
                                    width='25px'
                                    height='25px'
                                    viewBox='0 0 25 25'
                                    version='1.1'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                    <g
                                        id='Symbols'
                                        stroke='none'
                                        strokeWidth='1'
                                        fill='none'
                                        fillRule='evenodd'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    >
                                        <g
                                            id='drugs-active'
                                            stroke='#ffffff'
                                            strokeWidth='1.5'
                                        >
                                            <g
                                                id='icon-drugs'
                                                transform='translate(0.433949, 0.539595)'
                                            >
                                                <path
                                                    d='M0.75,11 L23.25,11'
                                                    id='Shape'
                                                />
                                                <path
                                                    d='M21.7500002,11 L2.24999983,11 C2.24952679,14.6417461 4.28048409,17.9796138 7.515,19.653 C7.70105359,19.749908 7.8381711,19.9201266 7.89323897,20.1225485 C7.94830684,20.3249704 7.91631884,20.5411933 7.805,20.719 C7.56174435,21.1116314 7.34525285,21.5202257 7.157,21.942 C7.05245689,22.1741126 7.07279059,22.4434087 7.21100187,22.6571916 C7.34921315,22.8709746 7.58643075,23.0000546 7.841,23 L16.159,23 C16.4133599,22.9995312 16.6501952,22.8703433 16.7882931,22.6567358 C16.9263909,22.4431283 16.94698,22.1741365 16.843,21.942 C16.6547471,21.5202257 16.4382556,21.1116314 16.195,20.719 C16.0839882,20.5411162 16.0521747,20.3250005 16.107217,20.1226725 C16.1622593,19.9203446 16.2991721,19.75013 16.485,19.653 C19.7195159,17.9796138 21.7504732,14.6417461 21.7500002,11 Z'
                                                    id='Shape'
                                                />
                                                <path
                                                    d='M14.25,8 L18.207,1.405 C18.5245029,0.876061292 19.1668235,0.638672893 19.752,0.834 C20.08338,0.944420618 20.3572681,1.18205216 20.5133265,1.49454404 C20.6693848,1.80703592 20.6948097,2.16874995 20.584,2.5 L18.75,8'
                                                    id='Shape'
                                                />
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                                Order Status
                            </Menu.Item>

                            <Menu.Item
                                as={NavLink}
                                name='patients'
                                to='/patient-roster'
                                activeClassName='active'
                            >
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='25'
                                    height='25'
                                    viewBox='0 0 25 25'
                                >
                                    <g fill='none' fillRule='evenodd'>
                                        <rect
                                            width='25'
                                            height='25'
                                            fill='#D8D8D8'
                                            fillOpacity='.01'
                                            fillRule='nonzero'
                                        />
                                        <g
                                            stroke='#ffffff'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                            strokeWidth='1.5'
                                            transform='translate(.513 .578)'
                                        >
                                            <circle cx='4.5' cy='6' r='2.25' />
                                            <path d='M4.5,9.75 C2.42893219,9.75 0.75,11.4289322 0.75,13.5 L0.75,15.75 L2.25,15.75 L3,21.75 L6,21.75' />
                                            <circle cx='19.5' cy='6' r='2.25' />
                                            <path d='M19.5,9.75 C21.5710678,9.75 23.25,11.4289322 23.25,13.5 L23.25,15.75 L21.75,15.75 L21,21.75 L18,21.75' />
                                            <circle cx='12' cy='3.75' r='3' />
                                            <path d='M17.25,13.5 C17.25,10.6005051 14.8994949,8.25 12,8.25 C9.10050506,8.25 6.75,10.6005051 6.75,13.5 L6.75,15.75 L9,15.75 L9.75,23.25 L14.25,23.25 L15,15.75 L17.25,15.75 L17.25,13.5 Z' />
                                        </g>
                                    </g>
                                </svg>
                                Patient Roster
                            </Menu.Item>
                            <Menu.Item
                                as={NavLink}
                                name='account'
                                to='/account'
                                activeClassName='active'
                            >
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='25'
                                    height='25'
                                    viewBox='0 0 25 25'
                                >
                                    <g fill='none' fillRule='evenodd'>
                                        <rect
                                            width='25'
                                            height='25'
                                            fill='#D8D8D8'
                                            fillOpacity='.01'
                                            fillRule='nonzero'
                                        />
                                        <g
                                            stroke='#ffffff'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                            strokeWidth='1.5'
                                            transform='translate(.406 2.766)'
                                        >
                                            <circle
                                                cx='8.382'
                                                cy='6.415'
                                                r='2.625'
                                            />
                                            <path d='M3.88172829 14.2896077C3.88172832 11.8043264 5.89644694 9.78960778 8.38172829 9.78960778 10.8670096 9.78960778 12.8817283 11.8043264 12.8817283 14.2896077L3.88172829 14.2896077zM14.3817283 6.78960772L18.8817283 6.78960772M14.3817283 9.78960772L20.3817283 9.78960772' />
                                            <path d='M21.8817283,0.789607715 L2.38172829,0.789607715 C1.55330116,0.789607715 0.881728288,1.46118059 0.881728288,2.28960772 L0.881728288,17.2896077 C0.881728288,18.1180348 1.55330116,18.7896077 2.38172829,18.7896077 L5.38172829,18.7896077 C5.38172829,17.9611806 6.05330116,17.2896077 6.88172829,17.2896077 C7.71015541,17.2896077 8.38172829,17.9611806 8.38172829,18.7896077 L15.8817283,18.7896077 C15.8817283,17.9611806 16.5533012,17.2896077 17.3817283,17.2896077 C18.2101554,17.2896077 18.8817283,17.9611806 18.8817283,18.7896077 L21.8817283,18.7896077 C22.7101554,18.7896077 23.3817283,18.1180348 23.3817283,17.2896077 L23.3817283,2.28960772 C23.3817283,1.46118059 22.7101554,0.789607715 21.8817283,0.789607715 Z' />
                                        </g>
                                    </g>
                                </svg>
                                Account
                            </Menu.Item>
                            <Menu.Item
                                as={NavLink}
                                name='logout'
                                to='/'
                                activeClassName=''
                            >
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='25'
                                    height='25'
                                    viewBox='0 0 25 25'
                                >
                                    <g
                                        fill='none'
                                        fillRule='evenodd'
                                        transform='translate(0 -.179)'
                                    >
                                        <rect
                                            width='25'
                                            height='25'
                                            y='.179'
                                            fill='#D8D8D8'
                                            fillOpacity='.01'
                                            fillRule='nonzero'
                                        />
                                        <path
                                            stroke='#FFF'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                            strokeWidth='1.5'
                                            d='M0.865735219,12.6627365 L17.0726548,12.6627365'
                                        />
                                        <polyline
                                            stroke='#FFF'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                            strokeWidth='1.5'
                                            points='13.214 16.522 17.073 12.663 13.214 8.804'
                                        />
                                        <path
                                            stroke='#FFF'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                            strokeWidth='1.5'
                                            d='M3.49588673,17.39207 C5.68187174,21.882841 10.6609747,24.2900484 15.5382576,23.2140996 C20.4155405,22.1381508 23.9197148,17.8594973 24.013392,12.8658234 C24.1070692,7.87214946 20.7658318,3.4650817 15.932334,2.20696669 C11.0988361,0.948851692 6.03295369,3.16762354 3.68007966,7.57324931'
                                        />
                                    </g>
                                </svg>
                                Log out
                            </Menu.Item>
                        </Menu>
                    </div>
                </div>

                <div className={dimmerToggle} onClick={this.toggleExpanded} />
            </React.Fragment>
        );
    }
}

export default SidebarNavMenu;
