import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import auth from './auth/authReducers';
import contact from './contact/contactReducers';
import cases from './case/caseReducers';
import file from './file/fileReducer';
import downloadData from './download-data/downloadDataReducer';
import patient from './patient/patientReducer';
import orderStatus from './order-status/orderStatusReducer';
import patientRoster from './patient-roster/patientRosterReducers';
import dashboard from './dashboard/dashboardReducer';
import report from './report/reportReducer';
import reportData from './report-data/reportDataReducer';
import reportPath from './report-path/reportPathReducers';
import domain from './domain/domainReducer';
import caseList from './case-list/reducer';
import { CASE_LIST_PREFIX } from './case-list';
import notifications from './notifications/reducer';
import { NOTIFICATIONS_PREFIX } from './notifications';
import chat from './chat/chatReducers';

const createRootReducer = history =>
    combineReducers({
        auth,
        contact,
        domain,
        patient,
        downloadData,
        patientRoster,
        cases,
        orderStatus,
        router: connectRouter(history),
        dashboard,
        report,
        reportData,
        reportPath,
        file,
        chat,
        [CASE_LIST_PREFIX]: caseList,
        [NOTIFICATIONS_PREFIX]: notifications,
    });

export default createRootReducer;
