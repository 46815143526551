import * as types from './actionTypes';

export const fetch = () => ({
    type: types.GET_NOTIFICATIONS_REQUEST,
});

export const success = ({ data }) => ({
    type: types.GET_NOTIFICATIONS_SUCCESS,
    payload: {
        data,
    },
});

export const error = ({ errorMessage }) => ({
    type: types.GET_NOTIFICATIONS_ERROR,
    payload: {
        errorMessage: errorMessage,
    },
});

export const refresh = () => ({
    type: types.REFRESH_NOTIFICATIONS,
});

export const viewHighSpendFillNotification = ({ patientId }) => ({
    type: types.VIEW_NOTIFICATION,
    payload: {
        type: 'highSpendFill',
        data: {
            patientId,
        },
    },
});

export const viewHighSpendYearNotification = ({ patientId }) => ({
    type: types.VIEW_NOTIFICATION,
    payload: {
        type: 'highSpendYear',
        data: {
            patientId,
        },
    },
});
