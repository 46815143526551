import * as types from './reportDataActionTypes';
import DateFilterValues from '../../components/common/date-filter/DateFilterValues';

export const initialState = {
    reportDataStatus: {},
    reportDataIdMap: {},
    dateFilter: DateFilterValues.DEFAULT,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.REPORT_DATA_REQUEST: {
            const { reportId, defaultDateFilterC } = action;
            const reportDate = defaultDateFilterC || state.dateFilter;
            const status = state.reportDataStatus[reportId] || {};
            const reportDataStatus = {
                ...state.reportDataStatus,
                [reportId]: {
                    ...status,
                    [reportDate]: {
                        loading: true,
                        loaded: false,
                        error: null,
                    },
                },
            };
            return {
                ...state,
                reportDataStatus,
            };
        }
        case types.REPORT_DATA_SUCCESS: {
            const { reportId, reportDate } = action;
            const status = state.reportDataStatus[reportId] || {};
            const reportDataStatus = {
                ...state.reportDataStatus,
                [reportId]: {
                    ...status,
                    [reportDate]: {
                        loading: false,
                        loaded: true,
                        error: null,
                    },
                },
            };
            const reportDateMap = state.reportDataIdMap[reportId] || {};
            const reportDataIdMap = {
                ...state.reportDataIdMap,
                [reportId]: {
                    ...reportDateMap,
                    [reportDate]: action.result,
                },
            };
            return {
                ...state,
                reportDataIdMap,
                reportDataStatus,
            };
        }
        case types.REPORT_DATA_ERROR: {
            const { reportId, reportDate } = action;
            const status = state.reportDataStatus[reportId] || {};
            const reportDataStatus = {
                ...state.reportDataStatus,
                [reportId]: {
                    ...status,
                    [reportDate]: {
                        loading: false,
                        loaded: true,
                        error: action.error,
                    },
                },
            };
            // console.log('Error data status: ', reportDataStatus)
            return {
                ...state,
                reportDataStatus,
            };
        }
        case types.SET_DATE_FILTER:
            return {
                ...state,
                dateFilter: action.dateFilter,
            };
        default:
            return state;
    }
};
