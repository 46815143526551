import React from 'react';
import { toggleReport } from '../../../../store/report/reportActions';
import { connect } from 'react-redux';
import { Checkbox } from 'semantic-ui-react';
import { isGroupEnabledSelector } from '../../../../store/report/reportSelectors';

export const ReportFilterElement = ({ name, enabled, toggle }) => (
    <div>
        <Checkbox label={name} checked={enabled} onChange={toggle} />
    </div>
);

const mapStateToProps = (state, ownProps) => ({
    enabled: isGroupEnabledSelector(state, ownProps),
});

const mapDispatchToProps = (dispatch, { type, data }) => ({
    toggle: () => {
        dispatch(toggleReport({ type, data }));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReportFilterElement);
