import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import TwoRow from '../twoRow';
import Header from '../../newComponents/dashboard/header';
import Content from '../../components/dashboard/items';
import Sidebar from '../../components/dashboard/sidebar';
export class index extends Component {
    render() {
        const stage = 'Last Quarter';
        return (
            <Grid className='full-page' stretched>
                <Header
                    title='Dashboard'
                    subTitle={'Showing: ' + stage}
                    dateFilter
                />
                <Grid.Row style={{ width: '100%' }}>
                    <Grid.Column computer='11' tablet='11' mobile='16'>
                        <TwoRow>
                            <Content />
                        </TwoRow>
                    </Grid.Column>
                    <Grid.Column computer='5' tablet='5' mobile='16'>
                        <Sidebar />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(index);
