import * as types from './reportPathActionTypes';

export const fetchReportPath = id => ({
    type: types.FETCH_REPORT_PATH_REQUEST,
    id,
});

export const fetchReportPathSuccess = (id, result) => ({
    type: types.FETCH_REPORT_PATH_SUCCESS,
    id,
    result,
});

export const fetchReportPathError = (id, error) => ({
    type: types.FETCH_REPORT_PATH_ERROR,
    id,
    error,
});

export const gotoChildReportPath = ({
    filterColumn = null,
    filterValue = null,
    filterTitle = null,
}) => ({
    type: types.FETCH_REPORT_PATH_CHILD_REQUEST,
    filterColumn,
    filterValue,
    filterTitle,
});
