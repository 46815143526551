import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import { compose, withHandlers, mapProps, setDisplayName } from 'recompose';
import OrderStatusCount from './OrderStatusCount';

const enhance = compose(
    mapProps(({ current, value, ...otherProps }) => ({
        ...otherProps,
        value,
        active: current === value,
    })),
    withHandlers({
        toggle: ({ handleClick, active, value }) => () => {
            handleClick(active ? null : value);
        },
    }),
    setDisplayName('StatusButton'),
);

/**
 * @param {Object} props
 * @param {Bool} props.active
 * @param {*} props.value
 * @param {Function} props.toggle
 * @param {String} props.title
 */
export const StatusButton = ({ active, toggle, title, value }) => {
    return (
        <Button
            secondary={!active}
            primary={active}
            size='small'
            onClick={toggle}
        >
            {title} (<OrderStatusCount value={value} />)
        </Button>
    );
};

StatusButton.propTypes = {
    current: PropTypes.string,
    active: PropTypes.bool,
    toggle: PropTypes.func.isRequired,
    title: PropTypes.string,
    value: PropTypes.any.isRequired,
};

export const EnhancedStatusButton = enhance(StatusButton);

EnhancedStatusButton.propTypes = {
    current: PropTypes.any,
    value: PropTypes.any.isRequired,
    handleClick: PropTypes.func.isRequired,
    title: PropTypes.string,
};

export default EnhancedStatusButton;
