import { put, select, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import * as api from '../../api/patientRosterAPI';
import * as actions from './patientRosterActions';
import * as types from './patientRosterActionTypes';
import * as selectors from './patientRosterSelectors';

export function* fetchList() {
    try {
        const skip = yield select(selectors.getPatientRosterSkipItems);
        const limit = yield select(selectors.getPatientRosterPageSize);
        const filter = yield select(selectors.patientRosterFiltersSelector);
        const sort = yield select(selectors.patientRosterSort);
        const params = {
            skip,
            limit,
            filter,
            sort,
        };
        const result = yield api.getPatientRoster(params);
        const { total = 0, data = [] } = result;
        yield put({
            type: types.PATIENT_ROSTER_LIST_SUCCESS,
            data: data,
            total,
        });
    } catch (error) {
        yield put({
            type: types.PATIENT_ROSTER_LIST_ERROR,
            error: error.message,
        });
    }
}

export function* fetchOverview() {
    try {
        const filter = yield select(selectors.patientRosterFiltersSelector);
        const params = {
            filter,
        };
        const data = yield api.getPatientRosterOverview(params);
        yield put({
            type: types.PATIENT_ROSTER_OVERVIEW_SUCCESS,
            data: data.data,
        });
    } catch (error) {
        yield put({
            type: types.PATIENT_ROSTER_OVERVIEW_ERROR,
            error: error.message,
        });
    }
}

export function* resetReportPathFilters() {
    yield put(push('/patient-roster'));
}

export function* request() {
    yield put(actions.fetch());
}

export function* rootSaga() {
    yield takeLatest(types.PATIENT_ROSTER_LIST_REQUEST, fetchList);
    yield takeLatest(types.PATIENT_ROSTER_FILTER, request);
    yield takeLatest(types.PATIENT_ROSTER_FILTER_MULTIPLE, fetchOverview);
    yield takeLatest(types.PATIENT_ROSTER_FILTER_MULTIPLE, request);
    yield takeLatest(types.PATIENT_ROSTER_FILTER, fetchOverview);
    yield takeLatest(types.PATIENT_ROSTER_FILTER_RESET, resetReportPathFilters);
    yield takeLatest(types.PATIENT_ROSTER_FILTER_RESET, request);
    yield takeLatest(types.PATIENT_ROSTER_FILTER_RESET, fetchOverview);

    yield takeLatest(types.PATIENT_ROSTER_SORT, request);

    yield takeLatest(types.PATIENT_ROSTER_GO_TO_NEXT_PAGE, request);
    yield takeLatest(types.PATIENT_ROSTER_GO_TO_PREV_PAGE, request);
    yield takeLatest(types.PATIENT_ROSTER_GO_TO_FIRST_PAGE, request);
    yield takeLatest(types.PATIENT_ROSTER_GO_TO_LAST_PAGE, request);

    yield takeLatest(types.PATIENT_ROSTER_OVERVIEW_REQUEST, fetchOverview);
    yield takeLatest(types.SET_PAGE_SIZE, request);
}

export default rootSaga;
