import React from 'react';
import { connect } from 'react-redux';
import { Header, Statistic, Divider } from 'semantic-ui-react';
import {
    gaugeUpperBoundSelector,
    gaugeLowerBoundSelector,
} from '../../../store/auth/authSelectors';
import Donut from '../../common/charts/donut';
import GaugeChartType from '../../common/charts/gauge';

const donutProps = {
    label: {
        showPercentages: false,
        showValues: true,
    },
    nameKey: 'status',
    dataKey: 'count',
    shiftAngle: 250,
    labelPosition: 'inside',
    height: 375,
    legend: {
        layout: 'vertical',
        positionX: 'right',
        positionY: 'middle',
        show: true,
        title: 'Member Plan Status',
    },
};

export const PatientRosterStatistics = ({
    stats: { counts = [], totalPatients, activePatients, adherence = 0 } = {},
    lowerBound,
    upperBound,
}) => (
    <div className='flexDiv flexColumn alignCenter'>
        <Header as='h2' dividing className='SelfStretch'>
            Overview
        </Header>
        <Header as='h4'>Disease States</Header>
        <Statistic.Group horizontal>
            {counts.map((count, index) => {
                return (
                    <Statistic key={index} color='green'>
                        <Statistic.Value>{count.count}</Statistic.Value>
                        <Statistic.Label>{count.disease}</Statistic.Label>
                    </Statistic>
                );
            })}
        </Statistic.Group>
        <Header as='h4'>Active Patients</Header>
        <Donut
            {...donutProps}
            total={{
                show: true,
                value: totalPatients,
            }}
            data={[
                {
                    status: 'active',
                    count: activePatients,
                },
            ]}
        />
        <Divider hidden />
        <Header as='h4'>Adherence Performance</Header>
        <GaugeChartType
            min={70}
            max={150}
            validMin={80} // validMin={lowerBound || 80}
            validMax={110} // validMax={upperBound || 110}
            data={adherence}
        />
    </div>
);

const mapStateToProps = state => ({
    stats: state.patientRoster.stats,
    upperBound: gaugeUpperBoundSelector(state),
    lowerBound: gaugeLowerBoundSelector(state),
});

export default connect(mapStateToProps)(PatientRosterStatistics);
