import { createSelector } from 'reselect';
import queryString from 'query-string';

export const getReportPathStatus = (state, id) =>
    state.reportPath.statusMap[id] || {
        loaded: false,
        loading: false,
        error: null,
    };

export const currentReportPathSelector = state =>
    state.router.location.pathname.replace(/.*\/(\w+).*/, '$1');

export const locationSearchSelector = state => state.router.location.search;

export const filterMapSelector = state => state.reportPath.filterMap;

export const reportFilterSelector = createSelector(
    locationSearchSelector,
    search => queryString.parse(search),
);

export const getReportPathData = (state, id) =>
    state.reportPath.valueMap[id] || {};

export const getFilterTitle = createSelector(
    [reportFilterSelector, filterMapSelector],
    (filter, filterMap) =>
        Object.keys(filter)
            .map(filterColumn => {
                const filterValue = filter[filterColumn];
                return (filterMap[filterColumn] || {})[filterValue];
            })
            .filter(value => value)
            .join(', '),
);
