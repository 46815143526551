import React from 'react';
import { Header } from 'semantic-ui-react';

import DateFilterValue from '../../common/date-filter/DateFilterValue';

export const DashboardHeader = ({ date }) => {
    return (
        <Header as='h1'>
            Dashboard{' '}
            <span className='tablet-up-show'>
                <small>
                    Showing: <DateFilterValue />
                </small>
            </span>
        </Header>
    );
};

export default DashboardHeader;
