import PropTypes from 'prop-types';
import { statusFilterCountSelector } from '../../../store/order-status/orderStatusSelectors';
import { connect } from 'react-redux';

const mapStateToProps = (state, { value }) => ({
    count: statusFilterCountSelector(state, { value }),
});

export const OrderStatusCount = ({ count }) =>
    count !== undefined ? count : 0;

OrderStatusCount.propTypes = {
    count: PropTypes.number.isRequired,
};

const EnhancedOrderStatusCount = connect(mapStateToProps)(OrderStatusCount);
EnhancedOrderStatusCount.propTypes = {
    value: PropTypes.any.isRequired,
};

export default EnhancedOrderStatusCount;
