import React from 'react';
import { Pie, PieChart, ResponsiveContainer, Tooltip, Legend } from 'recharts';
import PropTypes from 'prop-types';

import withLabel from './withLabel';
import LegendContent from './Legend';
import { Total } from './Total';
import EmptyReport from '../empty';

/**
 *
 * @param {Object} props
 * @param {string|number} props.width
 * @param {string|number} props.height
 * @param {Array<Object>} props.data
 * @param {string} props.dataKey
 * @param {string} props.nameKey
 * @param {Object} props.legend
 * @param {bool} props.legend.show
 * @param {string} props.legend.title
 * @param {string} props.legend.layout
 * @param {string} props.legend.positionX
 * @param {string} props.legend.positionY
 * @param {Object} props.label
 * @param {bool} props.label.showValues
 * @param {bool} props.label.showPercentages
 * @param {Object} props.total
 * @param {bool} props.total.show
 * @param {string|number} props.total.value
 * @param {number} props.shiftAngle
 * @param {string} props.fillColor
 * @param {bool} props.animated
 * @param {Array<string>} props.colorPalette
 */
export const Donut = ({
    width,
    height,
    data,
    dataKey,
    nameKey,
    legend,
    label,
    total,
    shiftAngle,
    fillColor,
    animated,
    colorPalette,
}) => {
    const colorPaletteLength = colorPalette.length;
    const dataWithColors = data.map((row, index) => ({
        ...row,
        [dataKey]: parseFloat(row[dataKey]),
        fill: colorPalette[index % colorPaletteLength],
    }));
    if (!data.length) {
        return <EmptyReport />;
    }
    return (
        <ResponsiveContainer width={width} height={height}>
            <PieChart>
                {dataWithColors.length > 0 && (
                    <Pie
                        data={dataWithColors}
                        dataKey={dataKey}
                        nameKey={nameKey}
                        cx='50%'
                        cy='50%'
                        innerRadius='50%'
                        outerRadius='80%'
                        startAngle={shiftAngle}
                        endAngle={
                            shiftAngle > 0
                                ? -360 + shiftAngle
                                : 360 - shiftAngle
                        }
                        blendStroke={true}
                        label={
                            label.showValues
                                ? withLabel({ ...label, total })
                                : false
                        }
                        labelLine={false}
                        isAnimationActive={animated}
                        fill={fillColor}
                    />
                )}
                <Tooltip />
                {legend.show && (
                    <Legend
                        layout={legend.layout}
                        iconType='circle'
                        align={legend.center} //positionX
                        verticalAlign={legend.bottom} //positionY //hiding
                        /*title={legend.title}*/ content={LegendContent}
                    />
                )}
                {total.show && <Total value={total.value} />}
            </PieChart>
        </ResponsiveContainer>
    );
};

Donut.propTypes = {
    /**
     * Array of values
     */
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    /**
     * Key used to map chart values
     */
    dataKey: PropTypes.string,
    /**
     * Key used to map chart groupings
     */
    nameKey: PropTypes.string,
    /**
     * Legend options
     */
    legend: PropTypes.shape({
        show: PropTypes.bool.isRequired,
        title: PropTypes.string,
        layout: PropTypes.oneOf(['vertical', 'horizontal']),
        positionX: PropTypes.oneOf(['left', 'center', 'right']),
        positionY: PropTypes.oneOf(['top', 'middle', 'bottom']),
    }),
    /**
     * Label options
     */
    label: PropTypes.shape({
        showValues: PropTypes.bool.isRequired,
        showPercentages: PropTypes.bool.isRequired,
    }),
    /**
     * Total options
     */
    total: PropTypes.shape({
        show: PropTypes.bool.isRequired,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    /**
     * Colors used in chart palette
     */
    colorPalette: PropTypes.arrayOf(PropTypes.string),
    /**
     * Chart width in % or px
     */
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * Chart height in % or px
     */
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * Chart starting angle
     */
    shiftAngle: PropTypes.number,
    /**
     * Use animations in drawing
     */
    animated: PropTypes.bool,
    /**
     * Default donut color
     */
    fillColor: PropTypes.string,
};

Donut.defaultProps = {
    shiftAngle: 0,
    width: '100%',
    height: 375,
    animated: true,
    fillColor: '#D6D6D6',
    colorPalette: ['#00B06D', '#8FCC22', '#F7D108'],
};

export default Donut;
