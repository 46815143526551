import React from 'react';
import { connect } from 'react-redux';

import * as selectors from '../../../../store/report/reportSelectors';
import * as actions from '../../../../store/report/reportActions';

import ReportGroup from './ReportGroup';
import withLoading from '../../../common/withLoading';

export const ReportList = () => (
    <>
        <ReportGroup group='Financial Reports' />
        <ReportGroup group='Clinical Reports' />
        <ReportGroup group='Initiatives and Performance' />
    </>
);

const mapStateToProps = state => ({
    loading: selectors.loadingSelector(state),
    loaded: selectors.loadedSelector(state),
    error: selectors.errorSelector(state),
});

const mapDispatchToProps = dispatch => ({
    load: () => dispatch(actions.fetchReportsItems()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withLoading(ReportList));
