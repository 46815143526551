import { createSelector } from 'reselect';
import {
    getTableData,
    reportDataSelector,
    defaultSortColumnLabelSelector,
} from '../reportDataSelectors';
import { reportFilterSelector } from '../../report-path/reportPathSelectors';

export const reportTableSelector = createSelector(
    [reportDataSelector, reportFilterSelector, defaultSortColumnLabelSelector],
    (report, filter, defaultSortColumnLabel) => {
        return getTableData({
            report,
            filter,
            defaultSortColumnLabel,
        });
    },
);
