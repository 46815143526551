import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose, setPropTypes, setDisplayName } from 'recompose';
import withReport from './withReport';
import reportGaugeSelector from '../../../store/report-data/chart-selectors/gaugeSelector';
import {
    gaugeUpperBoundSelector,
    gaugeLowerBoundSelector,
} from '../../../store/auth/authSelectors';

const mapStateToProps = (state, ownProps) => ({
    data: reportGaugeSelector(state, ownProps),
    upperBound: gaugeUpperBoundSelector(state),
    lowerBound: gaugeLowerBoundSelector(state),
});

export const withReportGauge = WrappedComponent => {
    const enhance = compose(
        withReport,
        connect(mapStateToProps),
        setPropTypes({
            data: PropTypes.number,
            upperBound: PropTypes.number,
            lowerBound: PropTypes.number,
        }),
        setDisplayName('WithReportGauge'),
    );
    return enhance(WrappedComponent);
};
export default withReportGauge;
