import React, { Component } from 'react';
import {
    Bar,
    BarChart,
    ResponsiveContainer,
    CartesianGrid,
    Legend,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import PropTypes from 'prop-types';
import EmptyReport from '../empty';

// Stacked Vertical Bar  (Stacked Column)
//
// X axis = any of groupings = Y category, max 1
//
// Y axis = any on aggregates(summaries) = bars stacked, max 1
// Show reference line option is available on Y axis
// Reference line on X axis
//
// Measures = additional line chart , max 1
// Draw line for each stack by column with colors equals to these columsn
// Not shown on legend
// If option "Plot on Second Axis is enabled", the second Y axis will be show on the right with it's own values
// Show reference line is available
//
// Stack By = any of groupings except X axis, max 1
// Stack to 100% is available if no additinal mesasures selected,
// make bars on Y axis equals length and use %
//
// Legend Position availble
export class StackedColumnChartType extends Component {
    static propTypes = {
        data: PropTypes.array,
        columns: PropTypes.array,
        groupings: PropTypes.array,
        summaries: PropTypes.array,
        showLegend: PropTypes.bool,
        width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        colorPalette: PropTypes.arrayOf(PropTypes.string),
        animated: PropTypes.bool,
    };

    static defaultProps = {
        showLegend: false,
        data: [],
        width: '100%',
        height: 375,
        groupings: [],
        summaries: [],
        colorPalette: ['#00B06D', '#8FCC22', '#F7D108'],
    };

    render() {
        const {
            width,
            height,
            data,
            showLegend,
            // columns,
            groupings,
            summaries,
            colorPalette,
            animated,
        } = this.props;
        if (!data.length || !groupings.length || !summaries.length) {
            return <EmptyReport />;
        }
        const [grouping, stackBy] = groupings;
        const grouped = data.reduce((prev, cur) => {
            const currentGrouping = cur[grouping];
            const stack = prev[currentGrouping] || {};
            return {
                ...prev,
                [currentGrouping]: {
                    ...stack,
                    [cur[stackBy]]: cur,
                },
            };
        }, {});
        let groupedData = [];
        for (const groupingValue in grouped) {
            const stack = grouped[groupingValue];
            const group = {
                grouping: groupingValue,
                stackBy: {},
            };
            for (const stackValue in stack) {
                group.stackBy[stackValue] = stack[stackValue];
            }
            groupedData.push(group);
        }
        const allStackValues = data.reduce((prev, cur) => {
            if (prev.indexOf(cur[stackBy]) === -1) {
                return [...prev, cur[stackBy]];
            } else {
                return prev;
            }
        }, []);
        return (
            <ResponsiveContainer width={width} height={height}>
                <BarChart data={groupedData}>
                    <CartesianGrid horizontal={true} vertical={false} />
                    <XAxis type='category' dataKey='grouping' />
                    <YAxis type='number' />
                    <Tooltip dataKey={'grouping'} />
                    {showLegend && <Legend />}
                    {summaries.map(summary =>
                        allStackValues.map((stackValue, index) => {
                            return (
                                <Bar
                                    dataKey={`stackBy['${stackValue}']['${summary}']`}
                                    stackId='1'
                                    fill={
                                        colorPalette[
                                            index % colorPalette.length
                                        ]
                                    }
                                    name={stackValue}
                                    isAnimationActive={animated}
                                />
                            );
                        }),
                    )}
                </BarChart>
            </ResponsiveContainer>
        );
    }
}

export default StackedColumnChartType;
