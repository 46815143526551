import * as types from './fileActionTypes';
import * as auth from '../../helpers/auth.helpers';

export default (
    state = {
        error: null,
        loading: false,
        file: auth.user() ? JSON.parse(auth.user()).tflRampAvatarUrlC : '',
    },
    action,
) => {
    switch (action.type) {
        case types.UPLOAD_FILE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case types.UPLOAD_FILE_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                file: action.url,
            };
        case types.UPLOAD_FILE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
};
