import React from 'react';
import {
    Button,
    Grid,
    Header, //Image,
    /**/ Popup,
    Progress,
    Statistic,
} from 'semantic-ui-react';
import { connect } from 'react-redux';

import GaugeChartType from '../../common/charts/gauge';
import {
    getPatientTotalSpendSelector,
    getPatientMedicationsSelector,
} from '../../../store/patient/patientSelectors';
import { NumberFormat } from '../../../helpers/currency.helpers';

export const PatientStatistics = ({ profile, totalSpend, medications }) => (
    <Grid>
        <Grid.Row columns={2}>
            <Grid.Column className='center aligned'>
                <Statistic color='green' className='aa-score'>
                    <Statistic.Value>
                        {profile.adherenceAcuityScoreC || 0}
                    </Statistic.Value>
                    <Statistic.Label>
                        Adherence
                        <br />
                        Acuity Score
                        <br />
                        <Popup
                            trigger={
                                <Button
                                    className='info paddedDot5rem noBorder font1rem'
                                    basic
                                    icon='info circle'
                                />
                            }
                            on='click'
                            position='bottom center'
                        >
                            <h5>Adherence Acuity Score</h5>
                            <p style={{ paddingLeft: '1.2rem' }}>
                                {' '}
                                Adherence Acuity Score is a composite numerical
                                value and rating of clinical acuity for an
                                individual patient based medication possession,
                                barriers to adherence and persistence. Patients
                                with higher acuity scores require additional
                                focus from the healthcare team
                            </p>
                            <ul>
                                <li>
                                    <strong>&lt;1.5 Standard:</strong> Maintain
                                    surveillance for events indicating higher
                                    acuity episode
                                </li>
                                <li>
                                    <strong>1.5 – 2.5 Elevated:</strong> Monitor
                                    patient and employ protocol-driven
                                    interventions to improve adherence outlook
                                </li>
                                <li>
                                    <strong>&gt;2.5 Critical:</strong> Review
                                    unique patient profile and identify
                                    opportunities to improve adherence outlook
                                    through intervention
                                </li>
                            </ul>
                        </Popup>
                    </Statistic.Label>
                </Statistic>
            </Grid.Column>
            <Grid.Column>
                <Statistic.Group horizontal>
                    <Statistic color='green'>
                        <Statistic.Value>
                            {profile.medicationPossessionC || 0}
                        </Statistic.Value>
                        <Statistic.Label>
                            Medication
                            <br />
                            Possession
                        </Statistic.Label>
                    </Statistic>
                    <Statistic color='green'>
                        <Statistic.Value>
                            {profile.barriersToAdherenceC || 0}
                        </Statistic.Value>
                        <Statistic.Label>
                            Barriers to
                            <br />
                            Adherence
                        </Statistic.Label>
                    </Statistic>
                    <Statistic color='green'>
                        <Statistic.Value>
                            {profile.persistenceC || 0}
                        </Statistic.Value>
                        <Statistic.Label>Persistence</Statistic.Label>
                    </Statistic>
                </Statistic.Group>
            </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1} verticalAlign='middle'>
            <Grid.Column width={16}>
                <Header as='h2'>Rating</Header>
                <div className='guageCrop'>
                    <GaugeChartType
                        height={325}
                        min={0}
                        max={10}
                        validMin={0}
                        validMax={10}
                        data={profile.adherenceAcuityScoreC || 0}
                        showLegend={false}
                    />
                </div>
                <div className='ratingStats'>
                    <Statistic color='green' size='mini'>
                        <Statistic.Value>&lt; 1.5</Statistic.Value>
                        <Statistic.Label>Standard</Statistic.Label>
                    </Statistic>
                    <Statistic color='orange' size='mini'>
                        <Statistic.Value>1.5 - 2.5</Statistic.Value>
                        <Statistic.Label>Elevated</Statistic.Label>
                    </Statistic>
                    <Statistic color='red' size='mini'>
                        <Statistic.Value>&gt; 2.5</Statistic.Value>
                        <Statistic.Label>Critical</Statistic.Label>
                    </Statistic>
                </div>
            </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1} verticalAlign='middle'>
            <Grid.Column width={16}>
                <React.Fragment>
                    <Header as='h2'>
                        Payer Population Percentile
                        <Popup
                            trigger={
                                <Button
                                    className='info paddedDot5rem noBorder font1rem'
                                    basic
                                    icon='info circle'
                                />
                            }
                            on='click'
                            position='bottom center'
                        >
                            <h5>Payer Population Percentile</h5>
                            <p>
                                The percentile rank represents the percentage of
                                patient that have a lower score than this
                                patient.
                            </p>
                            {
                                // <p>
                                // The relationship between a single patient and
                                // the total patient population.
                                // </p>
                            }
                        </Popup>
                    </Header>
                </React.Fragment>
                <Progress
                    className='percent'
                    progress='percent'
                    percent={profile.percentile}
                />
            </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1} verticalAlign='middle'>
            <Grid.Column width={16}>
                <Header as='h2'>Total Spend</Header>
                <div>
                    <Statistic color='green'>
                        <Statistic.Value>{totalSpend}</Statistic.Value>
                    </Statistic>
                </div>
            </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1} verticalAlign='middle'>
            <Grid.Column width={16}>
                <Header as='h2'>Medications</Header>
                {medications.map(medication => (
                    <h3>{medication}</h3>
                ))}
            </Grid.Column>
        </Grid.Row>
    </Grid>
);

const mapStateToProps = state => ({
    totalSpend: NumberFormat.format(getPatientTotalSpendSelector(state)),
    medications: getPatientMedicationsSelector(state),
});

export default connect(mapStateToProps)(PatientStatistics);
