import React, { Component } from 'react';
import { Grid, Header } from 'semantic-ui-react';

import NavMenu from '../../common/NavMenu';
import DateFilter from '../../common/date-filter/DateFilter';
import ReportDetailLayout from './layout/ReportDetailLayout';
/* ORIGINAL */
/*
export const ReportDetail = ({ match }) => {
    const sfid = match.params.id;
    return (
        <Grid className='full-page' stretched>
            <Grid.Row className='full-page'>
                <NavMenu />
                <Grid.Column className='stretch-col'>
                    <Grid className='shrink marginV0'>
                        <Grid.Row
                            columns={2}
                            className='pageHeader'
                            verticalAlign='middle'
                        >
                            <Grid.Column width={11}>
                                <Header as='h1'>Report</Header>
                            </Grid.Column>
                            <Grid.Column width={5} textAlign='right'>
                                <DateFilter />
                            </Grid.Column>
                        </Grid.Row>
                        <ReportDetailLayout sfid={sfid} />
                    </Grid>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default ReportDetail;
*/
export default class ReportDetail extends Component {
    componentDidMount() {
        window.scroll(0, 0);
    }
    render() {
        const sfid = this.props.match.params.id;
        return (
            <Grid className='full-page' stretched>
                <Grid.Row className='full-page'>
                    <NavMenu />
                    <Grid.Column className='stretch-col'>
                        <Grid className='shrink marginV0'>
                            <Grid.Row
                                columns={2}
                                className='pageHeader'
                                verticalAlign='middle'
                            >
                                <Grid.Column width={11}>
                                    <Header as='h1'>Report</Header>
                                </Grid.Column>
                                <Grid.Column width={5} textAlign='right'>
                                    <DateFilter />
                                </Grid.Column>
                            </Grid.Row>
                            <ReportDetailLayout sfid={sfid} />
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}
