import * as auth from '../helpers/auth.helpers';

export const setProfileImage = (userId, fileUrl) => {
    return fetch(process.env.REACT_APP_API_URL + '/TflRampUserCs', {
        method: 'PUT',
        body: JSON.stringify({ id: userId, tflRampAvatarUrlC: fileUrl }),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.getSession(),
        },
    }).then(response => response.json());
};
