import React from 'react';

export const TableIcons = {
    openRow: (
        <svg
            className='toggleIcon'
            xmlns='http://www.w3.org/2000/svg'
            width='17'
            height='17'
            viewBox='0 0 17 17'
        >
            <g
                fill='none'
                fillRule='evenodd'
                stroke='#00B06D'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='1.5'
                transform='translate(.535 .552)'
            >
                <path d='M7.95927124 4.97454453L7.95927124 10.943998M4.97454453 7.95927124L10.943998 7.95927124' />
                <circle cx='7.959' cy='7.959' r='7.462' />
            </g>
        </svg>
    ),
    closeRow: (
        <svg
            className='toggleIcon'
            xmlns='http://www.w3.org/2000/svg'
            width='17'
            height='17'
            viewBox='0 0 17 17'
        >
            <g
                fill='none'
                fillRule='evenodd'
                stroke='#D2D2D2'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='1.5'
                transform='translate(.535 .513)'
            >
                <path d='M4.97454453,7.95927124 L10.943998,7.95927124' />
                <circle cx='7.959' cy='7.959' r='7.462' />
            </g>
        </svg>
    ),
};

export default TableIcons;
