import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as authActions from '../../store/auth/authActions';
import {
    Button,
    Dimmer,
    Divider,
    Form,
    Grid,
    Image,
    Input,
    Loader,
    Segment,
} from 'semantic-ui-react';
import defaultLogo from '../../assets/images/ah-logo-rev-notag.png';
import logo from '../../assets/images/ah-logo-rev-notag.png';
import Footer from '../common/Footer';
import Notify from '../../helpers/notification.helpers';
export class Login extends Component {
    /**
     * Default constructor to set up state and functions
     * @param props
     */
    static propTypes = {
        isAuthenticated: PropTypes.bool,
        profile: PropTypes.object,
        error: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            loading: false,
            submitted: false,
            authenticated: false,
            error: false,
            errorMessage: 'Please fill out required fields.',
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInvalidSubmit = this.handleInvalidSubmit.bind(this);
        this.handlePasswordReset = this.handlePasswordReset.bind(this);
    }
    /**
     * Check for state change from redux store on success or failure to login
     * @param nextProps
     */
    static getDerivedStateFromProps(nextProps) {
        // error on login
        if (nextProps.error) {
            Notify.error(nextProps.error);
            return {
                submitted: false,
                loading: false,
                error: true,
                errorMessage: nextProps.error,
                authenticated: false,
            };
        }
        // Successful login
        if (nextProps.profile && nextProps.profile.email) {
            return { submitted: false, authenticated: true, loading: false };
        }

        return null;
    }

    /**
     * When a form is submitted with validation errors
     * @param e
     */
    handleInvalidSubmit(e) {
        e.preventDefault();
        Notify.error(
            'MISSING ' +
                e.target.placeholder.toUpperCase() +
                ': Fill in ' +
                e.target.placeholder.toLowerCase(),
        );
        this.setState({ submitted: false, error: true });
    }

    handlePasswordReset(e) {
        if (this.state.email) {
            this.props.onPasswordReset(this.state.email);
            Notify.success(
                'If you have an account with Acaria Connect, ' +
                    'then a password reset email has been sent to the email provided.',
            );
        } else {
            Notify.error(
                'Please fill in the email field to reset your password.',
            );
        }
    }

    /**
     * Submit button for logging in, sets state and does validation based on user input
     */
    handleSubmit() {
        // Show loading
        this.setState({ submitted: true, loading: true });

        // Validation
        if (!this.state.email || !this.state.password) {
            this.setState({ submitted: false, error: true });
            this.forceUpdate();

            // Login
        } else {
            this.setState({ error: false });
            this.props.onSubmit(this.state.email, this.state.password);
        }
    }

    /**
     * Default input change method to handle saving state from inputs
     * @param event
     */
    handleInputChange(event) {
        const target = event.target;
        this.setState({
            [target.name]: target.value,
        });
    }

    render() {
        let clientLogo = this.props.logo ? this.props.logo : defaultLogo;
        if (this.state.loading) {
            return (
                <div>
                    <Dimmer active inverted>
                        <Loader size='large'>Loading</Loader>
                    </Dimmer>
                </div>
            );
        } else
            return (
                <div className='login-page'>
                    <div className='login-form'>
                        <Segment>
                            <div>
                                <div className='acaria-connect'>
                                    <Grid className='logo'>
                                        <Image
                                            src={logo}
                                            width='230'
                                            height='38'
                                            alt='Acaria Connect'
                                        />
                                        <p>Acaria Connect</p>
                                    </Grid>
                                    <div className='clientLogo'>
                                        <Image
                                            src={clientLogo}
                                            width='200'
                                            alt='client logo'
                                        />
                                    </div>
                                    <div className='paddedLR2rem'>
                                        <Divider horizontal>Sign In</Divider>
                                        <Form
                                            success={this.state.authenticated}
                                            loading={this.state.submitted}
                                            className='ui'
                                            onInvalid={this.handleInvalidSubmit}
                                            onSubmit={this.handleSubmit}
                                            error={this.state.error}
                                        >
                                            <Form.Field
                                                error={
                                                    this.state.error &&
                                                    !this.state.email
                                                }
                                            >
                                                <Input
                                                    icon='mail'
                                                    iconPosition='left'
                                                    name='email'
                                                    type='text'
                                                    placeholder='Email Address'
                                                    value={this.state.email}
                                                    onChange={
                                                        this.handleInputChange
                                                    }
                                                    required
                                                />
                                            </Form.Field>
                                            <Form.Field
                                                error={
                                                    this.state.error &&
                                                    !this.state.password
                                                }
                                            >
                                                <Input
                                                    icon='key'
                                                    iconPosition='left'
                                                    name='password'
                                                    type='password'
                                                    placeholder='Password'
                                                    value={this.state.password}
                                                    onChange={
                                                        this.handleInputChange
                                                    }
                                                    required
                                                />
                                            </Form.Field>
                                            <Button
                                                type='submit'
                                                className='primary fluid submit'
                                            >
                                                Sign In
                                            </Button>
                                            <Link
                                                style={{
                                                    color: 'black',
                                                    display: 'block',
                                                    margin: 10,
                                                }}
                                                to='/password-recovery'
                                            >
                                                Need help signing in?
                                            </Link>
                                            <Footer />
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </Segment>
                    </div>
                </div>
            );
    }
}

function mapStatesToProps({
    auth: { isAuthenticated, profile, error },
    domain: { domain, name, logo },
}) {
    return {
        isAuthenticated,
        profile,
        error,
        domain,
        name,
        logo,
    };
}

const mapDispatchToProps = dispatch => ({
    onSubmit: (email, password) =>
        dispatch(authActions.loginRequest(email, password)),
    onPasswordReset: email => dispatch(authActions.passwordResetRequest(email)),
});

export default connect(
    mapStatesToProps,
    mapDispatchToProps,
)(Login);
