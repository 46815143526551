import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import get from 'lodash/get';

export class PatientLink extends Component {
    static propTypes = {
        row: PropTypes.object.isRequired,
        accessor: PropTypes.string.isRequired,
    };

    render() {
        const { row = {}, accessor } = this.props;
        const patientId = get(row, 'patient.id') || get(row, 'member.id');
        return (
            <div>
                <Link
                    to={`/patient-detail/${patientId}`}
                    onClick={this.handleClick}
                >
                    {get(row, accessor)}
                </Link>
            </div>
        );
    }
}

export default PatientLink;
