import React, { Component } from 'react';
import PropTypes from 'prop-types';

export class Case extends Component {
    static propTypes = {
        caseNumber: PropTypes.oneOfType([
            PropTypes.number.isRequired,
            PropTypes.string.isRequired,
        ]),
        handleClick: PropTypes.func,
    };

    static defaultProps = {
        handleClick: () => {},
    };
    render() {
        return (
            <span
                className='green-text hover-cursor'
                onClick={this.props.handleClick}
            >
                {this.props.caseNumber}
            </span>
        );
    }
}
export default Case;
