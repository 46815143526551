import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ReportPreview } from '../../report-preview/ReportPreview';
import { viewReport } from '../../../../store/report/reportActions';
import { AllCustomChartTypes } from '../../report-chart/ChartTypes';

export const Item = ({
    showHeader,
    showViewButton,
    showDate,
    data,
    viewReport,
}) => {
    return (
        <ReportPreview
            showHeader={showHeader}
            showViewButton={showViewButton}
            showDate={showDate}
            data={data}
            viewReport={viewReport}
        />
    );
};

Item.propTypes = {
    showHeader: PropTypes.bool,
    showViewButton: PropTypes.bool,
    showDate: PropTypes.bool,
    data: PropTypes.shape({
        chartTypeC: PropTypes.oneOf(AllCustomChartTypes),
        chartReportIdC: PropTypes.string,
        name: PropTypes.string,
        clickableC: PropTypes.bool,
    }),
    viewReport: PropTypes.func,
};

Item.defaultProps = {
    showHeader: true,
    showViewButton: true,
};

const mapDispatchToProps = dispatch => ({
    viewReport: (sfid, date) => dispatch(viewReport(sfid, date)),
});

export default connect(
    null,
    mapDispatchToProps,
)(Item);
