import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dimmer, Loader } from 'semantic-ui-react';
import ReactCollapsingTable from 'react-collapsing-table';

import { PATIENT_ORDER_STATUS_ICONS } from '../../../store/constants/tableIcons';
import { PATIENT_ORDER_STATUS_COLUMNS } from '../../../store/constants/table';
import { sortOrderStatus } from '../../../store/order-status/orderStatusActions';
import {
    patientOrderStatusListSelector,
    patientOrderStatusLoadingSelector,
} from '../../../store/order-status/orderStatusSelectors';
import { bindActionCreators } from 'redux';

export const PatientOrderStatusList = ({ list, sort, loading }) => (
    <Dimmer.Dimmable dimmed={loading}>
        <Dimmer active={loading} inverted>
            <Loader size='large'>Loading</Loader>
        </Dimmer>
        <ReactCollapsingTable
            rows={list}
            columns={PATIENT_ORDER_STATUS_COLUMNS}
            icons={PATIENT_ORDER_STATUS_ICONS}
            handleSort={sort}
            rowSize={99999}
        />
    </Dimmer.Dimmable>
);

PatientOrderStatusList.propTypes = {
    list: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    sort: PropTypes.func,
};

const mapStateToProps = state => ({
    list: patientOrderStatusListSelector(state),
    loading: patientOrderStatusLoadingSelector(state),
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            sort: sortOrderStatus,
        },
        dispatch,
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PatientOrderStatusList);
