import { put } from 'redux-saga/effects';
import { uploadFile } from '../../api/fileUploadAPI';
import { setProfileImage } from '../../api/userAPI';
import * as types from './fileActionTypes';
import * as authTypes from '../auth/authActionTypes';

export function* fileUploadSaga({ file, userId }) {
    try {
        const uploadedFile = yield uploadFile(file);
        const user = yield setProfileImage(userId, uploadedFile.secure_url);
        yield put({
            type: types.UPLOAD_FILE_SUCCESS,
            url: uploadedFile.url,
        });
        yield put({
            type: authTypes.UPDATE_USER_SUCCESS,
            user,
        });
    } catch (error) {
        yield put({
            type: types.UPLOAD_FILE_ERROR,
            error: error.message,
        });
    }
}
