import { createSelector } from 'reselect';
import { groupItems } from '../../helpers/grouping.helpers';

export const getItemIds = state => state.dashboard.dashboardItemsIds;

export const getItemMap = state => state.dashboard.dashboardItemsIdMap;

export const getAllItems = createSelector(
    [getItemIds, getItemMap],
    (ids, map) => ids.map(id => map[id]),
);

/**
 * Get name for a group of items
 *
 * @param {Array<Object>} items
 *
 * @returns {string}
 */
export const getGroupName = (items = []) => {
    return items.length > 0 ? items[0].name : '';
};

export const getDashboardItems = createSelector(
    getAllItems,
    items => groupItems(items),
);

export const getById = (state, id) => state.dashboard.dashboardItemsIdMap[id];

export const loadingSelector = state => state.dashboard.loading;

export const loadedSelector = state => state.dashboard.loaded;

export const errorSelector = state => state.dashboard.error;

export const getDashboardItemChildReportPath = (state, id) =>
    state.dashboard.reportPathMap[id];
