import React from 'react';
import { Grid } from 'semantic-ui-react';

import NavMenu from '../common/NavMenu';
import DateFilter from '../common/date-filter/DateFilter';
import DashboardHeader from './header';
import DashboardItems from './items';
import DashboardSidebar from './sidebar/DashboardSidebar';

export const Dashboard = () => (
    <Grid className='full-page' stretched>
        <Grid.Row className='full-page'>
            <NavMenu />
            <Grid.Column className='stretch-col'>
                <Grid className='shrink marginV0'>
                    <Grid.Row
                        columns={2}
                        className='pageHeader'
                        verticalAlign='middle'
                    >
                        <Grid.Column width={11}>
                            <DashboardHeader />
                        </Grid.Column>
                        <Grid.Column width={5} textAlign='right'>
                            <DateFilter />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className='paddingV0 shrink'>
                        <Grid.Column
                            computer={11}
                            tablet={10}
                            mobile={16}
                            className='reportGrid'
                        >
                            <Grid
                                columns={2}
                                className='reportGridContainer paddingV2rem mobileNoPad'
                                padded='vertically'
                                stretched
                                stackable
                            >
                                <DashboardItems />
                            </Grid>
                        </Grid.Column>
                        <DashboardSidebar />
                    </Grid.Row>
                </Grid>
            </Grid.Column>
        </Grid.Row>
    </Grid>
);

export default Dashboard;
