import React from 'react';
import PropTypes from 'prop-types';
import withReportDonut from '../../../common/report/withReportDonut';
import Donut from '../../../common/charts/donut';

export const DonutChart = withReportDonut(props => {
    return (
        <Donut
            height={375}
            {...props}
            shiftAngle={250}
            labelPosition='inside'
        />
    );
});

DonutChart.propTypes = {
    sfid: PropTypes.string.isRequired,
    defaultDateFilterC: PropTypes.string,
};

export default DonutChart;
