import React from 'react';
import ReportFilter from '../filter';
import { Grid } from 'semantic-ui-react';

export const ReportListSidebar = () => (
    <Grid.Column
        className='paddingH0 shaded tablet-up-show'
        computer={5}
        tablet={6}
    >
        <div className='padded2rem'>
            <ReportFilter />
        </div>
    </Grid.Column>
);

export default ReportListSidebar;
