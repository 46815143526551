import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Statistic } from 'semantic-ui-react';
import Numbro from 'numbro';
import { DateFilterText } from '../../date-filter/DateFilterValues';

export class Metric extends PureComponent {
    static propTypes = {
        clickableC: PropTypes.bool,
        name: PropTypes.string,
        data: PropTypes.string,
        showDate: PropTypes.bool,
        defaultDateFilterC: PropTypes.string,
        onViewReport: PropTypes.func,
        truncate: PropTypes.bool,
    };

    static defaultProps = {
        data: '',
        name: '',
        clickableC: false,
        showDate: false,
        onViewReport: () => {},
        truncate: true,
    };

    render() {
        const {
            data,
            name,
            clickableC,
            onViewReport,
            truncate,
            showDate,
            defaultDateFilterC: date,
        } = this.props;
        let value = data;
        let useDollars = false;
        if (truncate) {
            let number = parseFloat(data.replace(/[^\d.]/g, ''));
            if (!isNaN(number)) {
                number = Math.trunc(number);
                useDollars = data.length && data[0] === '$';
                if (number > 9999) {
                    value = Numbro(number).format({
                        average: true,
                        mantissa: 1,
                        thousandSeparated: true,
                    });
                } else {
                    value = Numbro(number).format({ thousandSeparated: true });
                }
            }
        }

        return (
            <Statistic color='green'>
                <Statistic.Value>
                    {useDollars ? '$' : ''}
                    {value}
                </Statistic.Value>
                <Statistic.Label>{name}</Statistic.Label>
                {showDate &&
                    (date ? (
                        <div className='ui date'>
                            <i>{DateFilterText[date]}</i>
                        </div>
                    ) : null)}
                {clickableC && (
                    <a href='/' className='report-link' onClick={onViewReport}>
                        View report
                    </a>
                )}
            </Statistic>
        );
    }
}

export default Metric;
