import * as types from './reportActionTypes';

export const fetchReportsItems = () => ({
    type: types.REPORT_ITEMS_REQUEST,
});

export const fetchReportsItemsSuccess = result => ({
    type: types.REPORT_ITEMS_SUCCESS,
    result,
});

export const fetchReportsItemsError = error => ({
    type: types.REPORT_ITEMS_ERROR,
    error,
});

export const fetcReportChildReportPath = id => ({
    type: types.FETCH_REPORT_CHILD_REPORT_PATH_REQUEST,
    id,
});

export const fetchReportChildReportPathSuccess = (id, result) => ({
    type: types.FETCH_REPORT_CHILD_REPORT_PATH_SUCCESS,
    id,
    result,
});

export const fetchReportChildReportPathError = (id, error) => ({
    type: types.FETCH_REPORT_CHILD_REPORT_PATH_ERROR,
    id,
    error,
});

export const viewReport = (id, date) => ({
    type: types.VIEW_REPORT,
    id,
    date,
});

export const toggleReport = report => ({
    type: types.TOGGLE_REPORT,
    report,
});
