export const FETCH_DASHBOARD_ITEMS_REQUEST = 'FETCH_DASHBOARD_ITEMS_REQUEST';
export const FETCH_DASHBOARD_ITEMS_SUCCESS = 'FETCH_DASHBOARD_ITEMS_SUCCESS';
export const FETCH_DASHBOARD_ITEMS_ERROR = 'FETCH_DASHBOARD_ITEMS_ERROR';
export const VIEW_DASHBOARD_ITEM_REPORT = 'VIEW_DASHBOARD_ITEM_REPORT';
export const FETCH_DASHBOARD_ITEM_CHILD_REPORT_PATH_REQUEST =
    'FETCH_DASHBOARD_ITEM_CHILD_REPORT_PATH_REQUEST';
export const FETCH_DASHBOARD_ITEM_CHILD_REPORT_PATH_SUCCESS =
    'FETCH_DASHBOARD_ITEM_CHILD_REPORT_PATH_SUCCESS';
export const FETCH_DASHBOARD_ITEM_CHILD_REPORT_PATH_ERROR =
    'FETCH_DASHBOARD_ITEM_CHILD_REPORT_PATH_ERROR';
