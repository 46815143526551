import { put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { login, passwordReset } from '../../api/authAPI';
import * as types from './authActionTypes';
import { changeDomain, verifyDomainSuccess } from '../domain/domainActions';

export function* loginSaga({ email, password }) {
    try {
        const profile = yield login(email, password);
        yield put({
            type: types.LOGIN_SUCCESS,
            profile: profile,
        });
        const domain = profile.domain;
        if (domain && domain.domain) {
            verifyDomainSuccess({ ...domain });
            yield put(changeDomain(domain.domain));
        } else {
            yield put(push('/'));
        }
    } catch (error) {
        yield put({
            type: types.LOGIN_ERROR,
            error: error.message,
        });
    }
}

export function* passwordResetSaga({ email }) {
    try {
        yield passwordReset(email);
        yield put({
            type: types.PASSWORD_RESET_SUCCESS,
        });
    } catch (error) {
        yield put({
            type: types.PASSWORD_RESET_ERROR,
            error: error.message,
        });
    }
}

export function* logOutSaga() {
    try {
        yield put({
            type: types.LOGOUT_SUCCESS,
        });
        yield put(push('/'));
    } catch (error) {
        yield put({
            type: types.LOGOUT_ERROR,
            error: error.message,
        });
    }
}
