import { takeLatest, takeEvery, all } from 'redux-saga/effects';

import { loginSaga, passwordResetSaga, logOutSaga } from '../auth/authSaga';
import caseManagementSaga from '../case/caseSaga';
import orderStatusSaga from '../order-status/orderStatusSaga';
import domainSaga from '../domain/domainSaga';
import patientRosterSaga from '../patient-roster/patientRosterSagas';
import caseListSaga from '../case-list/saga';
import notificationSaga from '../notifications/saga';
import contactSaga from '../contact/contactSaga';
import patientSaga from '../patient/patientSaga';

import {
    fetchDashboardItemsSaga,
    viewDashboardItemReportSaga,
} from '../dashboard/dashboardSaga';
import {
    patientProfileSaga,
    updatePatientFlaggedSaga,
    patientFillInfoSaga,
} from '../patient/patientSaga';

import { fetchReportsSaga, viewReportSaga } from '../report/reportSaga';
import { reportDataSaga } from '../report-data/reportDataSaga';
import { updateTimeZone } from '../contact/contactSaga';
import { fileUploadSaga } from '../file/fileSaga';
import {
    fetchReportPathSaga,
    fetchReportPathChildSaga,
    gotoReportPathSaga,
} from '../report-path/reportPathSaga';

import * as contactTypes from '../contact/contactActionTypes';
import { downloadDataSaga } from '../download-data/downloadDataSaga';
import * as patientTypes from '../patient/patientActionTypes';
import * as authTypes from '../auth/authActionTypes';
import * as dashboardTypes from '../dashboard/dashboardActionTypes';
import * as reportTypes from '../report/reportActionTypes';
import * as reportDataTypes from '../report-data/reportDataActionTypes';
import * as reportPathTypes from '../report-path/reportPathActionTypes';
import * as fileTypes from '../file/fileActionTypes';
import * as downloadDataTypes from '../download-data/downloadDataActionTypes';

export default function* AcariaSaga() {
    yield takeLatest(authTypes.LOGIN_REQUEST, loginSaga);
    yield takeLatest(authTypes.LOGOUT_REQUEST, logOutSaga);
    yield takeLatest(authTypes.PASSWORD_RESET_REQUEST, passwordResetSaga);

    yield takeLatest(fileTypes.UPLOAD_FILE_REQUEST, fileUploadSaga);
    yield takeLatest(contactTypes.CONTACT_TIMEZONE_REQUEST, updateTimeZone);

    yield takeLatest(
        patientTypes.PATIENT_FLAGGED_REQUEST,
        updatePatientFlaggedSaga,
    );
    yield takeLatest(
        dashboardTypes.FETCH_DASHBOARD_ITEMS_REQUEST,
        fetchDashboardItemsSaga,
    );
    yield takeLatest(patientTypes.PATIENT_PROFILE_REQUEST, patientProfileSaga);

    yield takeLatest(
        patientTypes.PATIENT_FILL_INFO_REQUEST,
        patientFillInfoSaga,
    );
    yield takeLatest(
        dashboardTypes.VIEW_DASHBOARD_ITEM_REPORT,
        viewDashboardItemReportSaga,
    );
    yield takeLatest(reportTypes.REPORT_ITEMS_REQUEST, fetchReportsSaga);
    yield takeLatest(reportTypes.VIEW_REPORT, viewReportSaga);
    yield takeEvery(reportDataTypes.REPORT_DATA_REQUEST, reportDataSaga);
    yield takeEvery(
        reportPathTypes.FETCH_REPORT_PATH_REQUEST,
        fetchReportPathSaga,
    );
    yield takeEvery(
        reportPathTypes.FETCH_REPORT_PATH_CHILD_REQUEST,
        fetchReportPathChildSaga,
    );
    yield takeLatest(reportPathTypes.GOTO_REPORT_PATH, gotoReportPathSaga);
    yield takeLatest(downloadDataTypes.DOWNLOAD_DATA_REQUEST, downloadDataSaga);

    yield all([
        orderStatusSaga(),
        domainSaga(),
        patientRosterSaga(),
        caseManagementSaga(),
        caseListSaga(),
        notificationSaga(),
        contactSaga(),
        patientSaga(),
    ]);
}
