export const REPORT_ITEMS_REQUEST = 'REPORT_ITEMS_REQUEST';
export const REPORT_ITEMS_SUCCESS = 'REPORT_ITEMS_SUCCESS';
export const REPORT_ITEMS_ERROR = 'REPORT_ITEMS_ERROR';
export const FETCH_REPORT_CHILD_REPORT_PATH_REQUEST =
    'FETCH_REPORT_CHILD_REPORT_PATH_REQUEST';
export const FETCH_REPORT_CHILD_REPORT_PATH_SUCCESS =
    'FETCH_REPORT_CHILD_REPORT_PATH_SUCCCESS';
export const FETCH_REPORT_CHILD_REPORT_PATH_ERROR =
    'FETCH_REPORT_CHILD_REPORT_PATH_ERROR';
export const VIEW_REPORT = 'VIEW_REPORT';
export const TOGGLE_REPORT = 'TOGGLE_REPORT';
