import PropTypes from 'prop-types';
import { Feed, Image, Button, Header } from 'semantic-ui-react';
import EventDate from '../EventDate';
import icon_fill from '../../../../assets/images/icon-fill.svg';
import orderBy from 'lodash/orderBy';
import React, { Component } from 'react';

class FillEvent extends Component {
    static propTypes = {
        eventType: PropTypes.string,
        handleClick: PropTypes.func,
    };

    static defaultProps = {
        handleClick: () => {},
        eventType: 'fill',
    };

    render() {
        let claims = this.props.event.claims;
        /**/ const claimsByDate = orderBy(claims, ['shipDateC'], ['desc']);
        const lastFillDate =
            (claimsByDate.length && claimsByDate[0].shipDateC) || null;
        return (
            <React.Fragment>
                <Feed.Event onClick={this.props.handleClick}>
                    <Feed.Label>
                        <Image src={icon_fill} alt='Works~' width='29' />
                    </Feed.Label>
                    <Feed.Content>
                        <EventDate date={lastFillDate} />
                        <div className='event-content'>
                            <div className='event-content__detail'>
                                <Header as='h5'>Medication</Header>
                                {claims.map(claim => {
                                    return (
                                        <Header as='h3' key={claim.id}>
                                            {claim.drug ? claim.drug.name : ''}
                                        </Header>
                                    );
                                })}
                            </div>
                            <div className='event-content__button'>
                                <Button size='mini' content='View' primary />
                            </div>
                        </div>
                    </Feed.Content>
                </Feed.Event>
            </React.Fragment>
        );
    }
}

export default FillEvent;
