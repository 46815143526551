/**
 * Custom chart types set for custom reports
 */
export const CustomChartTypes = {
    DONUT: 'Donut',
    METRIC: 'Metric',
    TABLE: 'Table',
    GAUGE: 'Gauge',
    LINE: 'Line',
    BAR: 'Bar',
    STACKED_BAR: 'Stacked Bar',
    COLUMN: 'Column',
    STACKED_COLUMN: 'Stacked Column',
};

/**
 * Default salesforce report types
 */
export const SalesforceChartTypes = {
    DONUT: 'Donut',
    GAUGE: 'Gauge',
    LINE: 'Line',
    COLUMN: 'Vertical Bar',
    COLUMN_WITH_LINE: 'Vertical Bar Line',
    STACKED_COLUMN: 'Vertical Bar Stacked',
    STACKED_COLUMN_WITH_LINE: 'Vertical Bar Stacked Line',
    GROUPED_COLUMN: 'Vertical Bar Grouped',
    GROUPED_COLUMN_WITH_LINE: 'Vertical Bar Grouped Line',
    BAR: 'Horizontal Bar',
    STACKED_BAR: 'Horizontal Bar Stacked',
};

export const AllCustomChartTypes = [
    CustomChartTypes.DONUT,
    CustomChartTypes.METRIC,
    CustomChartTypes.TABLE,
    CustomChartTypes.GAUGE,
    CustomChartTypes.LINE,
    CustomChartTypes.BAR,
    CustomChartTypes.STACKED_BAR,
    CustomChartTypes.COLUMN,
    CustomChartTypes.STACKED_COLUMN,
];

export const AllSalesforceChartTypes = [
    SalesforceChartTypes.DONUT,
    SalesforceChartTypes.GAUGE,
    SalesforceChartTypes.LINE,
    SalesforceChartTypes.COLUMN,
    SalesforceChartTypes.COLUMN_WITH_LINE,
    SalesforceChartTypes.STACKED_COLUMN,
    SalesforceChartTypes.STACKED_COLUMN_WITH_LINE,
    SalesforceChartTypes.GROUPED_COLUMN,
    SalesforceChartTypes.GROUPED_COLUMN_WITH_LINE,
    SalesforceChartTypes.BAR,
    SalesforceChartTypes.STACKED_BAR,
];
