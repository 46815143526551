import { put, takeLatest } from 'redux-saga/effects';
import { setTimeZone } from '../../api/contactAPI';
import { lockContact, unlockContact } from '../../api/accountAPI';
import * as types from './contactActionTypes';
import { updateContact } from '../auth/authActions';

export function* updateTimeZone({ contactId, timeZone }) {
    try {
        const data = yield setTimeZone(contactId, timeZone);
        yield put({
            type: types.CONTACT_TIMEZONE_SUCCESS,
            contact: data,
        });
    } catch (error) {
        yield put({
            type: types.CONTACT_TIMEZONE_ERROR,
            error: error.message,
        });
    }
}

const contactsLocking = {};
export function* contactLock({ payload: { contactId } }) {
    if (contactsLocking[contactId]) {
        return;
    }
    try {
        contactsLocking[contactId] = true;
        const contact = yield lockContact(contactId);
        contactsLocking[contactId] = false;
        yield put({
            type: types.CONTACT_LOCK_SUCCESS,
            payload: {
                contact,
            },
        });
        yield put(updateContact({ contact }));
    } catch (e) {
        yield put({
            type: types.CONTACT_LOCK_ERROR,
        });
    }
}

export function* contactUnlock({ payload: { contactId } }) {
    if (contactsLocking[contactId]) {
        return;
    }
    try {
        contactsLocking[contactId] = true;
        const contact = yield unlockContact(contactId);
        contactsLocking[contactId] = false;
        yield put({
            type: types.CONTACT_UNLOCK_SUCCESS,
            payload: {
                contact,
            },
        });
        yield put(updateContact({ contact }));
    } catch (e) {
        yield put({
            type: types.CONTACT_UNLOCK_ERROR,
        });
    }
}

export function* rootSaga() {
    yield takeLatest(types.CONTACT_LOCK_REQUEST, contactLock);
    yield takeLatest(types.CONTACT_UNLOCK_REQUEST, contactUnlock);
}

export default rootSaga;
