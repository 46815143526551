export const CHAT_START_REQUEST = 'CHAT_START_REQUEST';
export const CHAT_START_SUCCESS = 'CHAT_START_SUCCESS';
export const CHAT_START_ERROR = 'CHAT_START_ERROR';

export const CHAT_SEND_MESSAGE = 'CHAT_SEND_MESSAGE';

export const CHAT_RECEIVE_MESSAGE = 'CHAT_RECEIVE_MESSAGE';

export const CHAT_RECEIVE_ERROR = 'CHAT_RECEIVE_ERROR';

export const CHAT_END_BY_USER = 'CHAT_END_BY_USER';

export const CHAT_ENDED_BY_AGENT = 'CHAT_ENDED_BY_AGENT';

export const CHAT_SET_AGENT_STATUS = 'CHAT_SET_AGENT_STATUS';

export const CHAT_SESSION_START = 'CHAT_SESSION_START';
export const CHAT_SESSION_END = 'CHAT_SESSION_END';

export const CHAT_SUBSCRIBE_AVAILABILITY = 'CHAT_SUBSCRIBE_AVAILABILITY';
export const CHAT_UNSUBSCRIBE_AVAILABILITY = 'CHAT_UNSUBSCRIBE_AVAILABILITY';

export const CHAT_CREATE_CASE_REQUEST = 'CHAT_CREATE_CASE_REQUEST';
export const CHAT_CREATE_CASE_SUCCESS = 'CHAT_CREATE_CASE_SUCCESS';
export const CHAT_CREATE_CASE_ERROR = 'CHAT_CREATE_CASE_ERROR';
