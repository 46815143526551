import React from 'react';

export const EmptyReport = ({ children }) => {
    return (
        <div className='report-placeholder'>
            <div>{children ? children : 'No data for this time period'}</div>
        </div>
    );
};

export default EmptyReport;
