import React from 'react';
import { pure } from 'recompose';
import routes from './routes';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { withRouter } from 'react-router-dom';

import configureStore, { history } from './store';

import verifyDomain from './components/domain/verifyDomain';

export const USE_SUBDOMAINS = process.env.REACT_APP_USE_SUBDOMAINS;

export const ROOT_DOMAIN = process.env.REACT_APP_ROOT_DOMAIN;

const DomainRoutes = withRouter(
    verifyDomain(({ verified }) => (
        <div className='ui grid one column full-page'>
            <div className='ui grid vertical full-page'>
                {routes.domainRoutes}
            </div>
        </div>
    )),
);

export const App = ({ domain }) => {
    const store = configureStore({}, domain);
    return (
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <DomainRoutes domain={domain} />
            </ConnectedRouter>
        </Provider>
    );
};

export default pure(App);
