import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { resetPatientOrderStatusFilter } from '../../../store/order-status/orderStatusActions';
import { Button } from 'semantic-ui-react';

export const ResetFilter = ({ reset }) => (
    <Button type='submit' primary onClick={reset}>
        Clear filters
    </Button>
);

ResetFilter.propTypes = {
    reset: PropTypes.func,
};

ResetFilter.defaultProps = {
    reset: () => {},
};
const mapDispatchToProps = dispatch => ({
    reset: () => dispatch(resetPatientOrderStatusFilter()),
});

export default connect(
    null,
    mapDispatchToProps,
)(ResetFilter);
