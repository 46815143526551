import React, { Component } from 'react';
const months = [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC',
];

export class EventDate extends Component {
    render() {
        let year = '';
        let month = '';
        let day = '';
        if (this.props.date) {
            year = this.props.date.slice(0, 4);
            month = this.props.date.slice(5, 7);
            day = this.props.date.slice(8, 10);

            if (month.charAt(0) === '0') {
                month = month.slice(1, 2);
            }

            month = months[month - 1];
        }

        return (
            <div className='event-date'>
                <div>
                    <div className='day'>{day}</div>
                </div>
                <div>
                    <div className='month'>{month}</div>
                    <div className='year'>{year}</div>
                </div>
            </div>
        );
    }
}

export default EventDate;
