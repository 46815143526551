import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Legend, Cell, PieChart, Pie, ResponsiveContainer } from 'recharts';
import Arrow from './Arrow';
import EmptyReport from '../empty';

export class GaugeChartType extends Component {
    static propTypes = {
        width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        data: PropTypes.number,
        min: PropTypes.number,
        max: PropTypes.number,
        validMin: PropTypes.number,
        validMax: PropTypes.number,
        fillColor: PropTypes.string,
        backgroundColor: PropTypes.string,
        animated: PropTypes.bool,
        showLegend: PropTypes.bool,
    };

    static defaultProps = {
        width: '100%',
        height: 375,
        min: 70,
        max: 150,
        validMin: 80,
        validMax: 110,
        fillColor: '#00B06D',
        backgroundColor: '#D6D6D6',
        animated: false,
        showLegend: true,
    };

    render() {
        const {
            width,
            height,
            validMin,
            validMax,
            min,
            max,
            data,
            fillColor,
            backgroundColor,
            animated,
            showLegend,
        } = this.props;

        if (data === undefined) {
            return <EmptyReport />;
        }

        const pieProps = {
            innerRadius: '65%',
            outerRadius: '75%',
            startAngle: 180,
            endAngle: 0,
            cy: '60%',
            isAnimationActive: animated,
        };

        const colorData = [];
        if (validMin > min) {
            colorData.push({
                value: validMin - min,
                color: backgroundColor,
            });
        }

        colorData.push({
            value: validMax - Math.max(validMin, min),
            color: fillColor,
        });

        if (validMax < max) {
            colorData.push({
                value: max - validMax,
                color: backgroundColor,
            });
        }

        const arrowData = [
            { value: data - Math.min(validMin, min) },
            { value: 0 },
            { value: Math.max(validMax, max) - data },
        ];

        const backgroundPieData = [];
        const step = (max - min) / 10;
        for (let i = min; i < max; i += step) {
            backgroundPieData.push({
                label: Math.trunc(i) + step / 2,
                value: step,
            });
        }

        const percent = isNaN(data) ? '0%' : `${Number(data).toFixed(2)}%`;

        return (
            <ResponsiveContainer width={width} height={height} aspect={1.6}>
                <PieChart>
                    {showLegend && (
                        <Legend
                            verticalAlign='bottom'
                            align='center'
                            payload={[{ value: percent, color: fillColor }]}
                        />
                    )}
                    <Pie
                        data={backgroundPieData}
                        dataKey='value'
                        label={({ payload }) => payload.label}
                        fill={backgroundColor}
                        {...pieProps}
                    />

                    <Pie
                        data={colorData}
                        dataKey='value'
                        fill='none'
                        {...pieProps}
                    >
                        {colorData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color} />
                        ))}
                    </Pie>
                    <Pie
                        stroke='none'
                        activeIndex={1}
                        activeShape={Arrow}
                        data={arrowData}
                        dataKey='value'
                        fill='none'
                        {...pieProps}
                    />
                </PieChart>
            </ResponsiveContainer>
        );
    }
}

export default GaugeChartType;
