import React from 'react';
import PropTypes from 'prop-types';
import { Header } from 'semantic-ui-react';

import withReport from '../../../common/report/withReport';
import ReportChartComponent from '../../report-chart';

export const ReportDetailChart = withReport(({ sfid }) => {
    if (!sfid) {
        return null;
    }
    return (
        <div>
            <Header as='h3' dividing>
                Overview
            </Header>
            <ReportChartComponent sfid={sfid} />
        </div>
    );
});

ReportDetailChart.propTypes = {
    sfid: PropTypes.string.isRequired,
};

export default ReportDetailChart;
