import {
    groupItems,
    getGroupSfid,
    groupItemsByName,
} from '../../helpers/grouping.helpers';

export const getAllReports = state => {
    const ids = state.report.reportItemsIds;
    return ids.map(id => getById(state, id));
};

export const getById = (state, id) => state.report.reportItemsIdMap[id];

export const loadingSelector = state => state.report.loading;

export const loadedSelector = state => state.report.loaded;

export const errorSelector = state => state.report.error;

export const getByGroupSelector = (state, props) => {
    return groupItems(
        state.report.reportItemsIds
            .map(id => getById(state, id))
            .filter(item => item.reportTypeC === props.group),
    );
};

export const getByGroupEnabledSelector = (state, props) => {
    return groupItems(
        state.report.reportItemsIds
            .map(id => getById(state, id))
            .filter(
                item =>
                    item.reportTypeC === props.group &&
                    isEnabledSelector(state, item.sfid),
            ),
    );
};

export const getByGroupByNameEnabledSelector = (state, props) => {
    return groupItemsByName(
        state.report.reportItemsIds
            .map(id => getById(state, id))
            .filter(
                item =>
                    item.reportTypeC === props.group &&
                    isEnabledSelector(state, item.sfid),
            ),
    );
};

export const getByGroupByNameSelector = (state, props) => {
    return groupItemsByName(
        state.report.reportItemsIds
            .map(id => getById(state, id))
            .filter(item => item.reportTypeC === props.group),
    );
};

export const getChildReportPath = (state, id) => state.report.reportPathMap[id];

export const isReportEnabledSelector = (state, { sfid }) => {
    return sfid && state.report.enabledReports.indexOf(sfid) !== -1;
};

export const isEnabledSelector = (state, sfid) => {
    return sfid && state.report.enabledReports.indexOf(sfid) !== -1;
};

export const isGroupEnabledSelector = (state, ownProps) => {
    const sfid = getGroupSfid(ownProps);
    if (Array.isArray(sfid)) {
        return sfid.reduce(
            (acc, curr) => acc || isEnabledSelector(state, curr),
            false,
        );
    }
    return isEnabledSelector(state, sfid);
};
