export const DateFilterValues = {
    ALL_TIME: 'ALL_TIME',
    THIS_FISCAL_QUARTER: 'THIS_FISCAL_QUARTER',
    LAST_FISCAL_QUARTER: 'LAST_FISCAL_QUARTER',
    THIS_YEAR: 'THIS_YEAR',
    LAST_YEAR: 'LAST_YEAR',
    LAST_AND_THIS_FISCAL_QUARTER: 'LAST_AND_THIS_FISCAL_QUARTER',
    DEFAULT: 'THIS_FISCAL_QUARTER',
};

export const DateFilterText = {
    [DateFilterValues.ALL_TIME]: 'All Time',
    [DateFilterValues.THIS_FISCAL_QUARTER]: 'Current Quarter',
    [DateFilterValues.LAST_FISCAL_QUARTER]: 'Last Quarter',
    [DateFilterValues.THIS_YEAR]: 'Current Year',
    [DateFilterValues.LAST_YEAR]: 'Last Year',
    [DateFilterValues.LAST_AND_THIS_FISCAL_QUARTER]: 'Last And Current Quarter',
};

export default DateFilterValues;
