import React, { Component } from 'react';

export class Footer extends Component {
    constructor() {
        super();

        const today = new Date(),
            date = today.getFullYear();
        this.state = {
            date: date,
        };
    }

    render() {
        return (
            <div className='copyright'>
                {this.state.date} &copy; AcariaHealth, Inc. All Rights reserved.
            </div>
        );
    }
}

export default Footer;
