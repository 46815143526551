import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Button,
    Checkbox,
    Grid,
    Header,
    Image,
    List,
    Table,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import {
    lockContact,
    unlockContact,
} from '../../../store/contact/contactActions';
import get from 'lodash/get';
import DefaultUserImage from '../../../assets/images/defaultUserImage.png';
export class AccountOrgListMobile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
        };
        this.toggleExpanded = this.toggleExpanded.bind(this);
    }

    static propTypes = {
        list: PropTypes.array,
    };

    toggleExpanded() {
        this.setState({ expanded: !this.state.expanded });
    }

    toggleLock = (id, data) => {
        if (data.checked) {
            this.props.unlock({ contactId: id });
        } else {
            this.props.lock({ contactId: id });
        }
    };

    render() {
        const classToggle = this.state.expanded
            ? 'mobile-show drawer active'
            : 'mobile-show drawer inactive';
        const buttonToggle = this.state.expanded ? 'Hide' : 'Expand';
        const dimmerToggle = this.state.expanded
            ? 'drawerDimmer active'
            : 'drawerDimmer';

        const orgList = this.props.list
            ? Object.keys(this.props.list).map((key, i) => (
                  <List.Item key={i}>
                      <Image
                          avatar
                          src={
                              get(
                                  this.props,
                                  'list[key].user.tflRampAvatarUrlC',
                              )
                                  ? get(
                                        this.props,
                                        'list[key].user.tflRampAvatarUrlC',
                                    )
                                  : DefaultUserImage
                          }
                      />
                      <List.Content>
                          <List.Header>{this.props.list[key].name}</List.Header>
                      </List.Content>
                      <Checkbox
                          disabled={!this.props.isAdmin || this.props.locking}
                          defaultChecked={this.props.list[key].activeC}
                          onChange={(e, data) =>
                              this.toggleLock(this.props.list[key].id, data)
                          }
                          className='floatRight'
                          toggle
                      />
                  </List.Item>
              ))
            : '';

        const items = this.props.list ? this.props.list.length : 0;

        return (
            <React.Fragment>
                <div className={classToggle}>
                    <Grid
                        columns={2}
                        className='drawer-header'
                        verticalAlign='middle'
                        onClick={this.toggleExpanded}
                    >
                        <Grid.Column>
                            <Header as='h4'>Organizations ({items})</Header>
                        </Grid.Column>
                        <Grid.Column textAlign='right'>
                            <Button
                                basic
                                compact
                                size='mini'
                                className='noBorder'
                            >
                                {buttonToggle}
                            </Button>
                        </Grid.Column>
                    </Grid>
                    <div className='drawer-content'>
                        <Table basic='very' unstackable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>User</Table.HeaderCell>
                                    <Table.HeaderCell collapsing>
                                        Status (Active & Inactive)
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                        </Table>
                        <List>{orgList}</List>
                    </div>
                </div>

                <div className={dimmerToggle} onClick={this.toggleExpanded} />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    locking: state.contact.locking,
    isAdmin: state.auth.contact.portalUserTypeC === 'Admin',
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            lock: lockContact,
            unlock: unlockContact,
        },
        dispatch,
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AccountOrgListMobile);
