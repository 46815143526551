import get from 'lodash/get';
import moment from 'moment';

export const DateFormatter = ({ accessor, row }) => {
    const value = get(row, accessor);
    if (!value) {
        return null;
    }
    const date = moment.utc(value);
    if (!date) {
        return null;
    }
    return date.format('MM-DD-YYYY');
};

export default DateFormatter;
