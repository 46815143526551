import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/domain/domainActions';
import * as selectors from '../../store/domain/domainSelectors';
import { Dimmer, Loader } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import Notify from '../../helpers/notification.helpers';

const mapStateToProps = state => ({
    prevDomain: selectors.domainSelector(state),
    loading: selectors.domainLoadingSelector(state),
    error: selectors.domainErrorSelector(state),
    verified: selectors.domainVerifiedSelector(state),
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            verify: domain => actions.verifyDomain(domain),
        },
        dispatch,
    );

const enhance = connect(
    mapStateToProps,
    mapDispatchToProps,
);
export const verifyDomain = WrappedComponent =>
    enhance(
        ({
            domain,
            prevDomain,
            verified,
            loading,
            error,
            verify,
            ...otherProps
        }) => {
            useEffect(() => {
                if (domain && !verified) {
                    verify(domain);
                }
            }, [verified, domain, prevDomain]);

            if (error) {
                Notify.error(error.message);
            }

            return (
                <Dimmer.Dimmable dimmed={loading} className='dimmerWrapper'>
                    <Dimmer active={loading} page inverted>
                        <Loader size='large'>Loading</Loader>
                    </Dimmer>
                    {!loading && (
                        <WrappedComponent verified={verified} {...otherProps} />
                    )}
                </Dimmer.Dimmable>
            );
        },
    );

export default verifyDomain;
