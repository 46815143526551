import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as selectors from '../../../store/patient-roster/patientRosterSelectors';

export const TotalPatients = ({ count }) => count;

TotalPatients.propTypes = {
    count: PropTypes.number,
};

TotalPatients.defaultProps = {
    count: 0,
};

const mapStateToProps = state => ({
    count: selectors.patientRosterTotalPatientsSelector(state),
});

export default connect(mapStateToProps)(TotalPatients);
