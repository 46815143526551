import {
    withProps,
    compose,
    withState,
    withHandlers,
    mapProps,
    lifecycle,
} from 'recompose';
import withDebounceHandler from './withDebounceHandler';

export const withDebounce = compose(
    // HOC settings
    // delay - Debounce delay in ms
    // reset - Reset input on defaultValue change (mix contolled and uncontrolled component)
    withProps({ delay: 600, reset: true }),
    // onChange handler will be called once per delay ms
    withDebounceHandler('onChange', ({ delay }) => delay),
    // Store input value in state and pass to child input to be able to reset it
    withState('value', 'setValue', ({ defaultValue }) => defaultValue || ''),
    lifecycle({
        componentWillReceiveProps({ defaultValue }) {
            // If input was reset throw default property
            if (
                this.props.reset &&
                defaultValue !== this.props.value &&
                defaultValue !== this.props.defaultValue
            ) {
                this.props.setValue(defaultValue || '');
            }
        },
    }),
    // Call state update function (setValue) to update input value immediately and call change handler (onChage) to dispatch filter change
    withHandlers({
        onChange: ({ onChange, setValue }) => (...args) => {
            onChange(...args);
            const { value } = args[1];
            setValue(value);
        },
    }),

    mapProps(({ setValue, reset, ...otherProps }) => otherProps),
);

export default withDebounce;
