import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Grid, List, Dimmer, Loader } from 'semantic-ui-react';
import * as contactActions from '../../../store/contact/contactActions';
import { TIMEZONE } from '../../../store/constants/dropdown';

export class AccountBio extends Component {
    /**
     * Default constructor to set up state and functions
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {
            timezone:
                props.contact && props.contact.userTimezoneC
                    ? props.contact.userTimezoneC
                    : props.initialContact.userTimezoneC,
        };
    }

    handleChange = value => {
        this.setState({ timezone: value });
        this.props.setTimeZone(value, this.props.contact.id);
    };

    render() {
        let timeZone = this.props.loading ? (
            <div>
                <Dimmer active inverted>
                    <Loader size='large'>Loading</Loader>
                </Dimmer>
            </div>
        ) : (
            <Form.Select
                fluid
                label='Change Timezone'
                onChange={(e, { value }) => this.handleChange(value)}
                options={TIMEZONE}
                value={this.state.timezone}
            />
        );

        return (
            <Grid.Row>
                <Grid.Column width={8}>
                    <List>
                        <List.Item>
                            <List.Header>Organization</List.Header>
                            {this.props.account ? this.props.account.name : ''}
                        </List.Item>
                        <List.Item>
                            <List.Header>Username/Email</List.Header>
                            {this.props.contact.email}
                        </List.Item>
                    </List>
                </Grid.Column>
                <Grid.Column width={8}>
                    <Form>
                        <Form.Group widths='equal'>{timeZone}</Form.Group>
                    </Form>
                </Grid.Column>
            </Grid.Row>
        );
    }
}

const mapStateToProps = (state, ownProps = {}) => {
    return {
        account: state.auth.account,
        contact: state.contact.contact,
        loading: state.contact.loading,
        initialContact: state.auth.contact,
    };
};

const mapDispatchToProps = dispatch => ({
    setTimeZone: (value, contactId) =>
        dispatch(contactActions.timeZoneUpdate(value, contactId)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AccountBio);
