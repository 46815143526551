import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Divider, Grid, Header, Modal, Label } from 'semantic-ui-react';
import ReactCollapsingTable from 'react-collapsing-table';
import { FILL_COLUMNS } from '../../../.././store/constants/table';
import { FILL_ICONS } from '../../../.././store/constants/tableIcons';
import { NumberFormat } from '../../../../helpers/currency.helpers'; //import EventDate from '../EventDate';
/**/ export class CopayModal extends Component {
    static propTypes = {
        handleClose: PropTypes.func,
        handleResolve: PropTypes.func,
        open: PropTypes.bool.isRequired,
    };
    formatPrice(price) {
        return '$' + price.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
    getClaimCosts() {
        try {
            if (this.props.event.claims && this.props.event.claims.length) {
                const {
                    patientCopayC,
                    copayAssistancePaidC,
                } = this.props.event.claims[0];
                return {
                    patientCopay: patientCopayC || 0,
                    copayAssistancePaid: copayAssistancePaidC || 0,
                };
            } else
                return {
                    patientCopay: 0,
                    copayAssistancePaid: 0,
                };
        } catch (error) {
            console.log(error);
        }
    }
    render() {
        return (
            <Modal
                size='tiny'
                open={this.props.open}
                closeOnDimmerClick={false}
                closeOnDocumentClick={false}
            >
                <Modal.Header>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column
                                className='event-modal__header'
                                width={8}
                            >
                                <div className='event-modal__title'>
                                    <Header as='h2'>Fill detail</Header>
                                    <Label
                                        className='greenBG whiteColor'
                                        size='mini'
                                    >
                                        Copay Assistance
                                    </Label>
                                </div>
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <div className='event-modal__cost'>
                                    <Header as='h3' textAlign='right'>
                                        <span>Claim Cost</span>
                                        {NumberFormat.format(
                                            this.props.event.price,
                                        )}
                                        <span>Copay Assistance</span>
                                        {this.formatPrice(
                                            this.getClaimCosts()
                                                .copayAssistancePaid,
                                        )}
                                        <span>Patient Out Of Pocket</span>
                                        {this.formatPrice(
                                            this.getClaimCosts().patientCopay,
                                        )}
                                    </Header>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Modal.Header>
                <Modal.Content scrolling>
                    <div className='flexDiv justifyContent'>
                        <Header as='h3'>Disease State</Header>
                        <Header as='h5'>
                            {this.props.event.patientReferenceCategoryC}
                        </Header>
                    </div>
                    <Divider />
                    <Modal.Description>
                        <ReactCollapsingTable
                            rows={this.props.event.claims}
                            columns={FILL_COLUMNS}
                            icons={FILL_ICONS}
                        />
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions border>
                    <Grid columns={2} className='marginV0'>
                        <Grid.Column />
                        <Grid.Column>
                            <Button
                                secondary
                                fluid
                                onClick={this.props.handleClose}
                            >
                                Close
                            </Button>
                        </Grid.Column>
                    </Grid>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default CopayModal;
