import { put, call, select, fork } from 'redux-saga/effects';

import { getReports } from '../../api/reportApi';
import * as authSelectors from '../auth/authSelectors';
import * as actions from './reportActions';
import * as reportPathActions from '../report-path/reportPathActions';
import { gotoReportPathSaga } from '../report-path/reportPathSaga';
import { getReportChildItemReportPath } from '../../api/reportPathApi';
import * as selectors from './reportSelectors';
import { push } from 'connected-react-router';
import { setDateFilter } from '../report-data/reportDataActions';

export function* fetchReportsSaga() {
    try {
        const accountId = yield select(authSelectors.getAccountId);
        if (!accountId) {
            yield put(actions.fetchReportsItemsError('Not authenticated'));
            return;
        }
        const record = yield getReports(accountId);
        //console.log('== FetchReports ==');
        //console.log(record);
        yield put(actions.fetchReportsItemsSuccess(record));
    } catch (error) {
        yield put(actions.fetchReportsItemsError(error.message));
    }
}

export function* fetchReportChildReportPathSaga({ id }) {
    try {
        const reportPath = yield call(getReportChildItemReportPath, id);

        if (reportPath.length > 0) {
            const result = reportPath[0];
            const reportPathId = result.sfid;
            yield put(
                reportPathActions.fetchReportPathSuccess(reportPathId, result),
            );
            yield put(
                actions.fetchReportChildReportPathSuccess(id, reportPathId),
            );
        }
    } catch (error) {
        yield put(actions.fetchReportChildReportPathError(id, error.message));
    }
}

export function* viewReportSaga({ id, date }) {
    let reportPathId = yield select(selectors.getChildReportPath, id);
    if (date) {
        yield put(setDateFilter(date));
    }
    if (reportPathId) {
        yield fork(gotoReportPathSaga, {
            id: reportPathId,
        });
        return;
    }
    yield call(fetchReportChildReportPathSaga, { id });
    reportPathId = yield select(selectors.getChildReportPath, id);
    if (!reportPathId) {
        yield put(push('/patient-roster?sort=true'));
    }
    yield fork(gotoReportPathSaga, {
        id: reportPathId,
    });
}
