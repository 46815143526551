import React from 'react';
import PropTypes from 'prop-types';
import { Header, List } from 'semantic-ui-react';
import ReportDetailChart from '../chart';

export const ReportDetailMeta = ({ influencers, sfid }) => {
    return (
        <>
            <ReportDetailChart sfid={sfid} />
            {influencers && (
                <div className='paddingT2rem'>
                    <Header as='h3' dividing>
                        Influencers
                    </Header>
                    <List>
                        {influencers.split('\n').map((item, id) => (
                            <List.Item key={`influencers_${id}`}>
                                {item}
                            </List.Item>
                        ))}
                    </List>
                </div>
            )}
        </>
    );
};

ReportDetailMeta.propTypes = {
    influencers: PropTypes.string,
};

export default ReportDetailMeta;
