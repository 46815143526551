import React from 'react';
import PropTypes from 'prop-types';
import withReportLine from '../../../common/report/withReportLine';
import LineChartType from '../../../common/charts/line';

export const LineChart = withReportLine(props => <LineChartType {...props} />);

LineChart.propTypes = {
    sfid: PropTypes.string.isRequired,
    defaultDateFilterC: PropTypes.string,
};

export default LineChart;
