import * as types from './actionTypes';

export default (
    state = {
        error: null,
        loading: false,
        data: {},
    },
    action,
) => {
    switch (action.type) {
        case types.GET_NOTIFICATIONS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case types.REFRESH_NOTIFICATIONS:
            return {
                ...state,
                loading: false,
            };
        case types.GET_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                data: action.payload.data || {},
            };
        case types.GET_NOTIFICATIONS_ERROR:
            return {
                ...state,
                error: action.payload.errorMessage,
            };
        default:
            return state;
    }
};
