import {
    compose,
    setPropTypes,
    withHandlers,
    mapProps,
    defaultProps,
} from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { bindActionCreators } from 'redux';

import { patientOrderStatusFilterValueSelector } from '../../../store/order-status/orderStatusSelectors';
import { filterPatientOrderStatus } from '../../../store/order-status/orderStatusActions';

const mapStateToProps = (state, { name }) => ({
    defaultValue: get(
        patientOrderStatusFilterValueSelector(state, { name }),
        '[value]',
    ),
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            filter: filterPatientOrderStatus,
        },
        dispatch,
    );

export const withSearch = compose(
    setPropTypes({
        like: PropTypes.bool,
        name: PropTypes.string.isRequired,
        date: PropTypes.bool,
        dateFormat: PropTypes.string,
    }),
    defaultProps({
        like: false,
    }),
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withHandlers({
        onChange: ({ filter, like, name, date, dateFormat }) => (
            _,
            { value },
        ) => {
            filter({ name, value, date, dateFormat, like });
        },
    }),
    mapProps(({ filter, like, ...otherProps }) => otherProps),
);

export default withSearch;
