import { put, select, call, fork } from 'redux-saga/effects';
import * as api from '../../api/dashboardApi';
import * as authSelectors from '../auth/authSelectors';
import { getDashboardItemChildReportPath } from './dashboardSelectors';
import { gotoReportPathSaga } from '../report-path/reportPathSaga';
import * as reportPathApi from '../../api/reportPathApi';
import * as actions from './dashboardActions';
import * as reportPathActions from '../report-path/reportPathActions';
import { push } from 'connected-react-router';

export function* fetchDashboardItemsSaga() {
    try {
        const accountId = yield select(authSelectors.getAccountId);
        if (!accountId) {
            yield put(actions.fetchDashboardItemsError('Not authenticated'));
            return;
        }
        const record = yield api.getDashboards(accountId);
        if (record && record.error) {
            yield put(actions.fetchDashboardItemsError(record.error));
        } else {
            yield put(actions.fetchDashboardItemsSuccess(record));
        }
    } catch (error) {
        yield put(actions.fetchDashboardItemsError(error.message));
    }
}

export function* fetchDashboardItemChildReportPathSaga({ id }) {
    try {
        const reportPath = yield call(
            reportPathApi.getDashboardsItemChildReportPath,
            id,
        );

        if (reportPath.length > 0) {
            const result = reportPath[0];
            const reportPathId = result.sfid;
            yield put(
                reportPathActions.fetchReportPathSuccess(reportPathId, result),
            );
            yield put(
                actions.fetchDashboardItemChildReportPathSuccess(
                    id,
                    reportPathId,
                ),
            );
        }
    } catch (error) {
        yield put(
            actions.fetchDashboardItemChildReportPathError(id, error.message),
        );
    }
}

export function* viewDashboardItemReportSaga({ id }) {
    let reportPathId = yield select(getDashboardItemChildReportPath, id);
    if (reportPathId) {
        yield fork(gotoReportPathSaga, {
            id: reportPathId,
        });
        return;
    }
    yield call(fetchDashboardItemChildReportPathSaga, { id });
    reportPathId = yield select(getDashboardItemChildReportPath, id);
    if (!reportPathId) {
        yield put(push('/patient-roster?sort=true'));
    }
    yield fork(gotoReportPathSaga, {
        id: reportPathId,
    });
}
