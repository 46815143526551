import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Pagination from '../../common/Pagination';
import * as selectors from '../../../store/order-status/orderStatusSelectors';
import * as actions from '../../../store/order-status/orderStatusActions';

export const PatientOrderStatusPagination = ({
    currentPage,
    totalPages,
    goToFirstPage,
    goToLastPage,
    goToNextPage,
    goToPrevPage,
    setPageSize,
}) =>
    Pagination({
        currentPage: currentPage,
        totalPages: totalPages,
        firstPage: goToFirstPage,
        lastPage: goToLastPage,
        nextPage: goToNextPage,
        previousPage: goToPrevPage,
        handlePageSizeChange: setPageSize,
        showPegeSizeSelect: true,
    });

const mapStateToProps = state => ({
    currentPage: selectors.getOrderStatusCurrentPage(state),
    totalPages: selectors.getOrderStatusLastPage(state),
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            goToFirstPage: actions.goToFirstPage,
            goToLastPage: actions.goToLastPage,
            goToNextPage: actions.goToNextPage,
            goToPrevPage: actions.goToPrevPage,
            setPageSize: actions.setPageSize,
        },
        dispatch,
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PatientOrderStatusPagination);
