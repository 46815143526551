import { getPrefixedActionType, types } from './actionTypes';

/**
 *
 * @param {string} prefix State slice prefix
 */
export const getActions = prefix => {
    const filter = ({ column, value, like } = {}) => {
        return {
            type: getPrefixedActionType(prefix, types.FILTER),
            payload: {
                column,
                value,
                like,
            },
        };
    };

    const resetFilter = () => ({
        type: getPrefixedActionType(prefix, types.RESET_FILTER),
    });

    const fetch = () => ({
        type: getPrefixedActionType(prefix, types.REQUEST),
    });

    const success = ({ data, total } = {}) => ({
        type: getPrefixedActionType(prefix, types.SUCCESS),
        payload: {
            data,
            total,
        },
    });

    const error = ({ errorMessage }) => ({
        type: getPrefixedActionType(prefix, types.ERROR),
        payload: {
            errorMessage: errorMessage,
        },
    });

    const goToPage = ({ page }) => ({
        type: getPrefixedActionType(prefix, types.GO_TO_PAGE),
        payload: {
            page,
        },
    });

    const goToNextPage = () => ({
        type: getPrefixedActionType(prefix, types.GO_TO_NEXT_PAGE),
    });

    const goToPrevPage = () => ({
        type: getPrefixedActionType(prefix, types.GO_TO_PREV_PAGE),
    });

    const goToFirstPage = () => ({
        type: getPrefixedActionType(prefix, types.GO_TO_FIRST_PAGE),
    });

    const goToLastPage = () => ({
        type: getPrefixedActionType(prefix, types.GO_TO_LAST_PAGE),
    });

    const setPageSize = ({ pageSize }) => ({
        type: getPrefixedActionType(prefix, types.SET_PAGE_SIZE),
        payload: {
            pageSize,
        },
    });

    /**
     *
     * @param {Object} payload
     * @param {Object|string} payload.sort
     * @param {?string} [payload.sort.column]
     * @param {?string = 'asc'} [payload.sort.direction]
     */
    const sort = ({ sort }) => ({
        type: getPrefixedActionType(prefix, types.SORT),
        payload: {
            sort,
        },
    });
    return {
        fetch,
        success,
        error,
        sort,
        filter,
        resetFilter,
        setPageSize,
        goToPage,
        goToFirstPage,
        goToLastPage,
        goToPrevPage,
        goToNextPage,
    };
};

export default getActions;
