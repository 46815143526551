import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as authActions from '../../store/auth/authActions';
import {
    Button,
    Divider,
    Form,
    Grid,
    Image,
    Input,
    Segment,
} from 'semantic-ui-react';
import logo from '../../assets/images/ah-logo-rev-notag.png';
import defaultLogo from '../../assets/images/ah-logo-rev-notag.png';
import Footer from '../common/Footer';
import Notify from '../../helpers/notification.helpers'; //import PropTypes from 'prop-types';
/**/ export class PasswordRecovery extends Component {
    //static propTypes = {
    //    logOutRequest: PropTypes.func.isRequired,
    //    passwordResetRequest: PropTypes.func.isRequired,
    //};
    /**
     * Default constructor to set up state and functions
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            submitted: false,
            authenticated: false,
            error: false,
            errorMessage: 'Please fill out required fields.',
        };

        // this.getDerivedStateFromProps = this.getDerivedStateFromProps.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInvalidSubmit = this.handleInvalidSubmit.bind(this);
        this.handlePasswordReset = this.handlePasswordReset.bind(this);
    }

    /**
     * Default input change method to handle saving state from inputs
     * @param event
     */
    handleInputChange(event) {
        const target = event.target;
        this.setState({
            [target.name]: target.value,
        });
    }

    /**
     * Submit button for logging in, sets state and does validation based on user input
     */
    handleSubmit() {
        // Show loading
        this.setState({ submitted: true });

        // Validation
        if (!this.state.email || !this.state.password) {
            this.setState({ submitted: false, error: true });
            this.forceUpdate();

            // Login
        } else {
            this.setState({ error: false });
            this.props.onSubmit(this.state.email, this.state.password);
        }
    }

    handlePasswordReset(e) {
        if (this.state.email) {
            this.props.onPasswordReset(this.state.email);
            Notify.success(
                'If you have an account with Acaria Connect, ' +
                    'then a password reset email has been sent to the email provided.',
            );
        } else {
            Notify.error(
                'Please fill in the email field to reset your password.',
            );
        }
    }

    /**
     * When a form is submitted with validation errors
     * @param e
     */
    handleInvalidSubmit(e) {
        e.preventDefault();
        Notify.error(
            'MISSING ' +
                e.target.placeholder.toUpperCase() +
                ': Fill in ' +
                e.target.placeholder.toLowerCase(),
        );
        this.setState({ submitted: false, error: true });
    }

    /**
     * Check for state change from redux store on success or failure to login
     * @param nextProps
     */
    static getDerivedStateFromProps(nextProps) {
        // Successful login
        if (nextProps.profile && nextProps.profile.email) {
            this.setState({ submitted: false, authenticated: true });
        }

        // error on login
        if (nextProps.error && nextProps.error.message) {
            Notify.error(nextProps.error.message);
            this.setState({
                submitted: false,
                error: true,
                errorMessage: nextProps.error.message,
                authenticated: false,
            });
        }

        return null;
    }

    render() {
        let clientLogo = this.props.logo ? this.props.logo : defaultLogo;
        return (
            <div className='login-page'>
                <div className='login-form'>
                    <Segment>
                        <div>
                            <div className='acaria-connect'>
                                <Grid className='logo'>
                                    <Image
                                        src={logo}
                                        width='230'
                                        alt='Acaria Connect'
                                    />
                                    <p>Acaria Connect</p>
                                </Grid>
                                <div className='clientLogo'>
                                    <Image
                                        src={clientLogo}
                                        width='200'
                                        alt='client logo'
                                    />
                                </div>
                                <div className='paddedLR2rem'>
                                    <Divider horizontal>
                                        Password Recovery
                                    </Divider>
                                    <Form
                                        success={this.state.authenticated}
                                        loading={this.state.submitted}
                                        className='ui'
                                        onInvalid={this.handleInvalidSubmit}
                                        onSubmit={this.handleSubmit}
                                        error={this.state.error}
                                    >
                                        <Form.Field
                                            error={
                                                this.state.error &&
                                                !this.state.email
                                            }
                                        >
                                            <Input
                                                icon='mail'
                                                iconPosition='left'
                                                name='email'
                                                type='text'
                                                placeholder='Email Address'
                                                value={this.state.email}
                                                onChange={
                                                    this.handleInputChange
                                                }
                                                required
                                            />
                                        </Form.Field>
                                        <Button
                                            type='submit'
                                            className='primary fluid submit'
                                            onClick={this.handlePasswordReset}
                                        >
                                            Send
                                        </Button>
                                        {/* <p>
                                            <a
                                                onClick={this.props.onSubmit}
                                                href='/'
                                            >
                                                Back to Sign in.
                                            </a>
                                        </p> */}

                                        <Link
                                            style={{
                                                color: 'black',
                                                display: 'block',
                                                margin: 10,
                                            }}
                                            to='/'
                                        >
                                            Back to Sign in.
                                        </Link>
                                        <Footer />
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </Segment>
                </div>
            </div>
        );
    }
}

function mapStatesToProps({
    auth: { isAuthenticated, profile, error },
    domain: { domain, name, logo },
}) {
    return {
        isAuthenticated,
        profile,
        error,
        logo,
    };
}

const mapDispatchToProps = dispatch => ({
    onSubmit: () => dispatch(authActions.logOutRequest()),
    onPasswordReset: email => dispatch(authActions.passwordResetRequest(email)),
});

export default connect(
    mapStatesToProps,
    mapDispatchToProps,
)(PasswordRecovery);
