import api from './api';

export const getReports = accountId =>
    api.get('/PayerCustomReportCs', {
        params: {
            filter: {
                where: {
                    payerC: accountId,
                },
            },
        },
    });

export const getReportData = (reportId, accountId, dateFilter) =>
    api.get('/Reports', {
        params: {
            filter: {
                where: {
                    and: [
                        {
                            sfid: reportId,
                        },
                        {
                            payerC: accountId,
                        },
                        {
                            reportDateFilter: dateFilter,
                        },
                        {
                            success: true,
                        },
                    ],
                },
                order: 'fetched DESC',
                limit: 1,
            },
        },
    });
