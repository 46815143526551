import React from 'react';
import Table from './Table';
import Pagination from './Pagination';

export default () => (
    <>
        <Table />
        <Pagination />
    </>
);
