import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Divider, Header, Modal } from 'semantic-ui-react';

export class CaseModal extends Component {
    static propTypes = {
        handleAssign: PropTypes.func,
        open: PropTypes.bool.isRequired,
        patientId: PropTypes.string,
        problem: PropTypes.string,
    };

    handleAssign = () => {
        this.props.handleAssignToMe();
    };

    render() {
        return (
            <Modal size='mini' open={this.props.open}>
                <Modal.Header>
                    <div className='event-modal__header'>
                        <Header as='h2'>Case Detail</Header>
                    </div>
                </Modal.Header>
                <Modal.Content>
                    <div className='flexDiv justifyContent'>
                        <Header as='h3'>Problem</Header>
                        <Header as='h5'>{this.props.problem}</Header>
                    </div>
                    <Divider clearing hidden className='marginV0' />
                    <Modal.Description />
                </Modal.Content>
                <Modal.Actions border='true'>
                    <div className='ui three column grid marginV0'>
                        <div className='column'>
                            <button
                                className='ui mini fluid button'
                                onClick={this.props.handleModalClose}
                            >
                                Close
                            </button>
                        </div>
                        <div className='column'>
                            <Link
                                className='ui mini fluid secondary button'
                                to={`/patient-detail/${
                                    this.props.patientId
                                }?type=assessment`}
                            >
                                View Patient
                            </Link>
                        </div>
                        <div className='column'>
                            <button
                                className='ui mini fluid primary button'
                                onClick={this.handleAssign}
                            >
                                Assign to me
                            </button>
                        </div>
                    </div>
                </Modal.Actions>
            </Modal>
        );
    }
}
