import React, { Component } from 'react';
import { connect } from 'react-redux';
import { YearInput, MonthInput } from 'semantic-ui-calendar-react';
import { Container, Grid, Select } from 'semantic-ui-react';
import { filter } from '../../store/patient-roster/patientRosterActions';
import { filterPatientOrderStatus } from '../../store/order-status/orderStatusActions';
import './calendar.scss';
const day = () => {
    let arr = [];
    for (let index = 1; index <= 31; index++) {
        arr.push({
            key: (0 + index).toString(),
            value: (0 + index).toString(),
            text: (0 + index).toString(),
        });
        if (index >= 31) return arr;
    }
};
const month = [
    { key: '01', value: '01', text: 'Jan' },
    { key: '02', value: '02', text: 'Feb' },
    { key: '03', value: '03', text: 'Mar' },
    { key: '04', value: '04', text: 'Apr' },
    { key: '05', value: '05', text: 'May' },
    { key: '06', value: '06', text: 'Jun' },
    { key: '07', value: '07', text: 'Jul' },
    { key: '08', value: '08', text: 'Aug' },
    { key: '09', value: '09', text: 'Sep' },
    { key: '10', value: '10', text: 'Oct' },
    { key: '11', value: '11', text: 'Nov' },
    { key: '12', value: '12', text: 'Dec' },
];

export class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            day: '',
            monthText: '',
            month: '',
            year: '',
        };
        this.handleOnClick = this.handleOnClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.newDate = this.newDate.bind(this);
    }
    componentWillReceiveProps(newProps) {
        const { filtersOrder, filtersRoster } = newProps;
        if (
            Object.keys(filtersOrder).length < 1 ||
            Object.keys(filtersRoster).length < 1
        )
            this.setState({
                day: '',
                monthText: '',
                month: '',
                year: '',
            });
    }
    handleOnClick(event, data) {
        const { day, month, year } = this.state;
        if (day && day !== '' && month && month !== '' && year && year !== '')
            this.setState({
                day: '',
                monthText: '',
                month: '',
                year: '',
            });
    }
    handleChange(event, data) {
        const { name, value } = data;
        console.log({ event, data });
        if (name === 'month')
            return this.setState(
                {
                    monthText: value,
                    month: month.find(item => item.text === value).value,
                },
                () => this.newDate(),
            );
        else return this.setState({ [name]: value }, () => this.newDate());
    }
    newDate() {
        const {
            onChange,
            name,
            dateFormat,
            filterPatientOrder,
            filterPatientRoster,
            type,
        } = this.props;
        const { day, month, year } = this.state;
        if (day && month && year && onChange) {
            const result = {
                name,
                value: `${month}-${
                    day < 10 ? '0' + day.toString() : day
                }-${year}`,
                date: undefined,
                dateFormat,
                like: false,
            };
            type === 'roster'
                ? filterPatientRoster(result)
                : filterPatientOrder(result);
        }
    }
    render() {
        const { label } = this.props;
        return (
            <Container className='field'>
                {label && <label>{label}</label>}
                <Grid className='select-calendar' columns='3' stackable>
                    <Grid.Column width='5'>
                        <Select
                            onClick={this.handleOnClick}
                            search
                            value={this.state.day}
                            icon={false}
                            fluid
                            onChange={this.handleChange}
                            name='day'
                            placeholder='Day'
                            options={day()}
                        />
                    </Grid.Column>
                    <Grid.Column width='5'>
                        <MonthInput
                            onClick={this.handleOnClick}
                            closeOnMouseLeave
                            value={this.state.monthText}
                            icon={false}
                            fluid
                            onChange={this.handleChange}
                            name='month'
                            placeholder='Month'
                        />
                    </Grid.Column>
                    <Grid.Column width='5'>
                        <YearInput
                            onClick={this.handleOnClick}
                            closeOnMouseLeave
                            value={this.state.year}
                            icon={false}
                            fluid
                            onChange={this.handleChange}
                            name='year'
                            placeholder='Year'
                        />
                    </Grid.Column>
                </Grid>
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    filtersOrder: state.orderStatus.filters,
    filtersRoster: state.patientRoster.filters,
});

const mapDispatchToProps = dispatch => ({
    filterPatientRoster: ({ name, value, date, dateFormat, like }) =>
        dispatch(filter({ name, value, date, dateFormat, like })),
    filterPatientOrder: ({ name, value, date, dateFormat, like }) =>
        dispatch(
            filterPatientOrderStatus({ name, value, date, dateFormat, like }),
        ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(index);
