import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Button, Divider, Form, Grid, Segment } from 'semantic-ui-react';
import { DatesRangeInput } from 'semantic-ui-calendar-react';
import ResetPasswordModal from '../accountModals/ResetPasswordModal';
import * as authActions from '../../../store/auth/authActions';
import { downloadData } from '../../../store/download-data/downloadDataActions';

const DATE_FORMAT = 'YYYY-MM-DD';
export class AccountSecurity extends Component {
    constructor(props) {
        super(props);

        this.state = {
            date: '',
            time: '',
            dateTime: '',
            datesRange: '',
        };
    }

    handleChange = (event, { name, value }) => {
        if (this.state.hasOwnProperty(name)) {
            this.setState({ [name]: value });
        }
    };

    handleDownload = () => {
        const [start, end] = this.state.datesRange.split(' - ');
        const startDate = start ? moment(start).toDate() : null;
        const endDate = end ? moment(end).toDate() : null;
        this.props.downloadData({
            start: startDate,
            end: endDate,
        });
    };

    render() {
        const { isAdmin } = this.props;
        return (
            <Grid>
                <Grid.Row className='paddingB0'>
                    <Grid.Column mobile={16} tablet={16} computer={8}>
                        <Form>
                            <h5>Reset Your Account Password</h5>
                            <ResetPasswordModal />
                        </Form>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={16} computer={8}>
                        <Form>
                            <h5>Log Out of Your Account</h5>
                            <Button
                                primary
                                size='tiny'
                                onClick={this.props.onSubmit}
                                href='./'
                            >
                                Logout
                            </Button>
                        </Form>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Divider />
                        <p>
                            The information attached may contain protected
                            health information (PHI). This is a formal notice to
                            indicate that you are now accountable for any
                            further dissemination of this sensitive material. We
                            are prohibited from sharing PHI externally unless
                            there is a business agreement in place.
                        </p>
                        <h5>Download All Patient Data (xls)</h5>
                        <Segment className='shaded noBorder' padded>
                            <h5>Date Range</h5>
                            <Form>
                                <DatesRangeInput
                                    name='datesRange'
                                    dateFormat={DATE_FORMAT}
                                    placeholder='From - To'
                                    value={this.state.datesRange}
                                    iconPosition='left'
                                    onChange={this.handleChange}
                                />
                                <Form.Button
                                    size='small'
                                    primary
                                    content='Download'
                                    onClick={this.handleDownload}
                                    disabled={!isAdmin}
                                />
                            </Form>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

function mapStatesToProps({ auth }) {
    const { isAuthenticated, contact } = auth;
    return {
        isAuthenticated,
        isAdmin: contact.portalUserTypeC === 'Admin',
    };
}

const mapDispatchToProps = dispatch => ({
    onSubmit: () => dispatch(authActions.logOutRequest()),
    onPasswordReset: email => dispatch(authActions.passwordResetRequest(email)),
    downloadData: datesRange => dispatch(downloadData(datesRange)),
});

export default connect(
    mapStatesToProps,
    mapDispatchToProps,
)(AccountSecurity);
