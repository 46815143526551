import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import * as reactRouter from 'react-router';
import { Grid, Menu, Popup } from 'semantic-ui-react'; //import { Grid, Menu, Popup, Button } from 'semantic-ui-react';
/**/ import {
    DashboardSVG,
    CaseManagementSVG,
    ReportSVG,
    AccountSVG,
    PatientsSVG,
    DrugsSVG,
} from '../svgs';

export class NavMenu extends Component {
    /**
     * Default constructor to set up state and functions
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {
            selectedNavOption: this.props.selectedNavOption,
            accessMap: props.accessMap,
            currentId: props.currentId,
        };
    }

    render() {
        return (
            <Grid.Column className='side-nav tablet-up-show'>
                <Menu icon vertical>
                    <Popup
                        content='Dashboard'
                        position='right center'
                        trigger={
                            <Menu.Item
                                as={NavLink}
                                name='dashboard'
                                to='/dashboard'
                                activeClassName='active'
                            >
                                {DashboardSVG}
                            </Menu.Item>
                        }
                    />
                    <Popup
                        content='Reports'
                        position='right center'
                        trigger={
                            <Menu.Item
                                as={NavLink}
                                name='reports'
                                to='/reports'
                                activeClassName='active'
                            >
                                {ReportSVG}
                            </Menu.Item>
                        }
                    />
                    <Popup
                        content='Case Management'
                        position='right center'
                        trigger={
                            <Menu.Item
                                as={NavLink}
                                name='case-management'
                                to='/case-management'
                                activeClassName='active'
                            >
                                {CaseManagementSVG}
                            </Menu.Item>
                        }
                    />
                    <Popup
                        content='Patient Orders'
                        position='right center'
                        trigger={
                            <Menu.Item
                                as={NavLink}
                                name='order-status'
                                to='/patient-order-status'
                                activeClassName='active'
                            >
                                {DrugsSVG}
                            </Menu.Item>
                        }
                    />
                    <Popup
                        content='Patient Roster'
                        position='right center'
                        trigger={
                            <Menu.Item
                                as={NavLink}
                                name='patients'
                                to='/patient-roster'
                                activeClassName='active'
                            >
                                {PatientsSVG}
                            </Menu.Item>
                        }
                    />
                    <Popup
                        content='My Account'
                        position='right center'
                        trigger={
                            <Menu.Item
                                as={NavLink}
                                name='account'
                                to='/account'
                                activeClassName='active'
                            >
                                {AccountSVG}
                            </Menu.Item>
                        }
                    />
                </Menu>
            </Grid.Column>
        );
    }
}

function mapStatesToProps(state, ownProps) {
    return {
        accessMap: state.auth.accessMap,
    };
}

export default reactRouter.withRouter(connect(mapStatesToProps)(NavMenu));
