import * as types from './contactActionTypes';
import * as auth from '../../helpers/auth.helpers';

export default (
    state = {
        contact: auth.contact() ? JSON.parse(auth.contact()) : {},
        loading: false,
        error: null,
        locking: false,
    },
    action,
) => {
    switch (action.type) {
        case types.CONTACT_TIMEZONE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case types.CONTACT_TIMEZONE_SUCCESS:
            auth.setContact(action.contact);
            return {
                ...state,
                contact: action.contact,
                loading: false,
            };
        case types.CONTACT_TIMEZONE_ERROR:
            return {
                ...state,
                contact: {},
                loading: false,
                error: action.error,
            };
        case types.CONTACT_LOCK_REQUEST:
            return {
                ...state,
                locking: true,
            };
        case types.CONTACT_LOCK_SUCCESS:
            return {
                ...state,
                locking: false,
            };
        case types.CONTACT_LOCK_ERROR:
            return {
                ...state,
                locking: false,
            };
        case types.CONTACT_UNLOCK_REQUEST:
            return {
                ...state,
                locking: true,
            };
        case types.CONTACT_UNLOCK_SUCCESS:
            return {
                ...state,
                locking: false,
            };
        case types.CONTACT_UNLOCK_ERROR:
            return {
                ...state,
                locking: false,
            };
        default:
            return state;
    }
};
