import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactCollapsingTable from 'react-collapsing-table';
import orderBy from 'lodash/orderBy';
import get from 'lodash/get';

import TableIcons from './TableIcons';
import EmptyReport from '../empty';

export class Table extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            sortColumn: props.defaultSortColumn || undefined,
            sortDirection: props.defaultSortDirection || undefined,
        };
    }
    static propTypes = {
        callbacks: PropTypes.object,
        columns: PropTypes.arrayOf(
            PropTypes.shape({
                accessor: PropTypes.string,
                label: PropTypes.string,
                position: PropTypes.number,
                priorityLevel: PropTypes.number,
                minWidth: PropTypes.number,
                CustomComponent: PropTypes.oneOfType([
                    PropTypes.func,
                    PropTypes.element,
                ]),
                sortType: PropTypes.oneOf(['date', null]),
                sortable: PropTypes.bool,
            }),
        ).isRequired,
        defaultSortColumn: PropTypes.string,
        defaultSortDirection: PropTypes.oneOf(['ascending', 'descending']),
        redirectFilterApiNameC: PropTypes.string,
        rows: PropTypes.arrayOf(PropTypes.object).isRequired,
        visibleLinesC: PropTypes.number,
    };

    static defaultProps = {
        callbacks: {},
    };

    sort = ({ column, direction }) => {
        this.setState({
            sortColumn: column,
            sortDirection: direction,
        });
    };

    componentDidUpdate(prevProps, prevState) {
        if (
            prevProps.defaultSortColumn !== this.props.defaultSortColumn ||
            prevProps.defaultSortDirection !== this.props.defaultSortDirection
        ) {
            this.setState({
                sortColumn: this.props.defaultSortColumn
                    ? this.props.defaultSortColumn
                    : undefined,
                sortDirection:
                    this.props.defaultSortDirection &&
                    this.props.defaultSortDirection.includes('asc')
                        ? 'ascending'
                        : 'descending',
            });
        }
    }

    handleSort = rows => {
        const { columns } = this.props;
        const { sortColumn, sortDirection = 'ascending' } = this.state;
        if (!sortColumn) {
            return rows;
        }

        const column = columns.find(col => col.accessor === sortColumn);
        if (!column || !column.dataType) {
            return rows;
        }
        const direction = sortDirection === 'ascending' ? 'asc' : 'desc';
        const sortPercent = row =>
            Number(get(row, sortColumn).replace(/[^\d.]/g, ''));
        const sortNumber = row => Number(get(row, sortColumn));
        const sortCurrency = row =>
            Number(get(row, sortColumn).replace(/[^\d.]/g, ''));
        switch (column.dataType.toLowerCase()) {
            case 'percent':
                return orderBy(rows, [sortPercent], [direction]);
            case 'double':
                return orderBy(rows, [sortNumber], [direction]);
            case 'currency':
                return orderBy(rows, [sortCurrency], [direction]);
            case 'string':
            case 'picklist':
            default:
                return orderBy(
                    rows,
                    [sortColumn],
                    [sortDirection === 'ascending' ? 'asc' : 'desc'],
                );
        }
    };

    getRedirectColumn = accessor => {
        if (!this.props.columns.length) {
            return null;
        }
        if (this.props.columns[0].accessor !== accessor) {
            return null;
        }
        return this.props.redirectFilterApiNameC;
    };

    render() {
        const {
            rows,
            columns,
            defaultSortColumn,
            defaultSortDirection,
            callbacks,
            visibleLinesC,
        } = this.props;
        if (rows.length <= 0) {
            return <EmptyReport />;
        }

        const sortedRows = this.handleSort(rows);
        const rowsToRender = visibleLinesC
            ? sortedRows.slice(0, visibleLinesC)
            : sortedRows;
        const allCallbacks = {
            ...callbacks,
        };
        if (columns.length) {
            allCallbacks[columns[0].accessor] = this.getRedirectColumn;
        }
        return (
            <ReactCollapsingTable
                rowSize={99999}
                rows={rowsToRender}
                columns={columns}
                icons={TableIcons}
                column={defaultSortColumn || undefined}
                direction={defaultSortDirection || undefined}
                callbacks={allCallbacks}
                handleSort={this.sort}
            />
        );
    }
}

export default Table;
