import * as types from './caseActionTypes';

export const assignToMe = (caseId, assigneeId) => ({
    type: types.ASSIGN_TO_ME_REQUEST,
    caseId: caseId,
    assigneeId: assigneeId,
});

export const resolve = caseId => ({
    type: types.RESOLVE_REQUEST,
    payload: {
        caseId,
    },
});
