import React from 'react';
import { Grid, Header } from 'semantic-ui-react';
import NavMenu from '../../common/NavMenu';

import Sidebar from './sidebar';
import List from './list';
import Drawer from './drawer'; //import DateFilter from '../../common/date-filter/DateFilter';

/**/ export const ReportList = () => (
    <Grid className='full-page' stretched>
        <Grid.Row className='full-page'>
            <NavMenu />
            <Grid.Column className='stretch-col'>
                <Grid className='shrink marginV0' stackable>
                    <Grid.Row
                        columns={2}
                        className='pageHeader'
                        verticalAlign='middle'
                    >
                        <Grid.Column width={11}>
                            <Header as='h1'>Reports</Header>
                        </Grid.Column>
                        {/*    <Grid.Column width={5} textAlign='right'>
                                    <DateFilter />
                                </Grid.Column> */}
                    </Grid.Row>
                    <Grid.Row className='paddingV0 shrink'>
                        <Grid.Column
                            computer={11}
                            tablet={10}
                            mobile={16}
                            className='reportGrid'
                        >
                            <Grid
                                columns={1}
                                className='reportGridContainer paddingV2rem mobileNoPad'
                                padded='vertically'
                                stretched
                                stackable
                            >
                                <List />
                            </Grid>
                        </Grid.Column>
                        <Sidebar />
                        <Drawer />
                    </Grid.Row>
                </Grid>
            </Grid.Column>
        </Grid.Row>
    </Grid>
);

export default ReportList;
