import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    compose,
    flattenProp,
    setDisplayName,
    lifecycle,
    withState,
} from 'recompose';
import ReactCollapsingTable from 'react-collapsing-table';
import queryString from 'query-string';

import * as selectors from '../../../store/patient-roster/patientRosterSelectors';
import * as actions from '../../../store/patient-roster/patientRosterActions';
import { ROSTER_TABLE_COLUMNS } from '../../../store/constants/table';
import { ROSTER_TABLE_ICONS } from '../../../store/constants/tableIcons';
import withLoading from '../../common/withLoading';
import { paths } from '../../../routes';
import { push } from 'connected-react-router';
import { withRouter } from 'react-router'; //import Table from '../../common/charts/table';
/**/ const mapStateToProps = state => ({
    status: selectors.patientRosterStatusSelector(state),
    list: selectors.patientRosterListSelector(state),
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            resetFilter: actions.resetFilter,
            sort: actions.sort,
            load: actions.fetch,
            filter: actions.filter,
            filterMultiple: actions.filterMultiple,
            resetLocation: () => push(paths.PATIENT_ROSTER),
        },
        dispatch,
    );

const enhance = compose(
    withRouter,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withState('searchFilters', 'setSearchFilter', props => {
        if (props.location.search) {
            const searchFilters = queryString.parse(props.location.search);
            return searchFilters;
        }
        return [];
    }),
    withState('defaultSort', 'setDefaultSort', props => {
        if (Object.keys(props.searchFilters).length) {
            return {
                column: 'spend',
                direction: 'descending',
            };
        } else {
            return {
                column: 'spend',
                direction: 'descending',
            };
        }
    }),
    lifecycle({
        componentDidMount() {
            const {
                searchFilters,
                filterMultiple,
                sort,
                resetLocation,
                resetFilter,
            } = this.props;
            if (!Object.keys(searchFilters).length) {
                sort({
                    column: 'spend',
                    direction: 'descending',
                });
                return;
            }
            const filters = [];
            for (const key in searchFilters) {
                const field = key
                    .split('.')
                    .map(field =>
                        field
                            .replace(/^(.)/, letter => letter.toLowerCase())
                            .replace(/_+c/g, 'C')
                            .replace(/_/g, '')
                            .replace(/^claimC/, 'claims')
                            .replace(/^drugC/, 'drug'),
                    )
                    .join('.');
                const value = searchFilters[key];
                filters.push({
                    filterType: field,
                    value,
                    like: false,
                });
            }
            resetFilter();
            filterMultiple({ filters });
            sort({
                column: 'spend',
                direction: 'descending',
            });
            resetLocation();
        },
    }),
    flattenProp('status'),
    withLoading,
    setDisplayName('PatientRosterList'),
);

export const PatientRosterList = enhance(({ list = [], sort, defaultSort }) => (
    <ReactCollapsingTable
        rowSize={99999}
        rows={list}
        columns={ROSTER_TABLE_COLUMNS}
        icons={ROSTER_TABLE_ICONS}
        handleSort={sort}
        column={defaultSort.column}
        direction={defaultSort.direction}
    />
));

export default PatientRosterList;
