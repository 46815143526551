import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

import withReportPath from '../../../common/withReportPath';
import ReportDetailMeta from '../meta';
import ReportDetailTable from '../table';
import ReportDetailDrawer from '../drawer';
import ReportDetailHeader from '../header';

export const ReportDetailLayout = withReportPath(
    ({
        influencersOpportuntiesC,
        reportIdC,
        redirectFilterApiNameC,
        defaultSortColumnLabelC,
    }) => {
        return (
            <Grid.Row className='paddingV0 shrink'>
                <Grid.Column computer={11} tablet={10} mobile={16}>
                    <Grid
                        columns={1}
                        className='paddingV2rem mobileNoPad mobilePadB100'
                        padded='vertically'
                        stretched
                        stackable
                    >
                        <Grid.Column width={16}>
                            <ReportDetailHeader sfid={reportIdC} />
                            <ReportDetailTable
                                sfid={reportIdC}
                                redirectFilterApiNameC={redirectFilterApiNameC}
                                defaultSortColumnLabelC={
                                    defaultSortColumnLabelC
                                }
                            />
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
                <Grid.Column
                    className='paddingV2rem tablet-up-show'
                    computer={5}
                    tablet={6}
                >
                    <ReportDetailMeta
                        sfid={reportIdC}
                        influencers={influencersOpportuntiesC}
                    />
                </Grid.Column>
                <ReportDetailDrawer
                    sfid={reportIdC}
                    influencers={influencersOpportuntiesC}
                />
            </Grid.Row>
        );
    },
);

ReportDetailLayout.propTypes = {
    sfid: PropTypes.string.isRequired,
};

export default ReportDetailLayout;
