import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Label } from 'semantic-ui-react';
import { Link, NavLink } from 'react-router-dom';
import { NOTIFICATIONS_PREFIX } from '../../../store/notifications';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    viewHighSpendFillNotification,
    viewHighSpendYearNotification,
} from '../../../store/notifications/actions';

export const NotificationAlert = withRouter(
    ({
        caseManagement,
        orderStatus,
        highSpendFills,
        highSpendYear,
        viewHighSpendFill,
        viewHighSpendYear,
    }) => {
        const total =
            caseManagement +
            orderStatus +
            highSpendFills.length +
            highSpendYear.length;
        return (
            <>
                <Dropdown
                    icon='bell outline'
                    button
                    basic
                    className='icon noBorder'
                >
                    <Dropdown.Menu className='notificationAlert'>
                        <Dropdown.Item
                            as={Link}
                            to='/case-management'
                            key='case-management-alert'
                        >
                            Case Management{' '}
                            <span className='alert'>( {caseManagement} )</span>
                        </Dropdown.Item>
                        <Dropdown.Item
                            as={Link}
                            to='/patient-order-status/?referral.statusC=Needs Attention'
                            key='patient-order-alert'
                        >
                            Needs Attention Patients{' '}
                            <span className='alert'>( {orderStatus} )</span>
                        </Dropdown.Item>
                        {highSpendFills.length ? (
                            <Dropdown
                                item
                                button
                                basic
                                text={`High Spend Fill ( ${
                                    highSpendFills.length
                                } )`}
                                key='hsf-alert'
                            >
                                {highSpendFills.length && (
                                    <Dropdown.Menu>
                                        {highSpendFills.map(({ id, name }) => (
                                            <Dropdown.Item
                                                onClick={() => {
                                                    window.location.replace(
                                                        `/patient-detail/${id}`,
                                                    );
                                                    return viewHighSpendFill({
                                                        patientId: id,
                                                    });
                                                }}
                                                text={name}
                                                key={`hsy-alert-patient-${id}`}
                                            />
                                        ))}
                                    </Dropdown.Menu>
                                )}
                            </Dropdown>
                        ) : (
                            <Dropdown.Item>
                                {`High Spend Fill ( ${highSpendFills.length} )`}
                                <i
                                    className='caret right icon'
                                    style={{
                                        fontSize: 11,
                                        float: 'right',
                                        marginRight: -4,
                                    }}
                                />
                            </Dropdown.Item>
                        )}

                        {highSpendYear.length ? (
                            <Dropdown
                                item
                                basic
                                button
                                text={`High Spend YTD ( ${
                                    highSpendYear.length
                                } )`}
                                key='hsytd-alert'
                            >
                                {
                                    <Dropdown.Menu>
                                        {highSpendYear.map(({ id, name }) => (
                                            <Dropdown.Item
                                                onClick={() => {
                                                    window.location.replace(
                                                        `/patient-detail/${id}`,
                                                    );
                                                    return viewHighSpendYear({
                                                        patientId: id,
                                                    });
                                                }}
                                                text={name}
                                                key={`hsytd-alert-patient-${id}`}
                                            />
                                        ))}
                                    </Dropdown.Menu>
                                }
                            </Dropdown>
                        ) : (
                            <Dropdown.Item>
                                {`High Spend YTD ( ${highSpendYear.length} )`}
                                <i
                                    className='caret right icon'
                                    style={{
                                        fontSize: 11,
                                        float: 'right',
                                        marginRight: -4,
                                    }}
                                />
                            </Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                </Dropdown>
                {total > 0 && (
                    <Label color='red' floating>
                        {total}
                    </Label>
                )}
            </>
        );
    },
);

NotificationAlert.propTypes = {
    caseManagement: PropTypes.number,
    orderStatus: PropTypes.number,
    highSpendFills: PropTypes.array,
    highSpendYear: PropTypes.array,
    viewHighSpendFill: PropTypes.func,
    viewHighSpendYear: PropTypes.func,
};

NotificationAlert.defaultProps = {
    caseManagement: 0,
    orderStatus: 0,
    highSpendFills: [],
    highSpendYear: [],
    viewHighSpendFill: () => {},
    viewHighSpendYear: () => {},
};

const mapStateToProps = state => ({
    caseManagement: state[NOTIFICATIONS_PREFIX].data.caseManagement || 0,
    orderStatus: state[NOTIFICATIONS_PREFIX].data.orderStatus || 0,
    highSpendFills: state[NOTIFICATIONS_PREFIX].data.highSpendFills || [],
    highSpendYear: state[NOTIFICATIONS_PREFIX].data.highSpendYear || [],
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            viewHighSpendFill: viewHighSpendFillNotification,
            viewHighSpendYear: viewHighSpendYearNotification,
        },
        dispatch,
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(NotificationAlert);
