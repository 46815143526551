import * as types from './dashboardActionTypes';

export const fetchDashboardItems = () => ({
    type: types.FETCH_DASHBOARD_ITEMS_REQUEST,
});

export const fetchDashboardItemsSuccess = result => ({
    type: types.FETCH_DASHBOARD_ITEMS_SUCCESS,
    result,
});

export const fetchDashboardItemsError = error => ({
    type: types.FETCH_DASHBOARD_ITEMS_ERROR,
    error,
});

export const fetchDashboardItemChildReportPath = id => ({
    type: types.FETCH_DASHBOARD_ITEM_CHILD_REPORT_PATH_REQUEST,
    id,
});

export const fetchDashboardItemChildReportPathSuccess = (id, result) => ({
    type: types.FETCH_DASHBOARD_ITEM_CHILD_REPORT_PATH_SUCCESS,
    id,
    result,
});

export const fetchDashboardItemChildReportPathError = (id, error) => ({
    type: types.FETCH_DASHBOARD_ITEM_CHILD_REPORT_PATH_REQUEST,
    id,
    error,
});

export const viewReport = id => ({
    type: types.VIEW_DASHBOARD_ITEM_REPORT,
    id,
});
