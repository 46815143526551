import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Checkbox, Header, Image, List, Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import {
    lockContact,
    unlockContact,
} from '../../../store/contact/contactActions';
import PopUp from '../../alertPopUp';
import DefaultUserImage from '../../../assets/images/defaultUserImage.png';
export class AccountOrgList extends Component {
    static propTypes = {
        list: PropTypes.array,
    };

    toggleLock = (id, data) => {
        //console.log(data);
        if (data.checked) {
            this.props.unlock({ contactId: id });
        } else {
            this.props.lock({ contactId: id });
        }
    };

    togglePopUpLock = (id, status) => {
        if (!status) this.props.unlock({ contactId: id });
        else this.props.lock({ contactId: id });
    };

    list() {
        const list = this.props.list;
        return (
            <List>
                {list &&
                    list.map((item, index) => {
                        //console.log(item);
                        const { user } = item;
                        if (user)
                            return (
                                <List.Item key={index}>
                                    <Image
                                        avatar
                                        src={
                                            user.tflRampAvatarUrlC
                                                ? user.tflRampAvatarUrlC
                                                : DefaultUserImage
                                        }
                                    />
                                    <List.Content>
                                        <List.Header>{item.name}</List.Header>
                                    </List.Content>
                                    <PopUp
                                        header={
                                            'Confirm Org User ' +
                                            (item.activeC
                                                ? 'Deactivation'
                                                : 'Activation')
                                        }
                                        status={
                                            item.activeC
                                                ? 'Deactivate'
                                                : 'Activate'
                                        }
                                        content={
                                            <p>
                                                You are about to{' '}
                                                {item.activeC
                                                    ? 'deactivate'
                                                    : 'activate'}{' '}
                                                the status of: {item.name}
                                            </p>
                                        }
                                        trigger={
                                            <Checkbox
                                                disabled={
                                                    !this.props.isAdmin ||
                                                    this.props.locking
                                                }
                                                checked={item.activeC}
                                                className='floatRight'
                                                toggle
                                            />
                                        }
                                        ok={() =>
                                            this.togglePopUpLock(
                                                item.id,
                                                item.activeC,
                                            )
                                        }
                                    />
                                </List.Item>
                            );
                        else return null;
                    })}
            </List>
        );
    }

    render() {
        return (
            <Grid.Column
                className='paddingV2rem shaded tablet-up-show'
                mobile={16}
                computer={5}
                tablet={6}
            >
                <div className='support'>
                    <Header as='h2' dividing>
                        Organizations (
                        {this.props.list && this.props.list.length})
                    </Header>
                    <Table basic='very'>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>User</Table.HeaderCell>
                                <Table.HeaderCell collapsing>
                                    Status (Active & Inactive)
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                    </Table>
                    {this.list()}
                </div>
            </Grid.Column>
        );
    }
}

const mapStateToProps = state => ({
    locking: state.contact.locking,
    isAdmin: state.auth.contact.portalUserTypeC === 'Admin',
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            lock: lockContact,
            unlock: unlockContact,
        },
        dispatch,
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AccountOrgList);
