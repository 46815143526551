import React from 'react';
import PropTypes from 'prop-types';

export const Total = ({ x, y, value }) => (
    <g className='totalWraper'>
        <text x={x} y={y} textAnchor='middle' className='totalWrapperText'>
            <tspan x='0' y={y} className='donut-total'>
                {value}
            </tspan>
            <tspan x='0' y={y} dy='18px' className='donut-total-desc'>
                Total Patient
            </tspan>
            <tspan x='0' y={y} dy='30px' className='donut-total-desc'>
                Population
            </tspan>
        </text>
    </g>
);

Total.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Total;
