import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Accordion, Icon } from 'semantic-ui-react';
import { AllReportTypeValues } from '../../../common/ReportTypeValues';
import { getByGroupByNameSelector } from '../../../../store/report/reportSelectors';
import ReportFilterElement from './ReportFilterElement';

export const ReportFilterGroup = ({ group, items, active, toggle }) => {
    return (
        <>
            <Accordion.Title active={active} onClick={toggle}>
                <span>{group}</span>
                <Icon name='angle up' />
            </Accordion.Title>
            <Accordion.Content active={active}>
                {items.map((item, key) => {
                    const { type, name, data } = item;
                    return (
                        <ReportFilterElement
                            key={`report_filter_${group}_column_${name}`}
                            type={type}
                            name={name}
                            data={data}
                        />
                    );
                })}
            </Accordion.Content>
        </>
    );
};

ReportFilterGroup.propTypes = {
    group: PropTypes.oneOf(AllReportTypeValues),
    items: PropTypes.array,
    active: PropTypes.bool,
    toggle: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => ({
    items: getByGroupByNameSelector(state, ownProps),
});

export default connect(mapStateToProps)(ReportFilterGroup);
