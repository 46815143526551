import * as types from './reportActionTypes';
import { isGroupEnabledSelector } from './reportSelectors';
import { ITEM_TYPES, getGroupSfid } from '../../helpers/grouping.helpers';

export default (
    state = {
        reportItemsIds: [],
        enabledReports: [],
        reportItemsIdMap: {},
        reportPathMap: {},
        error: null,
        loading: false,
        loaded: false,
    },
    action,
) => {
    switch (action.type) {
        case types.REPORT_ITEMS_REQUEST:
            return {
                ...state,
                loading: true,
                loaded: false,
                error: null,
            };
        case types.REPORT_ITEMS_SUCCESS: {
            const ids = action.result.map(item => item.sfid);
            const idMap = action.result.reduce(
                (acc, curr) => ({ ...acc, [curr.sfid]: curr }),
                {},
            );
            return {
                ...state,
                loading: false,
                loaded: true,
                reportItemsIds: ids,
                reportItemsIdMap: idMap,
                enabledReports: ids,
            };
        }
        case types.REPORT_ITEMS_ERROR:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: action.error,
            };
        case types.FETCH_REPORT_CHILD_REPORT_PATH_SUCCESS: {
            const reportPathMap = {
                ...state.reportPathMap,
                [action.id]: action.result,
            };
            return {
                ...state,
                reportPathMap,
            };
        }
        case types.TOGGLE_REPORT: {
            const { type, data } = action.report;
            if (type === ITEM_TYPES.SINGLE_ITEM) {
                const sfid = data.sfid;
                const enabled = state.enabledReports.indexOf(sfid) !== -1;
                const enabledReports = enabled
                    ? state.enabledReports.filter(report => report !== sfid)
                    : [...state.enabledReports, sfid];
                return {
                    ...state,
                    enabledReports,
                };
            }
            const enabled = isGroupEnabledSelector(
                { report: state },
                { type, data },
            );
            const sfids = getGroupSfid({ type, data });
            const enabledReports = enabled
                ? state.enabledReports.filter(report => !sfids.includes(report))
                : [...state.enabledReports, ...sfids];
            return {
                ...state,
                enabledReports,
            };
        }
        default:
            return state;
    }
};
