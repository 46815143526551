import React, { Component } from 'react';
import { Button, Form, Icon, Modal } from 'semantic-ui-react';
import * as patientActions from '../../../store/patient/patientActions';
import { connect } from 'react-redux';
import Notify from '../../../helpers/notification.helpers';

const flagOptions = [
    {
        key: 'MNA',
        text: 'Medication non-adherence',
        value: 'Medication non-adherence',
    },
    { key: 'CC', text: 'Care Coordination', value: 'Care Coordination' },
    { key: 'Mon', text: 'Monitoring', value: 'Monitoring' },
    { key: 'OR', text: 'Order Review', value: 'Order Review' },
];

export class PatientFlag extends Component {
    constructor(props) {
        super(props);
        this.state = {
            issues: '',
            open: false,
            backgroundcolor: '',
        };
    }

    show = size => () => this.setState({ size, open: true });
    hide = size => () => this.setState({ size, open: false });

    handleSave = (e, { value }) => {
        if (!this.state.issues) {
            Notify.error('Please select a condition to flag patient');
        } else {
            this.props.setFlaggedStatus(
                true,
                this.state.issues,
                this.props.patientProfile.id,
            );
        }
        this.setState({ open: false });
    };

    handleChange = (e, { value }) => {
        this.setState({ issues: value, backgroundcolor: '#DB2828' });
    };

    handleClear = (e, { value }) => {
        this.props.setFlaggedStatus(false, null, this.props.patientProfile.id);
        this.setState({ backgroundcolor: '', open: false, issues: '' });
    };

    render() {
        const buttonClass = this.props.patientProfile.flaggedC
            ? '#DB2828'
            : this.state.backgroundcolor;
        const { open } = this.state;
        return (
            <React.Fragment>
                <Button
                    style={{ backgroundColor: buttonClass }}
                    primary
                    icon
                    labelPosition='left'
                    size='tiny'
                    onClick={this.show()}
                >
                    <Icon name='exclamation circle' />
                    Flag Patient
                </Button>

                <Modal size='tiny' open={open} onClose={this.close}>
                    <Modal.Header>Flag Patient</Modal.Header>
                    <Modal.Content>
                        <p>
                            Patient is currently flagged for:{' '}
                            {this.props.patientProfile.flaggedReasonC ||
                                this.state.issues}
                        </p>
                        <Form>
                            <Form.Group widths='equal'>
                                <Form.Select
                                    fluid
                                    options={flagOptions}
                                    onChange={this.handleChange}
                                    placeholder='select issue'
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            style={{ backgroundColor: '#DB2828' }}
                            primary
                            content='Clear Flag'
                            onClick={this.handleClear}
                        />
                        <Button
                            style={{ backgroundColor: '#000000de' }}
                            primary
                            content='Cancel'
                            onClick={this.hide()}
                        />
                        <Button
                            primary
                            content='Save'
                            onClick={this.handleSave}
                        />
                    </Modal.Actions>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps = {}) => {
    return {
        patientProfile: state.patient.patientProfile,
    };
};

const mapDispatchToProps = dispatch => ({
    setFlaggedStatus: (isFlagged, flagReasonText, patientId) =>
        dispatch(
            patientActions.patientFlagRequest(
                isFlagged,
                flagReasonText,
                patientId,
            ),
        ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PatientFlag);
