import { connect } from 'react-redux';
import { DateFilterText } from './DateFilterValues';

export const DateFilterValue = ({ date }) => date;

const mapStateToProps = state => ({
    date: DateFilterText[state.reportData.dateFilter] || '',
});

export default connect(mapStateToProps)(DateFilterValue);
