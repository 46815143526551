/**/ //import moment from 'moment';

import * as types from './patientRosterActionTypes';
import * as selectors from './patientRosterSelectors';

import { ROSTER_TABLE_COLUMNS } from '../constants/table';

export const PATIENT_ROSTER_FIRST_PAGE = 1;

const defaultSort = {
    column: ROSTER_TABLE_COLUMNS[0].accessor,
    direction: 'ascending',
};

export default (
    state = {
        total: 0,
        error: null,
        loading: false,
        loaded: false,
        data: [],
        filters: {},
        page: PATIENT_ROSTER_FIRST_PAGE,
        pageSize: 10,
        sort: defaultSort,
        statusCount: {},
        overviewLoaded: false,
        overviewLoading: false,
    },
    action,
) => {
    switch (action.type) {
        case types.PATIENT_ROSTER_LIST_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case types.PATIENT_ROSTER_LIST_SUCCESS: {
            return {
                ...state,
                loading: false,
                loaded: true,
                error: null,
                total: action.total,
                data: Array.isArray(action.data) ? action.data : [],
            };
        }
        case types.PATIENT_ROSTER_LIST_ERROR:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.error,
                data: [],
            };
        case types.PATIENT_ROSTER_OVERVIEW_REQUEST:
            return {
                ...state,
                overviewLoading: true,
                overviewLoaded: true,
            };
        case types.PATIENT_ROSTER_OVERVIEW_SUCCESS:
            return {
                ...state,
                stats: action.data,
                overviewLoading: false,
                overviewLoaded: true,
            };
        case types.PATIENT_ROSTER_OVERVIEW_ERROR:
            return {
                ...state,
                overviewLoading: false,
                overviewLoaded: true,
            };
        case types.PATIENT_ROSTER_FILTER: {
            const { value, name, like, date, dateFormat } = action.payload;
            if (!name) {
                return state;
            }
            const newFilter = {
                value,
                like,
                date,
                dateFormat,
            };
            const filters = {
                ...state.filters,
                [name]: newFilter,
            };
            return {
                ...state,
                filters,
                page: 1,
            };
        }
        case types.PATIENT_ROSTER_FILTER_MULTIPLE: {
            const { filters = [] } = action;
            const validFilters = {};
            for (const filter of filters) {
                const { value, filterType, like } = filter;
                if (!filterType) {
                    continue;
                }
                validFilters[filterType] = {
                    value,
                    like,
                };
            }
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...validFilters,
                },
                page: 1,
            };
        }
        case types.PATIENT_ROSTER_FILTER_RESET: {
            return {
                ...state,
                filters: {},
                page: 1,
            };
        }
        case types.PATIENT_ROSTER_GO_TO_PREV_PAGE: {
            const page = selectors.getPatientRosterCurrentPage({
                patientRoster: state,
            });
            return {
                ...state,
                page: page > PATIENT_ROSTER_FIRST_PAGE ? page - 1 : page,
            };
        }
        case types.PATIENT_ROSTER_GO_TO_NEXT_PAGE: {
            const page = selectors.getPatientRosterCurrentPage({
                patientRoster: state,
            });
            const maxPage = selectors.getPatientRosterLastPage({
                patientRoster: state,
            });
            return {
                ...state,
                page: page < maxPage ? page + 1 : page,
            };
        }
        case types.PATIENT_ROSTER_GO_TO_FIRST_PAGE: {
            return {
                ...state,
                page: PATIENT_ROSTER_FIRST_PAGE,
            };
        }
        case types.PATIENT_ROSTER_GO_TO_LAST_PAGE: {
            const lastPage = selectors.getPatientRosterLastPage({
                patientRoster: state,
            });
            return {
                ...state,
                page: lastPage,
            };
        }
        case types.PATIENT_ROSTER_SORT: {
            return {
                ...state,
                sort: {
                    ...state.sort,
                    ...action.sort,
                },
            };
        }
        case types.SET_PAGE_SIZE: {
            const newPageSize = Math.round(+action.payload.pageSize);
            if (isNaN(newPageSize)) {
                return state;
            }
            if (newPageSize === state.pageSize) {
                return state;
            }
            return {
                ...state,
                pageSize: newPageSize,
                loaded: state.page === 1 && newPageSize < state.pageSize,
            };
        }
        default:
            return state;
    }
};
