import React, { PureComponent } from 'react';
import get from 'lodash/get';
import { Icon } from 'semantic-ui-react';

export class PatientFlag extends PureComponent {
    render() {
        const { accessor, row } = this.props;
        let flagged = get(row, accessor) ? (
            <Icon name='circle' color='red' />
        ) : (
            <span />
        );

        return <div>{flagged}</div>;
    }
}

export default PatientFlag;
