import React from 'react';
import PropTypes from 'prop-types';
import { Header } from 'semantic-ui-react';

export const ReportHeader = ({ asLink, name, handleClick, date }) => {
    if (asLink) {
        return (
            <Header className='headerLink' onClick={handleClick}>
                {name}{' '}
                <span>
                    <i>{date}</i>View report
                </span>
            </Header>
        );
    }
    return (
        <Header as='h2' className='headerLink'>
            {name}{' '}
            <span>
                <i>{date}</i>
            </span>
        </Header>
    );
};

ReportHeader.propTypes = {
    asLink: PropTypes.bool,
    handleClick: PropTypes.func,
    name: PropTypes.string,
    date: PropTypes.string,
};

export default ReportHeader;
