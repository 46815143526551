import React from 'react';
import PropTypes from 'prop-types';
import { Header } from 'semantic-ui-react';
import { connect } from 'react-redux';

import { getFilterTitle } from '../../../../store/report-path/reportPathSelectors';
import { reportDataSelector } from '../../../../store/report-data/reportDataSelectors';

export const ReportDetailHeader = ({
    data: { data: { reportMetadata: { name } = {} } = {} } = {},
    filter,
}) => (
    <Header as='h2' dividing>
        {name} {filter ? `for ${filter}` : ''}
    </Header>
);

ReportDetailHeader.propTypes = {
    sfid: PropTypes.string,
    data: PropTypes.object,
    filter: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => ({
    filter: getFilterTitle(state),
    data: reportDataSelector(state, ownProps),
});

export default connect(mapStateToProps)(ReportDetailHeader);
