import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Feed, Image, Button, Header, Label } from 'semantic-ui-react';
import EventDate from '../EventDate';
import icon_copay from '../../../../assets/images/icon-copay.svg';
import orderBy from 'lodash/orderBy';

export class CopayAssistanceEvent extends Component {
    static propTypes = {
        eventType: PropTypes.string,
        handleClick: PropTypes.func,
    };

    static defaultProps = {
        handleClick: () => {},
        eventType: 'fill',
    };

    render() {
        let claims = this.props.event.claims; //const { filter } = this.props;
        /**/ const claimsByDate = orderBy(claims, ['shipDateC'], ['desc']);
        const lastFillDate =
            (claimsByDate.length && claimsByDate[0].shipDateC) || null;
        return (
            <React.Fragment>
                <Feed.Event onClick={this.props.handleClick}>
                    <Feed.Label>
                        <Image src={icon_copay} alt='Works~' width='29' />
                    </Feed.Label>
                    <Feed.Content>
                        <EventDate date={lastFillDate} />
                        <div className='event-content'>
                            <div className='event-content__detail'>
                                <Header as='h5'>Medication</Header>
                                {claims.map(claim => {
                                    return (
                                        <Header as='h3' key={claim.id}>
                                            {claim.drug ? claim.drug.name : ''}
                                        </Header>
                                    );
                                })}
                            </div>
                            <Label className='greenBG whiteColor' size='mini'>
                                <span>Copay Assistance</span>
                            </Label>
                            <div className='event-content__button'>
                                <Button size='mini' content='View' primary />
                            </div>
                        </div>
                    </Feed.Content>
                </Feed.Event>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    filter: state.patient.filter,
});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CopayAssistanceEvent);
