import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactCollapsingTable from 'react-collapsing-table';

import { TABLE_ICONS } from '../../../store/constants/tableIcons';
import actions from '../../../store/case-list/actions';
import selectors from '../../../store/case-list/selectors';
import withLoading from '../../common/withLoading';
import CASE_TABLE_COLUMNS from './columns';

export const CaseListComponent = withLoading(({ rows, sort }) => (
    <ReactCollapsingTable
        rows={rows}
        columns={CASE_TABLE_COLUMNS}
        icons={TABLE_ICONS}
        handleSort={sort}
        column='status'
    />
));

CaseListComponent.propTypes = {
    rows: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,
    error: PropTypes.string,
};

const mapStateToProps = state => ({
    rows: selectors.dataSelector(state),
    loading: selectors.loadingSelector(state),
    loaded: selectors.loadedSelector(state),
    error: selectors.errorSelector(state),
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            load: actions.fetch,
            sort: actions.sort,
        },
        dispatch,
    );
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CaseListComponent);
