import React from 'react';
import { Grid, Form, Divider } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import NewCalendar from '../../../newComponents/Calendar';
import withDebounce from '../../common/DebouncedInput';
import ResetFilter from './ResetFilter';
import withSearch from './withSearch';
import StatusFilter from './StatusFilter';

const SearchInput = withSearch(withDebounce(Form.Input));

const SearchDate = withSearch(withDebounce(DateInput));

const NewSearchDate = withSearch(withDebounce(NewCalendar));

const SearchFilter = withSearch(StatusFilter);

const PatientOrderSearch = () => (
    <>
        <SearchFilter name='referral.statusC' />
        <Grid.Row className='paddingV0 marginV1 shrink'>
            <br />
            <Grid>
                <Grid.Column
                    computer={3}
                    tablet={4}
                    mobile={16}
                    className='marginBDot5rem'
                >
                    <Form>
                        <SearchInput
                            like
                            name='patient.name'
                            label='Patient Search'
                            placeholder='Sam Samson'
                        />
                    </Form>
                </Grid.Column>
                <Grid.Column computer={3} tablet={4} mobile={8}>
                    <Form>
                        <NewSearchDate
                            dateFormat='MM-DD-YYYY'
                            name='patient.birthDateC'
                            label='DOB'
                            type='order'
                        />
                        {
                            // <SearchDate
                            // //date
                            // dateFormat='MM-DD-YYYY'
                            // name='patient.birthDateC'
                            // label='DOB'
                            // placeholder='Date'
                            // iconPosition='left'
                            // closable
                            // delay={1000}
                            // />
                        }
                    </Form>
                </Grid.Column>
                <Grid.Column computer={3} tablet={4} mobile={8}>
                    <Form>
                        <SearchInput
                            like
                            name='drug.name'
                            label='Drug Search'
                            placeholder=''
                        />
                    </Form>
                </Grid.Column>
                <Grid.Column computer={3} tablet={4} mobile={16}>
                    <Form className='paddedNoLabel'>
                        <ResetFilter />
                        <Divider className='mobile-show' hidden />
                    </Form>
                </Grid.Column>
            </Grid>
        </Grid.Row>
    </>
);
export default PatientOrderSearch;
