import * as types from './patientActionTypes';

export const patientFlagRequest = (flaggedC, flaggedReasonC, accountId) => ({
    type: types.PATIENT_FLAGGED_REQUEST,
    accountId: accountId,
    flaggedC: flaggedC,
    flaggedReasonC: flaggedReasonC,
});

export const patientProfileRequest = accountId => ({
    type: types.PATIENT_PROFILE_REQUEST,
    accountId: accountId,
});

export const getPatientTimeline = memberId => ({
    type: types.PATIENT_FILL_INFO_REQUEST,
    memberId,
});

export const filterTimeline = filter => ({
    type: types.FILTER_TIMELINE_REQUEST,
    filter: filter,
});

export const resolveAssessment = assessmentId => ({
    type: types.RESOLVE_ASSESSMENT,
    payload: {
        assessmentId,
    },
});
