import { put, takeLatest, takeEvery } from 'redux-saga/effects';
import { updateAssignedUser, resolve } from '../../api/caseAPI';
import * as types from './caseActionTypes';
import caseListActions from '../case-list/actions';
import Notify from '../../helpers/notification.helpers';

export function* assignToMeSaga({ caseId, assigneeId }) {
    try {
        yield updateAssignedUser(caseId, assigneeId);

        yield put({
            type: types.ASSIGN_TO_ME_SUCCESS,
        });
        yield put(caseListActions.fetch());
    } catch (error) {
        yield put({
            type: types.ASSIGN_TO_ME_ERROR,
            error: error.message,
        });
    }
}

export function* resolveSaga({ payload: { caseId } }) {
    try {
        if (!caseId) {
            return;
        }
        yield resolve(caseId);
        yield put({
            type: types.RESOLVE_SUCCESS,
        });
        Notify.success('Successfully resolved');
        yield put(caseListActions.fetch());
    } catch (error) {
        yield put({
            type: types.RESOLVE_ERROR,
            error: error.message,
        });
        Notify.error(`Failed to resolve: ${error.message}`);
    }
}

export function* rootSaga() {
    yield takeLatest(types.ASSIGN_TO_ME_REQUEST, assignToMeSaga);
    yield takeEvery(types.RESOLVE_REQUEST, resolveSaga);
}

export default rootSaga;
