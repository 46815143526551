import React, { useEffect } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { withError } from './withError';

export const withLoading = WrappedComponent => {
    const ErrorWrapper = withError(WrappedComponent);
    const WithLoadingWrapper = ({ load, loaded, loading, ...otherProps }) => {
        useEffect(() => {
            if (!loaded && !loading) {
                load();
            }
        }, [loaded, loading]);
        return (
            <>
                <Dimmer active={loading} inverted>
                    <Loader size='large'>Loading</Loader>
                </Dimmer>
                <ErrorWrapper {...otherProps} />
            </>
        );
    };

    return WithLoadingWrapper;
};

export default withLoading;
