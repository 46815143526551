import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import Prechat from './Prechat';
import Chat from './Chat';
import { chatStartedSelector } from '../../store/chat/chatSelectors';
import { bindActionCreators } from 'redux';
import {
    subscribeAvailability,
    unsubscribeAvailability,
} from '../../store/chat/chatActions';

class LiveAgent extends PureComponent {
    componentDidMount() {
        this.props.subscribeAvailability();
    }

    componentWillUnmount() {
        this.props.unsubscribeAvailability();
    }
    render() {
        const { chatStarted } = this.props;
        return chatStarted ? <Chat /> : <Prechat />;
    }
}

const mapStatesToProps = state => ({
    chatStarted: chatStartedSelector(state),
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            subscribeAvailability: subscribeAvailability,
            unsubscribeAvailability: unsubscribeAvailability,
        },
        dispatch,
    );

export default connect(
    mapStatesToProps,
    mapDispatchToProps,
)(LiveAgent);
