import React, { Component } from 'react';
import { Grid, Header } from 'semantic-ui-react';
import NavMenu from '../components/common/NavMenu';

import CaseTable from '../components/case/table';

export class CaseManagement extends Component {
    render() {
        return (
            <Grid className='full-page' stretched>
                <Grid.Row className='full-page'>
                    <NavMenu />
                    <Grid.Column className='stretch-col'>
                        <Grid className='shrink marginV0' stackable>
                            <Grid.Row
                                columns={2}
                                className='pageHeader'
                                verticalAlign='middle'
                            >
                                <Grid.Column computer={11} tablet={10}>
                                    <Header as='h1'>Case Management</Header>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row className='paddingV2rem shrink'>
                                <Grid.Column width={16}>
                                    <Grid.Row className='paddingV0 shrink'>
                                        <CaseTable />
                                    </Grid.Row>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

export default CaseManagement;
