import React, { Component } from 'react';

export default class index extends Component {
    render() {
        const { logo } = this.props;
        if (logo) return svgLogo();
        else return null;
    }
}
const svgLogo = () => (
    <svg id='logo' width='176px' height='45px' viewBox='0 0 176 45'>
        <defs>
            <polygon
                id='path-1'
                points='0.363405553 0.413427463 19.5085872 0.413427463 19.5085872 21.0171296 0.363405553 21.0171296'
            />
        </defs>
        <g
            id='Symbols'
            stroke='none'
            strokeWidth='1'
            fill='none'
            fillRule='evenodd'
        >
            <g id='Header' transform='translate(-28.000000, -12.000000)'>
                <g id='ah-logo' transform='translate(28.855413, 11.930000)'>
                    <g id='logo-type' transform='translate(0.000000, 0.108023)'>
                        <g
                            id='ah'
                            transform='translate(0.000000, 7.000000)'
                            fill='#FEFEFE'
                        >
                            <path
                                d='M9.51201186,3.75651715 L6.23952235,13.5182017 L12.7287173,13.5182017 L9.51201186,3.75651715 Z M12.5624079,21.6710121 L12.5624079,20.1737061 L14.8365197,19.9239813 L13.3386923,15.5420673 L5.60191606,15.5420673 L4.13172003,19.9239813 L6.37767906,20.1737061 L6.37767906,21.6710121 L5.2134611e-05,21.6710121 L5.2134611e-05,20.2013374 L1.19237068,19.8963499 C1.63603622,19.7853032 1.77471428,19.7024092 1.88523966,19.369269 L7.98655315,1.5939735 L11.2032586,1.5939735 L17.4150975,19.3974217 C17.5261442,19.7300405 17.7482377,19.7853032 18.0808565,19.8687186 L19.3289591,20.1737061 L19.3289591,21.6710121 L12.5624079,21.6710121 Z'
                                id='Fill-4'
                            />
                            <path
                                d='M30.5308101,20.8392565 C29.4771696,21.5326468 27.6185707,22.0039437 25.7881245,22.0039437 C21.0464817,22.0039437 19.0220947,19.0917044 19.0220947,14.7103117 C19.0220947,9.44158791 22.0719695,7.13984485 26.2875741,7.13984485 C28.311961,7.13984485 29.8921611,7.69455711 30.5026574,7.99954458 L30.5026574,11.6875469 L28.6451012,11.6046529 L28.311961,9.77420673 C28.2843297,9.58026598 28.2285456,9.46921926 27.9788209,9.38580388 C27.5909394,9.24764716 26.870439,9.13660044 26.093112,9.13660044 C23.6250595,9.13660044 21.656978,10.5781224 21.656978,14.5445236 C21.656978,18.4264667 23.4863815,19.9237727 26.1765274,19.9237727 C27.563308,19.9237727 28.9495673,19.4806286 29.726373,19.0088103 L30.5308101,20.8392565 Z'
                                id='Fill-6'
                            />
                            <path
                                d='M40.4567707,15.2928117 L37.3234806,15.5701678 C34.9388435,15.7922612 34.2730845,16.4851302 34.2730845,17.9276949 C34.2730845,19.4250009 34.9106908,20.0349759 36.2140561,20.0349759 C37.8500402,20.0349759 39.5975923,18.7039793 40.4567707,17.8995422 L40.4567707,15.2928117 Z M40.9280676,21.67096 L40.6788642,19.8686665 L40.5959701,19.8410351 C39.2644522,21.0886164 37.7113621,22.0041001 35.5764498,22.0041001 C32.1100195,22.0041001 31.666354,19.6189417 31.666354,18.2045297 C31.666354,15.4320111 33.3857535,14.1281245 36.7135057,13.8507683 L40.4567707,13.5457809 L40.4567707,12.0761062 C40.4567707,10.0517193 40.0688892,9.05334147 37.6566208,9.05334147 C35.9643313,9.05334147 34.7443814,9.1091255 34.7167501,11.1887751 L32.332113,10.9390503 C32.332113,7.52788276 35.4930344,7.13947991 37.6842521,7.13947991 C41.6214579,7.13947991 42.9529759,8.24890442 42.9529759,12.0761062 L42.9529759,19.4250009 C42.9529759,19.8686665 42.9806072,19.9239291 43.3966414,19.9791918 L44.8110534,20.1736539 L44.8110534,21.67096 L40.9280676,21.67096 Z'
                                id='Fill-8'
                            />
                            <path
                                d='M46.4649718,21.6711685 L46.4649718,20.1457098 L47.8512311,19.9794004 C48.2396339,19.9236163 48.295418,19.8407223 48.295418,19.4246881 L48.295418,9.99614377 C48.295418,9.69115629 48.2120026,9.4966942 47.9346465,9.41380016 L46.3539251,8.8872406 L46.6036499,7.41704457 L50.2358682,7.41704457 L50.5137457,9.33038479 L50.597161,9.35853748 C51.5121235,8.33252834 52.8707514,7.13968845 54.7564603,7.13968845 C55.3945879,7.13968845 56.2537663,7.25073517 56.7532159,7.38941323 L56.7532159,11.5768652 L54.7840916,11.4939711 L54.4514728,9.58063092 C54.4238415,9.35853748 54.3680574,9.30275344 54.118854,9.30275344 C52.8707514,9.30275344 51.4563394,10.4398093 50.8187331,11.0497842 L50.8187331,19.3975781 C50.8187331,19.8407223 50.8463645,19.9236163 51.2623987,19.951769 L53.8138665,20.1738625 L53.8138665,21.6711685 L46.4649718,21.6711685 Z'
                                id='Fill-10'
                            />
                            <path
                                d='M61.1558274,4.33891289 C60.0182501,4.33891289 59.3530125,3.70078525 59.3530125,2.56425074 C59.3530125,1.45482622 60.0182501,0.817219935 61.1558274,0.817219935 C62.2928832,0.817219935 62.9304895,1.45482622 62.9304895,2.56425074 C62.9304895,3.70078525 62.2928832,4.33891289 61.1558274,4.33891289 Z M57.9943846,21.6710642 L57.9943846,20.1456055 L59.8243094,19.9792961 C60.2127122,19.9516648 60.2684963,19.840618 60.2684963,19.4251052 L60.2684963,9.99656084 C60.2684963,9.69157337 60.1850809,9.49711127 59.9077248,9.4136959 L58.3270034,8.88713633 L58.5767282,7.41694031 L62.7918114,7.41694031 L62.7918114,19.4251052 C62.7918114,19.8687707 62.8194428,19.9240334 63.235477,19.9792961 L65.0106605,20.1737582 L65.0106605,21.6710642 L57.9943846,21.6710642 Z'
                                id='Fill-12'
                            />
                            <path
                                d='M74.7978909,15.2928117 L71.6640795,15.5701678 C69.2794424,15.7922612 68.6142048,16.4851302 68.6142048,17.9276949 C68.6142048,19.4250009 69.2512897,20.0349759 70.554655,20.0349759 C72.1911604,20.0349759 73.9387126,18.7039793 74.7978909,17.8995422 L74.7978909,15.2928117 Z M75.2691878,21.67096 L75.0199844,19.8686665 L74.9370903,19.8410351 C73.6055724,21.0886164 72.0524823,22.0041001 69.91757,22.0041001 C66.4506184,22.0041001 66.0074742,19.6189417 66.0074742,18.2045297 C66.0074742,15.4320111 67.7268737,14.1281245 71.0541045,13.8507683 L74.7978909,13.5457809 L74.7978909,12.0761062 C74.7978909,10.0517193 74.4100094,9.05334147 71.9972196,9.05334147 C70.3054515,9.05334147 69.0855016,9.1091255 69.057349,11.1887751 L66.6727119,10.9390503 C66.6727119,7.52788276 69.8341547,7.13947991 72.024851,7.13947991 C75.9625781,7.13947991 77.2935748,8.24890442 77.2935748,12.0761062 L77.2935748,19.4250009 C77.2935748,19.8686665 77.3217274,19.9239291 77.7377616,19.9791918 L79.1521736,20.1736539 L79.1521736,21.67096 L75.2691878,21.67096 Z'
                                id='Fill-14'
                            />
                            <path
                                d='M93.2572968,21.6711685 L93.2572968,20.1738625 L95.004849,19.9794004 C95.3927305,19.9236163 95.4479932,19.8407223 95.4479932,19.4246881 L95.4479932,12.3255182 L85.9084021,12.3255182 L85.9084021,19.3975781 C85.9084021,19.8407223 85.9360334,19.8965063 86.3520676,19.951769 L88.2101452,20.1738625 L88.2101452,21.6711685 L81.1667593,21.6711685 L81.1667593,20.1738625 L82.9132687,19.9794004 C83.3016716,19.9236163 83.3574556,19.8407223 83.3574556,19.4246881 L83.3574556,3.84008893 C83.3574556,3.42405474 83.3016716,3.31300802 82.9132687,3.28485533 L81.1667593,3.09091458 L81.1667593,1.59360856 L88.2101452,1.59360856 L88.2101452,3.09091458 L86.3520676,3.31300802 C85.9360334,3.36879205 85.9084021,3.42405474 85.9084021,3.84008893 L85.9084021,10.3287626 L95.4479932,10.3287626 L95.4479932,3.84008893 C95.4479932,3.42405474 95.3927305,3.31300802 95.004849,3.28485533 L93.2572968,3.09091458 L93.2572968,1.59360856 L100.301204,1.59360856 L100.301204,3.09091458 L98.4431266,3.31300802 C98.0270924,3.36879205 97.999461,3.42405474 97.999461,3.84008893 L97.999461,19.3975781 C97.999461,19.8407223 98.0270924,19.8965063 98.4431266,19.951769 L100.301204,20.1738625 L100.301204,21.6711685 L93.2572968,21.6711685 Z'
                                id='Fill-16'
                            />
                            <path
                                d='M109.538675,13.2410541 C109.483412,10.0796113 108.040848,9.16464886 106.349601,9.16464886 C104.491002,9.16464886 102.938433,10.1625053 102.71634,13.2410541 L109.538675,13.2410541 Z M111.729371,20.7562582 C110.342591,21.5325426 108.678975,22.0043608 106.68222,22.0043608 C101.662699,22.0043608 100.026715,18.7042399 100.026715,14.5720507 C100.026715,9.49674633 102.855539,7.13974058 106.349601,7.13974058 C109.732616,7.13974058 112.06199,8.97018676 112.06199,13.7676136 C112.06199,14.1278638 112.034359,14.6830974 112.006206,15.1543943 L102.688709,15.1543943 C102.772124,17.9550656 103.908658,19.8689271 106.931423,19.8689271 C108.485035,19.8689271 109.45526,19.591571 110.924934,18.8705493 L111.729371,20.7562582 Z'
                                id='Fill-18'
                            />
                            <path
                                d='M122.182361,15.2928117 L119.048549,15.5701678 C116.663912,15.7922612 115.998675,16.4851302 115.998675,17.9276949 C115.998675,19.4250009 116.63576,20.0349759 117.939125,20.0349759 C119.57563,20.0349759 121.323182,18.7039793 122.182361,17.8995422 L122.182361,15.2928117 Z M122.653658,21.67096 L122.404454,19.8686665 L122.321039,19.8410351 C120.990042,21.0886164 119.436952,22.0041001 117.30204,22.0041001 C113.835088,22.0041001 113.391944,19.6189417 113.391944,18.2045297 C113.391944,15.4320111 115.111344,14.1281245 118.438574,13.8507683 L122.182361,13.5457809 L122.182361,12.0761062 C122.182361,10.0517193 121.794479,9.05334147 119.38169,9.05334147 C117.689921,9.05334147 116.469972,9.1091255 116.441819,11.1887751 L114.057182,10.9390503 C114.057182,7.52788276 117.218625,7.13947991 119.409321,7.13947991 C123.347048,7.13947991 124.678045,8.24890442 124.678045,12.0761062 L124.678045,19.4250009 C124.678045,19.8686665 124.706197,19.9239291 125.122232,19.9791918 L126.536644,20.1736539 L126.536644,21.67096 L122.653658,21.67096 Z'
                                id='Fill-20'
                            />
                            <path
                                d='M128.034784,21.6711685 L128.034784,20.1457098 L129.754183,19.9794004 C130.142586,19.951769 130.197849,19.8407223 130.197849,19.4246881 L130.197849,3.31300802 C130.197849,3.00802055 130.114433,2.81355845 129.837599,2.73066442 L128.173462,2.2035835 L128.423187,0.733908827 L132.721164,0.733908827 L132.721164,19.4246881 C132.721164,19.868875 132.749317,19.9236163 133.165351,19.9794004 L134.828966,20.1738625 L134.828966,21.6711685 L128.034784,21.6711685 Z'
                                id='Fill-22'
                            />
                            <path
                                d='M144.673023,21.1441918 C143.563599,21.5883787 142.232602,22.0038916 140.81819,22.0038916 C138.156197,22.0038916 136.963879,20.7563103 136.963879,18.2601052 L136.963879,9.60784519 C136.963879,9.44153578 136.963879,9.33048906 136.741785,9.33048906 L134.717398,9.33048906 L134.717398,7.72213632 C136.880463,7.44530153 137.490959,6.58508046 137.85121,3.75625648 L139.487194,3.75625648 L139.487194,7.13979271 C139.487194,7.30610212 139.487194,7.41714884 139.709287,7.41714884 L143.785692,7.41714884 L143.785692,9.33048906 L139.487194,9.33048906 L139.487194,17.5114522 C139.487194,19.258483 139.903228,19.9237206 141.483949,19.9237206 C142.343649,19.9237206 143.314395,19.6745172 144.063048,19.3695297 L144.673023,21.1441918 Z'
                                id='Fill-24'
                            />
                            <path
                                d='M155.320944,21.6711685 L155.320944,20.1457098 L156.651941,19.9794004 C157.012191,19.951769 157.15139,19.8136123 157.15139,19.4246881 L157.15139,12.9636458 C157.15139,10.9111062 156.790619,9.2751221 154.821495,9.2751221 C153.157879,9.2751221 151.521895,10.2177159 150.440102,11.0779369 L150.440102,19.4246881 C150.440102,19.868875 150.467733,19.9236163 150.883767,19.9794004 L152.436857,20.1738625 L152.436857,21.6711685 L146.086341,21.6711685 L146.086341,20.1457098 L147.4726,19.9794004 C147.861003,19.9236163 147.916787,19.8407223 147.916787,19.4246881 L147.916787,3.31300802 C147.916787,3.00802055 147.833371,2.81355845 147.556015,2.73066442 L145.891878,2.2035835 L146.142125,0.733908827 L150.412471,0.733908827 L150.412471,8.97013463 C151.687683,8.05517221 153.296557,7.13968845 155.403838,7.13968845 C158.37134,7.13968845 159.674705,9.05355 159.674705,12.1873615 L159.674705,19.4246881 C159.674705,19.868875 159.702337,19.9236163 160.118371,19.9794004 L161.699092,20.1738625 L161.699092,21.6711685 L155.320944,21.6711685 Z'
                                id='Fill-26'
                            />
                        </g>
                        <g
                            id='tagline'
                            transform='translate(0.000000, 34.000000)'
                            fill='#FEFEFE'
                        >
                            <path
                                d='M29.847586,3.21326805 L28.1672875,3.21326805 L28.1672875,3.79352627 L28.9169832,3.90561568 L27.6084045,7.61812131 L27.5343733,7.61812131 L26.5286967,4.4394741 L26.2101542,3.34308323 C26.1861723,3.25184766 26.1522848,3.21118267 26.0271617,3.21118267 L24.4427909,3.21326805 L24.4427909,3.80760261 C24.5251636,3.82428569 24.8051264,3.8831978 24.8859351,3.90040222 C24.9735212,3.91969203 25.0720556,3.95305818 25.1189768,4.10633393 L26.734107,8.80731178 L28.1584246,8.80731178 C28.2866757,8.46478739 30.0383987,3.7486905 30.0847985,3.62565282 C30.1421465,3.47185572 30.108259,3.21326805 29.847586,3.21326805'
                                id='Fill-31'
                            />
                            <path
                                d='M43.6041893,3.21326805 L41.9233695,3.21326805 L41.9233695,3.79352627 L42.6730652,3.90561568 L41.3644864,7.61812131 L41.2909766,7.61812131 L40.2847786,4.4394741 L39.9667575,3.34308323 C39.9422543,3.25184766 39.9088881,3.21118267 39.7827223,3.21118267 L38.1993942,3.21326805 L38.1993942,3.80760261 C38.2807242,3.82428569 38.5617298,3.8831978 38.6414957,3.90040222 C38.7290819,3.91969203 38.8281376,3.95305818 38.8750588,4.10633393 L40.4891463,8.80731178 L41.9134639,8.80731178 C42.0411937,8.46478739 43.7944806,3.7486905 43.8403591,3.62565282 C43.8982285,3.47185572 43.8638197,3.21326805 43.6041893,3.21326805'
                                id='Fill-33'
                            />
                            <path
                                d='M14.8053953,5.85008026 L14.8074807,5.81515007 C14.8325053,5.31882858 14.9769182,4.86473612 15.2125666,4.54045884 C15.4685475,4.18646483 15.8178494,3.99825889 16.22085,3.99825889 C16.6520032,3.99825889 16.9074628,4.19167829 16.9585547,4.55766326 C16.9835793,4.73648497 16.9371795,4.90383707 16.8209193,5.05502745 C16.3819459,5.62798682 15.0936997,5.81306469 14.8392828,5.84590949 L14.8053953,5.85008026 Z M18.0247075,8.48600618 C18.0919611,8.44534118 18.1258486,8.36453253 18.1070802,8.29102273 L17.9647527,7.73735317 C17.9449415,7.66019394 17.8599621,7.61848625 17.7833243,7.65810856 C17.6123227,7.74882278 17.3427868,7.84527181 16.9851434,7.94119949 C16.752623,7.99646218 16.5352217,8.02357218 16.3167777,8.02357218 C15.2662652,8.02357218 14.9748328,7.48397896 14.8617007,6.67380711 L14.8575299,6.64356903 L14.8862039,6.6409623 C16.3668269,6.46735405 17.3433081,6.08625005 17.7906231,5.50807721 C18.0231435,5.2083032 18.111251,4.85118112 18.0549456,4.44557385 C17.9329506,3.58013931 17.2927376,3.10258627 16.251088,3.10258627 C15.4570779,3.10258627 14.7819347,3.39506144 14.3028176,3.94768831 C13.7267302,4.60719114 13.4942098,5.57845894 13.6480069,6.67902057 C13.8581094,8.17684793 14.6990407,8.93801325 16.1478615,8.93801325 C17.0409274,8.93801325 17.6816618,8.69350193 18.0247075,8.48600618 Z'
                                id='Fill-35'
                            />
                            <path
                                d='M45.1967453,5.85008026 L45.1988306,5.81515007 C45.2243766,5.31882858 45.3682681,4.86473612 45.6044379,4.54045884 C45.8609402,4.18646483 46.2097207,3.99825889 46.6121999,3.99825889 C47.0438745,3.99825889 47.2982914,4.19167829 47.350426,4.55766326 C47.3749293,4.73648497 47.3285295,4.90383707 47.2127907,5.05502745 C46.7727745,5.62798682 45.4850497,5.81306469 45.2311541,5.84590949 L45.1967453,5.85008026 Z M48.4160575,8.48600618 C48.4843538,8.44534118 48.5182413,8.36453253 48.4984302,8.29102273 L48.356624,7.73735317 C48.3357702,7.66019394 48.2513121,7.61848625 48.1751956,7.65810856 C48.0031514,7.74882278 47.7341368,7.84527181 47.375972,7.94119949 C47.1450157,7.99646218 46.9260503,8.02357218 46.708649,8.02357218 C45.6581366,8.02357218 45.3667041,7.48397896 45.2530506,6.67380711 L45.2488799,6.64356903 L45.2775539,6.6409623 C46.7586982,6.46735405 47.7351795,6.08625005 48.1814517,5.50807721 C48.4134507,5.2083032 48.5020796,4.85118112 48.4452529,4.44557385 C48.3243006,3.58013931 47.6835662,3.10258627 46.6434807,3.10258627 C45.8473852,3.10258627 45.1732847,3.39506144 44.6936463,3.94768831 C44.1186015,4.60719114 43.8850385,5.57845894 44.0398782,6.67902057 C44.2499807,8.17684793 45.090912,8.93801325 46.5386901,8.93801325 C47.4327987,8.93801325 48.0724904,8.69350193 48.4160575,8.48600618 Z'
                                id='Fill-37'
                            />
                            <path
                                d='M23.0272841,8.72306225 C23.0272841,8.76894071 23.0658637,8.80752032 23.1122635,8.80752032 L24.819672,8.80752032 L24.819672,8.21631384 L24.2414992,8.13289846 C24.0616347,8.10839519 24.0548572,8.07137962 24.0527719,7.90246348 L24.0527719,5.05382835 C24.0527719,3.62794675 23.2519842,3.32817273 22.5794478,3.32817273 C21.7624984,3.32817273 21.1707706,3.57059867 20.5409845,4.16649727 L20.493542,4.21341842 L20.4909353,3.41106677 C20.4904139,3.30158408 20.4017851,3.21347659 20.2923024,3.21347659 L18.6782148,3.21347659 L18.6782148,3.80728981 L19.2537809,3.93449826 C19.3616996,3.97412056 19.4112275,4.06066402 19.4065354,4.19464996 L19.4133129,7.88473771 C19.4153982,8.06043135 19.37734,8.10683115 19.2110306,8.13289846 L18.7433831,8.20536557 L18.7433831,8.80752032 L21.4116325,8.80752032 L21.4111111,8.21631384 L20.7990508,8.13289846 C20.6207504,8.10839519 20.5925978,8.07555039 20.589991,7.8857804 L20.589991,5.2071041 L20.5983326,5.19667718 C21.0717148,4.71391069 21.8062915,4.37503572 22.3860284,4.37503572 C22.7207326,4.37503572 22.9417833,4.58565955 22.9939179,4.95633663 C23.0199852,5.1414145 23.0272841,5.20554007 23.0262414,5.43597505 L23.0272841,8.72306225 Z'
                                id='Fill-39'
                            />
                            <path
                                d='M33.9521439,7.65133106 C33.7050258,7.93702873 33.3666722,8.07987756 32.9459459,8.07987756 C32.1389021,8.07987756 31.6603064,7.58929087 31.5263204,6.62323654 C31.3850357,5.61443182 31.5096374,4.87724842 31.8975189,4.4314975 C32.147765,4.14162907 32.5121859,3.99043869 32.9495953,3.99043869 C33.7342212,3.99043869 34.1982192,4.45339404 34.325949,5.36992049 C34.4714046,6.41469809 34.3426321,7.20297341 33.9521439,7.65133106 M32.9704492,3.09528743 C32.0815541,3.09528743 31.3657458,3.37942106 30.8986197,3.9158862 C30.350685,4.54619364 30.1468386,5.49087279 30.3089773,6.64826115 C30.514909,8.12002121 31.4575028,8.92862902 32.9631503,8.92862902 C33.8504814,8.92862902 34.5318808,8.66326385 34.9896226,8.13774698 C35.5281732,7.51734511 35.7247206,6.56015366 35.5584112,5.36679242 C35.3530009,3.90180986 34.4338677,3.09528743 32.9704492,3.09528743'
                                id='Fill-41'
                            />
                            <path
                                d='M38.5648578,8.6217647 L38.5106378,8.15411724 C38.501775,8.06444571 38.4532898,8.02169533 38.3855148,8.0461986 L38.3589261,8.05401879 C38.2468367,8.08529956 38.1415248,8.11345225 37.9945052,8.11345225 C37.6629291,8.11345225 37.4861927,7.90022169 37.4861927,7.49722115 L37.4861927,1.15765249 C37.4861927,1.04869115 37.3986066,0.960583658 37.2891239,0.960583658 L35.5754593,0.960583658 L35.5749379,1.55439687 L36.1343423,1.71236474 C36.2552946,1.74677359 36.3126427,1.8327957 36.3063865,1.97147376 L36.3126427,7.63746325 C36.3126427,8.49299221 36.7432745,8.92883756 37.592026,8.92883756 C37.944456,8.92883756 38.2937579,8.84750757 38.4850919,8.76669892 C38.5466107,8.74688777 38.5742421,8.69788123 38.5648578,8.6217647'
                                id='Fill-43'
                            />
                            <path
                                d='M2.47639401,6.42893084 L4.3386423,6.42893084 L3.89289138,5.1213948 C3.56600737,4.21008181 3.43723488,3.58655187 3.43723488,3.58655187 L3.39761258,3.58655187 C3.39761258,3.58655187 3.25893452,4.20069758 2.94195608,5.11148923 L2.47639401,6.42893084 Z M4.60557151,7.18123327 L2.21884903,7.18123327 L1.66413677,8.77603102 L0.713722821,8.77603102 L2.84290032,2.94268942 L4.04147502,2.94268942 L6.15084137,8.77603102 L5.17018934,8.77603102 L4.60557151,7.18123327 Z'
                                id='Fill-45'
                            />
                            <path
                                d='M7.77311405,5.0817725 C8.02075345,4.39828775 8.54522764,4.09173624 9.23913931,4.09173624 C9.99144174,4.09173624 10.6253986,4.50724909 10.6253986,5.50719092 L10.6253986,8.77603102 L9.73389676,8.77603102 L9.73389676,5.77516282 C9.73389676,5.21054499 9.47635179,4.86384983 8.93206645,4.86384983 C8.37735419,4.86384983 7.85235866,5.16101711 7.85235866,6.15157471 L7.85235866,8.77603102 L6.96085682,8.77603102 L6.96085682,4.20069758 L7.66415272,4.20069758 L7.77311405,5.0817725 Z'
                                id='Fill-47'
                            />
                            <path
                                d='M52.4258347,5.88417629 L53.2678086,5.88417629 C54.1290724,5.88417629 54.3965229,5.44833095 54.3965229,4.78465735 C54.3965229,4.08136146 54.0008212,3.70494957 53.257903,3.70494957 L52.4258347,3.70494957 L52.4258347,5.88417629 Z M51.5145217,8.77608315 L51.5145217,2.94222021 L53.2777142,2.94222021 C54.7234069,2.94222021 55.3375527,3.61579938 55.3375527,4.73512947 C55.3375527,5.90398745 54.6738791,6.63647873 53.3668644,6.63647873 L52.4258347,6.63647873 L52.4258347,8.77608315 L51.5145217,8.77608315 Z'
                                id='Fill-49'
                            />
                            <path
                                d='M57.1370309,5.0721276 C57.3549536,4.41835958 57.8304212,4.09147557 58.5238115,4.09147557 C59.2766353,4.09147557 59.9100708,4.50750976 59.9100708,5.5074516 L59.9100708,8.77577034 L59.018569,8.77577034 L59.018569,5.76499657 C59.018569,5.21080566 58.761024,4.8641105 58.2167387,4.8641105 C57.6620264,4.8641105 57.1370309,5.15085086 57.1370309,6.15131404 L57.1370309,8.77577034 L56.245529,8.77577034 L56.245529,2.15988824 L57.1370309,2.15988824 L57.1370309,5.0721276 Z'
                                id='Fill-51'
                            />
                            <path
                                d='M63.5464599,6.73595156 C62.3186898,6.70571349 61.7837887,6.98359096 61.7837887,7.55759303 C61.7837887,7.93400492 62.0314281,8.22178797 62.51628,8.22178797 C62.992269,8.22178797 63.5464599,7.93400492 63.5464599,7.39910381 L63.5464599,6.73595156 Z M64.4478673,7.69679244 C64.4478673,8.04296625 64.6063566,8.12221086 64.7846569,8.12221086 C64.8936183,8.12221086 64.9728629,8.10239971 65.0422019,8.07268298 L65.0422019,8.76659465 C64.9134294,8.81560119 64.7549402,8.84531791 64.5568287,8.84531791 C64.0318332,8.84531791 63.6950436,8.62739524 63.615799,8.11282663 C63.3780651,8.6868287 62.843164,8.89484579 62.2592564,8.89484579 C61.4569047,8.89484579 60.8922869,8.43971064 60.8922869,7.65717014 C60.8922869,6.64680138 61.6748274,6.06237239 63.5464599,6.15152258 L63.5464599,5.58690474 C63.5464599,5.02228691 63.2096703,4.80436424 62.7242971,4.80436424 C62.2295397,4.80436424 61.9323724,5.06190921 61.8927501,5.53737686 L61.0408705,5.40860438 C61.1102096,4.43837927 61.9615678,4.09168411 62.8035417,4.09168411 C63.6950436,4.09168411 64.4478673,4.4477635 64.4478673,5.49775456 L64.4478673,7.69679244 Z'
                                id='Fill-53'
                            />
                            <path
                                d='M68.4722422,5.10163579 C68.3632809,5.08182464 68.2146972,5.06201348 68.0661136,5.06201348 C67.4222512,5.06201348 66.7987212,5.56719786 66.7987212,6.4586997 L66.7987212,8.77608315 L65.9072194,8.77608315 L65.9072194,4.20022837 L66.6595218,4.20022837 L66.7684831,5.43842537 C66.9467835,4.61626256 67.5213069,4.05164473 68.5415812,4.10117261 L68.4722422,5.10163579 Z'
                                id='Fill-55'
                            />
                            <path
                                d='M74.2994319,4.0915277 C75.0715455,4.0915277 75.6564958,4.5075619 75.6564958,5.46840277 L75.6564958,8.77582248 L74.764994,8.77582248 L74.764994,5.76504871 C74.764994,5.20043087 74.5173546,4.86364129 74.0022646,4.86364129 C73.4772691,4.86364129 72.9621792,5.23066895 72.9621792,6.15136617 L72.9621792,8.77582248 L72.0711987,8.77582248 L72.0711987,5.76504871 C72.0711987,5.20043087 71.8230379,4.86364129 71.3084693,4.86364129 C70.7933794,4.86364129 70.2683838,5.24005318 70.2683838,6.15136617 L70.2683838,8.77582248 L69.376882,8.77582248 L69.376882,4.20048904 L70.0801779,4.20048904 L70.1891392,5.08156396 C70.426873,4.37878941 70.9914909,4.0915277 71.6155422,4.0915277 C72.2687888,4.0915277 72.7442565,4.37878941 72.8631234,5.10137511 C73.0909516,4.36836249 73.6852862,4.0915277 74.2994319,4.0915277'
                                id='Fill-57'
                            />
                            <path
                                d='M79.2980463,6.73595156 C78.0702762,6.70571349 77.5353751,6.98359096 77.5353751,7.55759303 C77.5353751,7.93400492 77.7830145,8.22178797 78.2678664,8.22178797 C78.7438554,8.22178797 79.2980463,7.93400492 79.2980463,7.39910381 L79.2980463,6.73595156 Z M80.1994537,7.69679244 C80.1994537,8.04296625 80.3579429,8.12221086 80.5362433,8.12221086 C80.6452046,8.12221086 80.7239279,8.10239971 80.7937882,8.07268298 L80.7937882,8.76659465 C80.6650158,8.81560119 80.5060052,8.84531791 80.3078937,8.84531791 C79.7834195,8.84531791 79.4466299,8.62739524 79.3673853,8.11282663 C79.1301728,8.6868287 78.5947504,8.89484579 78.0103214,8.89484579 C77.2084911,8.89484579 76.6438732,8.43971064 76.6438732,7.65717014 C76.6438732,6.64680138 77.4264137,6.06237239 79.2980463,6.15152258 L79.2980463,5.58690474 C79.2980463,5.02228691 78.9612567,4.80436424 78.4764048,4.80436424 C77.981126,4.80436424 77.6834374,5.06190921 77.6443364,5.53737686 L76.7924569,5.40860438 C76.8617959,4.43837927 77.7136754,4.09168411 78.5551281,4.09168411 C79.4466299,4.09168411 80.1994537,4.4477635 80.1994537,5.49775456 L80.1994537,7.69679244 Z'
                                id='Fill-59'
                            />
                            <path
                                d='M85.1743989,7.61723502 C85.0159097,8.53845359 84.2834184,8.89453299 83.382011,8.89453299 C82.1839576,8.89453299 81.3617948,8.04265345 81.3617948,6.51823743 C81.3617948,4.99277872 82.1839576,4.0913713 83.45135,4.0913713 C84.4220965,4.0913713 85.055532,4.60646125 85.1154868,5.51777425 L84.3032296,5.68616904 C84.223985,5.11112429 83.9169121,4.82438393 83.4117277,4.82438393 C82.6291872,4.82438393 82.2230586,5.51777425 82.2230586,6.51823743 C82.2230586,7.48846254 82.6985262,8.18185286 83.45135,8.18185286 C83.9367233,8.18185286 84.2636073,7.95402461 84.4022853,7.42902908 L85.1743989,7.61723502 Z'
                                id='Fill-61'
                            />
                            <path
                                d='M87.3392365,6.71608828 C87.6165926,7.60759012 87.6963586,8.16178103 87.6963586,8.16178103 L87.7354595,8.16178103 C87.7354595,8.16178103 87.8147041,7.6174957 88.0821547,6.71608828 L88.844884,4.20059331 L89.7760082,4.20059331 L88.0920603,9.15233864 C87.6264982,10.499497 87.0425906,10.8357652 86.2303333,10.8357652 C86.0124106,10.8357652 85.7642499,10.7961429 85.5666597,10.7268039 L85.6459043,9.96407452 C85.8440159,10.0636516 86.0223162,10.1032739 86.2402389,10.1032739 C86.7250908,10.1032739 87.0024469,9.84572896 87.2698975,9.0433773 L85.5765653,4.20059331 L86.556696,4.20059331 L87.3392365,6.71608828 Z'
                                id='Fill-63'
                            />
                            <path
                                d='M93.1738293,7.15188149 C93.2926962,7.86456162 93.757737,8.19144563 94.3916938,8.19144563 C95.0157451,8.19144563 95.4416849,7.90418392 95.4416849,7.35937724 C95.4416849,6.84480863 95.1841399,6.59716923 94.2332046,6.24056849 C93.3218916,5.92411141 92.5299669,5.54717817 92.5299669,4.46799173 C92.5299669,3.24960588 93.4809022,2.81376053 94.3916938,2.81376053 C95.461496,2.81376053 96.2732319,3.22979472 96.372809,4.34860347 L95.4714016,4.52742518 C95.4119681,3.8830414 94.9865497,3.54677316 94.4015994,3.54677316 C93.8573141,3.54677316 93.461091,3.84341909 93.461091,4.38822577 C93.461091,4.97265476 93.9261317,5.17076628 94.738389,5.46845491 C95.6298908,5.79481757 96.372809,6.15141831 96.372809,7.24103167 C96.372809,8.38955715 95.461496,8.89474152 94.3421659,8.89474152 C93.1936405,8.89474152 92.5101557,8.43960637 92.3219498,7.37970974 L93.1738293,7.15188149 Z'
                                id='Fill-65'
                            />
                            <path
                                d='M98.0537853,6.46813607 C98.0537853,7.61718289 98.4599139,8.1917063 99.25236,8.1917063 C100.054712,8.1917063 100.460319,7.61718289 100.460319,6.46813607 C100.460319,5.34932732 100.044285,4.81442622 99.25236,4.81442622 C98.4698195,4.81442622 98.0537853,5.34932732 98.0537853,6.46813607 M97.1727104,6.45823049 C97.1727104,4.9035764 97.9948732,4.09131917 99.25236,4.09131917 C100.519752,4.09131917 101.342437,4.9035764 101.342437,6.45823049 C101.342437,8.02331151 100.519752,8.89448085 99.25236,8.89448085 C97.9948732,8.89448085 97.1727104,8.02331151 97.1727104,6.45823049'
                                id='Fill-67'
                            />
                            <path
                                d='M103.964234,8.77603102 C103.7265,8.83546447 103.568011,8.85527562 103.410043,8.85527562 C102.83552,8.85527562 102.360052,8.54820277 102.360052,7.86471802 L102.360052,2.16014892 L103.251554,2.16014892 L103.251554,7.67703342 C103.251554,7.96377378 103.419427,8.08264069 103.657161,8.08264069 C103.785934,8.08264069 103.875084,8.06282954 103.974661,8.03311281 L103.964234,8.77603102 Z'
                                id='Fill-69'
                            />
                            <path
                                d='M107.612666,7.94411903 C107.394743,8.6176982 106.92866,8.89453299 106.206074,8.89453299 C105.374006,8.89453299 104.819815,8.46911456 104.819815,7.3795012 L104.819815,4.20033264 L105.711317,4.20033264 L105.711317,7.24082313 C105.711317,7.9144023 105.968341,8.23138074 106.532958,8.23138074 C107.028237,8.23138074 107.592855,7.78562982 107.592855,6.90403355 L107.592855,4.20033264 L108.484357,4.20033264 L108.484357,8.77618742 L107.721627,8.77618742 L107.612666,7.94411903 Z'
                                id='Fill-71'
                            />
                            <path
                                d='M112.376049,8.62734311 C112.078882,8.80564347 111.692564,8.89479366 111.266625,8.89479366 C110.444983,8.89479366 109.949704,8.45946966 109.949704,7.60759012 L109.949704,4.91327344 L109.286031,4.91327344 L109.286031,4.20059331 L109.949704,4.20059331 L109.949704,3.22932551 L110.85059,3.12036418 L110.85059,4.20059331 L112.207654,4.20059331 L112.207654,4.91327344 L110.841206,4.91327344 L110.841206,7.42876841 C110.841206,7.92404721 111.068513,8.13258565 111.444925,8.13258565 C111.742092,8.13258565 111.950109,8.0528197 112.207654,7.8844249 L112.376049,8.62734311 Z'
                                id='Fill-73'
                            />
                            <path
                                d='M113.197795,8.77592675 L114.088775,8.77592675 L114.088775,4.20059331 L113.197795,4.20059331 L113.197795,8.77592675 Z M113.63364,2.14023349 C113.979814,2.14023349 114.25717,2.37796732 114.25717,2.73456806 C114.25717,3.07135764 113.979814,3.32890262 113.63364,3.32890262 C113.316662,3.32890262 113.038784,3.07135764 113.038784,2.73456806 C113.038784,2.37796732 113.316662,2.14023349 113.63364,2.14023349 Z'
                                id='Fill-75'
                            />
                            <path
                                d='M116.003054,6.46813607 C116.003054,7.61718289 116.409183,8.1917063 117.201629,8.1917063 C118.00398,8.1917063 118.409588,7.61718289 118.409588,6.46813607 C118.409588,5.34932732 117.993553,4.81442622 117.201629,4.81442622 C116.419088,4.81442622 116.003054,5.34932732 116.003054,6.46813607 M115.121979,6.45823049 C115.121979,4.9035764 115.944142,4.09131917 117.201629,4.09131917 C118.469021,4.09131917 119.291705,4.9035764 119.291705,6.45823049 C119.291705,8.02331151 118.469021,8.89448085 117.201629,8.89448085 C115.944142,8.89448085 115.121979,8.02331151 115.121979,6.45823049'
                                id='Fill-77'
                            />
                            <path
                                d='M121.164068,5.0817725 C121.411707,4.39828775 121.936181,4.09173624 122.630093,4.09173624 C123.382395,4.09173624 124.016352,4.50724909 124.016352,5.50719092 L124.016352,8.77603102 L123.12485,8.77603102 L123.12485,5.77516282 C123.12485,5.21054499 122.867827,4.86384983 122.32302,4.86384983 C121.767786,4.86384983 121.242791,5.16101711 121.242791,6.15157471 L121.242791,8.77603102 L120.35181,8.77603102 L120.35181,4.20069758 L121.055106,4.20069758 L121.164068,5.0817725 Z'
                                id='Fill-78'
                            />
                        </g>
                        <g
                            id='arrow'
                            transform='translate(155.361140, 0.000000)'
                        >
                            <mask id='mask-2' fill='white'>
                                <use xlinkHref='#path-1' />
                            </mask>
                            <g id='Clip-29' />
                            <path
                                d='M18.9186597,5.9276531 C18.3003432,5.00956261 16.9636118,2.99820933 16.3567649,2.09628056 C15.7493967,1.19591584 14.3464543,0.414418021 13.2370298,0.413375328 L12.3132045,0.413375328 L1.56409046,0.413896674 C0.457272671,0.413375328 0.0308115549,1.17558334 0.645999961,2.09575922 L3.39453664,6.15391731 C4.0092037,7.07669992 5.40536857,7.82222486 6.51218636,7.82587428 L10.5958904,7.82587428 C11.2319327,6.51573151 12.335101,4.23588499 12.7214185,3.40120987 C12.8918987,3.03261817 12.91953,2.6734107 12.7996204,2.38927707 C12.6943085,2.14111633 12.4779499,1.95343173 12.1740051,1.84759847 C11.9383566,1.76522578 11.7021869,1.65782849 11.7313822,1.48526292 C11.7522361,1.3617039 11.8924782,1.29862102 12.1484591,1.29862102 C12.3601256,1.29862102 12.5983808,1.34397813 12.7860654,1.42009466 C13.7635894,1.81579636 14.183273,2.63430974 13.8798495,3.55709235 C13.7708882,3.88814713 9.86444183,12.8433092 9.86444183,12.8433092 C9.44215148,13.8677543 9.60950358,15.3879996 10.2231279,16.3102608 L12.9695792,20.4195108 C13.5003096,21.2088289 14.3902474,21.2776465 14.8156658,20.2542441 L19.2710896,9.44778202 C19.6939013,8.42594365 19.5374975,6.84678629 18.9186597,5.9276531'
                                id='Fill-28'
                                fill='#FEFEFE'
                                mask='url(#mask-2)'
                            />
                        </g>
                        <g
                            id='tm'
                            transform='translate(163.000000, 26.000000)'
                            fill='#FEFEFE'
                        >
                            <path
                                d='M3.36899805,2.09586306 L3.02126019,1.49370831 C2.8992652,1.2867339 2.85338675,1.13815026 2.85338675,1.13815026 L2.83826771,1.13815026 C2.83826771,1.13815026 2.85755751,1.2674441 2.85755751,1.52707446 L2.85755751,2.62607205 L2.39616621,2.62607205 L2.39616621,0.378548984 L2.88414617,0.378548984 L3.34188805,1.22990718 C3.46023361,1.43531754 3.48630092,1.5421935 3.48630092,1.5421935 L3.5019413,1.5421935 C3.5019413,1.5421935 3.53270072,1.43531754 3.64635417,1.22990718 L4.10878817,0.378548984 L4.58529851,0.378548984 L4.58529851,2.62607205 L4.12338586,2.62607205 L4.12338586,1.52707446 C4.12338586,1.2674441 4.14684644,1.13815026 4.14684644,1.13815026 L4.13120605,1.13815026 C4.13120605,1.13815026 4.08167817,1.2867339 3.95968318,1.49370831 L3.61298802,2.09586306 L3.36899805,2.09586306 Z'
                                id='Fill-2'
                            />
                            <polygon
                                id='Fill-1'
                                points='0.960796107 2.62586351 0.960796107 0.779255602 0.334659433 0.779255602 0.334659433 0.378340446 2.05510159 0.378340446 2.05510159 0.779255602 1.42218741 0.779255602 1.42218741 2.62586351'
                            />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export const DashboardSVG = (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width='25'
        height='25'
        viewBox='0 0 25 25'
    >
        <g fill='none' fillRule='evenodd'>
            <rect
                width='25'
                height='25'
                fill='#D8D8D8'
                fillOpacity='.01'
                fillRule='nonzero'
            />
            <path
                stroke='#9B9B9B'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='1.5'
                d='M10.5 12.526C10.5011063 13.0657455 10.0647441 13.5043455 9.525 13.506L1.725 13.506C1.46570961 13.5054704 1.21726432 13.4018909 1.03438747 13.2180762.851510606 13.0342616.749203274 12.7852897.75 12.526L.75 1.736C.749469024 1.47661811.851999132 1.22764895 1.03503475 1.04386318 1.21807038.860077415 1.46661811.756529891 1.726.756L9.526.769C10.0653531.771204824 10.5011075 1.20964354 10.5 1.749L10.5 12.526zM23.246 6.544C23.242147 7.07877668 22.8057802 7.50932528 22.271 7.506L14.471 7.506C14.2140963 7.50786272 13.9669895 7.40752294 13.7841113 7.22708311 13.6012331 7.04664328 13.4975857 6.80090554 13.496 6.544L13.496 1.731C13.4993042 1.19677676 13.9347737.766230312 14.469.769L22.269.756C22.8043344.752118569 23.2415988 1.18266962 23.246 1.718L23.246 6.544zM10.5 22.291C10.4984136 22.5485267 10.3945876 22.7948751 10.2113634 22.9758488 10.0281393 23.1568225 9.78052669 23.2575962 9.523 23.256L1.723 23.243C1.18822289 23.2452147.752754866 22.8137746.75 22.279L.75 17.47C.751322053 17.2128299.854820953 16.9667335 1.03769877 16.7859189 1.22057659 16.6051043 1.46783145 16.5044042 1.725 16.506L9.525 16.506C10.0603308 16.5032303 10.4966958 16.9346722 10.5 17.47L10.5 22.291zM14.472 23.256C14.2124444 23.2554704 13.9637448 23.1517856 13.7806804 22.9677834 13.597616 22.7837811 13.4952033 22.5345549 13.496 22.275L13.496 11.487C13.4952031 11.2276179 13.5974787 10.978543 13.7803269 10.7945695 13.9631751 10.6105961 14.2116179 10.5067944 14.471 10.506L22.271 10.506C22.5303821 10.5067944 22.7788249 10.6105961 22.9616731 10.7945695 23.1445213 10.978543 23.2467969 11.2276179 23.246 11.487L23.246 22.262C23.2471092 22.8015202 22.8115143 23.2402457 22.272 23.243L14.472 23.256z'
                transform='translate(.534 .468)'
            />
        </g>
    </svg>
);

export const ReportSVG = (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width='25'
        height='25'
        viewBox='0 0 25 25'
    >
        <g fill='none' fillRule='evenodd'>
            <rect
                width='25'
                height='25'
                fill='#D8D8D8'
                fillOpacity='.01'
                fillRule='nonzero'
            />
            <path
                stroke='#9B9B9B'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='1.5'
                d='M.75 19.75L23.25 19.75M6 9.25L3 9.25C2.58578644 9.25 2.25 9.58578644 2.25 10L2.25 19.75 6.75 19.75 6.75 10C6.75 9.58578644 6.41421356 9.25 6 9.25zM13.5.25L10.5.25C10.0857864.25 9.75.585786438 9.75 1L9.75 19.75 14.25 19.75 14.25 1C14.25.585786438 13.9142136.25 13.5.25zM21 4.75L18 4.75C17.5857864 4.75 17.25 5.08578644 17.25 5.5L17.25 19.75 21.75 19.75 21.75 5.5C21.75 5.08578644 21.4142136 4.75 21 4.75z'
                transform='translate(.477 2.355)'
            />
        </g>
    </svg>
);

export const CaseManagementSVG = (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width='25'
        height='25'
        viewBox='0 0 25 25'
    >
        <g
            fill='none'
            fillRule='evenodd'
            stroke='#9B9B9B'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.5'
            transform='translate(.566 .56)'
        >
            <path
                stroke='#9B9B9B'
                d='M23.25,14.5314196 L23.25,17.9460739 L23.25,19.4574375 L23.25,22.271 C23.25,22.8111345 22.8121345,23.249 22.272,23.249 L1.728,23.249 C1.18786551,23.249 0.75,22.8111345 0.75,22.271 L0.75,19.4666705 L0.75,17.8897292 L0.75,14.4557803'
            />
            <path
                stroke='#9B9B9B'
                d='M.749304274 14.5144925L.750000082 13.7920001C.750221004 13.2520219 1.18802177 12.8144002 1.728 12.8144002L22.272 12.814C22.8121345 12.814 23.25 13.2518655 23.25 13.792L23.25 14.5299991M2.25 12.814L2.25 6.977C2.25330448 6.43488472 2.69487918 5.99778393 3.237 6L11.625 6C11.9349627 5.99952676 12.2271432 6.14469125 12.414 6.392L13.6 8.248 20.014 8.248C20.5565079 8.24633984 20.997795 8.68449409 21 9.227L21 12.814M9.065 15.748L14.935 15.748M19.737 5.248L19.737 3.977C19.7336955 3.43488472 19.2921208 2.99778393 18.75 3L13.6 3 12.414 1.14C12.2271432.892691251 11.9349627.747526762 11.625.748L4.5.748C4.23929154.746936973 3.98883896.849484633 3.80374009 1.03308321 3.61864121 1.21668179 3.51405871 1.46629152 3.513 1.727L3.513 2.77'
            />
        </g>
    </svg>
);

export const DrugsSVG = (
    <svg
        width='25px'
        height='25px'
        viewBox='0 0 25 25'
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
    >
        <g
            id='Symbols'
            stroke='none'
            strokeWidth='1'
            fill='none'
            fillRule='evenodd'
            strokeLinecap='round'
            strokeLinejoin='round'
        >
            <g id='drugs-active' stroke='#9B9B9B' strokeWidth='1.5'>
                <g id='icon-drugs' transform='translate(0.433949, 0.539595)'>
                    <path d='M0.75,11 L23.25,11' id='Shape' />
                    <path
                        d='M21.7500002,11 L2.24999983,11 C2.24952679,14.6417461 4.28048409,17.9796138 7.515,19.653 C7.70105359,19.749908 7.8381711,19.9201266 7.89323897,20.1225485 C7.94830684,20.3249704 7.91631884,20.5411933 7.805,20.719 C7.56174435,21.1116314 7.34525285,21.5202257 7.157,21.942 C7.05245689,22.1741126 7.07279059,22.4434087 7.21100187,22.6571916 C7.34921315,22.8709746 7.58643075,23.0000546 7.841,23 L16.159,23 C16.4133599,22.9995312 16.6501952,22.8703433 16.7882931,22.6567358 C16.9263909,22.4431283 16.94698,22.1741365 16.843,21.942 C16.6547471,21.5202257 16.4382556,21.1116314 16.195,20.719 C16.0839882,20.5411162 16.0521747,20.3250005 16.107217,20.1226725 C16.1622593,19.9203446 16.2991721,19.75013 16.485,19.653 C19.7195159,17.9796138 21.7504732,14.6417461 21.7500002,11 Z'
                        id='Shape'
                    />
                    <path
                        d='M14.25,8 L18.207,1.405 C18.5245029,0.876061292 19.1668235,0.638672893 19.752,0.834 C20.08338,0.944420618 20.3572681,1.18205216 20.5133265,1.49454404 C20.6693848,1.80703592 20.6948097,2.16874995 20.584,2.5 L18.75,8'
                        id='Shape'
                    />
                </g>
            </g>
        </g>
    </svg>
);

export const PatientsSVG = (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width='25'
        height='25'
        viewBox='0 0 25 25'
    >
        <g fill='none' fillRule='evenodd'>
            <rect
                width='25'
                height='25'
                fill='#D8D8D8'
                fillOpacity='.01'
                fillRule='nonzero'
            />
            <g
                stroke='#9B9B9B'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='1.5'
                transform='translate(.513 .578)'
            >
                <circle cx='4.5' cy='6' r='2.25' />
                <path d='M4.5,9.75 C2.42893219,9.75 0.75,11.4289322 0.75,13.5 L0.75,15.75 L2.25,15.75 L3,21.75 L6,21.75' />
                <circle cx='19.5' cy='6' r='2.25' />
                <path d='M19.5,9.75 C21.5710678,9.75 23.25,11.4289322 23.25,13.5 L23.25,15.75 L21.75,15.75 L21,21.75 L18,21.75' />
                <circle cx='12' cy='3.75' r='3' />
                <path d='M17.25,13.5 C17.25,10.6005051 14.8994949,8.25 12,8.25 C9.10050506,8.25 6.75,10.6005051 6.75,13.5 L6.75,15.75 L9,15.75 L9.75,23.25 L14.25,23.25 L15,15.75 L17.25,15.75 L17.25,13.5 Z' />
            </g>
        </g>
    </svg>
);

export const AccountSVG = (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width='25'
        height='25'
        viewBox='0 0 25 25'
    >
        <g fill='none' fillRule='evenodd'>
            <rect
                width='25'
                height='25'
                fill='#D8D8D8'
                fillOpacity='.01'
                fillRule='nonzero'
            />
            <g
                stroke='#9B9B9B'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='1.5'
                transform='translate(.406 2.766)'
            >
                <circle cx='8.382' cy='6.415' r='2.625' />
                <path d='M3.88172829 14.2896077C3.88172832 11.8043264 5.89644694 9.78960778 8.38172829 9.78960778 10.8670096 9.78960778 12.8817283 11.8043264 12.8817283 14.2896077L3.88172829 14.2896077zM14.3817283 6.78960772L18.8817283 6.78960772M14.3817283 9.78960772L20.3817283 9.78960772' />
                <path d='M21.8817283,0.789607715 L2.38172829,0.789607715 C1.55330116,0.789607715 0.881728288,1.46118059 0.881728288,2.28960772 L0.881728288,17.2896077 C0.881728288,18.1180348 1.55330116,18.7896077 2.38172829,18.7896077 L5.38172829,18.7896077 C5.38172829,17.9611806 6.05330116,17.2896077 6.88172829,17.2896077 C7.71015541,17.2896077 8.38172829,17.9611806 8.38172829,18.7896077 L15.8817283,18.7896077 C15.8817283,17.9611806 16.5533012,17.2896077 17.3817283,17.2896077 C18.2101554,17.2896077 18.8817283,17.9611806 18.8817283,18.7896077 L21.8817283,18.7896077 C22.7101554,18.7896077 23.3817283,18.1180348 23.3817283,17.2896077 L23.3817283,2.28960772 C23.3817283,1.46118059 22.7101554,0.789607715 21.8817283,0.789607715 Z' />
            </g>
        </g>
    </svg>
);
