import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose, setPropTypes, setDisplayName, flattenProp } from 'recompose';
import withReport from './withReport';
import reportMetricSelector from '../../../store/report-data/chart-selectors/metricSelector';

const mapStateToProps = (state, ownProps) => ({
    data: reportMetricSelector(state, ownProps),
});

export const withReportMetric = WrappedComponent => {
    const enhance = compose(
        withReport,
        connect(mapStateToProps),
        flattenProp('data'),
        setPropTypes({
            data: PropTypes.string,
            name: PropTypes.string,
        }),
        setDisplayName('WithReportMetric'),
    );
    return enhance(WrappedComponent);
};
export default withReportMetric;
