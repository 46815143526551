import React from 'react';
import PropTypes from 'prop-types';

import Table from '../../../common/charts/table';
import withReportTable from '../../../common/report/withReportTable';
import ReportPathLink from './ReportPathLink';

const ReportDetailTable = withReportTable(
    ({
        rows,
        columns,
        redirectFilterApiNameC,
        defaultSortColumn,
        defaultSortDirection,
    }) => {
        for (const column of columns) {
            if (column.grouping) {
                column.CustomComponent = ReportPathLink;
            }
        }
        return (
            <Table
                rows={rows}
                columns={columns}
                redirectFilterApiNameC={redirectFilterApiNameC}
                defaultSortColumn={defaultSortColumn}
                defaultSortDirection={defaultSortDirection}
            />
        );
    },
);

ReportDetailTable.propTypes = {
    sfid: PropTypes.string.isRequired,
    redirectFilterApiNameC: PropTypes.string,
    defaultSortColumnLabelC: PropTypes.string,
};

export default ReportDetailTable;
