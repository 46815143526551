import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react';
export default class index extends Component {
    render() {
        return (
            <Grid
                className='reportGridContainer paddingV2rem mobileNoPad'
                padded='vertically'
                stretched
                stackable
                columns='2'
            >
                {this.props.children}
            </Grid>
        );
    }
}
