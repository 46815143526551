import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

/**
 *
 * @param ComponentName
 * @param accessRoles
 * @returns {*}
 */
export default function requireAuthentication(
    ComponentName,
    accessRoles = ['Admin'],
) {
    class AuthenticatedComponent extends PureComponent {
        constructor() {
            super();
            this.state = {
                isAuthenticated: false,
            };
        }

        static getDerivedStateFromProps(props, state) {
            if (
                !props.isAuthenticated ||
                accessRoles.indexOf(props.currentRole) === -1
            ) {
                props.dispatch(push('/login'));
                return {
                    isAuthenticated: false,
                };
            } else {
                return {
                    isAuthenticated: true,
                };
            }
        }

        render() {
            return (
                <div className='full-page'>
                    {this.state.isAuthenticated ? (
                        <ComponentName {...this.props} />
                    ) : null}
                </div>
            );
        }
    }

    function mapStateToProps(state) {
        return {
            isAuthenticated: state.auth.isAuthenticated,
            currentRole: state.auth.currentRole,
        };
    }

    return connect(mapStateToProps)(AuthenticatedComponent);
}
