import { types, getPrefixedActionType } from './actionTypes';

export const getReducer = (prefix, defaultSort = {}, defaultPageSize = 10) => {
    return (
        state = {
            total: 0,
            error: null,
            loading: false,
            loaded: false,
            data: [],
            filters: {},
            page: 1,
            pageSize: defaultPageSize,
            sort: defaultSort,
        },
        action = {
            type: null,
            payload: null,
        },
    ) => {
        switch (action.type) {
            case getPrefixedActionType(prefix, types.REQUEST):
                return {
                    ...state,
                    loading: true,
                    error: null,
                };
            case getPrefixedActionType(prefix, types.SUCCESS): {
                const { total = 0, data = [] } = action.payload;
                return {
                    ...state,
                    loading: false,
                    loaded: true,
                    error: null,
                    total: total,
                    data: Array.isArray(data) ? data : [],
                };
            }
            case getPrefixedActionType(prefix, types.ERROR): {
                const { errorMessage } = action.payload;
                return {
                    ...state,
                    loading: false,
                    loaded: true,
                    error: errorMessage,
                    data: [],
                    total: 0,
                };
            }
            case getPrefixedActionType(prefix, types.FILTER): {
                const { value, column, like } = action.payload;
                if (!column || typeof column !== 'string') {
                    return state;
                }
                const prevFilter = state.filters[column];
                const filterChanged =
                    typeof prevFilter !== 'object' ||
                    prevFilter.value !== value ||
                    prevFilter.like !== like;
                if (!filterChanged) {
                    return state;
                }
                const filters = {
                    ...state.filters,
                    [column]: {
                        value: value,
                        like: !!like,
                    },
                };
                return {
                    ...state,
                    filters,
                    page: 1,
                    loaded: false,
                };
            }
            case getPrefixedActionType(prefix, types.FILTER_RESET): {
                if (Object.keys(state.filters).length === 0) {
                    return state;
                }
                return {
                    ...state,
                    filters: {},
                    page: 1,
                    loaded: false,
                };
            }
            case getPrefixedActionType(prefix, types.GO_TO_PAGE): {
                const { page, total, pageSize } = state;
                const newPage = Math.round(+action.payload.page);
                if (isNaN(newPage)) {
                    return state;
                }
                if (newPage === page) {
                    return state;
                }
                if (newPage < 1) {
                    return {
                        ...state,
                        page: 1,
                        loaded: false,
                    };
                }
                const pageCount = Math.ceil(total / pageSize) || 1;
                if (newPage > pageCount) {
                    return {
                        ...state,
                        page: pageCount,
                        loaded: false,
                    };
                }

                return {
                    ...state,
                    page: newPage,
                    loaded: false,
                };
            }
            case getPrefixedActionType(prefix, types.GO_TO_PREV_PAGE): {
                if (state.page <= 1) {
                    return state;
                }
                return {
                    ...state,
                    page: state.page - 1,
                    loaded: false,
                };
            }
            case getPrefixedActionType(prefix, types.GO_TO_NEXT_PAGE): {
                const { page, total, pageSize } = state;
                const pageCount = Math.ceil(total / pageSize) || 1;
                if (page >= pageCount) {
                    return state;
                }
                return {
                    ...state,
                    page: page + 1,
                    loaded: false,
                };
            }
            case getPrefixedActionType(prefix, types.GO_TO_FIRST_PAGE): {
                if (state.page === 1) {
                    return state;
                }
                return {
                    ...state,
                    page: 1,
                    loaded: false,
                };
            }
            case getPrefixedActionType(prefix, types.GO_TO_LAST_PAGE): {
                const { total, pageSize } = state;
                const pageCount = Math.ceil(total / pageSize) || 1;
                if (pageCount === state.page) {
                    return state;
                }
                return {
                    ...state,
                    page: pageCount,
                    loaded: false,
                };
            }
            case getPrefixedActionType(prefix, types.SORT): {
                const { sort = {} } = action.payload;
                if (typeof sort !== 'object') {
                    if (typeof sort === 'string') {
                        if (!sort.length) {
                            return {
                                ...state,
                                sort: {},
                            };
                        }
                        const [column, direction = 'asc'] = sort.split(' ');
                        return {
                            ...state,
                            sort: {
                                column,
                                direction,
                            },
                        };
                    }
                    return {
                        ...state,
                        sort: {},
                    };
                }
                const { column, direction = 'asc' } = sort;
                if (typeof column !== 'string' || !column.length) {
                    const sortingChanged = Object.keys(state.sort).length !== 0;
                    return {
                        ...state,
                        sort: {},
                        loaded: !sortingChanged,
                    };
                }
                const sortingChanged =
                    state.sort.column !== column ||
                    state.sort.direction !== direction;
                return {
                    ...state,
                    sort: {
                        column,
                        direction,
                    },
                    loaded: !sortingChanged,
                };
            }
            case getPrefixedActionType(prefix, types.SET_PAGE_SIZE): {
                const newPageSize = Math.round(+action.payload.pageSize);
                if (isNaN(newPageSize)) {
                    return state;
                }
                if (newPageSize === state.pageSize) {
                    return state;
                }
                return {
                    ...state,
                    pageSize: newPageSize,
                    loaded: state.page === 1 && newPageSize < state.pageSize,
                };
            }
            default:
                return state;
        }
    };
};

export default getReducer;
