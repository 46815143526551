import api, { buildSearchParams, buildCountParams } from './api';

export const getOrderList = params => {
    const _params = addFilterIfNone(params);
    const result = api.get('/ReferralLineCs', buildSearchParams(_params));
    /* FILTER */
    // const filterName = getfilterName(_params);
    // const resultFiltered = filterCheck(filterName, result);
    // console.log({filterName, resultFiltered})
    /*  */
    return result;
};

/*const filterCheck = (name, result) => {
    try {
        if (name !== 'Open Orders') {
            return result.then(res => {
                let newData = res.data.filter(function(item) {
                    return item.referral.statusC.includes(name);
                });
                const newResult = { data: newData, total: res.total };
                return newResult;
            });
        } else return result;
    } catch (error) {
        console.log(error);
    }
};

const getfilterName = params => {
    try {
        const filter = params.filter['referral.statusC'].value;
        return filter;
    } catch (error) {
        console.log(error);
    }
};*/

const addFilterIfNone = params => {
    const temp = {
        skip: 0,
        limit: 10,
        filter: {
            'referral.statusC': {
                value: 'Open Orders',
                like: false,
            },
        },
        sort: {
            column: 'patient.name',
            direction: 'ascending',
        },
    };
    try {
        if (!params) return temp;
        const filter = params.filter['referral.statusC'].value;
        console.log(filter);
        if (filter) return params;
        else return temp;
    } catch (error) {
        console.log(error);
        return temp;
    }
};

export const getStatusCount = params => {
    const { 'referral.statusC': _, ...filterWithoutStatus } = params.filter;
    const statuses = [
        'Open Orders',
        'Completed',
        'In Progress',
        'Needs Attention',
    ];
    const getAllStatusCount = statuses.map(status =>
        api
            .get(
                '/ReferralLineCs/count',
                buildCountParams({
                    ...params,
                    filter: {
                        ...filterWithoutStatus,
                        'referral.statusC': {
                            value: status,
                            like: false,
                        },
                    },
                }),
            )
            .then(({ count }) => ({
                status,
                count,
            })),
    );
    return Promise.all(getAllStatusCount).then(statusCounts => {
        const result = {};
        for (const { status, count } of statusCounts) {
            result[status] = count;
        }
        return result;
    });
};
