import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form, Dropdown } from 'semantic-ui-react';
import { connect } from 'react-redux';

import DateFilterValues from './DateFilterValues';
import { setDateFilter } from '../../../store/report-data/reportDataActions';

const filterOptions = [
    {
        key: 'cq',
        text: 'By Current Quarter',
        value: DateFilterValues.THIS_FISCAL_QUARTER,
    },
    {
        key: 'lq',
        text: 'By Last Quarter',
        value: DateFilterValues.LAST_FISCAL_QUARTER,
    },
    {
        key: 'ltq',
        text: 'By Last And Current Quarter',
        value: DateFilterValues.LAST_AND_THIS_FISCAL_QUARTER,
    },
    { key: 'cy', text: 'By Current Year', value: DateFilterValues.THIS_YEAR },
    { key: 'ly', text: 'By Last Year', value: DateFilterValues.LAST_YEAR },
];

export class DateFilter extends PureComponent {
    static propTypes = {
        disabled: PropTypes.bool,
        onChange: PropTypes.func,
        value: PropTypes.string,
    };

    static defaultProps = {
        disabled: false,
        value: DateFilterValues.DEFAULT,
        onChange: () => {},
    };

    render() {
        const { value, onChange, disabled } = this.props;
        return (
            <>
                <Form className='tablet-up-show'>
                    <Form.Group inline>
                        <Form.Field width={3}>
                            <label>Filter</label>
                        </Form.Field>
                        <Form.Select
                            disabled={disabled}
                            value={value}
                            onChange={onChange}
                            fluid
                            options={filterOptions}
                            placeholder='Date Filter'
                            width={13}
                        />
                    </Form.Group>
                </Form>
                <Dropdown
                    scrolling
                    options={filterOptions}
                    disabled={disabled}
                    value={value}
                    onChange={onChange}
                    text='Filter'
                    className='mainFilter mobile-show'
                />
            </>
        );
    }
}

const mapStateToProps = state => ({
    value: state.reportData.dateFilter,
});

const mapDispatchToProps = dispatch => ({
    onChange: (_, { value }) => dispatch(setDateFilter(value)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DateFilter);
