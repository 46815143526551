import { connect } from 'react-redux';
import { compose, flattenProp, setDisplayName } from 'recompose';
import withReport from './withReport';
import { reportTableSelector } from '../../../store/report-data/chart-selectors/tableSelector';

const mapStateToProps = (state, ownProps) => ({
    data: reportTableSelector(state, ownProps),
});

export const withReportTable = WrappedComponent => {
    const enhance = compose(
        withReport,
        connect(mapStateToProps),
        flattenProp('data'),
        setDisplayName('WithReportTable'),
    );
    return enhance(WrappedComponent);
};
export default withReportTable;
