import React, { Component } from 'react';
import { Divider, Grid, Header } from 'semantic-ui-react';

import NavMenu from '../../common/NavMenu';
import PatientRosterSidebar from './PatientRosterSidebar';
import PatientRosterSearch from './PatientRosterSearch';
import PatientRosterList from './PatientRosterList';
import ShownPatients from './ShownPatients';
import TotalPatients from './TotalPatients';
import PatientRosterPagination from './PatientRosterPagination';

/* ORIGINAL */
/*export const PatientRoster = () => (
    <Grid className='full-page' stretched>
        <Grid.Row className='full-page'>
            <NavMenu />
            <Grid.Column className='stretch-col'>
                <Grid className='vertical marginV0' stackable>
                    <Grid.Row
                        columns={1}
                        className='pageHeader'
                        verticalAlign='middle'
                    >
                        <Grid.Column computer={11} tablet={10}>
                            <Header as='h1'>
                                Patient Roster{' '}
                                <span>
                                    <small>Showing:</small> <ShownPatients />{' '}
                                    patient(s) out of <TotalPatients /> total
                                    patient(s)
                                </span>
                            </Header>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className='paddingV0 grow'>
                        <Grid.Column mobile={16} computer={11} tablet={10}>
                            <Grid.Row className='paddingV0 marginV1 shrink'>
                                <br />
                                <PatientRosterSearch />
                            </Grid.Row>
                            <Divider className='mobile-show' hidden />
                            <Grid.Row className='paddingV0 shrink mobileViewportPadding'>
                                <PatientRosterList />
                                <PatientRosterPagination />
                            </Grid.Row>
                        </Grid.Column>
                        <PatientRosterSidebar />
                    </Grid.Row>
                </Grid>
            </Grid.Column>
        </Grid.Row>
    </Grid>
);
export default PatientRoster;*/
export default class PatientRoster extends Component {
    componentDidMount() {
        window.scroll(0, 0);
    }
    render() {
        return (
            <Grid className='full-page' stretched>
                <Grid.Row className='full-page'>
                    <NavMenu />
                    <Grid.Column className='stretch-col'>
                        <Grid className='vertical marginV0' stackable>
                            <Grid.Row
                                columns={1}
                                className='pageHeader'
                                verticalAlign='middle'
                            >
                                <Grid.Column computer={11} tablet={10}>
                                    <Header as='h1'>
                                        Patient Roster{' '}
                                        <span>
                                            <small>Showing:</small>{' '}
                                            <ShownPatients /> patient(s) out of{' '}
                                            <TotalPatients /> total patient(s)
                                        </span>
                                    </Header>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row className='paddingV0 grow'>
                                <Grid.Column
                                    mobile={16}
                                    computer={11}
                                    tablet={10}
                                >
                                    <Grid.Row className='paddingV0 marginV1 shrink'>
                                        <br />
                                        <PatientRosterSearch />
                                    </Grid.Row>
                                    <Divider className='mobile-show' hidden />
                                    <Grid.Row className='paddingV0 shrink mobileViewportPadding'>
                                        <PatientRosterList />
                                        <PatientRosterPagination />
                                    </Grid.Row>
                                </Grid.Column>
                                <PatientRosterSidebar />
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}
