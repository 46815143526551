import get from 'lodash/get';

export const PercentFormatter = ({ accessor, row }) => {
    const num = Number(get(row, accessor));
    if (Number.isNaN(num)) {
        return null;
    }
    return num.toFixed(0) + `%`;
};

export default PercentFormatter;
