import React, { Component } from 'react';
import { Grid, Header, Container } from 'semantic-ui-react';
import DateFilter from '../../../components/common/date-filter/DateFilter';
import './style.scss';
export default class index extends Component {
    render() {
        const { title, subTitle, optional, dateFilter } = this.props;
        return (
            <Container className='Dashboard-Header'>
                <Grid columns='2' className='pageHeader' verticalAlign='middle'>
                    <Grid.Column width='11' className='title'>
                        <span>
                            <Header as='h1'>{title}</Header>
                        </span>
                        <span>
                            <Header.Subheader as='h1'>
                                <span>
                                    <small>{subTitle}</small>
                                </span>
                            </Header.Subheader>
                        </span>
                    </Grid.Column>
                    <Grid.Column width='5' textAlign='right'>
                        {dateFilter ? <DateFilter /> : null}
                        {optional}
                    </Grid.Column>
                </Grid>
            </Container>
        );
    }
}
