import { put, select, takeEvery, call } from 'redux-saga/effects';
import { setFlaggedPatient } from '../../api/patientFlaggedAPI';
import {
    getPatientProfile,
    getPatientFillInfo,
} from '../../api/patientDetailTopAPI';
import * as types from './patientActionTypes';
import { getAssessmentById, getPatientMemberPlan } from './patientSelectors';
import { resolve } from '../case/caseActions';
import Notify from '../../helpers/notification.helpers';

export function* updatePatientFlaggedSaga({
    accountId,
    flaggedReasonC,
    flaggedC,
}) {
    try {
        const flaggedPatient = yield setFlaggedPatient(
            accountId,
            flaggedReasonC,
            flaggedC,
        );
        yield put({
            type: types.PATIENT_FLAGGED_SUCCESS,
            account: flaggedPatient.data,
        });
    } catch (error) {
        yield put({
            type: types.PATIENT_FLAGGED_ERROR,
            error: error.message,
        });
    }
}

export function* patientProfileSaga({ accountId }) {
    try {
        const data = yield getPatientProfile(accountId);
        yield put({
            type: types.PATIENT_PROFILE_SUCCESS,
            patientProfile: data.data,
        });
    } catch (error) {
        yield put({
            type: types.PATIENT_PROFILE_ERROR,
            error: error.message,
        });
    }
}

export function* patientFillInfoSaga({ memberId }) {
    try {
        const fillInformation = yield getPatientFillInfo(memberId);

        yield put({
            type: types.PATIENT_FILL_INFO_SUCCESS,
            fillInfo: fillInformation.data,
        });
    } catch (error) {
        yield put({
            type: types.PATIENT_FILL_INFO_ERROR,
            error: error.message,
        });
    }
}

// TODO: move to assessment(patient timeline) slice
export function* resolveAssessment({ payload: assessmentId }) {
    try {
        const assessment = assessmentId
            ? yield select(getAssessmentById, assessmentId)
            : null;
        if (!assessment) {
            throw new Error('Assessment to found');
        }
        const caseToResolve = assessment.cases
            ? assessment.cases.find(aCase => aCase.status !== 'Closed')
            : null;
        if (caseToResolve) {
            yield put(resolve(caseToResolve.id));
            const membeplanId = yield select(getPatientMemberPlan);
            if (membeplanId) {
                yield call(patientFillInfoSaga, { memberId: membeplanId });
            }
        } else {
            throw new Error('Case not found');
        }
    } catch (error) {
        Notify.error(error.message);
    }
}

export function* rootSaga() {
    yield takeEvery(types.RESOLVE_ASSESSMENT, resolveAssessment);
}

export default rootSaga;
