import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Surface, Symbols } from 'recharts';
import { Header, List } from 'semantic-ui-react';

const Legend = ({ payload = [], title }) => {
    return (
        <Fragment>
            <Header>{title}</Header>
            <List horizontal>
                {payload.map((entry, index) => {
                    const { color, value, type } = entry;
                    return (
                        <List.Item
                            key={`ListItem${index}`}
                            className={`legend-item legend-item-${index}`}
                        >
                            <Surface
                                width={14}
                                height={14}
                                viewBox={{ width: 32, height: 32, x: 0, y: 0 }}
                            >
                                <Symbols
                                    cx={16}
                                    cy={16}
                                    type={type}
                                    size={32}
                                    fill={color}
                                    sizeType='diameter'
                                />
                            </Surface>
                            <span>{value}</span>
                        </List.Item>
                    );
                })}
            </List>
        </Fragment>
    );
};

Legend.propTypes = PropTypes.shape({
    title: PropTypes.string,
    payload: PropTypes.array,
});

export default Legend;
