import { createSelector } from 'reselect';
import uniq from 'lodash/uniq';

export const getAssessmentById = (state, { assessmentId }) => {
    return state.patient.unFilteredTimeLine.find(
        event => event.id === assessmentId,
    );
};

export const getPatientMemberPlan = state =>
    state.patient.patientProfile.memberPlan
        ? state.patient.patientProfile.memberPlan.id
        : null;

export const activeCaseSelector = createSelector(
    getAssessmentById,
    assessment => {
        const activeCase = assessment.cases
            ? assessment.cases.find(aCase => aCase.status !== 'Closed')
            : null;
        return activeCase ? activeCase.id : null;
    },
);

export const getPatientTotalSpendSelector = state =>
    state.patient.unFilteredTimeLine.reduce((acc, event) => {
        if (!event.claims || !event.claims.length) {
            return acc;
        }
        const eventSum = event.claims.reduce(
            (sum, claim) =>
                claim.primaryInsurancePaidC
                    ? sum + claim.primaryInsurancePaidC
                    : sum,
            0,
        );
        return acc + eventSum;
    }, 0);

export const getPatientMedicationsSelector = state =>
    uniq(
        state.patient.unFilteredTimeLine.reduce((mediactions, event) => {
            if (!event.claims || !event.claims.length) {
                return mediactions;
            }
            const eventMedications = event.claims.reduce(
                (acc, claim) => (claim.drug ? [...acc, claim.drug.name] : acc),
                [],
            );
            return [...mediactions, ...eventMedications];
        }, []),
    );
