import React from 'react';
import { paths } from '../../routes';
import pathToRegexp from 'path-to-regexp';

export const USE_SUBDOMAINS = process.env.REACT_APP_USE_SUBDOMAINS;

export const ROOT_DOMAIN = process.env.REACT_APP_ROOT_DOMAIN;

export const getDomainFromSubfolder = () => {
    let domain = null;

    const matchPaths = Object.values(paths).reduce(
        (match, path) =>
            match || pathToRegexp(path).test(window.location.pathname),
        false,
    );
    if (!matchPaths) {
        const parts = window.location.pathname.split('/');
        if (parts.length > 1) {
            domain = parts[1];
        }
    }

    return domain;
};

export const getSubdomain = () => {
    return window.location.href.match(new RegExp(`^[^.${ROOT_DOMAIN}]*`));
};

const domainGetter = USE_SUBDOMAINS ? getSubdomain : getDomainFromSubfolder;

/**
 *
 * @param {Object} props
 * @param { import('react').ReactElement } props.children
 */
export const withDomain = WrappedComponent => ({ ...props }) => {
    const domain = domainGetter();
    return <WrappedComponent domain={domain} {...props} />;
};

export default withDomain;
