import * as types from './chatActionTypes';

export const endSession = () => ({
    type: types.CHAT_SESSION_END,
});

export const startSession = session => ({
    type: types.CHAT_SESSION_START,
    payload: {
        session,
    },
});

export const startChat = ({ subject, description }) => ({
    type: types.CHAT_START_REQUEST,
    payload: {
        subject,
        description,
    },
});

export const startChatSuccess = () => ({
    type: types.CHAT_START_SUCCESS,
});

export const startChatError = ({ message }) => ({
    type: types.CHAT_START_ERROR,
    payload: {
        message,
    },
});

export const createCaseRequest = () => ({
    type: types.CHAT_CREATE_CASE_REQUEST,
});

export const createCaseSucess = () => ({
    type: types.CHAT_CREATE_CASE_SUCCESS,
});

export const createCaseError = error => ({
    type: types.CHAT_CREATE_CASE_ERROR,
    payload: {
        error,
    },
});

export const sendMessage = ({ text }) => ({
    type: types.CHAT_SEND_MESSAGE,
    payload: {
        text,
    },
});

export const receiveMessage = message => ({
    type: types.CHAT_RECEIVE_MESSAGE,
    payload: message,
});

export const receiveError = ({ error }) => ({
    type: types.CHAT_RECEIVE_ERROR,
    payload: {
        error,
    },
});

export const endChatByUser = () => ({
    type: types.CHAT_END_BY_USER,
    payload: {},
});

export const endedByAgent = () => ({
    type: types.CHAT_ENDED_BY_AGENT,
    payload: {},
});

export const subscribeAvailability = () => ({
    type: types.CHAT_SUBSCRIBE_AVAILABILITY,
});

export const unsubscribeAvailability = () => ({
    type: types.CHAT_UNSUBSCRIBE_AVAILABILITY,
});

export const setAgentStatus = ({ isOnline }) => ({
    type: types.CHAT_SET_AGENT_STATUS,
    payload: {
        isOnline,
    },
});
