import { put } from 'redux-saga/effects';
import { downloadData } from '../../api/downloadDataAPI';
import * as types from './downloadDataActionTypes';
import moment from 'moment';

export function* downloadDataSaga({ datesRange }) {
    try {
        const result = yield downloadData(datesRange);

        const url = window.URL.createObjectURL(result);
        const link = document.createElement('a');
        link.href = url;
        const fileName = `Patient Data ${moment().toLocaleString()}.csv`;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        yield put({
            type: types.DOWNLOAD_DATA_SUCCESS,
            result: result,
        });
    } catch (error) {
        yield put({
            type: types.DOWNLOAD_DATA_ERROR,
            error: error.message,
        });
    }
}
