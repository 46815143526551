import api, { buildSearchParams } from './api';

export const getCaseList = params =>
    api.get('/Cases', buildSearchParams(params));

export const updateAssignedUser = (caseId, assigneeId) =>
    api.put('/Cases', {
        id: caseId,
        assigneeExternalIdC: assigneeId,
        status: 'In Progress',
    });

export const resolve = caseId =>
    api.put('/Cases', {
        id: caseId,
        status: 'Closed',
    });

export const createCase = ({ subject, description, contactId }) =>
    api.post('/Cases/createFromLiveAgent', {
        subject,
        description,
        contactId,
    });
