import React, { Component } from 'react';
import PatientRosterDrawer from './PatientRosterDrawer';
import { Grid } from 'semantic-ui-react';

import { connect } from 'react-redux';
import * as patientRosterActions from '../../../store/patient-roster/patientRosterActions';
import PatientRosterStatistics from './PatientRosterStatistics';

class PatientRosterSidebar extends Component {
    componentDidMount() {
        if (!this.props.loaded && !this.props.loading) {
            this.props.getRosterOverview();
        }
    }

    render() {
        return (
            <>
                {/* //These are the charts in overview */}
                <Grid.Column
                    className='paddingV2rem shaded tablet-up-show'
                    mobile={16}
                    computer={5}
                    tablet={6}
                >
                    <PatientRosterStatistics />
                </Grid.Column>
                <PatientRosterDrawer />
            </>
        );
    }
}

const mapStateToProps = (state, ownProps = {}) => {
    return {
        loading: state.patientRoster.overviewLoading,
        loaded: state.patientRoster.overviewLoaded,
    };
};

const mapDispatchToProps = dispatch => ({
    getRosterOverview: () => dispatch(patientRosterActions.getOverview()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PatientRosterSidebar);
