import React, { Component } from 'react';
import moment from 'moment';
import {
    Button,
    Dropdown,
    Form,
    Grid,
    Header,
    Dimmer,
    Loader,
    List,
} from 'semantic-ui-react';
import queryString from 'query-string';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import NavMenu from '../../common/NavMenu';
import * as patientActions from '../../../store/patient/patientActions';
import PatientTimeline from '../patient-timeline/PatientTimeline';
import PatientDrawer from './PatientDrawer';
import PatientFlag from './PatientFlag';
import PatientStatistics from './PatientStatistics';

const filterOptions = [
    { key: 'all', text: 'See all', value: 'all' },
    { key: 'fill', text: 'Fills', value: 'fill' },
    { key: 'assessment', text: 'Clinical Assessment', value: 'assessment' },
];

export class PatientDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            condensed: false,
        };
    }

    componentDidMount() {
        if (this.props.location.search) {
            const searchFilters = queryString.parse(this.props.location.search);
            if (Object.keys(searchFilters).length) {
                for (const key in searchFilters) {
                    if (key === 'type') {
                        this.props.filterTimeline(searchFilters[key]);
                    }
                }
            }
        }
        this.props.getPatientProfile(this.props.match.params.id);
    }

    handleChange = value => {
        this.setState({ timezone: value });
        this.props.filterTimeline(value);
    };

    formatPhonenumeber(number) {
        var cleaned = ('' + number).replace(/\D/g, '');
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
        return number;
    }

    toggleCondensed = () => {
        this.setState({ condensed: !this.state.condensed });
    };

    renderPatientDetail = () => {
        const a = new moment();
        const b = moment(this.props.patientDob);
        const age = a.diff(b, 'years');
        const timelineToggle = this.state.condensed
            ? 'listView'
            : 'timelineView';
        const timelineButtonToggle = this.state.condensed
            ? 'timelineButton noBorder list-active'
            : 'timelineButton noBorder tl-active';

        const {
            shippingCity,
            shippingStreet,
            shippingState,
            shippingPostalCode,
        } = this.props.patientProfile;
        const address = [
            shippingStreet,
            shippingCity,
            shippingState,
            shippingPostalCode,
        ].filter(value => value);

        return this.props.loading || !this.props.patientProfile ? (
            <div>
                <Dimmer active inverted>
                    <Loader size='large'>Loading</Loader>
                </Dimmer>
            </div>
        ) : (
            <Grid className='vertical marginV0 grow'>
                <Grid.Row
                    className='paddingT2B1rem mobileNoPad alignCenter'
                    columns={2}
                    stackable='true'
                >
                    <Grid.Column>
                        <Header as='h2' className='patientName'>
                            {this.props.patientProfile.name}
                        </Header>
                    </Grid.Column>
                    <Grid.Column textAlign='right'>
                        <PatientFlag />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row
                    className='patientInfo paddingV1rem'
                    stretched
                    stackable='true'
                >
                    <Grid.Column computer={5} tablet={16} mobile={16}>
                        <List>
                            <List.Item>
                                <List.Header>Member ID</List.Header>
                                <List.Description>
                                    {this.props.patientProfile.memberPlan
                                        ? this.props.patientProfile.memberPlan
                                              .primaryMemberIdC
                                        : ''}
                                </List.Description>
                            </List.Item>
                            <List.Item>
                                <List.Header>Group ID</List.Header>
                                <List.Description>
                                    {this.props.patientProfile.memberPlan
                                        ? this.props.patientProfile.memberPlan
                                              .groupIdC
                                        : ''}
                                </List.Description>
                            </List.Item>
                            <List.Item>
                                <List.Header>ICD-10, Current</List.Header>
                                <List.Description>
                                    {this.props.patientProfile.icd10CodeC}
                                </List.Description>
                            </List.Item>
                        </List>
                    </Grid.Column>
                    <Grid.Column computer={5} tablet={16} mobile={16}>
                        <List>
                            <List.Item>
                                <List.Header>Age</List.Header>
                                <List.Description>{age}</List.Description>
                            </List.Item>
                            <List.Item>
                                <List.Header>DOB</List.Header>
                                <List.Description>
                                    {this.props.patientProfile.birthDateC}
                                </List.Description>
                            </List.Item>
                            <List.Item>
                                <List.Header>ICD-10 Description</List.Header>
                                <List.Description>
                                    {
                                        this.props.patientProfile
                                            .icd10DescriptionC
                                    }
                                </List.Description>
                            </List.Item>
                        </List>
                    </Grid.Column>
                    <Grid.Column computer={6} tablet={16} mobile={16}>
                        <List>
                            <List.Item>
                                <List.Header>Address</List.Header>
                                <List.Description>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: address.join(',&nbsp;'),
                                        }}
                                    />
                                </List.Description>
                            </List.Item>
                            <List.Item>
                                <List.Header>Phone Number</List.Header>
                                <List.Description>
                                    {this.formatPhonenumeber(
                                        this.props.patientProfile
                                            .personMobilePhone,
                                    )}
                                </List.Description>
                            </List.Item>
                        </List>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row
                    columns={2}
                    className='pageHeader activityHeader ruled noBGcolor paddingV0'
                    verticalAlign='middle'
                >
                    <Grid.Column computer={11} tablet={10} mobile={9}>
                        <Header as='h2'>
                            Activity Timeline
                            <Button
                                basic
                                icon
                                className={timelineButtonToggle}
                                onClick={this.toggleCondensed}
                            >
                                <svg
                                    className='inlineBlock iconList ui image'
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='16'
                                    height='15'
                                    viewBox='0 0 16 15'
                                >
                                    <g
                                        fill='none'
                                        fillRule='evenodd'
                                        stroke='#9B9B9B'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        strokeWidth='1.5'
                                        transform='translate(.479 .464)'
                                    >
                                        <circle
                                            cx='1.74'
                                            cy='1.739'
                                            r='1.305'
                                        />
                                        <circle
                                            cx='1.74'
                                            cy='6.959'
                                            r='1.305'
                                        />
                                        <circle
                                            cx='1.74'
                                            cy='12.179'
                                            r='1.305'
                                        />
                                        <path d='M5.69811047 1.73192146L13.8552088 1.73192146M5.69811047 6.91203244L13.8552088 6.91203244M5.69811047 12.1689897L13.8552088 12.1689897' />
                                    </g>
                                </svg>
                                <svg
                                    className='inlineBlock iconTimeline ui image'
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='15'
                                    height='15'
                                    viewBox='0 0 15 15'
                                >
                                    <g
                                        fill='none'
                                        fillRule='evenodd'
                                        stroke='#00B06D'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        strokeWidth='1.5'
                                        transform='translate(.765 .426)'
                                    >
                                        <rect
                                            width='5.22'
                                            height='5.22'
                                            x='.435'
                                            y='.433'
                                            rx='.87'
                                        />
                                        <rect
                                            width='5.22'
                                            height='5.22'
                                            x='.435'
                                            y='8.263'
                                            rx='.87'
                                        />
                                        <rect
                                            width='5.22'
                                            height='5.22'
                                            x='8.265'
                                            y='.433'
                                            rx='.87'
                                        />
                                        <rect
                                            width='5.22'
                                            height='5.22'
                                            x='8.265'
                                            y='8.263'
                                            rx='.87'
                                        />
                                    </g>
                                </svg>
                            </Button>
                        </Header>
                    </Grid.Column>
                    <Grid.Column
                        computer={5}
                        tablet={6}
                        mobile={7}
                        textAlign='right'
                    >
                        <Form className='tablet-up-show'>
                            <Form.Group inline>
                                <Form.Field width={3}>
                                    <label>Filter</label>
                                </Form.Field>
                                <Form.Select
                                    fluid
                                    onChange={(e, { value }) =>
                                        this.handleChange(value)
                                    }
                                    options={filterOptions}
                                    defaultValue={this.props.filter}
                                    placeholder='Select'
                                    width={13}
                                />
                            </Form.Group>
                        </Form>
                        <Dropdown
                            inline
                            onChange={(e, { value }) =>
                                this.handleChange(value)
                            }
                            defaultValue={filterOptions[0].value}
                            className='mainFilter mobile-show'
                            options={filterOptions}
                        />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row className='grow'>
                    <Grid.Column width={16} className={timelineToggle}>
                        <PatientTimeline patient={this.props.patientProfile} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    };

    render() {
        return (
            <Grid className='full-page' stretched>
                <Grid.Row className='full-page'>
                    <NavMenu />
                    <Grid.Column className='grow'>
                        <Grid className='vertical marginV0' stackable>
                            <Grid.Row
                                className='pageHeader'
                                verticalAlign='middle'
                            >
                                <Grid.Column width={16}>
                                    <Header as='h1'>Patient Profile</Header>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row className='paddingV0 grow'>
                                <Grid.Column
                                    computer={10}
                                    tablet={10}
                                    className='flexDiv flexColumn'
                                >
                                    {this.renderPatientDetail()}
                                </Grid.Column>
                                <Grid.Column
                                    className='paddingH0 lightGrayBG tablet-up-show'
                                    computer={6}
                                    tablet={6}
                                >
                                    <div className='reportCard'>
                                        <PatientStatistics
                                            profile={this.props.patientProfile}
                                        />
                                    </div>
                                </Grid.Column>
                                <PatientDrawer
                                    profile={this.props.patientProfile}
                                />
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }

    static propTypes = {
        getPatientProfile: PropTypes.func.isRequired,
        getPatientFillInfo: PropTypes.func,
        patientProfile: PropTypes.object.isRequired,
        memberPlan: PropTypes.object.isRequired,
        primaryMemberIdC: PropTypes.string.isRequired,
    };
}

const mapStateToProps = (state = {}) => {
    return {
        loading: state.patient.profileLoad,
        patientDob: state.patient.patientProfile.birthDateC,
        patientProfile: state.patient.patientProfile,
        fillInformation: state.patient.fillInformation,
        filter: state.patient.filter,
    };
};

const mapDispatchToProps = dispatch => ({
    getPatientProfile: accountId => {
        return dispatch(patientActions.patientProfileRequest(accountId));
    },
    filterTimeline: filter => {
        return dispatch(patientActions.filterTimeline(filter));
    },
});
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PatientDetail);
