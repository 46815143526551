import { connect } from 'react-redux';
import { compose, setDisplayName, flattenProp } from 'recompose';
import withReport from './withReport';
import reportDonutSelector from '../../../store/report-data/chart-selectors/donutSelector';

const mapStateToProps = (state, ownProps) => ({
    data: reportDonutSelector(state, ownProps),
});

export const withReportDonut = WrappedComponent => {
    const enhance = compose(
        withReport,
        connect(mapStateToProps),
        flattenProp('data'),
        setDisplayName('WithReportDonut'),
    );
    return enhance(WrappedComponent);
};
export default withReportDonut;
