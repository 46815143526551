import {
    put,
    takeLatest,
    take,
    cancel,
    fork,
    select,
} from 'redux-saga/effects';
import { delay } from 'redux-saga';

import * as api from '../../api/notificationsApi';
import * as types from './actionTypes';
import * as actions from './actions';
import { getAccount } from '../auth/authSelectors';

export function* fetchSaga() {
    try {
        // TODO: check if is authenticated instead
        const account = yield select(getAccount);
        if (!account) {
            return;
        }
        const notificaitons = yield api.getNotifications();
        yield put(
            actions.success({
                data: notificaitons,
            }),
        );
    } catch (error) {
        yield put(
            actions.error({
                errorMessage: error.message,
            }),
        );
    }
}

export function* refreshSaga() {
    while (true) {
        try {
            yield put(actions.fetch());
            yield delay(60000);
        } catch (e) {
            console.error(e);
        }
    }
}

export function* viewNotification({ payload: { type, data } }) {
    try {
        yield api.viewNotification({ type, data });
        yield put(actions.refresh());
    } catch (e) {
        console.error(e);
    }
}

export function* requestFetch() {
    yield put(actions.fetch());
}

export function* debounceFetchSaga() {
    let task;
    while (true) {
        yield take(types.GET_NOTIFICATIONS_REQUEST);
        if (task) {
            yield cancel(task);
        }
        task = yield fork(fetchSaga);
    }
}

export function* rootSaga() {
    yield takeLatest(types.REFRESH_NOTIFICATIONS, requestFetch);
    yield takeLatest(types.VIEW_NOTIFICATION, viewNotification);
    yield fork(refreshSaga);
    yield fork(debounceFetchSaga);
}
export default rootSaga;
