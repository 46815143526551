import { put, select, cancel, fork, call } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import * as authSelectors from '../auth/authSelectors';
import { getReportData } from '../../api/reportApi';
import * as reportDataSelectors from './reportDataSelectors';
import * as types from './reportDataActionTypes';

const ids = {};
const tasks = {};

export function* fetchReportData({ reportId, reportDate }) {
    try {
        yield call(delay, 50);
        const accountId = yield select(authSelectors.getAccountId);
        const report = yield getReportData(reportId, accountId, reportDate);
        console.log(report);
        if (report.length > 0) {
            yield put({
                type: types.REPORT_DATA_SUCCESS,
                result: report[0],
                reportId,
                reportDate,
            });
        } else {
            yield put({
                type: types.REPORT_DATA_ERROR,
                error: 'No data for this period',
                reportId,
                reportDate,
            });
        }
    } catch (error) {
        yield put({
            type: types.REPORT_DATA_ERROR,
            error: error.message,
            reportId,
            reportDate,
        });
    }
    delete ids[reportDate][reportId];
    delete tasks[reportDate][reportId];
}

export function* reportDataSaga({ reportId, defaultDateFilterC }) {
    try {
        const reportDate = yield select(
            reportDataSelectors.reportDateFilterSelector,
            {
                defaultDateFilterC,
            },
        );
        if (!ids[reportDate]) {
            ids[reportDate] = {};
        }
        if (!tasks[reportDate]) {
            tasks[reportDate] = {};
        }
        ids[reportDate][reportId] = true;
        if (tasks[reportDate][reportId]) {
            yield cancel(tasks[reportDate][reportId]);
        }
        tasks[reportDate][reportId] = yield fork(fetchReportData, {
            reportId,
            reportDate,
        });
    } catch (error) {
        yield put({
            type: types.REPORT_DATA_ERROR,
            error: error.message,
            reportId,
        });
    }
}
