import { connect } from 'react-redux';
import { compose, setDisplayName, flattenProp } from 'recompose';
import withReport from './withReport';
import reportBarSelector from '../../../store/report-data/chart-selectors/barSelector';

const mapStateToProps = (state, ownProps) => ({
    data: reportBarSelector(state, ownProps),
});

export const withReportBar = WrappedComponent => {
    const enhance = compose(
        withReport,
        connect(mapStateToProps),
        flattenProp('data'),
        setDisplayName('WithReportBar'),
    );
    return enhance(WrappedComponent);
};
export default withReportBar;
