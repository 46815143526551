import * as types from './orderStatusActionTypes';

export const filterPatientOrderStatus = ({
    name,
    value,
    like,
    date,
    dateFormat,
}) => ({
    type: types.PATIENT_ORDER_STATUS_FILTER,
    payload: {
        name,
        value,
        like,
        date,
        dateFormat,
    },
});

export const patientOrderStatusRequest = () => ({
    type: types.PATIENT_ORDER_STATUS_REQUEST,
});

export const patientOrderStatusSuccess = ({ total, data }) => ({
    type: types.PATIENT_ORDER_STATUS_SUCCESS,
    total,
    data,
});
export const patientOrderStatusError = error => ({
    type: types.PATIENT_ORDER_STATUS_ERROR,
    error,
});

export const patientOrderStatusCountRequest = () => ({
    type: types.PATIENT_ORDER_STATUS_COUNT_REQUEST,
});

export const patientOrderStatusCountSuccess = ({ statusCount }) => ({
    type: types.PATIENT_ORDER_STATUS_COUNT_SUCCESS,
    statusCount,
});

export const patientOrderStatusCountError = error => ({
    type: types.PATIENT_ORDER_STATUS_COUNT_ERROR,
    error,
});

export const resetPatientOrderStatusFilter = () => ({
    type: types.PATIENT_ORDER_STATUS_FILTER_RESET,
});

export const goToNextPage = () => ({
    type: types.PATIENT_ORDER_STATUS_GO_TO_NEXT_PAGE,
});

export const goToPrevPage = () => ({
    type: types.PATIENT_ORDER_STATUS_GO_TO_PREV_PAGE,
});

export const goToFirstPage = () => ({
    type: types.PATIENT_ORDER_STATUS_GO_TO_FIRST_PAGE,
});

export const goToLastPage = () => ({
    type: types.PATIENT_ORDER_STATUS_GO_TO_LAST_PAGE,
});

export const sortOrderStatus = sort => ({
    type: types.PATIENT_ORDER_STATUS_SORT,
    sort,
});

export const setPageSize = ({ pageSize }) => ({
    type: types.SET_PAGE_SIZE,
    payload: {
        pageSize,
    },
});
