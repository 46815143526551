import * as types from './downloadDataActionTypes';

export default (
    state = {
        error: null,
        loading: false,
        loaded: false,
        result: {},
    },
    action,
) => {
    switch (action.type) {
        case types.DOWNLOAD_DATA_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case types.DOWNLOAD_DATA_SUCCESS: {
            return {
                ...state,
                loading: false,
                result: action.result,
            };
        }
        case types.DOWNLOAD_DATA_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
};
