import * as types from './authActionTypes';

export const loginRequest = (email, password) => ({
    type: types.LOGIN_REQUEST,
    email: email.toLowerCase(),
    password: password,
});

export const passwordResetRequest = email => ({
    type: types.PASSWORD_RESET_REQUEST,
    email: email.toLowerCase(),
});

export const logOutRequest = () => ({
    type: types.LOGOUT_REQUEST,
});

export const updateContact = ({ contact }) => ({
    type: types.UPDATE_CONTACT,
    payload: {
        contact,
    },
});
