import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Image, Menu } from 'semantic-ui-react';
import backgroundImage from '../../assets/images/m-nav-bg.svg';
// import summacareLogo from '../../assets/images/client-logo.svg';

export class MobileNavMenu extends Component {
    /**
     * Default constructor to set up state and functions
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            selectedNavOption: this.props.selectedNavOption,
            accessMap: props.accessMap,
            currentRole: props.currentRole,
            currentId: props.currentId,
        };
    }

    render() {
        let clientLogo = this.props.account ? this.props.account.logoUrlC : '';
        return (
            <Menu
                className='mobile-nav mobile-show'
                icon
                style={{ backgroundImage: `url('${backgroundImage}')` }}
            >
                <Menu.Item
                    as={NavLink}
                    name='dashboard'
                    to='/dashboard'
                    activeClassName='active'
                >
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='25'
                        height='25'
                        viewBox='0 0 25 25'
                    >
                        <g fill='none' fillRule='evenodd'>
                            <rect
                                width='25'
                                height='25'
                                fill='#D8D8D8'
                                fillOpacity='.01'
                                fillRule='nonzero'
                            />
                            <path
                                stroke='#9B9B9B'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                strokeWidth='1.5'
                                d='M10.5 12.526C10.5011063 13.0657455 10.0647441 13.5043455 9.525 13.506L1.725 13.506C1.46570961 13.5054704 1.21726432 13.4018909 1.03438747 13.2180762.851510606 13.0342616.749203274 12.7852897.75 12.526L.75 1.736C.749469024 1.47661811.851999132 1.22764895 1.03503475 1.04386318 1.21807038.860077415 1.46661811.756529891 1.726.756L9.526.769C10.0653531.771204824 10.5011075 1.20964354 10.5 1.749L10.5 12.526zM23.246 6.544C23.242147 7.07877668 22.8057802 7.50932528 22.271 7.506L14.471 7.506C14.2140963 7.50786272 13.9669895 7.40752294 13.7841113 7.22708311 13.6012331 7.04664328 13.4975857 6.80090554 13.496 6.544L13.496 1.731C13.4993042 1.19677676 13.9347737.766230312 14.469.769L22.269.756C22.8043344.752118569 23.2415988 1.18266962 23.246 1.718L23.246 6.544zM10.5 22.291C10.4984136 22.5485267 10.3945876 22.7948751 10.2113634 22.9758488 10.0281393 23.1568225 9.78052669 23.2575962 9.523 23.256L1.723 23.243C1.18822289 23.2452147.752754866 22.8137746.75 22.279L.75 17.47C.751322053 17.2128299.854820953 16.9667335 1.03769877 16.7859189 1.22057659 16.6051043 1.46783145 16.5044042 1.725 16.506L9.525 16.506C10.0603308 16.5032303 10.4966958 16.9346722 10.5 17.47L10.5 22.291zM14.472 23.256C14.2124444 23.2554704 13.9637448 23.1517856 13.7806804 22.9677834 13.597616 22.7837811 13.4952033 22.5345549 13.496 22.275L13.496 11.487C13.4952031 11.2276179 13.5974787 10.978543 13.7803269 10.7945695 13.9631751 10.6105961 14.2116179 10.5067944 14.471 10.506L22.271 10.506C22.5303821 10.5067944 22.7788249 10.6105961 22.9616731 10.7945695 23.1445213 10.978543 23.2467969 11.2276179 23.246 11.487L23.246 22.262C23.2471092 22.8015202 22.8115143 23.2402457 22.272 23.243L14.472 23.256z'
                                transform='translate(.534 .468)'
                            />
                        </g>
                    </svg>
                </Menu.Item>

                <Menu.Item
                    as={NavLink}
                    name='reports'
                    to='/reports'
                    activeClassName='active'
                >
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='25'
                        height='25'
                        viewBox='0 0 25 25'
                    >
                        <g fill='none' fillRule='evenodd'>
                            <rect
                                width='25'
                                height='25'
                                fill='#D8D8D8'
                                fillOpacity='.01'
                                fillRule='nonzero'
                            />
                            <path
                                stroke='#9B9B9B'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                strokeWidth='1.5'
                                d='M.75 19.75L23.25 19.75M6 9.25L3 9.25C2.58578644 9.25 2.25 9.58578644 2.25 10L2.25 19.75 6.75 19.75 6.75 10C6.75 9.58578644 6.41421356 9.25 6 9.25zM13.5.25L10.5.25C10.0857864.25 9.75.585786438 9.75 1L9.75 19.75 14.25 19.75 14.25 1C14.25.585786438 13.9142136.25 13.5.25zM21 4.75L18 4.75C17.5857864 4.75 17.25 5.08578644 17.25 5.5L17.25 19.75 21.75 19.75 21.75 5.5C21.75 5.08578644 21.4142136 4.75 21 4.75z'
                                transform='translate(.477 2.355)'
                            />
                        </g>
                    </svg>
                </Menu.Item>

                <Menu.Item className='payerBrand clientLogoMobile'>
                    <Image src={clientLogo} width='87' alt='payer logo' />
                </Menu.Item>

                <Menu.Item
                    as={NavLink}
                    name='order-status'
                    to='/patient-order-status'
                    activeClassName='active'
                >
                    <svg
                        width='25px'
                        height='25px'
                        viewBox='0 0 25 25'
                        version='1.1'
                        xmlns='http://www.w3.org/2000/svg'
                    >
                        <g
                            id='Symbols'
                            stroke='none'
                            strokeWidth='1'
                            fill='none'
                            fillRule='evenodd'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                        >
                            <g
                                id='drugs-active'
                                stroke='#9B9B9B'
                                strokeWidth='1.5'
                            >
                                <g
                                    id='icon-drugs'
                                    transform='translate(0.433949, 0.539595)'
                                >
                                    <path d='M0.75,11 L23.25,11' id='Shape' />
                                    <path
                                        d='M21.7500002,11 L2.24999983,11 C2.24952679,14.6417461 4.28048409,17.9796138 7.515,19.653 C7.70105359,19.749908 7.8381711,19.9201266 7.89323897,20.1225485 C7.94830684,20.3249704 7.91631884,20.5411933 7.805,20.719 C7.56174435,21.1116314 7.34525285,21.5202257 7.157,21.942 C7.05245689,22.1741126 7.07279059,22.4434087 7.21100187,22.6571916 C7.34921315,22.8709746 7.58643075,23.0000546 7.841,23 L16.159,23 C16.4133599,22.9995312 16.6501952,22.8703433 16.7882931,22.6567358 C16.9263909,22.4431283 16.94698,22.1741365 16.843,21.942 C16.6547471,21.5202257 16.4382556,21.1116314 16.195,20.719 C16.0839882,20.5411162 16.0521747,20.3250005 16.107217,20.1226725 C16.1622593,19.9203446 16.2991721,19.75013 16.485,19.653 C19.7195159,17.9796138 21.7504732,14.6417461 21.7500002,11 Z'
                                        id='Shape'
                                    />
                                    <path
                                        d='M14.25,8 L18.207,1.405 C18.5245029,0.876061292 19.1668235,0.638672893 19.752,0.834 C20.08338,0.944420618 20.3572681,1.18205216 20.5133265,1.49454404 C20.6693848,1.80703592 20.6948097,2.16874995 20.584,2.5 L18.75,8'
                                        id='Shape'
                                    />
                                </g>
                            </g>
                        </g>
                    </svg>
                </Menu.Item>

                <Menu.Item
                    as={NavLink}
                    name='patients'
                    to='/patient-roster'
                    activeClassName='active'
                >
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='25'
                        height='25'
                        viewBox='0 0 25 25'
                    >
                        <g fill='none' fillRule='evenodd'>
                            <rect
                                width='25'
                                height='25'
                                fill='#D8D8D8'
                                fillOpacity='.01'
                                fillRule='nonzero'
                            />
                            <g
                                stroke='#9B9B9B'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                strokeWidth='1.5'
                                transform='translate(.513 .578)'
                            >
                                <circle cx='4.5' cy='6' r='2.25' />
                                <path d='M4.5,9.75 C2.42893219,9.75 0.75,11.4289322 0.75,13.5 L0.75,15.75 L2.25,15.75 L3,21.75 L6,21.75' />
                                <circle cx='19.5' cy='6' r='2.25' />
                                <path d='M19.5,9.75 C21.5710678,9.75 23.25,11.4289322 23.25,13.5 L23.25,15.75 L21.75,15.75 L21,21.75 L18,21.75' />
                                <circle cx='12' cy='3.75' r='3' />
                                <path d='M17.25,13.5 C17.25,10.6005051 14.8994949,8.25 12,8.25 C9.10050506,8.25 6.75,10.6005051 6.75,13.5 L6.75,15.75 L9,15.75 L9.75,23.25 L14.25,23.25 L15,15.75 L17.25,15.75 L17.25,13.5 Z' />
                            </g>
                        </g>
                    </svg>
                </Menu.Item>
            </Menu>
        );
    }
}

const mapStatesToProps = (state, ownProps = {}) => {
    return {
        contact: state.auth.contact,
        account: state.auth.account,
        accessMap: state.auth.accessMap,
    };
};

export default withRouter(connect(mapStatesToProps)(MobileNavMenu));
