import { connect } from 'react-redux';
import { compose, setDisplayName, flattenProp } from 'recompose';
import withReport from './withReport';
import reportLineSelector from '../../../store/report-data/chart-selectors/lineSelector';

const mapStateToProps = (state, ownProps) => ({
    data: reportLineSelector(state, ownProps),
});

export const withReportLine = WrappedComponent => {
    const enhance = compose(
        withReport,
        connect(mapStateToProps),
        flattenProp('data'),
        setDisplayName('WithReportLine'),
    );
    return enhance(WrappedComponent);
};
export default withReportLine;
