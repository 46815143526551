import api from './api';

/**
 *
 * @param {string} accountId
 * @param {*} timeZone
 */
export const setAccountTimeZone = (accountId, timeZone) =>
    api.put('/Accounts', {
        id: accountId,
        userTimezoneC: timeZone,
    });

export const lockContact = contactId =>
    api.post(`/Accounts/contacts/${contactId}/lock`);

export const unlockContact = contactId =>
    api.post(`/Accounts/contacts/${contactId}/unlock`);
