import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Header } from 'semantic-ui-react';

import ReportDetailMeta from '../meta';

export class ReportDetailDrawer extends PureComponent {
    static propTypes = {
        sfid: PropTypes.string.isRequired,
        influencers: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
        };
    }

    toggleExpanded = () => {
        this.setState(state => ({
            expanded: !state.expanded,
        }));
    };

    render() {
        const { sfid, influencers } = this.props;
        const classToggle = this.state.expanded
            ? 'mobile-show drawer active'
            : 'mobile-show drawer inactive';
        const buttonToggle = this.state.expanded ? 'Hide' : 'Expand';
        const dimmerToggle = this.state.expanded
            ? 'drawerDimmer mobile-show active'
            : 'drawerDimmer mobile-show';

        return (
            <>
                <div className={classToggle}>
                    <Grid
                        columns={2}
                        className='drawer-header'
                        verticalAlign='middle'
                        onClick={this.toggleExpanded}
                    >
                        <Grid.Column>
                            <Header as='h4'>Charts</Header>
                        </Grid.Column>
                        <Grid.Column textAlign='right'>
                            <Button
                                basic
                                compact
                                size='mini'
                                className='noBorder'
                            >
                                {buttonToggle}
                            </Button>
                        </Grid.Column>
                    </Grid>
                    <div className='drawer-content'>
                        <ReportDetailMeta
                            influencers={influencers}
                            sfid={sfid}
                        />
                    </div>
                </div>
                <div className={dimmerToggle} />
            </>
        );
    }
}
export default ReportDetailDrawer;
