/**
 * Check to see if current user exists in the local storage
 * @returns {boolean}
 */
export function isAuthenticated() {
    return !!localStorage.getItem('access_token');
}

/**
 * Get the current user session
 * @param authResult
 */
export function getSession() {
    return localStorage.getItem('access_token');
}

/**
 * Set the current user session
 * @param authResult
 */
export function setSession(authResult) {
    localStorage.setItem('access_token', authResult);
}

/**
 * Set current user profile
 * @param profile
 */
export function setProfile(profile) {
    window.localStorage.setItem('profile', JSON.stringify(profile));
}

/**
 * Set current user account
 * @param account
 */
export function setAccount(account) {
    window.localStorage.setItem('account', JSON.stringify(account));
}

/**
 * Set current user contact
 * @param contact
 */
export function setContact(contact) {
    window.localStorage.setItem('contact', JSON.stringify(contact));
}

/**
 * Set current user role
 * @param role
 */
export function setRole(role) {
    window.localStorage.setItem('role', JSON.stringify(role));
}

/**
 * Set current user
 * @param user
 */
export function setUser(user) {
    window.localStorage.setItem('user', JSON.stringify(user));
}

/**
 * Remove local session -- logout
 */
export function removeSession() {
    // Clear access token and ID token from local storage
    localStorage.clear();
}

/**
 * Get the current profile, used for local functions calls
 * @returns {null}
 */
export function profile() {
    const profile = localStorage.getItem('profile');
    if (profile !== undefined && profile !== null) {
        return profile;
    } else {
        return null;
    }
}
/**
 * Get the current account, used for local functions calls
 * @returns {null}
 */
export function account() {
    const account = localStorage.getItem('account');
    if (account !== undefined && account !== null) {
        return account;
    } else {
        return null;
    }
}

/**
 * Get the current contact, used for local functions calls
 * @returns {null}
 */
export function contact() {
    const contact = localStorage.getItem('contact');
    if (contact !== undefined && contact !== null) {
        return contact;
    } else {
        return null;
    }
}

/**
 * Get the current Role, used for local functions calls
 * @returns {null}
 */
export function role() {
    const role = localStorage.getItem('role');
    if (role !== undefined && role !== null) {
        return role;
    } else {
        return null;
    }
}

/**
 * Get the current Role, used for local functions calls
 * @returns {null}
 */
export function user() {
    const user = localStorage.getItem('user');
    if (user !== undefined && user !== null) {
        return user;
    } else {
        return null;
    }
}
