export const types = {
    REQUEST: 'REQUEST',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
    FILTER: 'FILTER',
    RESET_FILTER: 'RESET_FILTER',
    SET_PAGE_SIZE: 'SET_PAGE_SIZE',
    GO_TO_NEXT_PAGE: 'GO_TO_NEXT_PAGE',
    GO_TO_PREV_PAGE: 'GO_TO_PREV_PAGE',
    GO_TO_FIRST_PAGE: 'GO_TO_FIRST_PAGE',
    GO_TO_LAST_PAGE: 'GO_TO_LAST_PAGE',
    GO_TO_PAGE: 'GO_TO_PAGE',
    SORT: 'SORT',
};

export const getPrefixedActionType = (prefix, type) =>
    `${prefix.toUpperCase()}_${types[type]}`;

export const getActionTypes = prefix => {
    const actionTypes = {};
    for (const type in types) {
        const prefixedType = getPrefixedActionType(prefix, type);
        actionTypes[prefixedType] = prefixedType;
    }
    return actionTypes;
};

export default getActionTypes;
