import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Total from './Total';

const RADIAN = Math.PI / 180;

export const withLabel = ({
    showValues,
    showPercentages,
    total: { show: showTotal, value: totalValue },
    totalRendered = false,
}) => ({ cx, cy, midAngle, innerRadius, outerRadius, percent, value }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    if (!showValues && !showPercentages) {
        return null;
    }
    return (
        <Fragment>
            <text
                x={x}
                y={y}
                fill='white'
                textAnchor='middle'
                dominantBaseline='central'
            >
                {showValues ? `${value}\n` : ''}
                {showPercentages
                    ? `${showValues ? '( ' : ''}${percent}${
                          showValues ? '( ' : ''
                      }`
                    : ''}
            </text>
            {showTotal && !totalRendered && (
                <Total x={cx} y={cy} value={totalValue} />
            )}
            {(totalRendered = true)}
        </Fragment>
    );
};

withLabel.propTypes = PropTypes.shape({
    showValues: PropTypes.bool.isRequired,
    showPercentages: PropTypes.bool.isRequired,
});

export default withLabel;
