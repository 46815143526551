export const PATIENT_FLAGGED_REQUEST = 'PATIENT_FLAGGED_REQUEST';
export const PATIENT_FLAGGED_SUCCESS = 'PATIENT_FLAGGED_SUCCESS';
export const PATIENT_FLAGGED_ERROR = 'PATIENT_FLAGGED_ERROR';

export const PATIENT_PROFILE_REQUEST = 'PATIENT_PROFILE_REQUEST';
export const PATIENT_PROFILE_SUCCESS = 'PATIENT_PROFILE_SUCCESS';
export const PATIENT_PROFILE_ERROR = 'PATIENT_PROFILE_ERROR';

export const FILTER_TIMELINE_REQUEST = 'FILTER_TIMELINE_REQUEST';

export const PATIENT_FILL_INFO_REQUEST = 'PATIENT_FILL_INFO_REQUEST';
export const PATIENT_FILL_INFO_SUCCESS = 'PATIENT_FILL_INFO_SUCCESS';
export const PATIENT_FILL_INFO_ERROR = 'PATIENT_FILL_INFO_ERROR';

export const RESOLVE_ASSESSMENT = 'RESOLVE_ASSESSMENT';
