import AccountUser from '../../account/AccountUser';
import CaseEvent from '../view';

export const CASE_TABLE_COLUMNS = [
    {
        accessor: 'caseNumber',
        label: 'Case ID',
        priorityLevel: 1,
        position: 1,
        minWidth: 150,
        CustomComponent: CaseEvent,
    },
    {
        accessor: 'patient.name',
        label: 'Patient',
        priorityLevel: 2,
        position: 2,
        minWidth: 150,
    },
    {
        accessor: 'subject',
        label: 'Area of concern',
        priorityLevel: 3,
        position: 3,
        minWidth: 150,
    },
    {
        accessor: 'status',
        label: 'Status',
        priorityLevel: 4,
        position: 4,
        minWidth: 150,
    },
    {
        accessor: 'createdDate',
        label: 'Created Date',
        priorityLevel: 5,
        position: 5,
        minWidth: 150,
    },
    {
        accessor: 'closedDate',
        label: 'Completed Date',
        priorityLevel: 6,
        position: 6,
        minWidth: 150,
    },
    {
        accessor: 'assignee.name',
        label: 'Assignment',
        priorityLevel: 7,
        position: 7,
        minWidth: 150,
        CustomComponent: AccountUser,
    },
];

export default CASE_TABLE_COLUMNS;
