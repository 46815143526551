import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Divider, Grid, Header, Image } from 'semantic-ui-react';
import NavMenu from '../common/NavMenu';
import AccountDrawer from './OrgList/AccountOrgListMobile';
import AccountOrgList from './OrgList/AccountOrgList';
import AccountSecurity from './security/AccountSecurity';
import AccountBio from './bio/AccountBio';
import * as fileActions from '../../store/file/fileActions';
import DefaultUserImage from '../../assets/images/defaultUserImage.png';

const SIZE_LIMIT = 5 * 1024 * 1024;

export class Account extends Component {
    static propTypes = {
        account: PropTypes.object,
        user: PropTypes.object.isRequired,
        getAccount: PropTypes.func,
        profile: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            error: false,
        };
        this.validExt = ['png', 'jpeg', 'jpg'];
    }

    handleSelectFile = files => {
        this.setState({ error: false });
        if (files && files.length > 0) {
            const file = files[0];
            const fileExt = file.name.match(/\.([^.]+)$/)[1];
            if (this.validExt.includes(fileExt)) {
                if (file && file.size) {
                    if (file.size < SIZE_LIMIT) {
                        this.props.uploadFile(file, this.props.user.id);
                    } else {
                        this.setState({ error: true });
                    }
                }
            } else {
                this.setState({ error: true });
            }
        }
    };

    render() {
        let file = this.props.file.file
            ? this.props.file.file
            : this.props.user.tflRampAvatarUrlC
            ? this.props.user.tflRampAvatarUrlC
            : DefaultUserImage;
        // console.log(this.props.file.file)
        // console.log(this.props.user.tflRampAvatarUrlC)
        return (
            <Grid className='full-page' stretched>
                <Grid.Row className='full-page'>
                    <NavMenu />
                    <Grid.Column className='stretch-col'>
                        <Grid className='vertical marginV0' stackable>
                            <Grid.Row
                                columns={1}
                                className='pageHeader'
                                verticalAlign='middle'
                            >
                                <Grid.Column computer={11} tablet={10}>
                                    <Header as='h1'>Account</Header>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row className='paddingV0 grow'>
                                <Grid.Column
                                    mobile={16}
                                    computer={11}
                                    tablet={10}
                                >
                                    <Grid
                                        className='paddingV1rem'
                                        padded='vertically'
                                        stretched
                                    >
                                        <Grid.Column
                                            mobile={6}
                                            computer={4}
                                            tablet={4}
                                            verticalAlign='top'
                                            className='account-avatar'
                                        >
                                            <Grid
                                                columns={1}
                                                className='paddingV1rem'
                                                padded='vertically'
                                                stackable
                                            >
                                                <Grid.Column>
                                                    <Image src={file} rounded />
                                                    <div className='paddingV1rem'>
                                                        <label
                                                            className='ui fluid button primary'
                                                            //for='profilePhoto'
                                                            htmlFor='profilePhoto'
                                                        >
                                                            Update photo
                                                            <input
                                                                type='file'
                                                                name='profilePhoto'
                                                                className='inputfile'
                                                                accept='image/png,image/jpeg,image/jpg,image/gif'
                                                                onChange={e =>
                                                                    this.handleSelectFile(
                                                                        e.target
                                                                            .files,
                                                                    )
                                                                }
                                                            />
                                                        </label>
                                                    </div>
                                                </Grid.Column>
                                            </Grid>
                                        </Grid.Column>
                                        <Grid.Column
                                            mobile={16}
                                            computer={12}
                                            tablet={12}
                                            className='account-details'
                                        >
                                            <Grid
                                                columns={8}
                                                padded='vertically'
                                                stretched
                                                stackable
                                            >
                                                <Grid.Row>
                                                    <Grid.Column width={16}>
                                                        <Header
                                                            as='h2'
                                                            dividing
                                                        >
                                                            {
                                                                this.props
                                                                    .contact
                                                                    .name
                                                            }
                                                        </Header>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <AccountBio
                                                    account={this.props.contact}
                                                />
                                                <Divider />
                                                <AccountSecurity
                                                    account={this.props.account}
                                                />
                                            </Grid>
                                        </Grid.Column>
                                    </Grid>
                                </Grid.Column>
                                <AccountOrgList
                                    list={this.props.account.list}
                                />
                                <AccountDrawer list={this.props.account.list} />
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

const mapStateToProps = (state, ownProps = {}) => {
    return {
        account: state.auth.account,
        contact: state.auth.contact,
        user: state.auth.user,
        file: state.file,
        profile: state.auth.profile,
    };
};

const mapDispatchToProps = dispatch => ({
    uploadFile: (file, userId) =>
        dispatch(fileActions.uploadFile(file, userId)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Account);
