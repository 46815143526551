import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import withLoading from '../withLoading';
import { reportDataRequest } from '../../../store/report-data/reportDataActions';
import { compose, setPropTypes, flattenProp, withProps } from 'recompose';
import { reportStatusSelector } from '../../../store/report-data/reportDataSelectors';
import EmptyReport from '../charts/empty';

const mapStateToProps = (state, ownProps) => ({
    status: reportStatusSelector(state, ownProps),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    load: () =>
        dispatch(reportDataRequest(ownProps.sfid, ownProps.defaultDateFilterC)),
});

export const withReport = WrappedComponent => {
    const enhance = compose(
        setPropTypes({
            sfid: PropTypes.string.isRequired,
            defaultDateFilterC: PropTypes.string,
        }),
        connect(
            mapStateToProps,
            mapDispatchToProps,
        ),
        flattenProp('status'),
        withProps({
            ErrorComponent: EmptyReport,
        }),
        withLoading,
    );
    return enhance(WrappedComponent);
};
export default withReport;
