import { put, select, fork } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import queryString from 'query-string';

import * as types from './reportPathActionTypes';
import { getReportPathById, getChildReportPath } from '../../api/reportPathApi';
import {
    currentReportPathSelector,
    getReportPathData,
} from './reportPathSelectors';
import {
    reportDateFilterSelector,
    getReportDateFilterColumn, //reportDataSelector,
} from /**/ '../report-data/reportDataSelectors';

export function* fetchReportPathSaga({ id }) {
    try {
        const reportPath = yield getReportPathById(id);
        if (reportPath.length > 0) {
            yield put({
                type: types.FETCH_REPORT_PATH_SUCCESS,
                result: reportPath[0],
                id,
            });
        } else {
            yield put({
                type: types.FETCH_REPORT_PATH_SUCCESS,
                result: null,
                id,
            });
        }
    } catch (error) {
        yield put({
            type: types.FETCH_REPORT_PATH_ERROR,
            error: error.message,
            id,
        });
    }
}

export function* fetchReportPathChildSaga({ filterColumn, filterValue }) {
    try {
        const currentReportPathId = yield select(currentReportPathSelector);
        if (!currentReportPathId) {
            return;
        }
        const currentReportPathData = yield select(
            getReportPathData,
            currentReportPathId,
        );
        try {
            const reportPath = yield getChildReportPath(currentReportPathId);

            if (reportPath.length > 0) {
                const result = reportPath[0];
                yield put({
                    type: types.FETCH_REPORT_PATH_SUCCESS,
                    result: result,
                    id: result.sfid,
                });
                yield fork(gotoReportPathSaga, {
                    id: result.sfid,
                    parent: currentReportPathData.reportIdC,
                    filterColumn,
                    filterValue,
                });
            } else {
                yield fork(gotoReportPathSaga, {
                    id: null,
                    parent: currentReportPathData.reportIdC,
                    filterColumn,
                    filterValue,
                });
            }
        } catch (error) {
            yield put({
                type: types.FETCH_REPORT_PATH_CHILD_ERROR,
                error: error.message,
                id: currentReportPathId,
            });
        }
    } catch (error) {
        console.error(error);
    }
}

export function* gotoReportPathSaga({ id, parent, filterColumn, filterValue }) {
    const query = yield select(state => state.router.location.search);
    const parsedQuery = queryString.parse(query);
    parsedQuery['sort'] = true;

    if (filterColumn) {
        parsedQuery[filterColumn] = filterValue;
    }
    if (id) {
        yield put(push(`/report/${id}?${queryString.stringify(parsedQuery)}`));
    } else {
        const dateFilter = yield select(reportDateFilterSelector);
        let dateColumn = yield select(getReportDateFilterColumn, {
            sfid: parent,
        });
        console.log(dateColumn, parent);
        if (!dateColumn) {
            dateColumn = 'createdDate';
        } else {
            dateColumn = dateColumn
                .split('.')
                .map(field =>
                    field
                        .replace(/^(.)/, letter => letter.toLowerCase())
                        .replace(/_+c/g, 'C')
                        .replace(/_/g, '')
                        .replace(/^claimC/, 'claims')
                        .replace(/^drugC/, 'drug'),
                )
                .join('.');
        }
        if (dateFilter) {
            parsedQuery[dateColumn] = dateFilter;
        }
        yield put(
            push(`/patient-roster?${queryString.stringify(parsedQuery)}`),
        );
    }
}
