import React from 'react';
import PropTypes from 'prop-types';
import withReportBar from '../../../common/report/withReportBar';
import StackedColumnChartType from '../../../common/charts/bar/StackedColumn';

export const StackedColumnChart = withReportBar(props => (
    <StackedColumnChartType {...props} />
));

StackedColumnChart.propTypes = {
    sfid: PropTypes.string.isRequired,
    defaultDateFilterC: PropTypes.string,
};

export default StackedColumnChart;
