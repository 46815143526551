import React from 'react';
import moment from 'moment';
import { Comment } from 'semantic-ui-react';

export const Message = ({ author, date, text, userSend }) => {
    const dateFormat = moment(date).format('LT');
    const className = userSend ? 'user-comment' : 'support-comment';
    return (
        <Comment className={className}>
            <Comment.Content>
                <Comment.Author as='a'>{author}</Comment.Author>
                <Comment.Metadata>
                    <div>{dateFormat}</div>
                </Comment.Metadata>
                <Comment.Text>{text}</Comment.Text>
            </Comment.Content>
        </Comment>
    );
};

export default Message;
