import { createSelector } from 'reselect';
import { get } from 'lodash';

export const reportDataMapSelector = (state, props) =>
    state.reportData.reportDataIdMap[props.sfid] || {};

export const reportStatusMapSelector = (state, props) =>
    state.reportData.reportDataStatus[props.sfid] || {};

export const reportDateFilterSelector = (state, { defaultDateFilterC } = {}) =>
    defaultDateFilterC || state.reportData.dateFilter;

export const reportDataSelector = createSelector(
    [reportDataMapSelector, reportDateFilterSelector],
    (dataMap, dateFilter) => dataMap[dateFilter],
);

export const defaultSortColumnLabelSelector = (_, props) =>
    props.defaultSortColumnLabelC;

export const reportStatusSelector = createSelector(
    [reportDateFilterSelector, reportStatusMapSelector],
    (dateFilter, status) => {
        const dateMap = status || {
            [dateFilter]: {
                loading: false,
                loaded: false,
                error: null,
            },
        };
        return dateMap[dateFilter];
    },
);

export const makeReportChartTypeSelector = () =>
    createSelector(
        [reportDataSelector],
        reportData => get(reportData, 'data.reportMetadata.chart.chartType'),
    );

export const getReportType = reportData =>
    get(reportData, 'reportMetadata.reportFormat');

export const getReportDateFilterColumn = createSelector(
    reportDataSelector,
    reportData =>
        get(reportData, 'data.reportMetadata.standardDateFilter.column'),
);

/**
 * Get rows and columns from raw salesforce report data
 *
 * @param {Object} params
 * @param {Object} params.report - Report data from salesforce
 * @param {Object} params.filter - Filter from report path
 * @param {Bool} params.showGrandTotalIfAvailable - Show grand total even if it's not set in salesforce
 * @param {Bool} params.showSubtotalsIfAvailable - Show sub totals even if it's not set in salesforce
 * @param {Bool} params.useValues - Return values instead of labels
 * @param {?String} params.defaultSortColumnLabel - Label of sort column
 */
export const getTableData = ({
    report,
    filter = {},
    showGrandTotalIfAvailable = true,
    showSubtotalsIfAvailable = true,
    useValues = false,
    defaultSortColumnLabel = null,
}) => {
    const result = {
        rows: [],
        columns: [],
    };
    if (!report) {
        return result;
    }
    const reportData = get(report, 'data');
    const idField = get(report, 'idField');
    const columns = getTableColumns(reportData, filter);
    const defaultSortColumn = columns.find(
        column => column.label === defaultSortColumnLabel,
    );
    result.defaultSortColumn = defaultSortColumn
        ? defaultSortColumn.accessor
        : null;
    result.defaultSortDirection = 'descending';
    const reportType = getReportType(reportData);
    switch (reportType) {
        // Summary report is report with row groupings and without column groupings,
        // it can have detail rows or only summary, also can have a chart
        case 'SUMMARY': {
            const { rows, grandTotal, subTotals } = getSummaryReportData({
                reportData,
                columns: columns,
                idField: idField,
                showGrandTotalIfAvailable,
                showSubtotalsIfAvailable,
                useValues,
                filter,
            });
            result.grandTotal = grandTotal;
            result.subTotals = subTotals;
            result.rows = rows;
            result.columns = columns.filter(
                column =>
                    !column.filtered &&
                    (!idField || idField !== column.accessor),
            );
            break;
        }
        // Tabular report is report without any groupings, it can't have a chart and only have detail rows
        case 'TABULAR': {
            const { rows } = getTabularReportData({
                reportData,
                columns: columns,
                idField: idField,
                showGrandTotalIfAvailable,
                showSubtotalsIfAvailable,
                useValues,
                filter,
            });
            result.rows = rows;
            result.columns = columns.filter(
                column =>
                    column.detail &&
                    !column.filtered &&
                    (!idField || idField !== column.accessor),
            );
            break;
        }
        // Matrix report is report with row groupings and column groupings, it can't have details rows, but can have a chart
        case 'MATRIX':
            // TODO: implement (split groupings by rows and columns, use their combination to iterate fact map)
            break;
        default:
            break;
    }
    return result;
};

/**
 * @param {Object} params
 * @param {Object} params.reportData
 * @param {Object} params.reportData.factMap
 * @param {Object} params.reportData.reportMetadata
 * @param {Object} params.reportData.reportExtendedMetadata
 * @param {Array} params.columns
 * @param {Bool} params.showGrandTotalIfAvailable
 * @param {Bool} params.showSubtotalsIfAvailable
 * @param {Object} params.filter
 * @param {Bool} params.useValues
 */
export const getSummaryReportData = ({
    reportData: {
        factMap = {},
        reportMetadata: {
            aggregates = [],
            detailColumns = [],
            hasDetailRows = false,
            showSubtotals = false,
            showGrandTotal = false,
        } = {},
        reportExtendedMetadata: {
            aggregateColumnInfo = {},
            detailColumnInfo = {},
        } = {},
        groupingsDown: { groupings = [] } = {},
    } = {},
    columns,
    showGrandTotalIfAvailable = true,
    showSubtotalsIfAvailable = true,
    filter = {},
    useValues = false,
}) => {
    const rows = [];
    let grandTotal = {};
    const subTotals = [];
    // Grouping columns sort by grouping level
    const groupingColumns = columns
        .filter(column => column.grouping)
        .sort((first, second) => first.groupingLevel - second.groupingLevel);

    const maxGroupingsLevel = groupingColumns.length - 1;

    // Get values for grouping columns
    const groupingValues = {};
    getGroupingValues(groupingValues, groupings, groupingColumns, 0, filter);

    // Add total grouping
    // TODO: not implemented
    if (showGrandTotal) {
        groupingValues['T!T'] = {
            accessor: groupingColumns[0].accessor,
            label: 'Total',
            value: 'Total',
            level: 0,
        };
    }

    // Summary report always have grouping values
    for (const key in groupingValues) {
        const rowValue = groupingValues[key];
        const row = factMap[key];
        const { accessor, label, value } = rowValue;
        let { level } = rowValue;
        // TODO: add grand total row for multi level reports

        // Not summary

        if (level === maxGroupingsLevel) {
            const newRow = {
                [accessor]: label,
                filters: {
                    [accessor]: value,
                },
            };

            // Get values for grouping columns
            while (level > 0) {
                level--;
                const previousGrouping = key.replace(/(.*)_\d+!T/, '$1!T');
                const rowValue = groupingValues[previousGrouping];
                if (rowValue) {
                    const { value, label, accessor } = rowValue;
                    newRow.filters[accessor] = value;
                    newRow[accessor] = useValues ? value : label;
                }
            }

            // Get values for aggregate columns
            for (const expression in aggregateColumnInfo) {
                const aggregateIndex = aggregates.indexOf(expression);
                if (aggregateIndex >= 0 && row.aggregates[aggregateIndex]) {
                    const { value, label } = row.aggregates[aggregateIndex];
                    newRow[expression] = useValues ? value : label;
                }
            }

            // Get values for detail rows and columns
            if (hasDetailRows) {
                for (const rowNumber in row.rows) {
                    const dataCells = row.rows[rowNumber].dataCells;
                    // Copy grouping and aggregate values as they are the same for these rows
                    const detailRow = {
                        ...newRow,
                    };
                    for (const expression in detailColumnInfo) {
                        const detailColumnIndex = detailColumns.indexOf(
                            expression,
                        );
                        if (
                            detailColumnIndex >= 0 &&
                            row.rows.length &&
                            row.rows[0].dataCells &&
                            row.rows[0].dataCells.length > detailColumnIndex
                        ) {
                            detailRow[expression] =
                                dataCells[detailColumnIndex].label;
                        }
                    }
                    rows.push(detailRow);
                }
            } else {
                rows.push(newRow);
            }
        }
    }

    if (showGrandTotalIfAvailable) {
        grandTotal = {
            accessor: groupingColumns[0].accessor,
            label: 'Total',
            value: 'Total',
            level: 0,
        };
        const row = factMap['T!T'];
        // Get values for aggregate columns
        for (const expression in aggregateColumnInfo) {
            const aggregateIndex = aggregates.indexOf(expression);
            if (aggregateIndex >= 0) {
                const { value = null, label = '' } =
                    row.aggregates[aggregateIndex] || {};
                grandTotal[expression] = useValues ? value : label;
            }
        }
    }
    return {
        rows,
        grandTotal,
        subTotals,
    };
};

/**
 * @param {Object} params
 * @param {Object} params.reportData
 * @param {Object} params.reportData.factMap
 * @param {Object} params.reportData.reportMetadata
 * @param {Object} params.reportData.reportExtendedMetadata
 * @param {Array} params.columns
 * @param {Bool} params.showGrandTotalIfAvailable
 * @param {Bool} params.showSubtotalsIfAvailable
 * @param {Object} params.filter
 * @param {Bool} params.useValues
 */
export const getTabularReportData = ({
    reportData: { factMap = {} } = {},
    columns,
}) => {
    const rows = [];
    const reportRows = get(factMap, '[T!T].rows', []);
    // Summary report always have grouping values
    for (const row of reportRows) {
        const newRow = {};
        columns
            .filter(column => column.detail)
            .forEach((column, number) => {
                newRow[column.accessor] = get(row, `dataCells.${number}.label`);
            });
        rows.push(newRow);
    }
    return {
        rows,
    };
};

/**
 * Get flattet grouping values by iterating groupings object recusively
 *
 * @param {Object} groupingValues - Accumulator for grouping values, mutable
 * @param {Object} groupingValues.accessor - Key for column
 * @param {Object} groupingValues.label - Label for column
 * @param {Object} groupingValues.value - Value for column
 * @param {Object} groupingValues.level - Value for column
 * @param {Array} groupings - Array of grouping values
 * @param {string|number} groupings.value - Value for column
 * @param {string} groupings.label - Label of column
 * @param {string} groupings.key - Key on factmap
 * @param {Array} groupings.groupings - Child groupings
 * @param {Array} groupingColumns - Array of grouping columns
 * @param {number} groupingColumns.groupingLevel - Level of grouping
 * @param {number} groupingColumns.accessor - Key for column
 * @param {number} groupingColumns.filtered - Is column filtered and should be ignored if it's values does not match filter value
 * @param {number} level - Level to get grouping values
 * @param {Object} filter - Filters to ignore
 */
export const getGroupingValues = (
    groupingValues,
    groupings,
    groupingColumns,
    level,
    filter = {},
) => {
    const groupingColumn = groupingColumns.filter(
        groupingColumn => groupingColumn.groupingLevel === level,
    );
    if (!groupingColumn.length) {
        return;
    }
    const { accessor, filtered } = groupingColumn[0];

    if (filtered) {
        const filteredGroupings = groupings.filter(
            grouping => grouping.value === filter[accessor],
        );
        if (filteredGroupings.length > 0) {
            const childGroupings = filteredGroupings[0].groupings;
            if (childGroupings.length) {
                getGroupingValues(
                    groupingValues,
                    childGroupings,
                    groupingColumns,
                    level + 1,
                    filter,
                );
            }
        }
    } else {
        for (let i = 0; i < groupings.length; i++) {
            const grouping = groupings[i];
            const { key, label, value, groupings: childGroupings } = grouping;
            groupingValues[`${key}!T`] = {
                accessor,
                label,
                value,
                level,
            };
            if (childGroupings.length) {
                getGroupingValues(
                    groupingValues,
                    childGroupings,
                    groupingColumns,
                    level + 1,
                    filter,
                );
            }
        }
    }
};

/**
 * Get grouping, aggregate and detail columns for report
 *
 * @param {Object} data - Raw salesforce data
 * @param {Object} filter - Filter columns
 */
export const getTableColumns = (data, filter = {}) => {
    const columns = [];
    const hasDetailRows = data.hasDetailRows || false;
    const extendedMeta = data.reportExtendedMetadata || {};
    const groupingColumns = extendedMeta.groupingColumnInfo || {};
    const aggregateColumns = extendedMeta.aggregateColumnInfo || {};
    const detailColumns = extendedMeta.detailColumnInfo || {};
    let columnCounter = 0;
    for (const columnExpression in groupingColumns) {
        const column = groupingColumns[columnExpression];
        const { groupingLevel, label, dataType } = column;
        const colId = ++columnCounter;
        const filterValue = filter[columnExpression];
        columns.push({
            accessor: columnExpression,
            label,
            dataType,
            priorityLevel: colId,
            position: colId,
            minWidth: 140,
            grouping: true,
            groupingLevel,
            filtered: filterValue !== undefined,
        });
    }

    for (const columnExpression in aggregateColumns) {
        const column = aggregateColumns[columnExpression];
        const { label = '', dataType } = column;
        const colId = ++columnCounter;
        columns.push({
            accessor: columnExpression,
            label,
            dataType,
            priorityLevel: colId,
            position: colId,
            minWidth: 140,
            aggregate: true,
        });
    }

    if (hasDetailRows) {
        for (const columnExpression in detailColumns) {
            const column = detailColumns[columnExpression];
            const { label, dataType } = column;
            const colId = ++columnCounter;
            columns.push({
                accessor: columnExpression,
                label,
                dataType,
                priorityLevel: colId,
                position: colId,
                minWidth: 140,
                detail: true,
            });
        }
    }

    return columns;
};
