import { createSelector } from 'reselect';
import { get } from 'lodash';
import { reportDataSelector, getTableData } from '../reportDataSelectors';
import { reportFilterSelector } from '../../report-path/reportPathSelectors';

// chart: {
//     chartType: 'Vertical Bar',
//     groupings: ['Claim__c.Patient_Reference_Category__c'],
//     hasLegend: false,
//     legendPosition: null,
//     referenceLineColors: [null],
//     referenceLineValues: [null],
//     showChartPercentages: false,
//     showChartTotal: false,
//     showChartValues: true,
//     summaries: ['s!Claim__c.Primary_Insurance_Paid__c'],
//     summaryAxisLocations: ['Y'],
//     summaryColors: ['#00A1E0'],
//     summaryVisualizationTypes: ['Column'],
//     title: null,
// },
export const reportBarSelector = createSelector(
    [reportDataSelector, reportFilterSelector],
    (report = {}, filter) => {
        const { data: reportData } = report;
        const result = {
            data: [],
            columns: [],
            groupings: [],
            summaries: [],
            hasLegend: false,
            legendPosition: null,
            layout: 'vertical',
        };
        const chart = get(reportData, 'reportMetadata.chart');
        if (!reportData) {
            return result;
        }
        const {
            groupings,
            chartType,
            hasLegend,
            legendPosition,
            // showChartValues,
            // showChartPercentages,
            // showChartTotal,
            summaries,
            // summaryAxisLocations,
            // summaryColors, // If colors changed it contain array of hex colors, if default colors used empty array
            // summaryVisualizationTypes, // Type of chart for summaries
        } = chart || {
            groupings: reportData.reportMetadata.groupingsDown.map(
                grouping => grouping.name,
            ),
            chartType: 'Bar',
            hasLegend: true,
            legendPosition: 'Bottom',
            summaries: reportData.reportMetadata.aggregates,
        };

        if (
            chartType === 'Bar' ||
            chartType === 'Horizontal Bar' ||
            chartType === 'Horizontal Bar Stacked'
        ) {
            result.layout = 'horizontal';
        } else if (
            chartType === 'Vertical Bar' ||
            chartType === 'Vertical Bar Stacked' ||
            chartType === 'Vertical Bar Grouped'
        ) {
            result.layout = 'vertical';
        } else {
            console.error(`Wrong chart type for bar chart`, reportData);
            return result;
        }

        const { rows, columns } = getTableData({
            report,
            filter,
            showGrandTotalIfAvailable: true,
            useValues: true, // Labels are strings, but we need numbers for chart
        });
        result.data = rows;
        result.columns = columns;
        result.summaries = summaries;
        result.groupings = groupings;
        result.hasLegend = hasLegend;
        result.legendPosition = legendPosition;
        return result;
    },
);

export default reportBarSelector;
