export const CONTACT_TIMEZONE_REQUEST = 'CONTACT_TIMEZONE_REQUEST';
export const CONTACT_TIMEZONE_SUCCESS = 'CONTACT_TIMEZONE_SUCCESS';
export const CONTACT_TIMEZONE_ERROR = 'CONTACT_TIMEZONE_ERROR';

export const CONTACT_LOCK_REQUEST = 'CONTACT_LOCK_REQUEST';
export const CONTACT_LOCK_SUCCESS = 'CONTACT_LOCK_SUCCESS';
export const CONTACT_LOCK_ERROR = 'CONTACT_LOCK_ERROR';

export const CONTACT_UNLOCK_REQUEST = 'CONTACT_UNLOCK_REQUEST';
export const CONTACT_UNLOCK_SUCCESS = 'CONTACT_UNLOCK_SUCCESS';
export const CONTACT_UNLOCK_ERROR = 'CONTACT_UNLOCK_ERROR';
