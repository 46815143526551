import React from 'react';
import PropTypes from 'prop-types';
import withReportGauge from '../../../common/report/withReportGauge';
import GaugeChartType from '../../../common/charts/gauge';

export const GuageChart = withReportGauge(
    ({ lowerBound, upperBound, ...props }) => {
        return (
            <GaugeChartType
                min={70}
                max={150}
                validMin={80} //validMin={lowerBound || 80}
                validMax={110} //validMax={upperBound || 110}
                {...props}
            />
        );
    },
);

GuageChart.propTypes = {
    sfid: PropTypes.string.isRequired,
    defaultDateFilterC: PropTypes.string,
};

export default GuageChart;
