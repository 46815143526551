import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createEpicMiddleware } from 'redux-observable';
import { rootEpic } from './epics';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootSaga from './sagas';
import createRootReducer from './reducers';
import { createBrowserHistory } from 'history';

export let history = null;

/**
 *
 * @param {Object} preloadedState Initial state
 * @param {?string} [domain]
 */
const configureStore = (preloadedState, domain) => {
    const options = {};
    if (domain) {
        options.basename = domain;
    }
    history = createBrowserHistory(options);
    const middleware = routerMiddleware(history);
    const sagaMiddleware = createSagaMiddleware();
    const epicMiddleware = createEpicMiddleware();
    return {
        ...createStore(
            createRootReducer(history),
            preloadedState,
            composeWithDevTools(
                applyMiddleware(middleware, sagaMiddleware, epicMiddleware),
            ),
        ),
        runSaga: sagaMiddleware.run(rootSaga),
        runEpics: epicMiddleware.run(rootEpic),
    };
};

export default configureStore;
