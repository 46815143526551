import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react'; //import { Grid, Container } from 'semantic-ui-react';
/**/ import NavMenu from '../../components/common/NavMenu';
import MobileNavMenu from '../../components/common/MobileNavMenu';
import Footer from '../../components/common/Footer';
import Header from '../../components/common/GlobalHeader';
export default class index extends Component {
    render() {
        return (
            <React.Fragment>
                <Header />
                <div className='stretch-col'>
                    <Grid className='shrink marginV0' columns='2'>
                        <NavMenu />
                        <Grid.Column
                            style={{ margin: 0, padding: 0 }}
                            computer={15}
                            tablet={15}
                            mobile={16}
                        >
                            {this.props.children}
                        </Grid.Column>
                    </Grid>
                </div>
                <Footer />
                <MobileNavMenu />
            </React.Fragment>
        );
    }
}
