import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { withLayout } from '../components/common/withLayout';
import requireAuthentication from '../Utilities/RouteProtector';

import Login from '../components/login/Login';
import PasswordRecovery from '../components/login/PasswordRecovery';
import Dashboard from '../components/dashboard/Dashboard';
import PatientDetail from '../components/patient/patient-detail/PatientDetail';
import PatientRoster from '../components/patient/patient-roster/PatientRoster';
import PatientOrderStatus from '../components/patient/patient-order-status/PatientOrderStatus';
import Reports from '../components/reports/report-list';
import ReportDetail from '../components/reports/report-detail/ReportDetail';
import CaseManagement from '../pages/CaseManagement';
import Account from '../components/account/Account';

/* CONTAINERS */
import DashboardContainer from '../containers/dashboard';
import Home from '../containers/home';

export const paths = {
    TEST: '/test',
    DASHBOARD: '/dashboard',
    REPORTS: '/reports',
    REPORT: '/report/:id+',
    PATIENT_ROSTER: '/patient-roster',
    PATIENT_ORDER_STATUS: '/patient-order-status',
    PATIENT_DETAIL: '/patient-detail/:id',
    CASE_MANAGEMENT: '/case-management',
    ACCOUNT: '/account',
    LOGIN: '/login',
    PASSWORD_RECOVERY: '/password-recovery',
};

export const domainRoutes = (
    <Switch>
        <Route
            exact
            path={paths.DASHBOARD}
            component={withLayout(requireAuthentication(Dashboard), [
                'Admin',
                'Admin1',
            ])}
        />
        <Route
            exact
            path={paths.REPORTS}
            component={withLayout(requireAuthentication(Reports), [
                'Admin',
                'Admin2',
            ])}
        />
        <Route
            exact
            path={paths.REPORT}
            component={withLayout(requireAuthentication(ReportDetail), [
                'Admin',
                'Admin3',
            ])}
        />
        <Route
            exact
            path={paths.PATIENT_ROSTER}
            component={withLayout(requireAuthentication(PatientRoster), [
                'Admin',
                'Admin5',
            ])}
        />
        <Route
            exact
            path={paths.PATIENT_ORDER_STATUS}
            component={withLayout(requireAuthentication(PatientOrderStatus), [
                'Admin',
                'Admin6',
            ])}
        />
        <Route
            exact
            path={paths.PATIENT_DETAIL}
            component={withLayout(requireAuthentication(PatientDetail), [
                'Admin',
                'Admin7',
            ])}
        />
        <Route
            exact
            path={paths.CASE_MANAGEMENT}
            component={withLayout(requireAuthentication(CaseManagement), [
                'Admin',
                'Admin8',
            ])}
        />
        <Route
            exact
            path={paths.ACCOUNT}
            component={withLayout(requireAuthentication(Account), [
                'Admin',
                'Admin9',
            ])}
        />
        <Route exact path={paths.TEST}>
            <DashboardContainer>
                <Home />
            </DashboardContainer>
        </Route>
        <Route exact path={paths.LOGIN} component={Login} />
        <Route
            exact
            path={paths.PASSWORD_RECOVERY}
            component={PasswordRecovery}
        />
        <Route
            component={withLayout(requireAuthentication(Dashboard), [
                'Admin',
                'Admin1',
            ])}
        />
    </Switch>
);

export const publicRoutes = <Route component={Login} />;

export default {
    domainRoutes,
    publicRoutes,
};
