import * as types from './domainActionTypes';

export default (
    state = {
        domain: null,
        verified: false,
        name: null,
        error: null,
        loading: false,
    },
    action,
) => {
    switch (action.type) {
        case types.DOMAIN_VERIFY_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                verified: false,
            };
        case types.DOMAIN_VERIFY_SUCCESS:
            return {
                ...state,
                name: action.name,
                logo: action.logo,
                verified: true,
                loading: false,
            };
        case types.DOMAIN_VERIFY_ERROR:
            return {
                ...state,
                error: action.error,
                loading: false,
                verified: false,
            };
        default:
            return state;
    }
};
