import React from 'react';
import PropTypes from 'prop-types';

import { AllCustomChartTypes } from '../report-chart/ChartTypes';
import { compose, withHandlers, setDisplayName } from 'recompose';
import ReportHeader from './ReportHeader';
import Chart from '../report-chart';
import ViewReportButton from './ViewReportButton';
import { DateFilterText } from '../../common/date-filter/DateFilterValues';
import { connect } from 'react-redux';
import { reportDateFilterSelector } from '../../../store/report-data/reportDataSelectors';

const mapStateToProps = (state, onwProps) => ({
    date: reportDateFilterSelector(state, {
        defaultDateFilterC: onwProps.data.defaultDateFilterC,
    }),
});

const enhance = compose(
    connect(mapStateToProps),
    withHandlers({
        onViewReport: props => e => {
            e.preventDefault();
            props.viewReport(props.data.sfid, props.date);
        },
    }),
    setDisplayName('ReportPreview'),
);

export const ReportPreview = enhance(
    ({ showHeader, showViewButton, data, onViewReport, showDate, date }) => {
        const {
            name,
            clickableC,
            chartReportIdC,
            chartTypeC,
            visibleLinesC,
            defaultSortColumnLabelC,
            redirectFilterApiNameC,
        } = data;
        let lines = null;
        if (typeof visibleLinesC === 'string') {
            lines = parseInt(visibleLinesC);
            if (isNaN(lines)) {
                lines = null;
            }
        }
        if (typeof visibleLinesC === 'number') {
            lines = Math.trunc(visibleLinesC);
        }
        return (
            <>
                {showHeader && (
                    <ReportHeader
                        asLink={clickableC}
                        handleClick={onViewReport}
                        name={name}
                        date={showDate && date ? DateFilterText[date] : null}
                    />
                )}
                {chartReportIdC && (
                    <Chart
                        sfid={chartReportIdC}
                        customChartType={chartTypeC}
                        clickableC={clickableC}
                        onViewReport={onViewReport}
                        defaultDateFilterC={date}
                        showDate={!showHeader && showDate}
                        visibleLinesC={lines}
                        defaultSortColumnLabelC={defaultSortColumnLabelC}
                        redirectFilterApiNameC={redirectFilterApiNameC}
                    />
                )}
                {showViewButton && clickableC && (
                    <ViewReportButton handleClick={onViewReport} />
                )}
            </>
        );
    },
);

ReportPreview.propTypes = {
    data: PropTypes.shape({
        chartTypeC: PropTypes.oneOf(AllCustomChartTypes),
        chartReportIdC: PropTypes.string,
        name: PropTypes.string,
        clickableC: PropTypes.bool,
        sfid: PropTypes.string.isRequired,
        defaultDateFilterC: PropTypes.string,
        visibleLinesC: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        defaultSortColumnLabelC: PropTypes.string,
        redirectFilterApiNameC: PropTypes.string,
    }).isRequired,
    showHeader: PropTypes.bool,
    showViewButton: PropTypes.bool,
    viewReport: PropTypes.func,
    showDate: PropTypes.bool,
};

ReportPreview.defaultProps = {
    showHeader: true,
    showViewButton: true,
    data: {},
    viewReport: () => {},
};

export default ReportPreview;
