import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Divider } from 'semantic-ui-react';
import { connect } from 'react-redux';

import ReportPlaceholder from '../../report-placeholder';
import { AllReportTypeValues } from '../../../common/ReportTypeValues';
import { getByGroupByNameEnabledSelector } from '../../../../store/report/reportSelectors';
import Item from './Item';
import ItemGroup from './ItemGroup';
import { ITEM_TYPES } from '../../../../helpers/grouping.helpers';

export const ReportGroup = ({ group, items }) => {
    return (
        <Grid
            columns={2}
            className='paddingV2rem mobileNoPad'
            padded='vertically'
            stretched
            stackable
        >
            <Grid.Column className='dividerCol sticky' width={16}>
                <Divider className='labeledDivider' horizontal>
                    <span>{group}</span>
                </Divider>
            </Grid.Column>

            {items.map((item, key) => {
                const { type, data, name } = item;
                return (
                    <Grid.Column key={`report_${group}_column_${key}`}>
                        {type === ITEM_TYPES.SINGLE_ITEM && (
                            <Item data={data} showDate />
                        )}
                        {type === ITEM_TYPES.ITEM_GROUP && (
                            <ItemGroup items={data} title={name} />
                        )}
                    </Grid.Column>
                );
            })}
            {items.length % 2 > 0 && (
                    <Grid.Column key={`report_${group}_column_placeholder`}>
                        <ReportPlaceholder />
                    </Grid.Column>
                ) &&
                null}
        </Grid>
    );
};

ReportGroup.propTypes = {
    group: PropTypes.oneOf(AllReportTypeValues),
    items: PropTypes.array,
};

const mapStateToProps = (state, ownProps) => ({
    items: getByGroupByNameEnabledSelector(state, ownProps),
});

export default connect(mapStateToProps)(ReportGroup);
