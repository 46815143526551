import toastr from 'toastr';

function notifyError(message) {
    toastr.options = {
        timeOut: 5000,
        showMethod: 'slideDown',
        hideMethod: 'slideUp',
        escapeHtml: false,
        preventDuplicates: true,
        positionClass: 'toast-top-full-width',
        newestOnTop: false
    };

    toastr.error(message);
}


function notifySuccess(message) {
    toastr.options = {
        timeOut: 5000,
        showMethod: 'slideDown',
        hideMethod: 'slideUp',
        escapeHtml: false,
        preventDuplicates: true,
        positionClass: 'toast-top-full-width',
        newestOnTop: false
    };

    toastr.success(message);
}

export default { error: notifyError, success: notifySuccess };