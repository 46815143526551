import React from 'react';
import { Select } from 'semantic-ui-react';

const Pagination = ({
    currentPage,
    totalPages,
    nextPage,
    previousPage,
    firstPage,
    lastPage,
    showPegeSizeSelect,
    pageSizeOptions = [
        { key: '10', text: '10', value: 10 },
        { key: '25', text: '25', value: 25 },
        { key: '50', text: '50', value: 50 },
        { key: '100', text: '100', value: 100 },
    ],
    handlePageSizeChange,
}) => {
    const firstPageIcon =
        currentPage > 2 ? (
            <i className='angle double left icon' onClick={firstPage} />
        ) : null;
    const previousPageIcon =
        currentPage > 1 ? (
            <i className='angle left icon' onClick={previousPage} />
        ) : null;
    const nextPageIcon =
        currentPage < totalPages ? (
            <i className='angle right icon' onClick={nextPage} />
        ) : null;
    const lastPageIcon =
        currentPage < totalPages ? (
            <i className='angle double right icon' onClick={lastPage} />
        ) : null;

    return (
        <div className='react-collapsible-page'>
            <div className='report-pagination'>
                {showPegeSizeSelect && (
                    <Select
                        text=''
                        size='mini'
                        options={pageSizeOptions}
                        onChange={(e, data) =>
                            handlePageSizeChange({ pageSize: data.value })
                        }
                        defaultValue={10}
                    />
                )}
                {firstPageIcon}
                {previousPageIcon}
                Page {currentPage} of {Math.round(totalPages)}
                {nextPageIcon}
                {lastPageIcon}
            </div>
        </div>
    );
};

export default Pagination;
