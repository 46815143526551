export const PATIENT_ORDER_STATUS_REQUEST = 'PATIENT_ORDER_STATUS_REQUEST';
export const PATIENT_ORDER_STATUS_SUCCESS = 'PATIENT_ORDER_STATUS_SUCCESS';
export const PATIENT_ORDER_STATUS_ERROR = 'PATIENT_ORDER_STATUS_ERROR';

export const PATIENT_ORDER_STATUS_COUNT_REQUEST =
    'PATIENT_ORDER_STATUS_COUNT_REQUEST';
export const PATIENT_ORDER_STATUS_COUNT_SUCCESS =
    'PATIENT_ORDER_STATUS_COUNT_SUCCESS';
export const PATIENT_ORDER_STATUS_COUNT_ERROR =
    'PATIENT_ORDER_STATUS_COUNT_ERROR';

export const PATIENT_ORDER_STATUS_FILTER = 'PATIENT_ORDER_STATUS_FILTER';
export const PATIENT_ORDER_STATUS_FILTER_RESET =
    'PATIPATIENT_ORDER_STATUS_FILTER_RESET';

export const PATIENT_ORDER_STATUS_GO_TO_NEXT_PAGE =
    'PATIENT_ORDER_STATUS_GO_TO_NEXT_PAGE';
export const PATIENT_ORDER_STATUS_GO_TO_PREV_PAGE =
    'PATIENT_ORDER_STATUS_GO_TO_PREV_PAGE';
export const PATIENT_ORDER_STATUS_GO_TO_FIRST_PAGE =
    'PATIENT_ORDER_STATUS_GO_TO_FIRST_PAGE';
export const PATIENT_ORDER_STATUS_GO_TO_LAST_PAGE =
    'PATIENT_ORDER_STATUS_GO_TO_LAST_PAGE';

export const PATIENT_ORDER_STATUS_SORT = 'PATIENT_ORDER_STATUS_SORT';
export const SET_PAGE_SIZE = 'PATIENT_ORDER_STATUS_SET_PAGE_SIZE';
