import * as types from './reportDataActionTypes';

export const reportDataRequest = (reportId, defaultDateFilterC) => ({
    type: types.REPORT_DATA_REQUEST,
    reportId,
    defaultDateFilterC,
});

export const setDateFilter = dateFilter => ({
    type: types.SET_DATE_FILTER,
    dateFilter,
});
