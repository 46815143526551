import React from 'react';
import 'react-app-polyfill/ie9';
import ReactDOM from 'react-dom';

import './index.scss';
import '../node_modules/toastr/build/toastr.min.css';
import withDomain from './components/domain/withDomain';
import App from './App';

//require('dotenv').config();

const DomainApp = withDomain(App);

ReactDOM.render(<DomainApp />, document.getElementById('root'));
