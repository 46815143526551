import getReducer from '../list/reducer';
import { CASE_LIST_PREFIX } from '.';
import CASE_TABLE_COLUMNS from '../../components/case/table/columns';

const defaultSort = {
    column: CASE_TABLE_COLUMNS[0].accessor,
    direction: 'ascending',
};

const reducer = getReducer(CASE_LIST_PREFIX, defaultSort);

export default reducer;
