import * as types from './chatActionTypes';

export default (
    state = {
        started: false,
        agentIsOnline: false,
        waitingForAgentToAccept: false,
        messages: [],
        session: null,
        prechat: {},
    },
    action,
) => {
    switch (action.type) {
        case types.CHAT_START_REQUEST:
            return {
                ...state,
                waitingForAgentToAccept: !!state.agentIsOnline,
                prechat: {
                    ...action.payload,
                },
            };
        case types.CHAT_START_SUCCESS:
            return {
                ...state,
                waitingForAgentToAccept: false,
                started: true,
            };
        case types.CHAT_START_ERROR:
            return {
                ...state,
                waitingForAgentToAccept: false,
            };
        case types.CHAT_ENDED_BY_AGENT:
        case types.CHAT_END_BY_USER:
            return {
                ...state,
                messages: [],
                started: false,
            };
        case types.CHAT_RECEIVE_MESSAGE:
            return {
                ...state,
                messages: [
                    ...state.messages,
                    {
                        ...action.payload,
                    },
                ],
            };
        case types.CHAT_SET_AGENT_STATUS:
            return {
                ...state,
                agentIsOnline: action.payload.isOnline,
            };
        case types.CHAT_SESSION_START:
            return {
                ...state,
                session: action.payload.session,
            };
        case types.CHAT_SESSION_END:
            return {
                ...state,
                started: false,
            };
        default:
            return state;
    }
};
