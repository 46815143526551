import React from 'react';

export const ReportPlaceholder = () => {
    return (
        <div className='report-placeholder'>
            <div>Choose another report</div>
        </div>
    );
};

export default ReportPlaceholder;
