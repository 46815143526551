import React from 'react';
import { Message } from 'semantic-ui-react';

const ErrorMessage = ({ error, ErrorComponent }) => {
    if (!error) {
        return null;
    }
    const errorMessage =
        typeof error === 'string'
            ? error
            : error instanceof Error
            ? error.toString()
            : typeof error === 'object' && error.message !== undefined
            ? error.message
            : 'Unknown error';
    if (ErrorComponent !== undefined) {
        return <ErrorComponent>{errorMessage}</ErrorComponent>;
    }
    return (
        <Message negative>
            <p>{errorMessage}</p>
        </Message>
    );
};

export default ErrorMessage;
