import { PATIENT_ORDER_STATUS_FIRST_PAGE } from './orderStatusReducer';

export const getOrderStatusSkipItems = ({ orderStatus: { page, pageSize } }) =>
    (page - 1) * pageSize;

export const getOrderStatusCurrentPage = ({ orderStatus: { page } }) => page;

export const getOrderStatusPageSize = ({ orderStatus: { pageSize } }) =>
    pageSize;

export const getOrderStatusLastPage = ({
    orderStatus: { pageSize, total },
}) => {
    const page = Math.ceil(total / pageSize);
    if (page > PATIENT_ORDER_STATUS_FIRST_PAGE) {
        return page;
    }
    return PATIENT_ORDER_STATUS_FIRST_PAGE;
};

export const patientOrderStatusLoadingSelector = state =>
    state.orderStatus.loading;

export const patientOrderStatusListSelector = state => state.orderStatus.data;

export const patientOrderStatusFiltersSelector = state =>
    state.orderStatus.filters;

export const patientOrderStatusFilterValueSelector = (state, { name }) =>
    state.orderStatus.filters[name];

export const statusFilterCountSelector = (state, { value }) => {
    return state.orderStatus.statusCount[value] || 0;
};

export const patientOrderStatusSort = state => state.orderStatus.sort;
