import PatientLink from '../../components/patient/PatientLink';
import PatientFlag from '../../components/patient/PatientFlag';
import DateFormatter from '../../components/common/table/formatters/DateFormatter';
import CurrencyFormatter from '../../components/common/table/formatters/CurrencyFormatter';
import PercentFormatter from '../../components/common/table/formatters/PercentFormatter';
import YesNoFormatter from '../../components/common/table/formatters/YesNoFormatter';

export const ROSTER_TABLE_COLUMNS = [
    {
        accessor: 'member.fullName',
        label: 'Patient',
        priorityLevel: 1,
        position: 1,
        minWidth: 150,
        CustomComponent: PatientLink,
    },
    {
        accessor: 'primaryMemberIdC',
        label: 'Member ID',
        priorityLevel: 2,
        position: 2,
        minWidth: 150,
    },
    {
        accessor: 'member.birthDateC',
        label: 'DOB',
        priorityLevel: 3,
        position: 3,
        minWidth: 150,
        CustomComponent: DateFormatter,
    },
    {
        accessor: 'patientReferenceCategoryC',
        label: 'Disease State',
        priorityLevel: 4,
        position: 4,
        minWidth: 100,
    },
    {
        accessor: 'spend',
        label: 'Spend',
        priorityLevel: 5,
        position: 5,
        minWidth: 150,
        CustomComponent: CurrencyFormatter,
    },
    {
        accessor: 'member.patientAdherence[0].mrpC',
        label: 'Adherence',
        priorityLevel: 6,
        position: 6,
        minWidth: 60,
        CustomComponent: PercentFormatter,
    },
    {
        accessor: 'dateOfLastFill',
        label: 'Date of Last Fill',
        priorityLevel: 7,
        position: 7,
        minWidth: 150,
        CustomComponent: DateFormatter,
    },
    {
        accessor: 'referrals[0].statusC',
        label: 'Open Order',
        priorityLevel: 9,
        position: 9,
        minWidth: 80,
    },
    {
        accessor: 'member.flaggedC',
        label: 'Flagged',
        priorityLevel: 10,
        position: 10,
        minWidth: 80,
        CustomComponent: PatientFlag,
    },
];

export const CLINICAL_ASSESSMENT_COLUMNS = [
    {
        accessor: 'questionC',
        label: 'Question',
        priorityLevel: 1,
        position: 1,
        minWidth: 140,
    },
    {
        accessor: 'answerValueC',
        label: 'Response',
        priorityLevel: 2,
        position: 2,
        minWidth: 140,
    },
    {
        accessor: 'problemC',
        label: 'Requires Review',
        priorityLevel: 3,
        position: 3,
        minWidth: 140,
        CustomComponent: YesNoFormatter,
    },
];

export const FILL_COLUMNS = [
    {
        accessor: 'fillDateC',
        label: 'Fill Date',
        priorityLevel: 1,
        position: 1,
        minWidth: 140,
    },
    {
        accessor: 'drug.name',
        label: 'Medication',
        priorityLevel: 2,
        position: 2,
        minWidth: 140,
    },
    {
        accessor: 'quantityWrittenC',
        label: 'Quantity',
        priorityLevel: 4,
        position: 4,
        minWidth: 140,
    },
    {
        accessor: 'daysSupplyC',
        label: 'Day Supply',
        priorityLevel: 5,
        position: 5,
        minWidth: 140,
    },
    {
        accessor: 'doctor',
        label: 'Prescriber',
        priorityLevel: 6,
        position: 6,
        minWidth: 140,
    },
];

export const PATIENT_ORDER_STATUS_COLUMNS = [
    {
        accessor: 'patient.name',
        label: 'Patient',
        priorityLevel: 1,
        position: 1,
        minWidth: 150,
        CustomComponent: PatientLink,
    },
    {
        accessor: 'memberIdC',
        label: 'Member ID',
        priorityLevel: 2,
        position: 2,
        minWidth: 150,
    },
    {
        accessor: 'patient.birthDateC',
        label: 'DOB',
        priorityLevel: 3,
        position: 3,
        minWidth: 150,
        CustomComponent: DateFormatter,
    },
    {
        accessor: 'patientReferenceCategoryC',
        label: 'Disease State',
        priorityLevel: 4,
        position: 4,
        minWidth: 100,
    },
    {
        accessor: 'drug.name',
        label: 'Drug',
        priorityLevel: 5,
        position: 5,
        minWidth: 150,
    },
    {
        accessor: 'referral.statusC',
        label: 'Status',
        priorityLevel: 6,
        position: 6,
        minWidth: 60,
    },
    {
        accessor: 'referral.outcomeStatusDescriptionC',
        label: 'Sub Status',
        priorityLevel: 7,
        position: 7,
        minWidth: 150,
    },
    {
        accessor: 'referral.outcomeSubstatusDescriptionC',
        label: 'Problem',
        priorityLevel: 9,
        position: 9,
        minWidth: 80,
    },
    {
        accessor: 'patient.flaggedC',
        label: 'Flagged',
        priorityLevel: 10,
        position: 10,
        minWidth: 80,
        CustomComponent: PatientFlag,
    },
];
