import { PATIENT_ROSTER_FIRST_PAGE } from './patientRosterReducers';
import { createSelector } from 'reselect';

export const getPatientRosterSkipItems = ({
    patientRoster: { page, pageSize },
}) => (page - 1) * pageSize;

export const getPatientRosterCurrentPage = ({ patientRoster: { page } }) =>
    page;

export const getPatientRosterPageSize = ({ patientRoster: { pageSize } }) =>
    pageSize;

export const getPatientRosterLastPage = ({
    patientRoster: { pageSize, total },
}) => {
    const page = Math.ceil(total / pageSize);
    if (page > PATIENT_ROSTER_FIRST_PAGE) {
        return page;
    }
    return PATIENT_ROSTER_FIRST_PAGE;
};

export const patientRosterListSelector = state => state.patientRoster.data;

export const patientRosterFiltersSelector = state =>
    state.patientRoster.filters;

export const filterKeySelector = (_, { name }) => name;

export const patientRosterFilterValueSelector = createSelector(
    patientRosterFiltersSelector,
    filterKeySelector,
    (filters, name) => (filters[name] ? filters[name].value : undefined),
);

export const patientRosterStatusSelector = state => ({
    loading: state.patientRoster.loading,
    loaded: state.patientRoster.loaded,
    error: state.patientRoster.error,
});

export const patientRosterLoadingSelector = state => state.orderStatus.loading;

export const patientRosterShownPatientSelector = state =>
    state.patientRoster.data.length;

export const patientRosterTotalPatientsSelector = state =>
    state.patientRoster.total;

export const patientRosterSort = state => state.patientRoster.sort;
