import React from 'react';
import { Button, Comment, Form } from 'semantic-ui-react';
import Message from './Message';
import { connect } from 'react-redux';
import { chatMessagesSelector } from '../../store/chat/chatSelectors';
import { compose, withState, withHandlers } from 'recompose';
import { sendMessage, endChatByUser } from '../../store/chat/chatActions';

export const Chat = ({
    messages,
    message,
    sendMessage,
    handleMessageChange,
    endChat,
}) => {
    return (
        <>
            <Comment.Group className='chat whiteBG padded1rem'>
                {messages.map(message => {
                    return <Message key={message.id} {...message} />;
                })}
            </Comment.Group>
            <Form onSubmit={sendMessage}>
                <Form.TextArea
                    required
                    onChange={handleMessageChange}
                    value={message}
                />
                <div className='rowJustify'>
                    <Button type='submit' className='primary'>
                        Send
                    </Button>
                    <Button type='button' onClick={endChat}>
                        End chat
                    </Button>
                </div>
            </Form>
        </>
    );
};

const mapStateToProps = state => ({
    messages: chatMessagesSelector(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    sendMessage: () => {
        dispatch(sendMessage({ text: ownProps.message }));
        ownProps.setMessage('');
    },
    endChat: () => dispatch(endChatByUser()),
});

export default compose(
    withState('message', 'setMessage', ''),
    withHandlers({
        handleMessageChange: props => (e, { value }) => props.setMessage(value),
    }),
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
)(Chat);
