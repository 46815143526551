import React from 'react';
import ErrorMessage from './ErrorMessage';

export const withError = WrappedComponent => ({
    error,
    ErrorComponent,
    ...otherProps
}) =>
    error ? (
        <ErrorMessage error={error} ErrorComponent={ErrorComponent} />
    ) : (
        <WrappedComponent {...otherProps} />
    );

export default withError;
