import React from 'react';
import PropTypes from 'prop-types';
import withReportTable from '../../../common/report/withReportTable';
import Table from '../../../common/charts/table';

export const TableChart = withReportTable(props => <Table {...props} />);

TableChart.propTypes = {
    sfid: PropTypes.string.isRequired,
    defaultDateFilterC: PropTypes.string,
    visibleLinesC: PropTypes.number,
    defaultSortColumnLabelC: PropTypes.string,
    redirectFilterApiNameC: PropTypes.string,
};

export default TableChart;
